// SignatureModal.js
import React, { useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import SignaturePad from './SignaturePad';

const SignatureModal = ({ onSave }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const signaturePadRef = useRef(null);

    const handleSave = () => {
        if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
            const signatureData = signaturePadRef.current.getSignatureData();
            onSave(signatureData);
            onClose();
        }
    };

    const handleClear = () => {
        signaturePadRef.current && signaturePadRef.current.clear();
    };

    return (
        <>
            <Button width={"50%"} height={100} onClick={onOpen}>Sign</Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Sign Here</ModalHeader>
                    <ModalBody>
                        <SignaturePad ref={signaturePadRef} />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleClear}>
                            Clear
                        </Button>
                        <Button colorScheme="green" onClick={handleSave}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SignatureModal;
