import React, { useState, useEffect } from 'react';
import { fetchInventoryLevels } from '../../api';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Center, VStack, Heading } from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { useParams } from 'react-router-dom';

const InventoryLevels = () => {
    const { warehouseId } = useParams();
    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("Warehouse ID:", warehouseId); // Check if warehouseId is correctly extracted
        fetchInventoryData();
    }, [warehouseId]);

    const fetchInventoryData = async () => {
        try {
            const result = await fetchInventoryLevels(warehouseId);
            if (result.success) {
                setInventory(result.data);
            } else {
                setError(result.error);
            }
        } catch (err) {
            console.error('Error fetching inventory:', err);
            setError('An error occurred while fetching inventory data.');
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <Center mt="20">
                <Loading />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="7xl" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Inventory Levels" />

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Supply Item</Th>
                            <Th>Quantity on Hand</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {inventory.map((item, index) => (
                            <Tr key={item.supplyItemId._id}>
                                <Td>{index + 1}</Td>
                                <Td>{item.supplyItemId.name}</Td>
                                <Td>{item.quantity}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </VStack>
        </Box>
    );
};

export default InventoryLevels;
