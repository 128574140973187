// Initial state
const initialState = {
    info: null,      // Store basic user info like name, email, etc.
    permissions: [], // Store user permissions
};

// Reducer function
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_INFO':
            return {
                ...state,
                info: action.payload,
            };
        case 'SET_USER_PERMISSIONS':
            return {
                ...state,
                permissions: action.payload,
            };
        case 'LOGOUT_USER':
            return initialState;
        default:
            return state;
    }
};

export default userReducer;
