// components/ViewTemplateModal.js
import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, Box } from '@chakra-ui/react';

const ViewTemplateModal = ({ isOpen, onClose, template }) => {
    if (!template) return null;

    return (
        <Modal scrollBehavior='inside' isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>View Template: {template.template_name}</ModalHeader>
                <ModalBody>
                    <Text><strong>Subject:</strong> {template.subject}</Text>
                    <Box
                        p={4}
                        bg="gray.50"
                        border="1px solid"
                        borderColor="gray.300"
                        borderRadius="md"
                        dangerouslySetInnerHTML={{ __html: template.body }}
                    />

                    {/* <Text mt={4}><strong>Body:</strong></Text> */}
                    {/* <Text whiteSpace="pre-wrap">{template.body}</Text> */}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewTemplateModal;
