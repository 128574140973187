import React, { useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Box,
    Text,
    Table,
    Tbody,
    Tr,
    Td,
    Thead,
    HStack,
    Image,
    useDisclosure
} from '@chakra-ui/react';
import { Logo } from '../../Utils';
import PdfGeneratorPsc from '../../components/PdfGeneratorPsc';

const ExportPSCModal = ({ pscData, isOpen, onClose }) => {

    // Function to split array into chunks of 12 items
    const chunkArray = (arr, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    };

    // Split the pscData into chunks of 12 items
    const pscChunks = chunkArray(pscData, 14);

    // Create refs for each chunk outside of the loop
    const chunkRefs = useRef(pscChunks.map(() => React.createRef()));
    // const formattedString = inputString.replace(/, /g, '\n');
    function formatStringWithNewLines(inputString) {
        // Normalize spaces around commas
        const normalizedString = inputString.replace(/\s*,\s*/g, ', '); // Ensure only one space after each comma

        // Replace commas with newlines
        return normalizedString.split(', ').map((line, index) => (
            <span style={{
                color: "#1a375d",
                fontSize: 13
            }} key={index}>
                {line}
                <br />
            </span>
        ));
    }
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><PdfGeneratorPsc chunkRefs={chunkRefs} name="PSC Locations" title="Download PSC" /></ModalHeader>


                    {/* For each chunk of 12 items, render them in a new section */}
                    {pscChunks.map((pscChunk, chunkIndex) => (
                        <Box key={chunkIndex} ref={chunkRefs.current[chunkIndex]}>
                            <ModalBody>
                                <Box bg="blue.900" color="white" p={10}>
                                    <HStack>
                                        {/* Left side: Logo */}
                                        <Image src={Logo} alt="Logo" boxSize="150px" w={200} objectFit="contain" />
                                        {/* Right side: Text */}
                                        <Box textAlign="center" ml={"15%"}>
                                            <Text fontSize="4xl" fontWeight="bold">Patient Service Centers</Text>
                                            <Text>info@mydlslab.com | +1(888)-882-3330</Text>
                                        </Box>
                                    </HStack>
                                </Box>

                                <Table variant="simple" mt={4} borderWidth={1} borderColor="gray.200">
                                    <Thead bg="blue.900">
                                        <Tr>
                                            <Td w={"35%"} borderRight={"1px solid #ccc"} h={20} color="orange.300" fontWeight="bold" textAlign="center">Location</Td>
                                            <Td w={"30%"} borderRight={"1px solid #ccc"} color="orange.300" fontWeight="bold" textAlign="center">Address</Td>
                                            <Td w={"35%"} color="orange.300" fontWeight="bold" textAlign="center">HOURS OF OPERATION</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {pscChunk.map((psc, index) => (
                                            <Tr key={index}>
                                                <Td borderRight={"1px solid #ccc"} textAlign="left" fontWeight={'bold'} style={{ color: "#1a375d", fontSize: 13 }}>{psc.name}</Td>
                                                <Td borderRight={"1px solid #ccc"} textAlign="left" style={{ color: "#1a375d", fontSize: 13 }}>{psc.address}</Td>
                                                <Td textAlign="left">{formatStringWithNewLines(psc.hoursOfOperation)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </ModalBody>
                        </Box>
                    ))}

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    );
};

export default ExportPSCModal;
