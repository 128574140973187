import React, { useState, useEffect } from 'react';
import { fetchWarehouses } from '../../api';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, HStack, Heading, Center, Spinner, Alert, AlertIcon, AlertDescription, VStack } from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import { useNavigate } from 'react-router-dom';
import TitleHeader from '../../components/TitleHeader';
import { FaArrowDown, FaBox, FaIntercom, FaRegFrownOpen } from 'react-icons/fa';
import { AddIcon, CalendarIcon, ViewIcon } from '@chakra-ui/icons';

const Warehouses = () => {
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        // Fetch warehouses when the component mounts
        fetchWarehousesData();
    }, []);

    const fetchWarehousesData = async () => {
        try {
            const result = await fetchWarehouses();
            if (result.success) {
                setWarehouses(result.data);
            } else {
                setError(result.error);
            }
        } catch (err) {
            console.error('Error fetching warehouses:', err);
            setError('An error occurred while fetching warehouses.');
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <Center mt="20">
                <Loading />
            </Center>
        );
    }

    if (error) {
        return (
            <AccessDenied msg={error} />
        );
    }

    return (
        <Box maxW="7xl" mx="auto" p="8">
            <VStack spacing={6} align="stretch">

                <TitleHeader
                    title="Inventory Management"
                    buttonText={"View"}
                    onClick={() => { }}
                />

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>Location</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {warehouses.map((warehouse, index) => (
                            <Tr key={warehouse._id}>
                                <Td>{index + 1}</Td>
                                <Td>{warehouse.name}</Td>
                                <Td>{`${warehouse.address.city}, ${warehouse.address.state}`}</Td>
                                <Td>
                                    <HStack spacing={3}>
                                        <Button size={"sm"} colorScheme="blue" onClick={() => navigate(`/inventory/levels/${warehouse._id}`)}>
                                            <ViewIcon mr={2} /> Inventory
                                        </Button>
                                        <Button size={"sm"} colorScheme="green" onClick={() => navigate(`/inventory/receive/${warehouse._id}`)}>
                                            <AddIcon mr={2} /> Receive
                                        </Button>
                                        <Button size={"sm"} colorScheme="purple" onClick={() => navigate(`/inventory/transactions/${warehouse._id}`)}>
                                            <CalendarIcon mr={2} /> Transactions
                                        </Button>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </VStack>
        </Box>
    );
};

export default Warehouses;
