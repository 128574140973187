// CreateTemplate.js
import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Textarea, Button, VStack, Divider, Heading, useToast } from '@chakra-ui/react';
import { createEmailTemplate } from '../../api';
import TitleHeader from '../../components/TitleHeader';
import { alert } from '../../Utils';

const CreateTemplate = () => {
    const toast = useToast()
    const [templateData, setTemplateData] = useState({
        template_name: '',
        body: '',
        slug: ''
    });

    const handleCreateTemplate = async () => {

        try {
            console.log(templateData)
            const result = await createEmailTemplate(templateData);
            console.log(result)
            if (result.success) {
                toast({
                    title: "Email Template Created successfully.",
                    status: "success",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            } else {
                toast({
                    title: result.error,
                    status: "error",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error Creating email template.",
                status: "error",
                duration: 3000,
                position: 'top-right',
                isClosable: true,
            });
        }
    };

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="New Email Template" />

                <FormControl mb="4">
                    <FormLabel>Template Name</FormLabel>
                    <Input
                        value={templateData.template_name}
                        onChange={(e) => setTemplateData({ ...templateData, template_name: e.target.value })}
                        placeholder="Enter a name for this template"
                    />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Slug</FormLabel>
                    <Input
                        value={templateData.slug}
                        onChange={(e) => setTemplateData({ ...templateData, slug: e.target.value })}
                        placeholder="Slug for this email"
                    />
                </FormControl>

                <FormControl mb="4">
                    <FormLabel>Body (HTML Content)</FormLabel>
                    <Textarea
                        value={templateData.body}
                        onChange={(e) => setTemplateData({ ...templateData, body: e.target.value })}
                        placeholder="Enter HTML content for the email body"
                        rows={10}
                        resize="vertical"
                        bg="gray.100"
                        border="1px solid"
                        borderColor="gray.300"
                        fontFamily="monospace" // Optional: Adds a code-like feel to the textarea
                    />
                </FormControl>

                <Divider my={4} />

                <Heading size="md" mb={2}>Preview</Heading>
                <Box
                    p={4}
                    bg="gray.50"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="md"
                    dangerouslySetInnerHTML={{ __html: templateData.body }}
                />

                <Button colorScheme="blue" onClick={handleCreateTemplate}>
                    Create Template
                </Button>
            </VStack>
        </Box>
    );
};

export default CreateTemplate;
