import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    Select,
    Text,
    IconButton,
    Center,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    AlertDescription,
    HStack
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    fetchPrices,
    fetchPriceCategories,
    createPrice,
    updatePrice,
    deletePrice
} from '../../api';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { hasPermission } from '../../Utils';
import { useNavigate } from 'react-router-dom';
import { FaBookmark } from 'react-icons/fa';
import ExportModal from './ExportModal';
import Pagination from '../../components/Pagination';

const Prices = () => {
    const navigate = useNavigate()
    const [prices, setPrices] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPrice, setSelectedPrice] = useState({
        category: '',
        testCode: '',
        description: '',
        cashPrice: '',
        cptCodes: ''
    });

    const [error, setError] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const [exportModal, setExportModal] = useState(false)
    const itemsPerPage = 25

    // Fetch prices and categories
    useEffect(() => {
        fetchAllPrices(currentPage);
        fetchCategories();
    }, []);


    const fetchAllPrices = async (page = 1) => {
        const pricesResult = await fetchPrices(page, itemsPerPage);
        console.log(pricesResult)
        if (pricesResult.success) {
            setPrices(pricesResult.data.prices);
            setCurrentPage(pricesResult.data.currentPage);
            setTotalPages(pricesResult.data.totalPages);
        } else {
            setError(pricesResult.error);
        }

    }

    const fetchCategories = async () => {
        const categoryResult = await fetchPriceCategories();
        if (categoryResult.success) {
            setCategories(categoryResult.data)
        } else {
            setError(categoryResult.error);
        }
        setLoading(false)
    }

    // Pagination controls
    const handlePageChange = (newPage) => {
        fetchAllPrices(newPage);
    };


    // Handle Create
    const handleCreate = async (pricesData) => {
        if (!pricesData || !pricesData.category || !pricesData.testCode || !pricesData.description || !pricesData.cashPrice) {
            setErrorModal('All fields are requird');
            return;
        }

        try {
            const newPrice = await createPrice(pricesData);
            if (newPrice.success) {
                setPrices([newPrice.data, ...prices]);
                setSelectedPrice({
                    category: '',
                    testCode: '',
                    description: '',
                    cashPrice: '',
                    cptCodes: ''
                });
                handleCloseModal();
            } else {
                setErrorModal(newPrice.error);
            }
        } catch (error) {
            console.error('Error creating Price:', error);
        }
    };

    // Handle Update
    const handleUpdate = async (updatedData) => {
        if (!updatedData || !updatedData.category || !updatedData.testCode || !updatedData.description || !updatedData.cashPrice) {
            setErrorModal('All fields are requird');
            return;
        }
        if (!updatedData.category) {
            setErrorModal('Category must be selected');
            return;
        }

        try {
            const updatedPrice = await updatePrice(selectedPrice._id, updatedData);
            if (updatedPrice.success) {
                setPrices(prices.map(ins => ins._id === selectedPrice._id ? updatedPrice.data : ins));
                handleCloseModal();
            } else {
                setErrorModal(updatedPrice.error);
            }
        } catch (error) {
            console.error('Error updating Price:', error);
        }
    };

    // Handle Delete
    const handleDelete = async () => {
        try {
            const del = await deletePrice(selectedPrice._id);
            if (del.success) {
                setPrices(prices.filter(ins => ins._id !== selectedPrice._id));
                handleCloseModal();
            } else {
                setErrorModal(del.error);
            }
        } catch (error) {
            console.error('Error deleting price:', error);
        }
    };

    const handleCloseModal = () => {
        onEditClose();
        onCreateClose();
        onDeleteClose();
        setErrorModal(null);
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return (
            <AccessDenied msg={error} />
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Tests Prices "
                    buttonText={hasPermission("createPrice") && "Create New Price"}
                    onClick={hasPermission("createPrice") && onCreateOpen}

                    secondButton={hasPermission("viewPriceCategory")}
                    secondButtonText='Categories'
                    secondButtonOnClick={() => navigate('/test-prices-categories')}
                    secondButtonIcon={<FaBookmark />}

                    thirdButton={hasPermission("exportPrice")}
                    thirdButtonText='Export'
                    thirdButtonOnClick={() => setExportModal(true)}
                // thirdButtonIcon={<FaBookmark />}

                />
                <ExportModal
                    isOpen={exportModal}
                    onClose={() => setExportModal(false)}
                    insData={prices}
                    insCat={categories}

                />

                {prices.length > 0 ?
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Test Code</Th>
                                    <Th>Description</Th>
                                    <Th>CPT</Th>
                                    <Th>Category</Th>
                                    <Th>Price</Th>
                                    <Th>Last Update</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {prices.map((price, index) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={price._id}>
                                        <Td>{price.testCode}</Td>
                                        <Td>{price.description}</Td>
                                        <Td>{price.cptCodes}</Td>
                                        <Td>{price.category?.name}</Td>
                                        <Td>{price.cashPrice}</Td>
                                        <Td>{price.updatedAt ? new Date(price.updatedAt).toLocaleDateString() : "10/17/2024"}</Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                {hasPermission('updateInsurance') &&
                                                    <Button size="sm" onClick={() => {
                                                        // setSelectedPrice(price);
                                                        setSelectedPrice({ ...price, category: price.category?._id })
                                                        onEditOpen();
                                                    }} colorScheme="blue">
                                                        <EditIcon mr={2} /> Edit
                                                    </Button>
                                                }
                                                {hasPermission('deleteInsurance') &&
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedPrice(price);
                                                        onDeleteOpen();
                                                    }} colorScheme="red">
                                                        <DeleteIcon mr={2} /> Delete
                                                    </Button>
                                                }
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </>
                    :
                    <AccessDenied msg={"Nothing here yet!"} icon="info" />
                }

                {/* Create Modal */}
                <Modal isOpen={isCreateOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Price</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>

                            {categories.length == 0 &&
                                <Alert status='error'><AlertIcon /><AlertDescription>You can't create price without having categories</AlertDescription></Alert>
                            }

                            {categories.length > 0 &&
                                <>
                                    <FormControl id="category">
                                        <FormLabel>Category</FormLabel>
                                        <Select
                                            placeholder="Select Category"
                                            onChange={(e) => setSelectedPrice({ ...selectedPrice, category: e.target.value })}
                                        >
                                            {categories.map(category => (
                                                <option key={category._id} value={category._id}>{category.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl id="name" mt={4}>
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                            placeholder="Price Name"
                                            onChange={(e) => setSelectedPrice({ ...selectedPrice, name: e.target.value })}
                                        />
                                    </FormControl>
                                </>
                            }


                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={() => handleCreate({ ...selectedPrice, category: selectedPrice.category })}>
                                Create
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Modal */}
                <Modal isOpen={isEditOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Price</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <FormControl id="category">
                                <FormLabel>Category</FormLabel>
                                <Select
                                    placeholder="Select Category"
                                    value={selectedPrice?.category || ''}
                                    onChange={(e) => setSelectedPrice({ ...selectedPrice, category: e.target.value })}
                                >
                                    {categories.map(category => (
                                        <option key={category?._id} value={category?._id}>{category?.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="testCode" mt={4}>
                                <FormLabel>Test Code</FormLabel>
                                <Input
                                    defaultValue={selectedPrice?.testCode}
                                    onChange={(e) => setSelectedPrice({ ...selectedPrice, testCode: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="cptCodes" mt={4}>
                                <FormLabel>CPT Codes (comma-separated)</FormLabel>
                                <Input
                                    value={selectedPrice.cptCodes}
                                    onChange={(e) => setSelectedPrice({ ...selectedPrice, cptCodes: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="description" mt={4}>
                                <FormLabel>Description</FormLabel>
                                <Input
                                    defaultValue={selectedPrice?.description}
                                    onChange={(e) => setSelectedPrice({ ...selectedPrice, description: e.target.value })}
                                />
                            </FormControl>

                            <FormControl id="cashPrice" mt={4}>
                                <FormLabel>Cash Price</FormLabel>
                                <Input
                                    defaultValue={selectedPrice?.cashPrice}
                                    onChange={(e) => setSelectedPrice({ ...selectedPrice, cashPrice: e.target.value })}
                                />
                            </FormControl>

                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="yellow" mr={3} onClick={() => handleUpdate({
                                testCode: selectedPrice.testCode,
                                description: selectedPrice.description,
                                cashPrice: selectedPrice.cashPrice,
                                category: selectedPrice.category,
                                cptCodes: selectedPrice.cptCodes
                            })}>
                                Update
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal isOpen={isDeleteOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Price</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <Text>Are you sure you want to delete the price for "<strong>{selectedPrice?.description}</strong>"?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default Prices;
