import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, VStack, Alert, AlertIcon } from '@chakra-ui/react';
import { getUserDetails, updateUserAccount } from '../../api'; // Assumes these API functions exist
import TitleHeader from '../../components/TitleHeader'
import AccessDenied from '../../components/AccessDenied';
const UpdateAccountPage = () => {
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null)
    // Fetch current user details
    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        try {
            const result = await getUserDetails();
            if (result.success) {
                setUser(result.data);
            } else {
                setErrorDetails(result.error);
            }
        } catch (error) {
            setErrorDetails(error);
        }
    };

    // Handle form submission for updating user details
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const result = await updateUserAccount(user);
            if (result.success) {
                setSuccess('Account updated successfully');
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to update account');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box maxW="md" mx="auto" p="8" boxShadow="md" borderRadius="md">
            {errorDetails ?
                <AccessDenied msg={errorDetails} />
                :

                <>
                    <TitleHeader title={"Update your account"} />

                    {error && (
                        <Alert status="error" mb="4">
                            <AlertIcon />
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert status="success" mb="4">
                            <AlertIcon />
                            {success}
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit}>
                        <VStack mt={4} spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    value={user.firstName}
                                    onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    value={user.lastName}
                                    onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={user.email}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    placeholder="Leave blank to keep unchanged"
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="blue" width="full" isLoading={loading}>
                                Update Account
                            </Button>
                        </VStack>
                    </form>
                </>
            }
        </Box>
    );
};

export default UpdateAccountPage;
