import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    Select,
    Text,
    IconButton,
    Center,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    AlertDescription,
    HStack
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    fetchInsurances,
    createInsurance,
    updateInsurance,
    deleteInsurance,
    fetchInsuranceCategories
} from '../../api';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { hasPermission } from '../../Utils';
import { useNavigate } from 'react-router-dom';
import { FaBookmark } from 'react-icons/fa';
import ExportModal from './ExportModal';
import Pagination from '../../components/Pagination';

const Insurances = () => {
    const navigate = useNavigate()
    const [insurances, setInsurances] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInsurance, setSelectedInsurance] = useState(null);
    const [error, setError] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const [exportModal, setExportModal] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 25


    // Fetch insurances and categories
    useEffect(() => {
        fetchInsurancesData(currentPage)
        fetchInsuranceCategoriesData();
    }, []);


    const fetchInsurancesData = async (page = 1) => {
        try {
            const insuranceResult = await fetchInsurances(page, itemsPerPage);

            if (insuranceResult.success) {
                setInsurances(insuranceResult.data.insurances);
                setCurrentPage(insuranceResult.data.currentPage);
                setTotalPages(insuranceResult.data.totalPages);
            } else {
                setError(insuranceResult.error);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }
    // Pagination controls
    const handlePageChange = (newPage) => {
        fetchInsurancesData(newPage);
    };


    const fetchInsuranceCategoriesData = async () => {
        try {
            const categoryResult = await fetchInsuranceCategories();

            if (categoryResult.success) {
                setCategories(categoryResult.data);
            } else {
                setError(categoryResult.error);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setLoading(false);
    };



    // Handle Create
    const handleCreate = async (insuranceData) => {
        if (!insuranceData || !insuranceData.name || insuranceData.name.trim() === '') {
            setErrorModal('Insurance name cannot be empty');
            return;
        }
        if (!insuranceData?.category) {
            setErrorModal('Category must be selected');
            return;
        }

        try {
            const newInsurance = await createInsurance(insuranceData);
            if (newInsurance.success) {
                setInsurances([newInsurance.data, ...insurances]);
                handleCloseModal();
            } else {
                setErrorModal(newInsurance.error);
            }
        } catch (error) {
            console.error('Error creating insurance:', error);
        }
    };

    // Handle Update
    const handleUpdate = async (updatedData) => {
        if (!updatedData || !updatedData.name || updatedData.name.trim() === '') {
            setErrorModal('Insurance name cannot be empty');
            return;
        }
        if (!updatedData.category) {
            setErrorModal('Category must be selected');
            return;
        }

        try {
            const updatedInsurance = await updateInsurance(selectedInsurance._id, updatedData);
            if (updatedInsurance.success) {
                setInsurances(insurances.map(ins => ins._id === selectedInsurance._id ? updatedInsurance.data : ins));
                handleCloseModal();
            } else {
                setErrorModal(updatedInsurance.error);
            }
        } catch (error) {
            console.error('Error updating insurance:', error);
        }
    };

    // Handle Delete
    const handleDelete = async () => {
        try {
            const del = await deleteInsurance(selectedInsurance._id);
            if (del.success) {
                setInsurances(insurances.filter(ins => ins._id !== selectedInsurance._id));
                handleCloseModal();
            } else {
                setErrorModal(del.error);
            }
        } catch (error) {
            console.error('Error deleting insurance:', error);
        }
    };

    const handleCloseModal = () => {
        onEditClose();
        onCreateClose();
        onDeleteClose();
        setErrorModal(null);
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return (
            <AccessDenied msg={error} />
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Insurances"
                    buttonText={hasPermission("createInsurance") && "Create New Insurance"}
                    onClick={hasPermission("createInsurance") && onCreateOpen}

                    secondButton={hasPermission("viewInsuranceCategory")}
                    secondButtonText='Categories'
                    secondButtonOnClick={() => navigate('/insurance-categories')}
                    secondButtonIcon={<FaBookmark />}

                    thirdButton={hasPermission("exportInsurance")}
                    thirdButtonText='Export'
                    thirdButtonOnClick={() => setExportModal(true)}
                // thirdButtonIcon={<FaBookmark />}

                />
                <ExportModal
                    isOpen={exportModal}
                    onClose={() => setExportModal(false)}
                    insData={insurances}
                    insCat={categories}

                />

                {insurances.length > 0 ?
                    <>


                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    {/* <Th>#</Th> */}
                                    <Th>Name</Th>
                                    <Th>Category</Th>
                                    <Th>Created At</Th>
                                    <Th>Last Update</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {insurances.map((insurance, index) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={insurance._id}>
                                        {/* <Td>{index + 1}</Td> */}
                                        <Td>{insurance.name}</Td>
                                        <Td>{insurance.category?.name || 'N/A'}</Td>
                                        <Td>{new Date(insurance.createdAt).toLocaleDateString()}</Td>
                                        <Td>{insurance.updatedAt ? new Date(insurance.updatedAt).toLocaleDateString() : new Date(insurance.createdAt).toLocaleDateString()}</Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                {hasPermission('updateInsurance') &&
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedInsurance({ ...insurance, category: insurance.category._id });
                                                        onEditOpen();
                                                    }} colorScheme="blue">
                                                        <EditIcon mr={2} /> Edit
                                                    </Button>
                                                }
                                                {hasPermission('deleteInsurance') &&
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedInsurance(insurance);
                                                        onDeleteOpen();
                                                    }} colorScheme="red">
                                                        <DeleteIcon mr={2} /> Delete
                                                    </Button>
                                                }
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </>
                    :
                    <AccessDenied msg={"Nothing here yet!"} icon="info" />
                }

                {/* Create Modal */}
                <Modal isOpen={isCreateOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Insurance</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>

                            {categories.length == 0 &&
                                <Alert status='error'><AlertIcon /><AlertDescription>You can't create insurance without having categories</AlertDescription></Alert>
                            }

                            {categories.length > 0 &&
                                <>
                                    <FormControl id="category">
                                        <FormLabel>Category</FormLabel>
                                        <Select
                                            placeholder="Select Category"
                                            onChange={(e) => setSelectedInsurance({ ...selectedInsurance, category: e.target.value })}
                                        >
                                            {categories.map(category => (
                                                <option key={category._id} value={category._id}>{category.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl id="name" mt={4}>
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                            placeholder="Insurance Name"
                                            onChange={(e) => setSelectedInsurance({ ...selectedInsurance, name: e.target.value })}
                                        />
                                    </FormControl>
                                </>
                            }


                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={() => handleCreate({ ...selectedInsurance, category: selectedInsurance.category })}>
                                Create
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Modal */}
                <Modal isOpen={isEditOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Insurance</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <FormControl id="category">
                                <FormLabel>Category</FormLabel>
                                <Select
                                    placeholder="Select Category"
                                    value={selectedInsurance?.category || ''}
                                    onChange={(e) => setSelectedInsurance({ ...selectedInsurance, category: e.target.value })}
                                >
                                    {categories.map(category => (
                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl id="name" mt={4}>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    defaultValue={selectedInsurance?.name}
                                    onChange={(e) => setSelectedInsurance({ ...selectedInsurance, name: e.target.value })}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="yellow" mr={3} onClick={() => handleUpdate({ name: selectedInsurance.name, category: selectedInsurance.category })}>
                                Update
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal isOpen={isDeleteOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Insurance</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <Text>Are you sure you want to delete the insurance "{selectedInsurance?.name}"?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default Insurances;
