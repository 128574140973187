import { Flex, Heading, Button, Divider, Box } from '@chakra-ui/react';
import { FaCheck, FaPlusSquare, FaShare } from "react-icons/fa";

const TitleHeader = ({ title, onClick, buttonText, icon = <FaPlusSquare />, buttonColor = 'blue', secondButton = false, secondButtonOnClick = null, secondButtonText = '', secondButtonIcon = <FaCheck />, thirdButton = false, thirdButtonOnClick = null, thirdButtonText = '', thirdButtonIcon = <FaShare />, }) => {
    return (
        <>
            <Flex justifyContent="space-between" alignItems="center" mb="6">
                <Heading size="lg" fontWeight="semiBold">{title}</Heading>
                <Box>
                    {onClick &&
                        <Button onClick={onClick} size="sm" leftIcon={icon} colorScheme={buttonColor} variant="solid">
                            {buttonText}
                        </Button>
                    }
                    {secondButton &&
                        <Button ml={4} size="sm" onClick={secondButtonOnClick} leftIcon={secondButtonIcon} colorScheme="purple">
                            {secondButtonText}
                        </Button>
                    }

                    {thirdButton &&
                        <Button ml={4} size="sm" onClick={thirdButtonOnClick} leftIcon={thirdButtonIcon} colorScheme="yellow" color={'white'}>
                            {thirdButtonText}
                        </Button>
                    }
                </Box>
            </Flex >
            <Divider />
        </>
    );
};

export default TitleHeader;
