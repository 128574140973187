import React, { useState, useEffect } from 'react';
import { getAllRoles, updateRole, deleteRole, createRole } from '../../api';  // Import the API functions
import { Grid, GridItem, Box, Button, Checkbox, Heading, HStack, Table, Tbody, Td, Th, Thead, Tr, VStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, Textarea, Text, Alert, AlertIcon, AlertDescription, Center } from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { alert, hasPermission, Permissions } from '../../Utils';
import AccessDenied from '../../components/AccessDenied';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import PermissionsDisplay from '../../components/PermissionsDisplay';
import PopoverId from '../../components/PopoverId';
import PermissionsTable from './PermissionsTable';

const RolesManagement = () => {
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);  // Role for editing or deleting
    const [newRole, setNewRole] = useState({ name: '', description: '', permissions: [] });  // New role data
    const [errorModal, setErrorModal] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: closeCreateModal } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: closeEditModal } = useDisclosure();

    const onCreateClose = () => {
        setErrorModal(null);  // Clear error modal on close
        closeCreateModal();    // Call the original onClose handler
    };

    const onEditClose = () => {
        setErrorModal(null);  // Clear error modal on close
        closeEditModal();     // Call the original onClose handler
    };

    // Fetch roles when the component mounts
    useEffect(() => {
        fetchRoles();
    }, []);


    const fetchRoles = async () => {
        try {
            const result = await getAllRoles();
            if (result.success) {
                setRoles(result.data);
            } else {
                setError(result.error)
            }
        } catch (error) {
            setError(error)
        }
        setLoading(false)
    };

    // Handle role creation with validation
    const handleSubmitCreate = async () => {
        setErrorModal(null);
        try {
            // Validation: Check if name, description, and permissions are provided
            if (!newRole.name || !newRole.description) {
                setErrorModal("Name and description are required.");
                return;
            }

            // if (newRole.permissions.length === 0) {
            //     setErrorModal("At least one permission must be selected.");
            //     return;
            // }

            newRole.permissions = ['updateAccount']

            // Create the role
            const createdRole = await createRole({ ...newRole });
            if (createdRole.success) {
                alert('Role Created', "success", "Role created successfully", "Okay !");
                fetchRoles();  // Refresh the roles list
                onCreateClose();
            } else {
                setErrorModal(createdRole.error);
            }
        } catch (error) {
            console.error('Error creating role:', error);
        }
    };

    // Handle role update with validation
    const handleSubmitEdit = async () => {
        setErrorModal(null);
        try {
            // Validation: Check if name, description, and permissions are provided
            if (!selectedRole.name || !selectedRole.description) {
                setErrorModal("Name and description are required.");
                return;
            }

            if (selectedRole.permissions.length === 0) {
                setErrorModal("At least one permission must be selected.");
                return;
            }

            // Update the role
            const updatedRole = await updateRole(selectedRole._id, { ...selectedRole });
            if (updatedRole.success) {
                fetchRoles();  // Refresh the roles list
                onEditClose();
            } else {
                setErrorModal(updatedRole.error);
            }
        } catch (error) {
            console.error('Error updating role:', error);
        }
    };

    // Handle role deletion
    const handleSubmitDelete = async () => {
        try {
            await deleteRole(selectedRole._id);
            fetchRoles();  // Refresh the roles list
            onDeleteClose();
        } catch (error) {
            console.error('Error deleting role:', error);
        }
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {!error ?
                    <>
                        <TitleHeader title={"Roles Management"} buttonText={hasPermission('createRole') ? "New Role" : null} onClick={hasPermission('createRole') ? () => {
                            setNewRole({ name: '', description: '', permissions: '' });
                            onCreateOpen();
                        } : null} />

                        {/* Roles Table */}
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>Name</Th>
                                    <Th>Description</Th>
                                    <Th>Permissions</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {roles.map((role, index) => (
                                    <Tr key={role._id} _hover={{ bg: "gray.100" }}>
                                        <Td><PopoverId place='Roles Management' index={index} content={role._id} /></Td>
                                        <Td>{role.name}</Td>
                                        <Td>{role.description}</Td>
                                        <Td>
                                            <PermissionsDisplay roleName={role.name} permissions={role.permissions} />
                                        </Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                {hasPermission('updateRole') &&
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedRole(role);
                                                        onEditOpen();
                                                    }} colorScheme="green">
                                                        <EditIcon mr={2} /> Edit
                                                    </Button>
                                                }
                                                {hasPermission('deleteRole') &&
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedRole(role);
                                                        onDeleteOpen();
                                                    }} colorScheme="red">
                                                        <CloseIcon mr={2} /> Delete
                                                    </Button>
                                                }
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        {/* Create Role Modal */}
                        {hasPermission('createRole') &&
                            <Modal isOpen={isCreateOpen} scrollBehavior={'inside'} size={"xla"} onClose={onCreateClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Create Role</ModalHeader>
                                    <ModalCloseButton />
                                    {errorModal &&
                                        <Alert status='error'>
                                            <AlertIcon />
                                            <AlertDescription>{errorModal}</AlertDescription>
                                        </Alert>
                                    }
                                    <ModalBody>
                                        <FormControl mt={2}>
                                            <Text>Name</Text>
                                            <Input value={newRole.name} onChange={(e) => setNewRole({ ...newRole, name: e.target.value })} />
                                        </FormControl>
                                        <FormControl mt={5}>
                                            <Text>Description</Text>
                                            <Textarea value={newRole.description} onChange={(e) => setNewRole({ ...newRole, description: e.target.value })} />
                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="blue" onClick={handleSubmitCreate}>Create</Button>
                                        <Button ml={3} onClick={onCreateClose}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        }

                        {/* Edit Role Modal */}
                        {hasPermission('updateRole') &&
                            <Modal isOpen={isEditOpen} size={'xla'} scrollBehavior='inside' onClose={onEditClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Edit Role</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <FormControl>
                                            <FormLabel>Name</FormLabel>
                                            <Input value={selectedRole?.name} onChange={(e) => setSelectedRole({ ...selectedRole, name: e.target.value })} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea value={selectedRole?.description} onChange={(e) => setSelectedRole({ ...selectedRole, description: e.target.value })} />
                                        </FormControl>
                                        <FormControl>

                                            <PermissionsTable
                                                selectedRole={selectedRole}
                                                setSelectedRole={setSelectedRole}
                                            />


                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="green" onClick={handleSubmitEdit}>Save</Button>
                                        <Button ml={3} onClick={onEditClose}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        }

                        {/* Delete Role Confirmation Modal */}
                        {hasPermission('deleteRole') &&
                            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Delete Role</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        Are you sure you want to delete the role "{selectedRole?.name}"?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme="red" onClick={handleSubmitDelete}>Yes, Delete</Button>
                                        <Button ml={3} onClick={onDeleteClose}>No</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        }
                    </>
                    :
                    <AccessDenied msg={error} />
                }
            </VStack>
        </Box>
    );
};

export default RolesManagement;
