import { FormControl, Box, VStack, Heading, Text, Center, HStack, Table, Tbody, Tr, Td, Checkbox, Thead, Th, Image } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { getBusinessDetails } from '../../api'; // Assuming fetch is in api.js
import Loading from '../../components/Loading/Loading';
import PdfGenerator from '../../components/PdfGenerator';
import { Logo } from '../../Utils';
import SignatureGenerator from '../../components/SignatureGenerator';


const BusinessDetailsPage = () => {
    const { id } = useParams(); // Get the business ID from the URL
    const [business, setBusiness] = useState(null); // State to store business details
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // State for error handling


    // Fetch business details using the API call from api.js
    useEffect(() => {
        fetchBusiness();
    }, [id]);


    const fetchBusiness = async () => {
        try {
            const response = await getBusinessDetails(id);

            // Remove _id from daysOfOperation, molecularTests, routineTests, and supplies
            const { daysOfOperation, molecularTests, routineTests, supplies, ...rest } = response.data;
            delete daysOfOperation._id;
            delete molecularTests._id;
            delete routineTests._id;
            delete supplies._id;

            setBusiness({
                ...rest,
                daysOfOperation,
                molecularTests,
                routineTests,
                supplies
            });
            setLoading(false);
        } catch (err) {
            console.error('Error fetching business:', err);
            setError('Error fetching business details');
            setLoading(false);
        }
    };


    const clientDetailsRef = useRef(null);
    const testsAndBilling = useRef(null);
    const tos = useRef(null);
    const phi = useRef(null);
    const psdr = useRef(null);
    const addInfo = useRef(null);


    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return <Box p="8" color="red.500">{error}</Box>;
    }

    return (


        <>
            {/* 
            <Button colorScheme="blue" onClick={generatePdf}>
                Print
            </Button> */}
            <Box className='print-content' maxW="full" mx="auto" p="8" align="stretch">
                <VStack mb={6} spacing={6} align="stretch">
                    {/* Client Details */}

                    <HStack mb={4} spacing={5} >
                        <PdfGenerator
                            clientDetailsRef={clientDetailsRef}
                            testsAndBilling={testsAndBilling}
                            tos={tos}
                            phi={phi}
                            psdr={psdr}
                            addInfo={addInfo}
                            name={"Internal Copy"}
                        />
                        <PdfGenerator
                            clientDetailsRef={clientDetailsRef}
                            testsAndBilling={testsAndBilling}
                            tos={tos}
                            phi={phi}
                            psdr={psdr}
                            addInfo={null}
                            name={"Client Copy"}
                        />
                    </HStack>


                    <HStack mb={4} spacing={5} >
                        <SignatureGenerator signatureBase64={business.tos.tosSignature} name={"Client Signature (TOS)"} />
                        <SignatureGenerator signatureBase64={business.phi.phiSignature} name={"Ordering Physician Signature (PHI)"} />
                        <SignatureGenerator signatureBase64={business.psdr.psdrSignature} name={"Physicians Signature and Date Requirements Signature"} />
                    </HStack>

                    {/* Ordering Physician Signature */}


                    <Box ref={clientDetailsRef}>

                        <HStack style={{ borderBottom: "9px solid #f4b31b" }} pb={4} mb={6}>
                            <img width={150} src={Logo} />
                            <Heading ml="15%" color={'#253853'}>Account Details for {business?.clientDetails?.clinicName}</Heading>
                            <br />
                        </HStack>


                        <VStack spacing={4} align="stretch">
                            <>
                                <HStack mb={4}>
                                    <Text><strong>Client Name: </strong> {business?.clientDetails?.clinicName}</Text>
                                    <Text ml={'20%'}><strong>Client Start Date: </strong> {new Date(business.clientStartDate).toLocaleDateString()} </Text>
                                </HStack>


                                {/* Address and City */}
                                <HStack mb={4} spacing={10} flexDirection={['column', 'row']}>
                                    <Text><strong>Address: </strong>{business.clientDetails.address}</Text>
                                    <Text><strong>City: </strong>{business.clientDetails.city}</Text>
                                    <Text><strong>State: </strong>{business.clientDetails.state}</Text>
                                    <Text><strong>Zip: </strong>{business.clientDetails.zip}</Text>
                                </HStack>

                                {/* Phone, Fax, Email */}
                                <HStack mb={6} spacing={4} flexDirection={['column', 'row']}>
                                    <Text><strong>Phone:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.phone} </Text>
                                    </FormControl>
                                    <Text><strong>Fax:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.fax} </Text>
                                    </FormControl>
                                    <Text><strong>Email:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.email} </Text>
                                    </FormControl>
                                </HStack>


                            </>
                        </VStack>

                        <hr />

                        {/* Providers */}
                        <Box mb={6}>
                            <Heading size="md" mt="6" mb={6}>Providers</Heading>
                            <VStack spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                {business?.providers[0]?.name !== '' ? (

                                    <Table variant="simple">
                                        <Tbody>
                                            {business.providers.map((provider, index) => (
                                                <Tr key={index}>
                                                    <Td><strong>Name:</strong> {provider.name}</Td>
                                                    <Td><strong>License:</strong> {provider.license}</Td>
                                                    <Td><strong>NPI:</strong> {provider.npi}</Td>
                                                </Tr>

                                            ))}
                                        </Tbody>
                                    </Table>
                                ) : <Text>No providers available</Text>}
                            </VStack>
                        </Box>
                        <hr />
                        {/* Contacts */}

                        {/* Office Contacts Section */}
                        <Box mb={6}>
                            <Heading size="md" mt="6">Office Contacts</Heading>
                            <VStack spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                <>
                                    {business?.officeContacts[0]?.name !== '' ? (
                                        <>

                                            <Table variant="simple">
                                                <Tbody>
                                                    {business.officeContacts.map((contact, index) => (
                                                        <Tr key={index}>
                                                            <Td><strong>Name:</strong> {contact.name}</Td>
                                                            <Td><strong>Title:</strong> {contact.title}</Td>
                                                            <Td><strong>Phone:</strong> {contact.phone}</Td>
                                                        </Tr>

                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </>
                                    ) : <Text>No contacts available</Text>}
                                </>
                            </VStack>
                        </Box>

                        <hr />
                        {/* Days of Operation */}
                        <VStack mb={6} mt={6} spacing={6} align="stretch">
                            <Heading size="md">Days of Operation</Heading>
                            <Table variant="simple" mt={4}>
                                <Tbody>
                                    <Tr>
                                        {Object.keys(business.daysOfOperation).map((day) => (
                                            <Td key={day} textAlign="center"><strong>{day}</strong></Td>
                                        ))}
                                    </Tr>
                                    <Tr>
                                        {Object.keys(business.daysOfOperation).map((day) => (
                                            <Td key={day} textAlign="center">
                                                {business.daysOfOperation[day].from
                                                    ? `${business.daysOfOperation[day].from} - ${business.daysOfOperation[day].to}`
                                                    : 'Closed'}
                                            </Td>
                                        ))}
                                    </Tr>
                                </Tbody>
                            </Table>
                        </VStack>


                        <hr />
                        {/* Tests and Supplies */}
                        <VStack mb={6} spacing={6} align="stretch">
                            <Heading size="md" mt="6">Tests and Supplies</Heading>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Molecular Tests</Th>
                                        <Th>Routine Tests</Th>
                                        <Th>Supplies</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {Array.from({
                                        length: Math.max(
                                            Object.entries(business.molecularTests || {}).filter(([_, checked]) => checked).length,
                                            Object.entries(business.routineTests || {}).filter(([_, checked]) => checked).length,
                                            Object.entries(business.supplies || {}).filter(([_, checked]) => checked).length
                                        )
                                    }).map((_, i) => {
                                        const molecularTest = Object.entries(business.molecularTests || {}).filter(([_, checked]) => checked)[i];
                                        const routineTest = Object.entries(business.routineTests || {}).filter(([_, checked]) => checked)[i];
                                        const supply = Object.entries(business.supplies || {}).filter(([_, checked]) => checked)[i];

                                        return (
                                            <Tr key={i}>
                                                <Td>
                                                    {molecularTest && (
                                                        <>
                                                            <Checkbox isChecked={molecularTest[1]} isReadOnly mr="2" />
                                                            {molecularTest[0].replace(/([A-Z])/g, ' $1')}
                                                        </>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {routineTest && (
                                                        <>
                                                            <Checkbox isChecked={routineTest[1]} isReadOnly mr="2" />
                                                            {routineTest[0].replace(/([A-Z])/g, ' $1')}
                                                        </>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {supply && (
                                                        <>
                                                            <Checkbox isChecked={supply[1]} isReadOnly mr="2" />
                                                            {supply[0].replace(/([A-Z])/g, ' $1')}
                                                        </>
                                                    )}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>

                            </Table>
                        </VStack>


                        {/* Billing Preference */}
                        <VStack mb={6} spacing={6} align="stretch">
                            <Heading size="md" mt="6">Billing Preference</Heading>
                            <HStack>
                                <Text>if insurance / cash is not provided at the time of service, the party to bill for the service is </Text>
                                <Text fontWeight={'semibold'}>
                                    {business.billingPreference.billClient ? 'The client' : 'The patient'}
                                </Text>
                            </HStack>
                        </VStack>


                        {/* Signature */}
                        {/* <hr />
                        <VStack spacing={4} align="stretch">
                            <Heading size="md" mt="6">Client Signature</Heading>
                            <Box p="4" width="full">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="md"
                                    overflow="hidden"
                                    width="auto"
                                    height="auto"
                                    maxW="300px" // Limit the width to prevent stretching
                                    maxH="200px" // Set a max height to keep the signature proportional
                                >
                                    <Image
                                        src={business.signature}
                                        alt="Client Signature"
                                        maxW="100%" // Scale the image within the container
                                        height="auto" // Ensure the height adjusts automatically to maintain aspect ratio
                                        objectFit="contain" // Ensure the image fits without being stretched
                                    />
                                </Box>
                            </Box>
                        </VStack> */}
                    </Box>






                    {/* TOS Section */}
                    <br /><hr /><br />
                    {/* Terms of Service and Signature Display */}
                    <VStack ref={tos} spacing={4} align="stretch">
                        <>
                            <Heading size="lg" textAlign="center">Terms of Service</Heading>


                            <Text>
                                <Heading size="md">LABORATORY REQUISITIONS</Heading>

                                All ordering physicians must provide complete information, including the NPI # the first time a specimen is submitted. Once that physician is added to our database, the physician’s name, office location and Account # are sufficient.
                            </Text>

                            <Text>

                                <Heading size="md">BILLING INFORMATION</Heading>

                                Patients demographics are required on the first day of service. Once in our system, billing information (insurance, ID, Address) is saved for future days of service.
                            </Text>


                            <Text>

                                <Heading size="md">DIAGNOSIS</Heading>

                                All laboratory test requisitions MUST have an ICD-10 (diagnosis code) especially with Medicare patients according to their “medical necessity” (see attached grid) and clinical (blood).

                            </Text>

                            <Text>

                                <Heading size="md">CASH PATIENTS</Heading>

                                As a courtesy, this office provides insurance billing services at no extra charge. However, client/or patients are responsible for payment of all services rendered and we will look to you for payment if insurance information or patient address was not provided by client in a timely manner.

                            </Text>

                            <Text>

                                <Heading size="md">INSURANCE BILLING  </Heading>

                                (PPO, HMO, ETC): All billing will be done by DIAGNOSTIC LABORATORY SCIENCE. However, the client and /or patients are personally responsible for all applicable deductibles, co-insurance co-payments and services denied as not a covered benefit by your health plan carrier. All information required to bill patient’s insurance must be received in time to meet the 60- day timely filling deadline set by most insurances. Client and or patient will be responsible for information not received on time.
                            </Text>






                            <Text>

                                <Heading size="md"> WORKERS COMPENSATION</Heading>

                                When billing claims to workers compensation, insurance name, claim number, date of injury and adjuster’s information must be submitted with requisition form. The patients private health insurance is not billed for services that are covered by workers’ compensation.
                            </Text>


                            <Text>
                                <Heading size="md">ACKNOWLEDGMENT</Heading>

                                I understand that DIAGNOSTIC LABORATORY SCIENCE will submit patient’s claims to the patients insurance company as a courtesy at no extra cost but timely submission of patient’s insurance and/or patients address is the clients responsibility.
                            </Text>















                            <Text mt="4">
                                By signing below, I agree and acknowledge the Terms of Services for Diagnostic Laboratory Science
                            </Text>
                            <HStack spacing={4} align="center">
                                <Box p="4" width="full">
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        borderRadius="md"
                                        overflow="hidden"
                                        width="auto"
                                        height="auto"
                                        maxW="300px" // Limit the width to prevent stretching
                                        maxH="200px" // Set a max height to keep the signature proportional
                                    >
                                        {business?.tos?.tosSignature ?
                                            <Image
                                                src={business.tos.tosSignature}
                                                alt="Client Signature"
                                                maxW="100%" // Scale the image within the container
                                                height="auto" // Ensure the height adjusts automatically to maintain aspect ratio
                                                objectFit="contain" // Ensure the image fits without being stretched
                                            />
                                            :
                                            <Text>No Signature</Text>
                                        }
                                    </Box>
                                </Box>

                            </HStack>
                            <HStack spacing={100} >
                                <VStack>
                                    <Text fontSize='md' fontWeight={'bold'}>Ordering Provider / Clinician</Text>
                                    <Text>{business.tos.orderingProvider}</Text>
                                </VStack>

                                <VStack>
                                    <Text fontSize='md' fontWeight={'bold'}>Date</Text>
                                    <Text> {new Date(business.tos.orderingProviderDate).toLocaleDateString()}</Text>
                                </VStack>
                            </HStack>
                            <VStack>
                                <Text fontSize='md' fontWeight={'bold'}>

                                </Text>
                            </VStack>
                        </>
                    </VStack>



                    <br /><hr /><br />
                    {/* PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENTPROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT */}
                    <VStack ref={phi} spacing={4} mb={3} align="stretch">
                        <>


                            <Heading size="lg" textAlign="center">PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT</Heading>

                            <Text>
                                This Privacy Agreement ("Agreement") is effective upon signing this Agreement and is entered by and between
                                <Text as="span" fontWeight="bold"> {business.clientDetails.clinicName}</Text> ("Covered Entity")
                                and  <Text as="span" fontWeight="bold">Diagnostic Laboratory Science</Text> (the "Business Associate").
                            </Text>

                            <Heading size="md">1. Term</Heading>
                            <Text>
                                This Agreement shall remain in effect for the duration of this Agreement and shall apply to all the Services
                                and/or Supplies delivered by the Business Associate pursuant to this Agreement.
                            </Text>

                            <Heading size="md">2. HIPAA Assurances</Heading>
                            <Text>
                                In the event Business Associate creates, receives, maintains, or otherwise is exposed to personally identifiable
                                or aggregate patient or other medical information defined as Protected Health Information ("PHI") in the Health
                                Insurance Portability and Accountability Act of 1996 or its relevant regulations ("HIPAA") and otherwise meets
                                the definition of Business Associate as defined in the HIPAA Privacy Standards (45 CFR Parts 160 and 164),
                                Business Associate shall:
                            </Text>

                            <VStack align="start" spacing={2} pl="4">
                                <Text as="p">(a) Recognize that HITECH (the Health Information Technology for Economic and Clinical Health Act of 2009) and the regulations thereunder apply to a business associate;</Text>
                                <Text as="p">(b) Not use or further disclose the PHI, except as permitted by law;</Text>
                                <Text as="p">(c) Not use or further disclose the PHI in a manner that had <Text as="span" fontWeight="bold">{business.clientDetails.clinicName}</Text> done so, would violate the requirements of HIPAA;</Text>
                                <Text as="p">(d) Use appropriate safeguards to protect the confidentiality, integrity, and availability of PHI;</Text>
                                <Text as="p">(e) Comply with each applicable requirement of 45 C.F.R. Part 162 if the Business Associate conducts Standard Transactions for or on behalf of the Covered Entity;</Text>
                                <Text as="p">(f) Report promptly to <Text as="span" fontWeight="bold">{business.clientDetails.clinicName}</Text> any security incident or other use or disclosure of PHI;</Text>
                                <Text as="p">(g) Ensure that subcontractors or agents who receive PHI agree to the same restrictions;</Text>
                                <Text as="p">(h) Make available PHI in accordance with the individual’s rights as required under HIPAA regulations;</Text>
                                <Text as="p">(i) Account for PHI disclosures for up to the past six (6) years;</Text>
                                <Text as="p">(j) Make internal practices available to the U.S. Secretary of Health and Human Services;</Text>
                                <Text as="p">(k) Incorporate any amendments or corrections to PHI when notified by the Covered Entity.</Text>
                            </VStack>

                            <Heading size="md">3. Termination Upon Breach of Provisions</Heading>
                            <Text>
                                Covered Entity may immediately terminate this Agreement if Business Associate breaches any term. Alternatively,
                                the Covered Entity may give written notice and allow five (5) business days to cure such breach.
                            </Text>

                            <Heading size="md">4. Return or Destruction of Protected Health Information upon Termination</Heading>
                            <Text>
                                Upon termination, Business Associate shall return or destroy all PHI received. If infeasible, the terms of this Agreement
                                shall survive.
                            </Text>

                            <Heading size="md">5. No Third-Party Beneficiaries</Heading>
                            <Text>
                                The terms of this Agreement shall apply only to the parties and are not for the benefit of any third-party beneficiaries.
                            </Text>

                            <Heading size="md">6. De-Identified Data</Heading>
                            <Text>
                                Business Associate and its subcontractors may disclose non-personally identifiable information, provided the information
                                cannot be identified.
                            </Text>

                            <Heading size="md">7. Amendment</Heading>
                            <Text>
                                Business Associate and Covered Entity agree to amend this Agreement to comply with HIPAA or other relevant state
                                or federal laws.
                            </Text>

                            <Heading size="md">8. Interpretation</Heading>
                            <Text>
                                Any ambiguity in this Agreement shall be resolved in favor of compliance with HIPAA regulations.
                            </Text>

                            <Heading size="md">9. Definitions</Heading>
                            <Text>
                                Capitalized terms used in this Agreement shall have the meanings assigned to them in HIPAA.
                            </Text>

                            <Heading size="md">10. Survival</Heading>
                            <Text>
                                The obligations imposed by this Agreement shall survive any expiration or termination of this Agreement.
                            </Text>

                            <hr />
                            <HStack>
                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} mb={2}>Name of Client/Clinic: </Text>
                                    <Text>{business.phi.clientName}</Text>
                                </HStack>

                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} mb={2}>Name of Laboratory: </Text>
                                    <Text>Diagnostic Laboratory Science</Text>
                                </HStack>
                            </HStack>

                            {/* Name of Ordering Physician and Director of Laboratory Services Initials */}
                            <HStack>
                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} mb={2}>Name of Ordering Physician: </Text>
                                    <Text>{business.phi.physicianName}</Text>

                                </HStack>

                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} mb={2}>Director of Laboratory Services CEO: </Text>
                                    <Text>Firas Tamary, MS.MT (ASCP), Bioanalyst</Text>
                                </HStack>
                            </HStack>

                            {/* Ordering Physician Signature and Director of Laboratory Services Initials */}
                            <HStack>
                                {/* Ordering Physician Signature */}
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Ordering Physician Signature</strong></Text>
                                    <HStack spacing={4} align="center">
                                        <Box p="4" width="full">
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="md"
                                                overflow="hidden"
                                                width="auto"
                                                height="auto"
                                                maxW="300px" // Limit the width to prevent stretching
                                                maxH="200px" // Set a max height to keep the signature proportional
                                            >
                                                {business?.phi?.phiSignature ?
                                                    <Image
                                                        src={business.phi.phiSignature}
                                                        alt="Client Signature"
                                                        maxW="100%" // Scale the image within the container
                                                        height="auto" // Ensure the height adjusts automatically to maintain aspect ratio
                                                        objectFit="contain" // Ensure the image fits without being stretched
                                                    />
                                                    :
                                                    <Text>No Signature</Text>
                                                }
                                            </Box>
                                        </Box>

                                    </HStack>
                                </VStack>

                                {/* Director of Laboratory Services Initials */}
                                <VStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'}>Director of Laboratory Services Signature</Text>
                                    <Text>FT</Text>
                                </VStack>
                            </HStack>

                            {/* Date Fields */}
                            <HStack>
                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'}>Date (Ordering Physician): </Text>
                                    <Text>{new Date(business.phi.physicianDate).toLocaleDateString()}</Text>
                                </HStack>

                                <HStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'}>Date (Director of Laboratory Services): </Text>
                                    <Text>{new Date(business.phi.directorDate).toLocaleDateString()}</Text>
                                </HStack>
                            </HStack>
                            <VStack>
                                <Text fontSize='md' fontWeight={'bold'}>

                                </Text>
                            </VStack>
                        </>
                    </VStack>




                    <br /><hr /><br />
                    {/* Physicians Signature and Date Requirements */}
                    <VStack ref={psdr} spacing={4} align="stretch">

                        {/* Physicians signature and date requirements */}

                        <Heading size="lg" textAlign="center">
                            Physicians Signature and Date Requirements
                        </Heading>

                        <Text>
                            For medical review purposes, Medicare requires that the person(s) responsible for the care of the beneficiary, including providing/ordering/certifying items/services for the beneficiary, be identifiable as such in accordance with Medicare billing coverage policies, such as the Social Security Act §1815(a) and §1833(e). Medicare contractors shall consider the totality of the medical record when reviewing for compliance with the above. Signatures are required upon medical review for two distinct purposes:
                        </Text>

                        <Text>
                            <strong>1)</strong> To satisfy specific signature requirements in statute, regulation, national coverage determination (NCD), or local coverage determination (LCD).
                        </Text>

                        <Text>
                            <strong>2)</strong> To resolve authenticity concerns related to the legitimacy or falsity of the documentation.
                        </Text>

                        <Text>
                            CMS/Medicare requires that laboratory services provided/ordered be authenticated by the provider. CMS considers a laboratory order form as a legal document between the provider and the laboratory. As with any other legal document, the test order form shall be signed by the ordering provider. The signature for each entry must be legible and should include the provider's first and last name. For clarification purposes, CMS recommends that the applicable credentials (e.g., P.A., D.O., or M.D.) also be included. These credentials confirm that the ordering physician has certified the reasonableness of the service(s) submitted to the Medicare program for payment consideration. CMS also requires that the patient sign the laboratory order form, to ensure the patient understands and agrees with the tests ordered.
                        </Text>

                        <Text>
                            Physician signatures are significant audit targets at the Federal/State level and with Commercial payers. The absence of physician signatures in these audits has led to significant claw-backs by the payers and Medicare. Furthermore, not providing a physician's signature may result in having the physicians or the patient pay for the services rendered themselves. Your cooperation is a legal requirement as outlined in the Social Security Act, Medicare Section 1842(p)(4).
                        </Text>

                        <hr />
                        {/* Fields Section */}
                        <VStack spacing={4} align="stretch">
                            <Heading size="md" mt="6">PSDR Information</Heading>
                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} >Client/Clinic Name</Text>
                                    <Text>{business.psdr.clientName}</Text>
                                </VStack>
                            </HStack>

                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} >Physician/Practitioner Name</Text>
                                    <Text>{business.psdr.physicianName}</Text>
                                </VStack>

                                <VStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} >Physician/Practitioner NPI</Text>
                                    <Text>{business.psdr.physicianNPI}</Text>
                                </VStack>

                                <VStack align="stretch" flex="1">
                                    <Text fontSize='md' fontWeight={'bold'} >Date</Text>
                                    <Text>{new Date(business.psdr.date).toLocaleDateString()}</Text>
                                </VStack>
                            </HStack>
                        </VStack>

                        <HStack spacing={4} align="center">
                            <Box p="4" width="full">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="md"
                                    overflow="hidden"
                                    width="auto"
                                    height="auto"
                                    maxW="300px" // Limit the width to prevent stretching
                                    maxH="200px" // Set a max height to keep the signature proportional
                                >
                                    {business.psdr.psdrSignature ?
                                        <Image
                                            src={business.psdr.psdrSignature}
                                            alt="Client Signature"
                                            maxW="100%" // Scale the image within the container
                                            height="auto" // Ensure the height adjusts automatically to maintain aspect ratio
                                            objectFit="contain" // Ensure the image fits without being stretched
                                        />
                                        :
                                        <Text>No Signature</Text>
                                    }
                                </Box>
                            </Box>

                        </HStack>



                        <VStack>
                            <Text fontSize='md' fontWeight={'bold'}>

                            </Text>
                        </VStack>
                    </VStack>


                    <br /><hr /><br />
                    <VStack ref={addInfo} spacing={6} mt={9} align="stretch">

                        <Heading mb="6" textAlign="center">Additional Information - Internal Use Only</Heading>

                        {/* Checkmarks */}
                        <Heading size="md" mb="4" mt={4}>Sales Rep: <a href={`/sales-reps/${business?.salesRep?._id}/clients`}>{business?.salesRep?.name}</a></Heading>

                        <hr />

                        {/* Checkmarks */}
                        <Heading size="md" mb="4" mt={4}>Lab Use</Heading>
                        <HStack spacing={6} flexDirection={['column', 'row']} align="stretch" mb={5}>
                            <Checkbox isChecked={business.additionalInfo.tigerConnect} readOnly>Tiger Connect</Checkbox>
                            <Checkbox isChecked={business.additionalInfo.portalIDPassword} readOnly>Portal ID & Password</Checkbox>
                            <Checkbox isChecked={business.additionalInfo.accountManager} readOnly>Account Manager</Checkbox>
                        </HStack>

                        <hr />

                        {/* Checkmarks */}
                        <Heading size="md" mb="4" mt={4}>H/R Use</Heading>


                        <VStack spacing={6} align="stretch" mb={5}>
                            <Text><strong>Medicare Verification:</strong> {(business.cs.medicareVerification != "0" && !business.cs.medicareVerification) ? business.cs.medicareVerification : "N/A"}</Text>
                            <Text><strong>Medi-Cal Verification:</strong> {(business.cs.mediCalVerification != "0" && !business.cs.mediCalVerification) ? business.cs.mediCalVerification : "N/A"}</Text>
                            <Text><strong>Client Number:</strong> {business.cs.clientNumber != "0" ? business.cs.clientNumber : "N/A"}</Text>
                            <Text><strong>Notes:</strong> {(business.cs.notes != "0" && !business.cs.notes) ? business.cs.notes : "No Notes"}</Text>
                        </VStack>

                        <hr />

                        {/* Reporting Preference Section */}
                        <Heading size="md" mb="4" mt={4}>Reporting Preference</Heading>
                        <VStack align="stretch" mb={5}>
                            <Checkbox isChecked={business.reportingPreference.fax} readOnly>Fax</Checkbox>
                            <Checkbox isChecked={business.reportingPreference.portal} readOnly>Portal</Checkbox>
                            <HStack>
                                <Checkbox isChecked={business.reportingPreference.emr} readOnly>EMR</Checkbox>
                                <Text>{business.reportingPreference.emrDetails}</Text>
                            </HStack>
                        </VStack>

                        <hr />

                        {/* Send Client Emails Section */}
                        <Heading size="md" mb="4" mt={4}>Send Client These Emails</Heading>
                        <VStack align="stretch" mb={5}>
                            <Checkbox readOnly isChecked={business.emails.sendAccountForm}>Send Client Copy of this Account Form once approved</Checkbox>
                        </VStack>
                        <hr />

                        {/* Email Address Display */}
                        <VStack mt={4} align="stretch" >
                            <Text color="green.500">Emails will be sent to: {business.clientDetails.email || business.emails.newClientEmail}</Text>
                        </VStack>
                        <VStack>
                            <Text fontSize='md' fontWeight={'bold'}>

                            </Text>
                        </VStack>
                    </VStack>








                </VStack>

            </Box>

        </>
    );
};

export default BusinessDetailsPage;
