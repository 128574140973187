import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import { generateRoute } from '../../api';
import { alert } from '../../Utils';
import { FaCheck } from 'react-icons/fa';
import { HiOutlineRefresh } from "react-icons/hi";

const RouteGenerator = ({ route }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isGeneratedToday, setIsGeneratedToday] = useState(false);

    // Get today's date without time for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        // Check if route was generated today
        if (route?.lastGeneration) {
            const lastGeneratedDate = new Date(route.lastGeneration.date);

            // Compare the date parts (year, month, day) directly
            const isSameDay =
                lastGeneratedDate.getUTCFullYear() === today.getFullYear() &&
                lastGeneratedDate.getUTCMonth() === today.getMonth() &&
                lastGeneratedDate.getUTCDate() === today.getDate();

            setIsGeneratedToday(isSameDay);
        }
    }, [route?.lastGeneration]);

    const handleGenerateRoute = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await generateRoute(route._id)
            if (response.success) {
                setIsGeneratedToday(true);
            } else {
                alert('Oops !', 'error', response.error, "Okay !")
            }
        } catch (error) {
            setError(error)
        } finally {
            setIsLoading(false);
        }

    };

    return (
        <Tooltip placement='left' label={isGeneratedToday ? `${route.name} has been generated today by ${route.lastGeneration?.by?.firstName} !` : `Generate ${route.name}`} hasArrow>
            <Button
                colorScheme="yellow"
                size="sm"
                onClick={handleGenerateRoute}
                isLoading={isLoading}
                loadingText="Generating Route..."
                isDisabled={isGeneratedToday}
            >
                {isGeneratedToday ? <FaCheck /> : <HiOutlineRefresh />}
            </Button>
        </Tooltip>
    );
};

export default RouteGenerator;
