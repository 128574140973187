// AddItemsModal.js
import React, { useState, useEffect } from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,
    Button, Checkbox, Input, Table, Tbody, Tr, Td
} from '@chakra-ui/react';
import { fetchSupplies } from '../../api';

const AddItemsModal = ({ isOpen, onClose, onAddItems, requestedItems }) => {
    const [supplies, setSupplies] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (isOpen) {
            console.log(requestedItems)
            loadSupplies();
            setSelectedItems([]); // Reset selected items when modal opens
        }
    }, [isOpen]);

    const loadSupplies = async () => {
        const result = await fetchSupplies(false);
        if (result.success) {
            // Filter out supplies that are already in the order
            const existingItemIds = new Set(requestedItems.map(item => item.item._id.toString()));

            const filteredSupplies = result.data.filter(supply =>
                !existingItemIds.has(supply._id.toString())  // Only include supplies not in existing items
            );

            setSupplies(filteredSupplies);
        }
    };

    const handleSelectItem = (supply, quantity) => {
        setSelectedItems(prevItems => {
            const itemIndex = prevItems.findIndex(i => i.itemId === supply._id);

            if (itemIndex !== -1) {
                // Update quantity for already selected item
                prevItems[itemIndex].quantity = quantity;
            } else {
                // Add new item to selection
                prevItems.push({ itemId: supply._id, name: supply.name, quantity });
            }

            // Remove item if quantity is zero (unselected)
            return prevItems.filter(item => item.quantity > 0);
        });
    };

    const handleAddItems = () => {
        onAddItems(selectedItems);
        onClose();
    };

    return (
        <Modal scrollBehavior='inside' size={'4xl'} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Supplies</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant="simple">
                        <Tbody>
                            {supplies.map((supply) => (
                                <Tr _hover={{ bg: "gray.100" }} key={supply._id}>
                                    <Td>
                                        <Checkbox onChange={(e) =>
                                            handleSelectItem(supply, e.target.checked ? 1 : 0)
                                        }>{supply.name}</Checkbox>
                                    </Td>

                                    <Td>{supply.size}</Td>

                                    <Td>
                                        <Input
                                            type="number"
                                            min="1"
                                            defaultValue="1"
                                            onChange={(e) => handleSelectItem(supply, parseInt(e.target.value, 10))}
                                            disabled={!selectedItems.some(item => item.itemId === supply._id)}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button colorScheme="blue" onClick={handleAddItems}>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddItemsModal;
