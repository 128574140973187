import { Box, Text, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Flex } from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';
import { Permissions } from '../Utils';

const PermissionsDisplay = ({ permissions, roleName = "" }) => {
    const initialPermissions = permissions.slice(0, 4);  // Display first 4 permissions
    const hasMorePermissions = permissions.length > 4;   // Check if there are more than 4

    return (
        <Flex alignItems="center">
            {/* Display first 4 permissions with matching descriptions */}
            <Box>
                {initialPermissions
                    .map((perm) => Permissions[perm])  // Map key to description
                    .filter((desc) => desc)  // Filter out any undefined descriptions
                    .map((desc, index) => (
                        <Text key={index} fontSize="sm" display="inline-block" mr={2}>
                            {desc + ", "}
                        </Text>
                    ))}
            </Box>

            {/* Display Popover for remaining permissions */}
            {hasMorePermissions && (
                <Popover>
                    <PopoverTrigger>
                        <IconButton size="xs" icon={<FaEllipsisH />} variant="ghost" aria-label="More permissions" />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>All Permissions For the {roleName} role</PopoverHeader>
                        <PopoverBody>
                            {permissions
                                .map((perm) => Permissions[perm])  // Map key to description
                                .filter((desc) => desc)  // Filter out any undefined descriptions
                                .map((desc, index) => (
                                    <Text mt={2} key={index} fontSize="sm">
                                        {index + 1 + ". " + desc}
                                    </Text>
                                ))}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            )}
        </Flex>
    );
};

export default PermissionsDisplay;
