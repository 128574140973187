import React from 'react';
import { Box, Text, Tooltip, Icon, HStack } from '@chakra-ui/react';
import { FaMap, FaMapMarkedAlt, FaMapMarkerAlt, FaRegMap } from 'react-icons/fa';

const FacilityAddressMap = ({ facility }) => {
    // Function to generate Google Maps link
    const getGoogleMapsLink = () => {
        const { address1, address2, city, state, zip } = facility.address;
        const fullAddress = `${address1} ${address2 ? address2 + ", " : ""}${city}, ${state} ${zip}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
        return googleMapsUrl;
    };

    return (
        <HStack>
            {/* Map Icon with Tooltip */}
            <Tooltip label="Open in map" aria-label="Open in map">
                <Box as="a" href={getGoogleMapsLink()} target="_blank" rel="noopener noreferrer">
                    <Icon as={FaMapMarkedAlt} w={5} h={5} color="black" />
                </Box>
            </Tooltip>

            <Box>
                {/* Facility Name */}
                <Text>{facility.address.city}</Text>
                {/* Facility Address */}
                <Text as="small">
                    {facility.address.address1}, {facility.address.address2 ? facility.address.address2 + ", " : ""}
                    {facility.address.city}, {facility.address.state}, {facility.address.zip}
                </Text>
            </Box>

        </HStack>
    );
};

export default FacilityAddressMap;
