
import { useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    FormControl,
    FormLabel,
    Select,
    Input,
    useToast
} from '@chakra-ui/react';
import { fetchAllCouriers, searchSchedules } from '../../api'; // API functions to fetch data
import ScheduleSearchResults from './ScheduleSearchResults'

const ScheduleSearch = () => {
    const toast = useToast();
    const [couriers, setCouriers] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCouriers();
    }, []);

    const fetchCouriers = async () => {
        try {
            const response = await fetchAllCouriers(); // Fetch all couriers for the dropdown
            if (response.success) {
                setCouriers(response.data.couriers);
            }
        } catch (error) {
            console.error('Error fetching couriers:', error);
        }
    };

    const handleSearch = async () => {
        setLoading(true);

        try {
            const fStartDate = formatDate(startDate) || null
            const fEdnDate = formatDate(endDate) || null

            const response = await searchSchedules({
                courierId: selectedCourier || undefined,
                startDate: fStartDate || undefined,
                endDate: fEdnDate || undefined
            });

            if (response.success) {
                setSchedules(response.data.schedules); // Set the filtered schedules
            } else {
                toast({
                    title: 'No schedules found.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error fetching schedules:', error);
            toast({
                title: 'Error fetching schedules.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }

        setLoading(false);
    };


    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <Heading>Search Schedules</Heading>

                {/* Filter Form */}
                <HStack spacing={4}>

                    <FormControl>
                        <FormLabel>Select Courier</FormLabel>
                        <Select
                            placeholder="Select courier"
                            value={selectedCourier}
                            onChange={(e) => setSelectedCourier(e.target.value)}
                        >
                            {couriers.map((courier) => (
                                <option key={courier._id} value={courier._id}>
                                    {courier.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Start Date</FormLabel>
                        <Input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>End Date</FormLabel>
                        <Input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <Button mt={8} size={"sm"} colorScheme="blue" onClick={handleSearch} isLoading={loading}>
                            Search
                        </Button>
                    </FormControl>

                </HStack>
                {/* Results Table */}
                {schedules.length > 0 && (
                    <ScheduleSearchResults schedules={schedules} startDate={startDate} endDate={endDate} />
                )}
            </VStack>
        </Box>
    );
};

export default ScheduleSearch;
