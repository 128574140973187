import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const SaveAsPDF = ({ clientName }) => {
    const printRef = useRef();
    const [boxContent, setBoxContent] = useState("");

    const handleSavePDF = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
            orientation: "landscape", // Set landscape mode
            unit: "in",
            format: [6, 4], // Landscape: Width 6in, Height 4in
        });
        pdf.addImage(imgData, "PNG", 0, 0, 6, 4); // Adjust to fit landscape dimensions
        pdf.save("client-info-landscape.pdf");
    };

    const handlePrintBrowser = () => {
        const printContent = printRef.current.innerHTML;
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
      <html>
        <head>
          <style>
            @page {
              size: 6in 4in landscape;
              margin: 0;
            }
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              font-family: Arial, sans-serif;
            }
            .print-container {
              width: 6in;
              height: 4in;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              background-color: #f9f9f9;
            }
          </style>
        </head>
        <body>
          <div class="print-container">${printContent}</div>
        </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    return (
        <div>
            <div
                ref={printRef}
                style={{
                    width: "6in", // Adjust for landscape width
                    height: "4in", // Adjust for landscape height
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid black",
                    backgroundColor: "#f9f9f9",
                }}
            >
                <img
                    src="https://mydlslab.com/wp-content/uploads/2021/03/cropped-DLS_Logos-01-1024x788.png"
                    alt="Example"
                    style={{ marginBottom: "10px", maxWidth: "50%" }}
                />
                <h2 style={{ fontWeight: "bold", textAlign: 'center' }}>{clientName}</h2>
                <p style={{ fontWeight: "bold", textAlign: 'center' }}>{boxContent}</p>
            </div>

            <div style={{ marginTop: "20px" }}>
                <textarea
                    placeholder="Route# "
                    value={boxContent}
                    onChange={(e) => setBoxContent(e.target.value)}
                    rows={4}
                    style={{ display: "block", marginBottom: "10px", width: "100%" }}
                ></textarea>
                <button onClick={handleSavePDF} style={{ marginRight: "10px" }}>
                    Save as PDF
                </button>
                <button onClick={handlePrintBrowser}>Print in Browser</button>
            </div>
        </div>
    );
};

export default SaveAsPDF;
