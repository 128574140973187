// EmailTemplates.js
import React, { useEffect, useState } from 'react';
import { Box, VStack, Table, Thead, Tr, Th, Tbody, Td, Button, HStack } from '@chakra-ui/react';
import { fetchEmailTemplates, deleteEmailTemplate } from '../../api'; // Import API functions
import { ViewIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ViewTemplateModal from './ViewTemplateModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const EmailTemplates = () => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadTemplates();
    }, [currentPage]);

    const loadTemplates = async () => {
        setLoading(true);
        try {
            const response = await fetchEmailTemplates(currentPage, 10);
            setTemplates(response.data.templates);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error loading templates:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleView = (template) => {
        setSelectedTemplate(template);
        setIsViewModalOpen(true);
    };

    const handleDelete = async (templateId) => {
        try {
            await deleteEmailTemplate(templateId);
            loadTemplates();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <HStack justifyContent="space-between">
                    <h2>Email Templates</h2>
                    <Button colorScheme="blue" onClick={() => navigate('/email/create')}>
                        Create New Template
                    </Button>
                </HStack>

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th textAlign="right">Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {templates.map((template, index) => (
                            <Tr key={template._id}>
                                <Td>{index + 1}</Td>
                                <Td>{template.template_name}</Td>
                                <Td textAlign="right">
                                    <HStack justifyContent="flex-end" spacing={4}>
                                        <Button size="sm" onClick={() => handleView(template)} colorScheme="blue">
                                            <ViewIcon mr={2} /> View
                                        </Button>
                                        <Button size="sm" onClick={() => navigate(`/email/edit/${template._id}`)} colorScheme="green">
                                            <EditIcon mr={2} /> Edit
                                        </Button>
                                        <Button size="sm" onClick={() => { setSelectedTemplate(template); setIsDeleteModalOpen(true); }} colorScheme="red">
                                            <DeleteIcon mr={2} /> Delete
                                        </Button>
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => setCurrentPage(page)} />
            </VStack>

            <ViewTemplateModal
                isOpen={isViewModalOpen}
                onClose={() => setIsViewModalOpen(false)}
                template={selectedTemplate}
            />

            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={() => handleDelete(selectedTemplate._id)}
                itemName={selectedTemplate?.template_name}
            />
        </Box>
    );
};

export default EmailTemplates;
