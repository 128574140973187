import React, { useEffect, useState } from 'react';
import { fetchInventoryLevels } from '../../api'; // Import the API function
import { Spinner, Text, VStack } from '@chakra-ui/react';

const InventoryQuantity = ({ warehouseId, itemId }) => {
    const [availableQuantity, setAvailableQuantity] = useState(null);
    const [loading, setLoading] = useState(true);

    // Function to load inventory quantity
    const loadInventoryQuantity = async () => {
        try {
            const result = await fetchInventoryLevels(warehouseId, itemId);
            console.log(result.data.quantity)
            if (result.success) {
                setAvailableQuantity(result.data[0].quantity || 0);
            }
        } catch (error) {
            console.error('Error fetching inventory quantity:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch inventory quantity when the component mounts or warehouse/item changes
    useEffect(() => {
        if (warehouseId && itemId) {
            loadInventoryQuantity();
        } else {
            setLoading(false);
        }
    }, [warehouseId, itemId]);

    return (
        <VStack align="start" spacing={1}>
            {loading ? (
                <Text fontSize="sm" color="gray.500">Checking availability <Spinner size="xs" /></Text>
            ) : (
                <Text fontSize="sm" color={availableQuantity > 0 ? "green.500" : "red.500"}>
                    Available: {availableQuantity !== null ? availableQuantity : 'N/A'}
                </Text>
            )}
        </VStack>
    );
};

export default InventoryQuantity;
