import React from 'react';
import { Box, VStack, HStack, Text, Heading, Divider, Thead, Table, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const Invoice = ({ clientName, clientAddress, clientEmail, requestBy, validatedItems }) => {
    // Getting the current date and time
    const currentDateTime = new Date().toLocaleString();

    return (
        <Box maxW="full" mx="auto" p={6} borderWidth="1px" borderRadius="md" boxShadow="lg">
            {/* Invoice Header */}
            <VStack align="stretch" spacing={4}>
                <Heading size="lg" textAlign="center">Order Review</Heading>
                <Divider />
                {/* Client Information */}
                <VStack align="stretch" spacing={2}>
                    <Heading size="sm">Client Information</Heading>
                    <Text><strong>Client Name:</strong> {clientName}</Text>
                    {clientAddress &&
                        <Text><strong>Address:</strong> {clientAddress}</Text>
                    }
                    {clientEmail &&
                        <Text><strong>Email:</strong> {clientEmail}</Text>
                    }
                    {requestBy &&
                        <Text><strong>Request By:</strong> {requestBy}</Text>
                    }
                </VStack>
                <Divider />

                {/* Date & Time */}
                <HStack justifyContent="space-between">
                    <Text fontSize="sm"><strong>Date:</strong> {currentDateTime.split(', ')[0]}</Text>
                    <Text fontSize="sm"><strong>Time:</strong> {currentDateTime.split(', ')[1]}</Text>
                </HStack>
                <Divider />

                {/* Validated Items */}
                <Heading size="sm">Validated Items</Heading>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Item</Th>
                            <Th>Quantity</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {validatedItems.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.name}</Td>
                                <Td>{item.quantity}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Heading size="sm">End of order</Heading>
            </VStack>
        </Box>
    );
};

export default Invoice