import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    VStack,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Box,
    Divider
} from '@chakra-ui/react';
import { fetchFacilitiesByLab } from '../../api'; // API call to fetch facilities by lab

const ViewLabFacilitiesModal = ({ isOpen, onClose, lab }) => {
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (isOpen && lab?._id) {
            fetchFacilities();
        }
    }, [isOpen, lab, page]);

    const fetchFacilities = async () => {
        try {
            setLoading(true);
            const result = await fetchFacilitiesByLab(lab._id, page, 50);
            if (result.success) {
                setFacilities(result.data.facilities);
                setTotalPages(result.data.totalPages);
            }
        } catch (error) {
            console.error('Error fetching facilities by lab:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => setPage(newPage);

    const getGoogleMapsLink = (address) => {
        const { address1, address2, city, state, zip } = address;
        const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
        return googleMapsUrl;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size="full">
            <ModalOverlay />
            <ModalContent>

                <ModalHeader>{lab?.name}</ModalHeader>
                <ModalCloseButton />


                <ModalBody>

                    <Box pl={7} pr={7}>
                        <Box justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                            {/* Action By and Action Row */}
                            <Text as='u' fontSize='2xl'>Lab Details </Text>
                            <Box display="flex" justifyContent="space-between" mb={4} mt={4}>
                                <Box>
                                    <Text>
                                        <span style={{ fontWeight: "500", fontSize: 15 }}>{lab?.name}</span>
                                        <small>({lab.address.city}) </small>
                                    </Text>
                                </Box>
                            </Box>


                        </Box>
                        <Divider mb={4} />

                        <Box justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                            {/* Action By and Action Row */}
                            <Text as='u' fontSize='2xl'>Address
                                <a href={getGoogleMapsLink(lab.address)} target="_blank" color="blue.500">
                                    <small style={{ fontSize: 12 }}> - View in map</small>
                                </a>
                            </Text>

                            <Box mt={4} mb={5}>
                                <Text fontSize="sm" fontWeight="semibold" > Full Address </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.address.address1 + ", " + (lab.address.address2 ? lab.address.address2 + ", " : "") + lab.address.city + ", " + lab.address.state + ", " + lab.address.zip} </span></Text >
                            </Box>

                            <Box display="flex" justifyContent="space-between" mb={4}>

                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold">Address</Text>
                                    <Text>
                                        <span style={{ fontWeight: "500", fontSize: 15 }}>{lab.address.address1}</span>
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold"> Address 2</Text>
                                    <Text>
                                        <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.address.address2 || ""} </span>
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold" > City </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.address.city} </span></Text >
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold" > State </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.address.state || "CA"} </span></Text >
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold" > ZIP </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.address.zip || ""} </span></Text >
                                </Box>

                            </Box>
                        </Box>
                        <Divider mb={4} />

                        <Box justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                            {/* Action By and Action Row */}
                            <Text as='u' fontSize='2xl'>Contact</Text>

                            <Box display="flex" justifyContent="space-between" mb={4}>

                                <Box mt={4} mb={5}>
                                    <Text fontSize="sm" fontWeight="semibold" > Person </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.contact.person} </span></Text >
                                </Box>

                                <Box mt={4} mb={5}>
                                    <Text fontSize="sm" fontWeight="semibold" > Phone </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.contact.phone} </span></Text >
                                </Box>

                                <Box mt={4} mb={5}>
                                    <Text fontSize="sm" fontWeight="semibold" > Email Address </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {lab.contact.emailAddress} </span></Text >
                                </Box>


                            </Box>
                        </Box>
                        <Divider mb={4} />

                    </Box>

                    <Text pl={7} pr={7} as='u' fontSize='2xl'>{`Facilities for ${lab?.name}`} </Text>

                    {loading ? (
                        <VStack>
                            <Spinner />
                            <p>Loading facilities...</p>
                        </VStack>
                    ) : facilities.length === 0 ? (
                        <p>No facilities found for this lab.</p>
                    ) : (
                        <Table mt={10} variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Facility Name</Th>
                                    <Th>Client Number</Th>
                                    <Th>City</Th>
                                    <Th>Sales Rep</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {facilities.map((facility) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={facility._id}>
                                        <Td>{facility.name}</Td>
                                        <Td>{facility.clientNumber}</Td>
                                        <Td>{facility.address.city}</Td>
                                        <Td>{facility.salesRep ? facility.salesRep.name : 'N/A'}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
                </ModalBody>








                <HStack mt={4} justify="center">
                    <Button
                        onClick={() => handlePageChange(page - 1)}
                        isDisabled={page == 1}
                    >
                        &laquo; Previous
                    </Button>

                    {/* First page */}
                    <Button
                        onClick={() => handlePageChange(1)}
                        colorScheme={page == 1 ? 'teal' : 'gray'}
                        isDisabled={page == 1}
                    >
                        1
                    </Button>

                    {/* Ellipses before current range */}
                    {page > 3 && <Button isDisabled>...</Button>}

                    {/* Middle pages (show currentPage, currentPage-1, and currentPage+1) */}
                    {Array.from({ length: 3 }, (_, i) => page - 1 + i)
                        .filter(p => p > 1 && p < totalPages)
                        .map((p) => (
                            <Button
                                key={p}
                                onClick={() => handlePageChange(p)}
                                colorScheme={page == p ? 'teal' : 'gray'}
                                isDisabled={page == p}
                            >
                                {p}
                            </Button>
                        ))}

                    {/* Ellipses after current range */}
                    {page < totalPages - 2 && <Button isDisabled>...</Button>}

                    {/* Last page */}
                    {totalPages > 1 && (
                        <Button
                            onClick={() => handlePageChange(totalPages)}
                            colorScheme={page == totalPages ? 'teal' : 'gray'}
                            isDisabled={page == totalPages}
                        >
                            {totalPages}
                        </Button>
                    )}

                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        isDisabled={page == totalPages}
                    >
                        Next &raquo;
                    </Button>
                </HStack>


                <ModalFooter>

                    <Button variant="ghost" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewLabFacilitiesModal;
