import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Center,
    Badge,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Textarea,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Select
} from '@chakra-ui/react'; import { deleteBusiness, fetchBusinesses, fetchSalesReps, updateBusinessCSFields } from '../../api'; // API service to fetch and update businesses
import { InfoIcon, ArrowUpIcon, ArrowDownIcon, EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import GenerateLinkButton from '../../components/GenerateLinkButton';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import PopoverId from '../../components/PopoverId';
import { hasPermission } from '../../Utils';

const AllBusinessesPage = () => {
    const navigate = useNavigate();
    const { isOpen: isCsModalOpen, onOpen: onOpenCsModal, onClose: onCloseCsModal } = useDisclosure(); // Chakra modal state for CS
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure(); // Chakra modal state for delete confirmation

    const [selectedBusiness, setSelectedBusiness] = useState(null); // To store the selected business for CS modal
    const [businessToDelete, setBusinessToDelete] = useState(null); // Business to delete

    const [csFields, setCsFields] = useState({ medicareVerification: '', mediCalVerification: '', clientNumber: '', notes: '' }); // CS form state

    const [businesses, setBusinesses] = useState([]);
    const [filteredBusinesses, setFilteredBusinesses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [addressSearchTerm, setAddressSearchTerm] = useState(''); // New state for address search
    const [salesRepFilter, setSalesRepFilter] = useState('');
    const [salesReps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errorSr, setErrorSr] = useState(null);
    const [errorBs, setErrorBs] = useState(null);

    // Sorting
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const handleSort = (field) => {
        const newOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newOrder);
    };

    const sortedBusinesses = [...filteredBusinesses].sort((a, b) => {
        if (sortField === 'date') {
            const dateA = new Date(a.dateSubmitted);
            const dateB = new Date(b.dateSubmitted);
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (sortField === 'status') {
            const getStatusValue = (business) => {
                const hasMissingSignatures = !business.tos.tosSignature || !business.phi.phiSignature || !business.psdr.psdrSignature;
                const hasCSIssues = business.cs.medicareVerification === '0' || !business.cs.medicareVerification || business.cs.mediCalVerification === '0' || !business.cs.mediCalVerification || !business.cs.clientNumber || business.cs.clientNumber === '0';

                if (hasMissingSignatures) return 3; // High priority
                if (hasCSIssues) return 2;
                return 1; // Completed
            };

            const statusA = getStatusValue(a);
            const statusB = getStatusValue(b);

            return sortOrder === 'asc' ? statusA - statusB : statusB - statusA;
        }

        return 0;
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Adjust as needed
    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedBusinesses.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedBusinesses.length / itemsPerPage);

    const handleClick = (pageNumber) => setCurrentPage(pageNumber);

    // Fetch businesses and sales reps on mount
    useEffect(() => {
        getBusinesses();
    }, []);

    const getBusinesses = async () => {

        const fetchedBusinesses = await fetchBusinesses();
        if (fetchedBusinesses.success) {
            setBusinesses(fetchedBusinesses.data);
        } else {
            setErrorBs(fetchedBusinesses.error)
        }
        const fetchedSalesReps = await fetchSalesReps();
        if (fetchedSalesReps.success) {
            setSalesReps(fetchedSalesReps.data);
        } else {
            setErrorSr(fetchedSalesReps.error)
        }
        setLoading(false);


    };

    const handleCSUse = (business) => {
        setSelectedBusiness(business);
        setCsFields({
            medicareVerification: business.cs.medicareVerification || '',
            mediCalVerification: business.cs.mediCalVerification || '',
            clientNumber: business.cs.clientNumber || '',
            notes: business.cs.notes || ''
        });
        onOpenCsModal(); // Open the CS modal
    };

    const handleSaveCSFields = async () => {
        try {
            const updatedBusiness = { ...selectedBusiness, cs: csFields };
            await updateBusinessCSFields(updatedBusiness._id, updatedBusiness.cs); // Update the business in backend
            onCloseCsModal(); // Close the modal
            getBusinesses(); // Refresh businesses list
        } catch (error) {
            console.error('Error updating CS fields', error);
        }
    };

    // Filter businesses based on search term, address, and sales rep filter
    useEffect(() => {
        const filtered = businesses.filter(
            (business) =>
                business.clientDetails.clinicName.toLowerCase().includes(searchTerm.toLowerCase()) &&
                business.clientDetails.address.toLowerCase().includes(addressSearchTerm.toLowerCase()) && // Filter by address
                (salesRepFilter === '' || business.salesRep._id === salesRepFilter)
        );
        setFilteredBusinesses(filtered);
        setCurrentPage(1); // Reset to first page on search/filter change
    }, [searchTerm, addressSearchTerm, salesRepFilter, businesses]);



    const handleDeleteBusiness = (business) => {
        setBusinessToDelete(business);
        onOpenDeleteModal(); // Open the delete confirmation modal
    };

    const confirmDeleteBusiness = async () => {
        if (businessToDelete) {
            try {
                const response = await deleteBusiness(businessToDelete._id);
                if (response.success) {
                    getBusinesses(); // Refresh the list after deletion
                } else {
                    alert('Failed to delete business: ' + response.message);
                }
            } catch (error) {
                console.error('Error deleting business:', error);
            } finally {
                onCloseDeleteModal(); // Close the delete confirmation modal
            }
        }
    };



    // const handleDeleteBusiness = async (businessId) => {
    //     const confirmed = window.confirm('Are you sure you want to delete this business?');
    //     if (confirmed) {
    //         try {
    //             const response = await deleteBusiness(businessId);
    //             if (response.success) {
    //                 getBusinesses(); // Refresh the list after deletion
    //             } else {
    //                 alert('Failed to delete business: ' + response.message);
    //             }
    //         } catch (error) {
    //             console.error('Error deleting business:', error);
    //         }
    //     }
    // };


    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {!errorBs ?
                    <>
                        <TitleHeader onClick={hasPermission('createUser') ? () => window.location.href = "/signup" : null} buttonText={hasPermission('createUser') ? "New Client" : null} title={"All Clients"} />

                        {/* <TitleHeader title="All Clients" buttonText={"New Client"} onClick={() => window.location.href = "/signup"} /> */}

                        <HStack spacing={4} mb="6">
                            <Input
                                placeholder="Search by business name"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Input
                                placeholder="Search by address"
                                value={addressSearchTerm}
                                onChange={(e) => setAddressSearchTerm(e.target.value)}
                            />
                            {!errorSr &&
                                <Select
                                    placeholder="Filter by Sales Rep"
                                    value={salesRepFilter}
                                    onChange={(e) => setSalesRepFilter(e.target.value)}
                                >
                                    {salesReps.map((sl) => (
                                        <option key={sl._id} value={sl._id}>{sl.name}</option>
                                    ))}
                                </Select>
                            }
                        </HStack>

                        {filteredBusinesses.length > 0 ? (
                            <>


                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th w={1}>#</Th>
                                            <Th w={"30%"}>Business Name</Th>
                                            <Th w={"10%"} >City</Th>
                                            <Th style={{ cursor: 'pointer' }} onClick={() => handleSort('date')}>
                                                Signup Date
                                                {sortField === 'date' && (
                                                    sortOrder === 'asc' ? <ArrowUpIcon ml={2} /> : <ArrowDownIcon ml={2} />
                                                )}
                                            </Th>
                                            <Th style={{ cursor: 'pointer' }} onClick={() => handleSort('status')}>
                                                Status
                                                {sortField === 'status' && (
                                                    sortOrder === 'asc' ? <ArrowUpIcon ml={2} /> : <ArrowDownIcon ml={2} />
                                                )}
                                            </Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {currentItems.map((business, index) => (
                                            <Tr key={business._id} _hover={{ bg: "gray.100" }}>
                                                <Td><PopoverId place='All Businesses' index={index} content={business._id} /></Td>
                                                <Td>{business.clientDetails.clinicName}</Td>
                                                <Td>
                                                    <Popover placement='top'>
                                                        <PopoverTrigger>
                                                            <IconButton
                                                                icon={<InfoIcon />}
                                                                aria-label="Info"
                                                                variant="ghost"
                                                            />
                                                        </PopoverTrigger>
                                                        <PopoverContent>
                                                            <PopoverArrow />
                                                            <PopoverCloseButton />
                                                            <PopoverHeader>Business Address</PopoverHeader>
                                                            <PopoverBody>
                                                                {`${business.clientDetails.address}, ${business.clientDetails.city}, ${business.clientDetails.state}, ${business.clientDetails.zip}`}
                                                            </PopoverBody>
                                                        </PopoverContent>
                                                    </Popover>
                                                    {business.clientDetails.city}
                                                </Td>
                                                <Td>{new Date(business.dateSubmitted).toLocaleDateString()}</Td>
                                                <Td>
                                                    <VStack>
                                                        {(!business.tos.tosSignature || !business.phi.phiSignature || !business.psdr.psdrSignature) && (
                                                            <Badge ml={1} colorScheme='red'>Missing Signatures</Badge>
                                                        )}
                                                        {business.cs.medicareVerification === '0' || !business.cs.medicareVerification || business.cs.mediCalVerification === '0' || !business.cs.mediCalVerification || !business.cs.clientNumber || business.cs.clientNumber === '0' ? (
                                                            <Badge colorScheme='red'>CS Attention</Badge>
                                                        ) : (
                                                            (!business.tos.tosSignature || !business.phi.phiSignature || !business.psdr.psdrSignature) ? (
                                                                <Badge colorScheme='green'>CS Completed</Badge>
                                                            ) : (
                                                                <Badge colorScheme='green'>Completed</Badge>
                                                            )
                                                        )}
                                                    </VStack>
                                                </Td>
                                                <Td>
                                                    <HStack spacing={4}>
                                                        {hasPermission('viewBusiness') &&
                                                            <Button size="sm" onClick={() => navigate(`/business-details/${business._id}`)} colorScheme="blue">
                                                                <ViewIcon mr={2} /> View
                                                            </Button>
                                                        }
                                                        {hasPermission('updateBusiness') &&
                                                            <Button size="sm" onClick={() => navigate(`/edit-business/${business._id}`)} colorScheme="green">
                                                                <EditIcon mr={2} /> Edit
                                                            </Button>
                                                        }
                                                        {hasPermission('updateBusinessCSFields') &&
                                                            <Button size="sm" onClick={() => handleCSUse(business)} colorScheme="purple">
                                                                <InfoIcon mr={2} /> CS
                                                            </Button>
                                                        }
                                                        {hasPermission('generateBusinessLink') &&
                                                            <GenerateLinkButton businessId={business._id} />
                                                        }
                                                        {hasPermission('deleteBusiness') &&
                                                            <Button size="sm" onClick={() => handleDeleteBusiness(business)} colorScheme="red">
                                                                <DeleteIcon mr={2} /> Delete
                                                            </Button>
                                                        }
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                                {/* Pagination Controls */}
                                <HStack spacing={4} mt={4} justify="center">
                                    {[...Array(totalPages)].map((_, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => handleClick(index + 1)}
                                            colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
                                        >
                                            {index + 1}
                                        </Button>
                                    ))}
                                </HStack>


                                {/* Delete Confirmation Modal */}
                                {hasPermission('deleteBusiness') &&
                                    <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalHeader>Confirm Deletion</ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete the business{' '}
                                                <strong>{businessToDelete?.clientDetails.clinicName}</strong>?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button colorScheme="red" mr={3} onClick={confirmDeleteBusiness}>
                                                    Delete
                                                </Button>
                                                <Button onClick={onCloseDeleteModal}>Cancel</Button>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                }


                                {/* CS Modal */}
                                {hasPermission('updateBusinessCSFields') &&
                                    <Modal isOpen={isCsModalOpen} onClose={onCloseCsModal}>
                                        <ModalOverlay />
                                        <ModalContent>
                                            <ModalHeader>CS USE Form</ModalHeader>
                                            <ModalBody>
                                                <FormControl mb={4}>
                                                    <FormLabel>Medicare Verification</FormLabel>
                                                    <Input
                                                        value={csFields.medicareVerification == "0" ? null : csFields.medicareVerification}
                                                        onChange={(e) => setCsFields({ ...csFields, medicareVerification: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl mb={4}>
                                                    <FormLabel>Medi-Cal Verification</FormLabel>
                                                    <Input
                                                        value={csFields.mediCalVerification == "0" ? null : csFields.mediCalVerification}
                                                        onChange={(e) => setCsFields({ ...csFields, mediCalVerification: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl mb={4}>
                                                    <FormLabel>Client Number</FormLabel>
                                                    <Input
                                                        value={csFields.clientNumber == "0" ? null : csFields.clientNumber}
                                                        onChange={(e) => setCsFields({ ...csFields, clientNumber: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl mb={4}>
                                                    <FormLabel>Notes</FormLabel>
                                                    <Textarea
                                                        rows={9}
                                                        value={csFields.notes == "0" ? null : csFields.notes}
                                                        onChange={(e) => setCsFields({ ...csFields, notes: e.target.value })}
                                                    />
                                                </FormControl>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button colorScheme="blue" mr={3} onClick={handleSaveCSFields}>
                                                    Save
                                                </Button>
                                                <Button onClick={onCloseCsModal}>Close</Button>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                }
                            </>
                        ) : (
                            <AccessDenied msg={'No Clients here yet !'} icon="info" />
                        )}
                    </>
                    :
                    <AccessDenied msg={errorBs} />

                }
            </VStack>
        </Box>
    );
};

export default AllBusinessesPage;
