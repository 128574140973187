import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    Badge,
    Tooltip,
    useToast,
    Divider,
    Alert,
    AlertTitle,
    AlertDescription,
    Input,
    Text,
    SimpleGrid,
    Card,
    CardHeader,
    Heading,
    CardBody,
    Flex,
    Circle,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    IconButton
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import Pagination from '../../components/Pagination';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { cancelPickup, deletePickupAttachements, fetchCouriersBySearch, fetchFacilitiesBySearch, fetchLabLocations, fetchRouteBySearch, getAllPickups, savePickupOrder, searchPickups, transferPickup, updatePickup, uploadPickupAttachements } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { hasPermission } from '../../Utils';
import PopoverId from '../../components/PopoverId';
import { FaBox, FaClock, FaCloudUploadAlt, FaEnvelope, FaFileUpload, FaPhotoVideo, FaShare, FaShareAlt } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PickupNotes from './PickupNotes';
import EditPickupModal from './EditPickupModal';
import CreatePickupModal from './CreatePickupModal';
import ImageLightbox from '../../components/ImageLightBox';
import { useNavigate } from 'react-router-dom';

const noReturn = ['pickupLocation', 'dropOffLocation', 'courier']


const getGoogleMapsLink = (address) => {
    const { address1, address2, city, state, zip } = address;
    const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
    return googleMapsUrl;
};

const humanReadableField = (field) => {


    const fieldMappings = {
        status: "Status",
        samplesReceived: "Samples Received",
        samplesCount: "Samples Count",
        "requirements.requestPhoto": "Request Photo",
        "requirements.hasSupplies": "Has Supplies",
        "requirements.hasDocs": "Has Documents",
        "priority": "Priority",
        "receivedBy": "Received By",
    };

    return fieldMappings[field] || field.replace(/\./g, " "); // Default fallback: replace dots with spaces
};

const formatValue = (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (value === null || value === undefined) return "N/A";
    if (typeof value === "string") return value; // Return the string as is, without JSON.stringify
    return JSON.stringify(value); // Use JSON.stringify for objects/arrays
};

const statusOptions = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'PICKED_UP', label: 'Picked Up' },
];
const AllPickups = () => {
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isViewModalOpen, onOpen: onOpenViewModal, onClose: onCloseViewModal } = useDisclosure();
    const { isOpen: isTransferModalOpen, onOpen: onOpenTransferModal, onClose: onCloseTransferModal } = useDisclosure();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);


    const toast = useToast()
    const [allPickups, setAllPickups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const itemsPerPage = 25;

    const [pickupLocation, setPickupLocation] = useState(null);
    const [courier, setCourier] = useState(null);
    const [dropOffLocation, setDropOffLocation] = useState(null);
    const [route, setRoute] = useState(null);

    const [searching, setSearching] = useState(false);

    const [cancellationReason, setCancellationReason] = useState('');
    const [modalError, setModalError] = useState('');

    const [labLocations, setLabLocations] = useState([]);
    const [statusSelected, setSelectedStatus] = useState([]);
    const [pendingChanges, setPendingChanges] = useState(false);

    const [transferCourier, setTransferCourier] = useState(null);
    const [transferAllPickups, setTransferAllPickups] = useState(false);

    const [routePickupCount, setRoutePickupCount] = useState(0)
    const [noneRoutePickupCount, setNoneRoutePickupCount] = useState(0)
    const [noneDefaultPickups, setNoneDefaultPickups] = useState(0)

    const [selectedFiles, setSelectedFiles] = useState([]);

    const navigate = useNavigate()


    useEffect(() => {
        getPickups();
        getLabLocations();
    }, []);

    const getLabLocations = async () => {
        try {
            const response = await fetchLabLocations();
            if (response.success) {
                setLabLocations(response.data);
            }
        } catch (error) {
            console.log('Error loading lab locations');
        }
    };

    const getPickups = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await getAllPickups(page, itemsPerPage);
            if (response.success) {
                const pickups = response.data.pickups;
                const routePickupCount = pickups.filter(pickup => pickup.routeId).length;
                const nonRoutePickupCount = pickups.filter(pickup => !pickup.routeId).length;
                const noneDefaultPickup = pickups.filter(pickup => pickup.type !== 'Default').length;
                setRoutePickupCount(routePickupCount)
                setNoneRoutePickupCount(nonRoutePickupCount)
                setNoneDefaultPickups(noneDefaultPickup)
                setAllPickups(pickups);


                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };

    const handleSearch = async (currentPage = 1) => {
        setLoading(true);
        try {
            const searchParams = {
                pickupLocationId: pickupLocation?.value || null,    // Extract value from react-select
                dropOffLocationId: dropOffLocation?.value || null,  // Extract value from react-select
                status: statusSelected.value || null,  // Ensure days is an array
                courier: courier?.value || null,  // Extract value from react-select
                route: route?.value || null,  // Extract value from react-select
                page: currentPage,
                limit: itemsPerPage,
            };

            console.log(searchParams)
            setSearching(true)

            const response = await searchPickups(searchParams);
            if (response.success) {

                setAllPickups(response.data.pickups);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);

            } else if (response.data.totalItems === 0) {
                setAllPickups([])
            } else {
                toast({
                    title: "Oops w!",
                    description: response.error,
                    status: "error",
                    duration: 4000, // duration in milliseconds
                    isClosable: true,
                });

            }
        } catch (error) {
            console.error('Error performing search:', error);
        }
        setLoading(false);
    };

    const handleDeleteRoute = async () => {
        setLoadingProcess(true);
        try {
            if (!cancellationReason) {
                setModalError("You need to type cancellation reason before canceling order")
                setLoadingProcess(false);
                return;
            }

            const response = await cancelPickup(selectedPickup._id, cancellationReason);
            if (response.success) {
                setAllPickups(allPickups.filter(pickup => pickup._id !== selectedPickup._id));
                toast({
                    title: "Canceled Successful",
                    description: `The pickup has been canceled Completely`,
                    status: "success",
                    duration: 3000, // duration in milliseconds
                    isClosable: true,
                });
                setCancellationReason("");
                setModalError("")
                onCloseDeleteModal();
            } else {
                setModalError(response.error);
            }
        } catch (error) {
            setModalError('Error Canceling pickup');
        }
        setLoadingProcess(false);
    };

    const clearSearch = () => {
        setSearching(false);
        setPickupLocation(null);
        setDropOffLocation(null);
        setCourier(null);
        setRoute(null);
        setSelectedStatus([]);
        getPickups(); // Reloads default pickups data
        setPendingChanges(false)
    };

    const handlePageChanges = (page) => {
        if (searching) {
            handleSearch(page);
        } else {
            getPickups(page);
        }
    };

    const status = (status) => {
        switch (status) {
            case 'PENDING':
                return "Pending";
            case 'PICKED_UP':
                return "Picked Up"
            case 'COMPLETED':
                return 'Received'
            case 'CANCELED':
                return "CANCELED"
            default:
                break;
        }
    }

    function getRequirementsIcons(requirements = {}) {
        const icons = [];


        if (requirements.hasDocs) {
            icons.push(
                <Tooltip label="This pickup has documents/envelopes" placement='top' key="docs">
                    <span><FaEnvelope size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.hasSupplies) {
            icons.push(
                <Tooltip label="This pickup has supplies" placement='top' key="supplies">
                    <span><FaBox size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.requestPhoto) {
            icons.push(
                <Tooltip label="This pickup requires a photo" placement='top' key="photo">
                    <span><FaPhotoVideo size={15} /></span>
                </Tooltip>
            );
        }


        return icons.length > 0 ? (
            <span style={{ display: 'flex', gap: '15px', alignItems: 'center', padding: 5 }}>
                {icons}
            </span>
        ) : "N/A";
    }

    // Reorder pickups state based on the result of a drag action
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedPickups = Array.from(allPickups);
        const [removed] = reorderedPickups.splice(result.source.index, 1);
        reorderedPickups.splice(result.destination.index, 0, removed);
        setAllPickups(reorderedPickups);
        setPendingChanges(true)
    };

    const handleTransferPickup = async () => {
        try {
            const updateParams = {
                courier: transferCourier.value,
                oldCourierName: courier.label,
                newCourierName: transferCourier.label
            };

            const response = await transferPickup(updateParams, selectedPickup._id);
            if (response.success) {

                setAllPickups(prevPickups => prevPickups.filter(pickup => pickup._id !== selectedPickup._id));

                toast({
                    title: "Great!",
                    description: "Pickup transfered",
                    status: "success",
                    duration: 4000, // duration in milliseconds
                    isClosable: true,
                });
                onCloseTransferModal()

            } else {
                toast({
                    title: "Oops w!",
                    description: response.error,
                    status: "error",
                    duration: 4000, // duration in milliseconds
                    isClosable: true,
                });

            }
        } catch (error) {
            console.error('Error performing search:', error);
        }
    }

    const handleBulkPickupTransfer = async () => {
        try {
            const updateParams = {
                courier: transferCourier.value,
                oldCourierName: courier.label,
                newCourierName: transferCourier.label
            };

            const updatePromises = allPickups.map((pickup) =>
                transferPickup(updateParams, pickup._id)
            );

            const responses = await Promise.all(updatePromises);
            const failedUpdates = responses.filter(response => !response.success);

            if (failedUpdates.length === 0) {
                setAllPickups([]);
                toast({
                    title: "Great!",
                    description: "All pickups transferred successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Partial Success",
                    description: `${failedUpdates.length} pickups failed to transfer`,
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                });
            }

            onCloseTransferModal();
        } catch (error) {
            console.error('Error transferring all pickups:', error);
            toast({
                title: "Error",
                description: "Failed to transfer all pickups",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    };


    const saveOrder = async () => {
        try {
            // Update each pickup's pickupOrder based on its current index
            const reorderedPickups = allPickups.map((pickup, index) => ({
                ...pickup,
                pickupOrder: index + 1, // Assuming 1-based order
            }));

            // Send the reordered pickups to the backend
            const response = await savePickupOrder(reorderedPickups); // savePickupOrder is an assumed API function

            if (response.success) {
                setAllPickups(reorderedPickups);  // Update state with new order
                setPendingChanges(false);  // Reset pending changes
                toast({
                    title: "Order Saved",
                    description: "The new order has been successfully saved.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                throw new Error(response.error);
            }
        } catch (error) {
            toast({
                title: "Error Saving Order",
                description: error.message || "An error occurred while saving the order.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleUpdatePickup = (updatedPickup) => {
        setAllPickups((prevPickups) =>
            prevPickups
                .filter((pickup) => pickup._id !== updatedPickup._id || updatedPickup.status !== 'RECEIVED')
                .map((pickup) => (pickup._id === updatedPickup._id ? updatedPickup : pickup))
        );
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to array
        const imageFiles = files.filter(file => file.type.startsWith('image/')); // Only keep image files
        setSelectedFiles(imageFiles);

        // Alert if some files are not images
        if (files.length !== imageFiles.length) {
            alert('Only image files are allowed.');
        }
    };

    // Handle file upload submission
    const handleFileUpload = async () => {
        if (selectedFiles.length === 0) {
            alert('No files selected');
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('documents', file);
        });
        formData.append('folder', 'couriers-attachements');

        try {

            const response = await uploadPickupAttachements(formData, selectedPickup._id)
            console.log(response)
            if (response.success) {
                alert('Files uploaded successfully');
                setSelectedFiles([]); // Clear selected files
                // onCloseViewModal(); // Optionally close the modal
            } else {
                alert(response.error);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('Error uploading files');
        }
    };


    const handleDeleteAttachment = async (attachmentId) => {
        if (!window.confirm('Are you sure you want to delete this attachment?')) {
            return;
        }
        try {
            const response = await deletePickupAttachements(attachmentId, selectedPickup._id);
            if (response.success) {
                // Directly update selectedPickup's attachments
                selectedPickup.attachments = selectedPickup.attachments.filter(
                    (attachment) => attachment._id !== attachmentId
                );

                // Optionally, if selectedPickup is in state, trigger a re-render
                setSelectedPickup({ ...selectedPickup });

                // alert("Attachment deleted successfully");
            } else {
                console.log(response.message || 'Failed to delete attachment');
            }
        } catch (error) {
            console.error('Error deleting attachment:', error);
            alert('Error deleting attachment');
        }
    };




    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title={`Today's Pickups`}
                    buttonText={hasPermission('createPickup') ? "Add Pickup" : null}
                    onClick={hasPermission('createPickup') ? () => setIsCreateOpen(true) : null}
                />

                <HStack p={2} mb={3} spacing={4} align="start">
                    <FormControl isRequired>
                        <FormLabel>Pickup Location</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchFacilitiesBySearch}
                            defaultOptions
                            onChange={(option) => setPickupLocation(option)}  // Set entire option
                            value={pickupLocation || null} // Use the selected option directly
                            placeholder="Search and select a facility"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>

                    <FormControl isRequired>
                        <FormLabel>Courier</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchCouriersBySearch}
                            defaultOptions
                            onChange={(option) => setCourier(option)}  // Set entire option
                            value={courier || null} // Use the selected option directly
                            placeholder="Search and select a courier"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>

                    <FormControl isRequired>
                        <FormLabel>Route</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchRouteBySearch}
                            defaultOptions
                            onChange={(option) => setRoute(option)}  // Set entire option
                            value={route || null} // Use the selected option directly
                            placeholder="Search and select a route"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>


                    <FormControl isRequired>
                        <FormLabel>Drop off Location</FormLabel>
                        <Select
                            cacheOptions
                            closeMenuOnSelect={true}
                            isClearable
                            options={labLocations.map(lab => ({ value: lab._id, label: lab.name }))}
                            value={dropOffLocation}  // Use the selected object directly
                            onChange={(option) => setDropOffLocation(option || null)}  // Store entire option or null if cleared
                        />
                    </FormControl>


                    <FormControl isRequired>
                        <FormLabel>Status</FormLabel>
                        <Select
                            options={statusOptions}
                            value={statusSelected}
                            onChange={(selectedOptions) => setSelectedStatus(selectedOptions || [])} // Update days
                        />
                    </FormControl>
                    <VStack>
                        <Button mt={searching ? 0 : 34} colorScheme="blue" size={"sm"} onClick={() => handleSearch(1)}>
                            Search
                        </Button>

                        {searching &&
                            <Button colorScheme="red" size={"sm"} onClick={clearSearch}>
                                Clear
                            </Button>
                        }
                    </VStack>
                </HStack>



                <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(20%, 1fr))'>
                    <Card>
                        <CardHeader>
                            <Heading size='md'> All Pickups</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text><strong>{allPickups?.length}</strong> Pickup in total</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Heading size='md'> Daily / Routes Pickups</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text><strong>{routePickupCount}</strong>  Daily pickup from the routes</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Heading size='md'> On Call / Added Pickups</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text><strong>{noneRoutePickupCount}</strong>  On Call Pickup Added</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Heading size='md'> Other Pickups</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text><strong>{noneDefaultPickups}</strong>  Other Pickups between dropoffs and supplies</Text>
                        </CardBody>
                    </Card>
                </SimpleGrid>




                <Divider />

                {loading ?
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                    :
                    <>
                        {error ? (
                            <AccessDenied msg={error} />
                        )
                            :


                            (courier && searching) ? (
                                <>
                                    {pendingChanges && (
                                        <Alert status='info' variant='subtle' flexDirection='column' alignItems='center' justifyContent='center' textAlign='center' height='200px'>
                                            <AlertTitle mt={4} mb={1} fontSize='lg'>Pickups order changed</AlertTitle>
                                            <AlertDescription maxWidth='sm'>
                                                You have changed the order of the pickups for this courier, but you didn't save the changes, Click Save Order when you’re done.
                                                <Divider mt={3} />
                                                <Button isLoading={false} loadingText={"Saving Order"} mt={4} size="sm" colorScheme="red" onClick={saveOrder}>
                                                    Save Order
                                                </Button>
                                            </AlertDescription>
                                        </Alert>
                                    )}


                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="pickups">
                                            {(provided) => (
                                                <Table ref={provided.innerRef} {...provided.droppableProps} variant="simple">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>#</Th>
                                                            <Th>Pickup</Th>
                                                            <Th>Drop</Th>
                                                            <Th>Courier</Th>
                                                            <Th>Type</Th>
                                                            <Th>RQ</Th>
                                                            <Th>Route</Th>
                                                            <Th>Status</Th>
                                                            <Th>SC</Th>
                                                            <Th>Created At</Th>
                                                            <Th textAlign="right">

                                                                <Button ml={4} size="xs" colorScheme="purple" onClick={() => { onOpenTransferModal(); setTransferAllPickups(true) }}>
                                                                    <FaShare /> Transfer All Pickups from {courier.label}
                                                                </Button>

                                                            </Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {allPickups.map((pickup, index) => (
                                                            <Draggable key={pickup._id} draggableId={pickup._id} index={index}>
                                                                {(provided) => (
                                                                    <Tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        color={pickup.type === 'Supplies' ? 'white' : 'gray.900'}
                                                                        bg={pickup.type === 'DropOffOnly' ? "pink.100" : pickup.type === 'Supplies' ? 'purple' : 'white'}
                                                                        _hover={{ bg: "gray.700", color: "white" }}
                                                                    >
                                                                        <Td maxW={1}><PopoverId place="Pickup" index={index} content={pickup._id} /></Td>
                                                                        <Td>{pickup?.pickupLocation?.name || "N/A"}</Td>
                                                                        <Td>{pickup?.dropOffLocation?.name || "N/A"}</Td>
                                                                        <Td>{pickup.courier?.name}</Td>
                                                                        <Td><Badge colorScheme={pickup.type === 'DropOffOnly' ? 'pink' : pickup.type === 'Supplies' ? 'purple' : "orange"}>{pickup.type}</Badge></Td>
                                                                        <Td>{getRequirementsIcons(pickup.requirements)}</Td>
                                                                        <Td><Badge colorScheme={pickup.routeId?.name ? 'red' : 'green'}>{pickup.routeId?.name || "On Call"}</Badge></Td>
                                                                        <Td><Badge colorScheme="blue">{status(pickup.status)}</Badge></Td>
                                                                        <Td>{pickup.status === 'PENDING' ? <FaClock /> : pickup.samplesCount}</Td>
                                                                        <Td>{new Date(pickup.createdAt).toLocaleTimeString()}</Td>
                                                                        <Td textAlign="right">
                                                                            <HStack justifyContent="flex-end" spacing={4}>
                                                                                <Button size="xs" colorScheme="green" onClick={() => { setSelectedPickup(pickup); onOpenViewModal(); }}><ViewIcon mr={2} /> View</Button>
                                                                                <Button size="xs" colorScheme="purple" onClick={() => { setSelectedPickup(pickup); onOpenTransferModal(); setTransferAllPickups(false) }}><FaShare /> Transfer</Button>
                                                                                {hasPermission('updatePickup') && <Button size="xs" colorScheme="blue" onClick={() => { setSelectedPickup(pickup); onOpenDeleteModal(); }}><EditIcon mr={2} /> Edit</Button>}
                                                                                {hasPermission('deletePickup') && <Button size="xs" colorScheme="red" onClick={() => { setSelectedPickup(pickup); onOpenDeleteModal(); }}><DeleteIcon mr={2} /> Cancel</Button>}
                                                                            </HStack>
                                                                        </Td>
                                                                    </Tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </Tbody>
                                                </Table>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                            ) : allPickups.length > 0 ? (
                                <>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>#ID</Th>
                                                <Th>Pickup</Th>
                                                <Th>Drop</Th>
                                                <Th>Courier</Th>
                                                <Th>Type</Th>
                                                <Th>RQ</Th>
                                                <Th>Route</Th>
                                                <Th>Order</Th>
                                                <Th>Status</Th>
                                                <Th>SC</Th>
                                                <Th>Created</Th>
                                                <Th textAlign="right"> Actions</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {allPickups?.map((pickup, index) => (
                                                <Tr
                                                    color={pickup.type === 'Supplies' ? 'white' : 'gray.900'}
                                                    bg={pickup.type === 'DropOffOnly' ? "pink.100" : pickup.type === 'Supplies' ? 'purple' : 'white'}
                                                    _hover={{ bg: "gray.700", color: "white" }} key={pickup._id}
                                                >
                                                    <Td maxW={1}><PopoverId place='Pickup' index={index} content={pickup._id} /></Td>
                                                    <Td>{pickup?.pickupLocation?.name || "N/A"}</Td>
                                                    <Td>{pickup?.dropOffLocation?.name || "N/A"}</Td>
                                                    <Td>{pickup.courier?.name}</Td>
                                                    <Td>
                                                        <Badge colorScheme={pickup.type === 'DropOffOnly' ? 'pink' : pickup.type === 'Supplies' ? 'purple' : "orange"}>
                                                            {pickup.type === 'DropOffOnly' ? "Drop Off" : pickup.type === 'Default' ? "Pickup" : pickup.type}
                                                        </Badge>
                                                    </Td>

                                                    <Td>
                                                        {getRequirementsIcons(pickup.requirements)}
                                                    </Td>


                                                    <Td>
                                                        {/* {pickup.pickupOrder} */}
                                                        <Badge colorScheme={pickup.routeId?.name ? 'red' : 'green'}>
                                                            {pickup.routeId?.name || "On Call"}
                                                        </Badge>
                                                    </Td>

                                                    <Td>
                                                        <Badge colorScheme={'orange'}>
                                                            {pickup.pickupOrder}
                                                        </Badge>
                                                    </Td>


                                                    <Td>
                                                        <Badge colorScheme={"blue"}>
                                                            {status(pickup.status)}
                                                        </Badge>
                                                    </Td>

                                                    <Td>{pickup.status === 'PENDING' ? <FaClock /> : pickup.samplesCount}</Td>

                                                    <Td>{new Date(pickup.createdAt).toLocaleTimeString()}</Td>


                                                    <Td textAlign="right">
                                                        <HStack justifyContent="flex-end" spacing={4}>

                                                            <Button onClick={() => { navigate('/pickups/view/' + pickup._id) }} size="xs" colorScheme="green">
                                                                <ViewIcon mr={2} /> View
                                                            </Button>

                                                            {hasPermission('updatePickup') &&
                                                                <Button onClick={() => {
                                                                    setSelectedPickup(pickup);
                                                                    setIsModalOpen(true)
                                                                }} size="xs" colorScheme="blue">
                                                                    <EditIcon mr={2} /> Edit
                                                                </Button>
                                                            }

                                                            {hasPermission('deletePickup') &&

                                                                <Button onClick={() => { setSelectedPickup(pickup); onOpenDeleteModal(); }} size="xs" colorScheme="red">
                                                                    <DeleteIcon mr={2} /> Cancel
                                                                </Button>
                                                            }



                                                        </HStack>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table >

                                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChanges} />
                                </>
                            ) : (
                                <AccessDenied msg="Nothing here yet !" icon='info' />
                            )}
                        {selectedPickup &&
                            <>
                                <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Confirm Cancellation</ModalHeader>
                                        <ModalBody>

                                            {modalError &&
                                                <Alert mb={5} status='error'>
                                                    <AlertDescription>{modalError}</AlertDescription>
                                                </Alert>
                                            }


                                            Are you sure you want to cancel this pickup ?
                                            <br /><br />
                                            <strong>{selectedPickup?.pickupLocation?.name || ''}</strong>
                                            <br /><br />
                                            <FormControl mb={3} isRequired>
                                                <FormLabel>Cancellation Reason</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={cancellationReason}
                                                    onChange={(e) => setCancellationReason(e.target.value)}
                                                    placeholder="Cancellation Reason"
                                                />
                                            </FormControl>

                                        </ModalBody>
                                        <ModalFooter>
                                            <Button mr={5} isLoading={loadingProcess} colorScheme="red" onClick={handleDeleteRoute}>Yes</Button>
                                            <Button onClick={onCloseDeleteModal}>No</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>


                                <Modal size={"full"} scrollBehavior='inside' isOpen={isViewModalOpen} onClose={onCloseViewModal}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>{selectedPickup?.pickupLocation?.name || selectedPickup?.dropOffLocation?.name}
                                            <Badge ml={5} colorScheme={"blue"}>
                                                {status(selectedPickup.status)}
                                            </Badge>
                                        </ModalHeader>
                                        <ModalBody>

                                            <Flex direction={["column", "row"]} wrap="wrap" maxW={'100%'} gap={4} p={4}>
                                                {/* Left Column (25% width) */}
                                                <Box flex={["1 1 100%", "1 1 48%"]} maxHeight="550px" overflowY="auto" bg="gray.50" p={4} boxShadow="md">
                                                    {/* Content for the left column goes here */}
                                                    <VStack spacing={6} align="start" p={4}>
                                                        {/* Timeline for pickup creation */}
                                                        <HStack align="start" spacing={4}>
                                                            {/* Timeline Marker */}
                                                            <Flex direction="column" align="center">
                                                                <Circle size="10px" bg="blue.500" />
                                                                <Box h="100px" w="2px" bg="gray.300" />
                                                            </Flex>

                                                            {/* Timeline Content */}
                                                            <Box>
                                                                <Text fontSize="sm" color="gray.500">
                                                                    {new Date(selectedPickup.createdAt).toLocaleDateString() +
                                                                        " - " +
                                                                        new Date(selectedPickup.createdAt).toLocaleTimeString()}
                                                                </Text>
                                                                <Text fontSize="lg" fontWeight="bold">Pickup Created</Text>
                                                                <Text fontSize="md" color="gray.600">
                                                                    Pickup was{" "}
                                                                    {selectedPickup.routeId?.name ? (
                                                                        <>
                                                                            generated from route{" "}
                                                                            <Popover>
                                                                                <PopoverTrigger>
                                                                                    <Text
                                                                                        as="span"
                                                                                        fontWeight="bold"
                                                                                        color="blue.500"
                                                                                        cursor="pointer"
                                                                                    >
                                                                                        {selectedPickup?.routeId?.name}
                                                                                    </Text>
                                                                                </PopoverTrigger>
                                                                                <PopoverContent>
                                                                                    <PopoverArrow />
                                                                                    <PopoverCloseButton />
                                                                                    <PopoverHeader fontWeight="bold" fontSize="lg">
                                                                                        {selectedPickup?.routeId?.name}
                                                                                    </PopoverHeader>
                                                                                    <PopoverBody>
                                                                                        <VStack align="start" spacing={2}>
                                                                                            <Text fontSize="sm">
                                                                                                {selectedPickup?.routeId?.cities}
                                                                                            </Text>
                                                                                        </VStack>
                                                                                    </PopoverBody>
                                                                                </PopoverContent>
                                                                            </Popover>
                                                                        </>
                                                                    ) : (
                                                                        "added"
                                                                    )}{" "}
                                                                    By {selectedPickup.createdBy?.firstName}
                                                                </Text>


                                                            </Box>
                                                        </HStack>

                                                        {/* Scrollable Timeline */}
                                                        <Box w="100%" pt={2}>
                                                            {selectedPickup?.lastUpdated?.length > 0 &&
                                                                selectedPickup.lastUpdated.map((log, index) => (
                                                                    <>
                                                                        <HStack align="start" spacing={4} mt={4} pb={3} key={index}>
                                                                            {/* Timeline Marker */}
                                                                            <Flex direction="column" align="center">
                                                                                <Circle size="10px" bg="blue.500" />
                                                                                {index < selectedPickup.lastUpdated.length - 1 && <Box h="90px" w="2px" bg="gray.300" />}
                                                                            </Flex>

                                                                            {/* Timeline Content */}
                                                                            <Box style={{ width: '100%' }}>
                                                                                <Text fontSize="sm" color="gray.500">
                                                                                    {new Date(log.timestamp).toLocaleDateString() +
                                                                                        " - " +
                                                                                        new Date(log.timestamp).toLocaleTimeString()}
                                                                                </Text>
                                                                                <Text fontSize="lg" fontWeight="bold">{log.action}</Text>
                                                                                <Text fontSize="md" color="gray.600">
                                                                                    {log.action === 'Pickup Requested' ? "Requested By " : "Updated by "}  {log.updatedBy?.firstName || "Unknown User"}
                                                                                </Text>
                                                                                <Box mt={2}>
                                                                                    <SimpleGrid columns={3} spacing={4}>
                                                                                        {log.fields.map((field, fieldIndex) => (
                                                                                            noReturn.includes(field.field) ? null :
                                                                                                <Box key={fieldIndex} mb={2}>
                                                                                                    <Text fontSize="sm" color="gray.600">
                                                                                                        <strong>{humanReadableField(field.field)}</strong>:
                                                                                                    </Text>
                                                                                                    <Text fontSize="sm" color="gray.500">
                                                                                                        Previously : <strong>{formatValue(field.previousValue)}</strong>
                                                                                                    </Text>
                                                                                                    <Text fontSize="sm" color="gray.500">
                                                                                                        Changed To : <strong>{formatValue(field.newValue)}</strong>
                                                                                                    </Text>
                                                                                                </Box>
                                                                                        ))}
                                                                                    </SimpleGrid>
                                                                                </Box>
                                                                            </Box>
                                                                        </HStack>
                                                                        <Divider />
                                                                    </>
                                                                ))}
                                                        </Box>
                                                    </VStack>
                                                </Box>


                                                {/* Right Column (75% width) */}
                                                <Box flex={["1 1 100%", "1 1 48%"]} p={4}>
                                                    {/* Content for the right column goes here */}
                                                    <HStack alignItems="flex-start" spacing={8}>
                                                        {/* Pickup and Drop Details */}
                                                        <Box borderRight={'1px solid #eef1f6'} pr={5} pb={5}>
                                                            <Heading as="h2" size="lg" mb={4}>Pickup Details</Heading>
                                                            <Text mb={3}>
                                                                <strong>Pickup</strong><br />
                                                                <span style={{ fontWeight: "500", fontSize: 15, marginRight: 5 }}>
                                                                    {selectedPickup.pickupLocation.name}
                                                                </span>
                                                                <small>
                                                                    ({selectedPickup.pickupLocation.address.city}) -
                                                                    <a
                                                                        href={getGoogleMapsLink(selectedPickup.pickupLocation.address)}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                    >
                                                                        View in map
                                                                    </a>
                                                                </small>
                                                            </Text>

                                                            <Text>
                                                                <strong>Drop</strong><br />
                                                                <span style={{ fontWeight: "500", fontSize: 15 }}>
                                                                    {selectedPickup.dropOffLocation.name}
                                                                </span>
                                                                <small>
                                                                    ({selectedPickup.dropOffLocation.address.city})
                                                                </small>
                                                            </Text>
                                                        </Box>

                                                        {/* Assigned Courier */}
                                                        <Box>
                                                            <Heading as="h3" size="lg" mb={4}>Assigned Courier</Heading>
                                                            <Text>
                                                                <strong>{selectedPickup.courier.name}</strong>
                                                            </Text>
                                                            <Text mt={5}>
                                                                <Badge mr={5} colorScheme={selectedPickup.type === 'DropOffOnly' ? 'pink' : selectedPickup.type === 'Supplies' ? 'purple' : "orange"}>
                                                                    {selectedPickup.type === 'DropOffOnly' ? "Drop Off" : selectedPickup.type === 'Default' ? "Pickup" : selectedPickup.type}
                                                                </Badge>
                                                                <Badge mr={5} colorScheme={selectedPickup.routeId?.name ? 'red' : 'green'}>
                                                                    {selectedPickup.routeId?.name || "On Call"}
                                                                </Badge>
                                                                <Badge colorScheme={'purple'}>
                                                                    Pickup Order #{selectedPickup.pickupOrder}
                                                                </Badge>

                                                            </Text>

                                                        </Box>
                                                    </HStack>


                                                    <Divider />

                                                    <HStack alignItems="flex-start" spacing={8}>
                                                        {/* Pickup Requirements */}
                                                        <Box pb={5} borderRight={'1px solid #eef1f6'} pr={5} >
                                                            <Heading mt={5} as="h3" size="lg">Pickup Requirements</Heading>
                                                            <Text mt={2}>
                                                                {getRequirementsIcons(selectedPickup.requirements)}
                                                            </Text>
                                                        </Box>

                                                        {/* Samples Count */}
                                                        <Box pb={5}>
                                                            <Heading mt={5} as="h3" size="lg">Samples Count</Heading>
                                                            <Heading mt={5} as="h6" size="lg" fontWeight={'light'}>
                                                                {selectedPickup.samplesCount || "Not Picked Up Yet"}
                                                            </Heading>

                                                        </Box>
                                                    </HStack>

                                                    <Divider />


                                                    <HStack alignItems="flex-start" spacing={8}>
                                                        <Box pb={5}>
                                                            <Heading mt={5} as="h3" size="lg">
                                                                Attachments
                                                                {/* Icon for file selection */}
                                                                <IconButton
                                                                    ml={4}
                                                                    icon={<FaCloudUploadAlt />}
                                                                    onClick={() => document.getElementById('file-input').click()} // Trigger file input click
                                                                    aria-label="Add Files"
                                                                />
                                                            </Heading>

                                                            {/* Hidden file input */}
                                                            <Input
                                                                id="file-input"
                                                                type="file"
                                                                accept="image/*"
                                                                multiple
                                                                onChange={handleFileChange}
                                                                display="none" // Hide the input field
                                                            />

                                                            {/* Display number of files selected */}
                                                            {selectedFiles.length > 0 && (
                                                                <>
                                                                    <Text mt={2} fontSize="md">
                                                                        {selectedFiles.length} file(s) selected
                                                                    </Text>

                                                                    <Button
                                                                        mt={4}
                                                                        colorScheme="blue"
                                                                        onClick={() => handleFileUpload(selectedFiles)}
                                                                        isDisabled={selectedFiles.length === 0}
                                                                    >
                                                                        Submit Files
                                                                    </Button>
                                                                </>
                                                            )}
                                                            {/* Display attachments */}
                                                            <SimpleGrid columns={[2, 3, 8]} spacing={6} mt={6}>
                                                                {selectedPickup?.attachments?.length > 0 && (
                                                                    selectedPickup.attachments
                                                                        .filter((attachment) => !attachment.deletedAt) // Filter out deleted attachments
                                                                        .map((attachment, index) => (
                                                                            <Box
                                                                                key={index}
                                                                                position="relative"
                                                                                textAlign="center"
                                                                                _hover={{ '.delete-button': { opacity: 1 } }} // Show the delete button on hover
                                                                            >
                                                                                {/* Image */}
                                                                                <ImageLightbox
                                                                                    imageUrl={"http://206.189.202.224:5000" + attachment.fileUrl}
                                                                                    altText="Pickup Attachment"
                                                                                    boxSize="64px"
                                                                                    borderRadius="md"
                                                                                    boxShadow="md"
                                                                                    cursor="pointer"
                                                                                />

                                                                                {/* Delete Button */}
                                                                                <Button
                                                                                    className="delete-button"
                                                                                    position="absolute"
                                                                                    top="10%"
                                                                                    left="0%"
                                                                                    transform="translate(-50%, -50%)"
                                                                                    opacity={0} // Hidden by default
                                                                                    transition="opacity 0.2s ease"
                                                                                    colorScheme="red"
                                                                                    size="xs"
                                                                                    onClick={() => handleDeleteAttachment(attachment._id)}
                                                                                >
                                                                                    X
                                                                                </Button>
                                                                            </Box>
                                                                        ))
                                                                )}
                                                            </SimpleGrid>

                                                        </Box>
                                                    </HStack>

                                                </Box>
                                            </Flex>

                                            <PickupNotes pickupId={selectedPickup._id} existingNotes={selectedPickup.notes} addNote={selectedPickup?.finishedAt ? false : true} />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={onCloseViewModal}>Close</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>

                                <EditPickupModal
                                    isOpen={isModalOpen}
                                    onClose={() => {
                                        setSelectedPickup(null)
                                        setIsModalOpen(false)
                                    }}
                                    pickup={selectedPickup}
                                    onUpdatePickup={handleUpdatePickup}
                                />

                            </>
                        }
                    </>
                }



                <Modal isOpen={isTransferModalOpen} onClose={onCloseTransferModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Transfer {transferAllPickups ? "All Pickups" : selectedPickup?.pickupLocation?.name}</ModalHeader>
                        <ModalBody>

                            <Text mb={4}>Select courier to transfer this pickup to</Text>
                            <FormControl isRequired>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={fetchCouriersBySearch}
                                    defaultOptions
                                    onChange={(option) => setTransferCourier(option)}  // Set entire option
                                    value={transferCourier || null} // Use the selected option directly
                                    placeholder="Search and select a courier"
                                    closeMenuOnSelect={true}
                                    isClearable
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button mr={5} isLoading={loadingProcess} colorScheme="red" onClick={transferAllPickups ? handleBulkPickupTransfer : handleTransferPickup}>Transfer</Button>
                            <Button onClick={onCloseTransferModal}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <CreatePickupModal
                    isOpen={isCreateOpen}
                    onClose={() => setIsCreateOpen(false)}
                    onCreate={() => null}
                />


            </VStack >
        </Box >
    );
};

export default AllPickups;
