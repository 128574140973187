import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    useToast,
    Text,
    SimpleGrid,
    Divider,
    Badge,
} from '@chakra-ui/react';
import { fetchPSCLocations, createPSCLocation, updatePSCLocation, deletePSCLocation, restorePSCLocation } from '../../api';
import { ViewIcon, EditIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import CreatePSCLocationModal from './CreatePSCLocationModal';
import EditPSCLocationModal from './EditPSCLocationModal';
import DeletePSCModal from './DeletePSCModal';
import ExportPSCModal from './ExportPSCModal';
import { hasPermission } from '../../Utils';

const PSCLocationsPage = () => {
    const [pscLocations, setPSCLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 40; // Adjust as needed
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    // Fetch PSC locations
    useEffect(() => {
        fetchLocations();
    }, []);

    const fetchLocations = async (page = 1) => {
        setLoading(true);
        try {
            const result = await fetchPSCLocations(page, itemsPerPage, false);
            console.log(result)
            if (result.success) {
                setPSCLocations(result.data.locations);
                setCurrentPage(result.data.currentPage);
                setTotalPages(result.data.totalPages);
            } else {
                setError(result.error || 'Failed to fetch locations');
            }
        } catch (error) {
            setError('Error fetching locations');
        } finally {
            setLoading(false);
        }
    };




    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }
    if (error) {
        return (
            <AccessDenied msg={error} />
        )
    }
    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="PSC Locations"
                    buttonText={hasPermission('createPSCLocations') ? "New PSC Location" : null}
                    onClick={hasPermission('createPSCLocations') ? () => setIsOpenCreate(true) : null}

                    secondButton={hasPermission('exportPSCLocations')}
                    secondButtonOnClick={() => setExportModal(true)}
                    secondButtonText='Export'
                />

                <ExportPSCModal
                    isOpen={exportModal}
                    onClose={() => setExportModal(false)}
                    pscData={pscLocations} />

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {/* <Th>#</Th> */}
                            <Th>Location Name</Th>
                            <Th>Address</Th>
                            <Th>Hours of Operation</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {pscLocations.map((location, index) => (
                            <Tr _hover={{ bg: "gray.100" }} key={location._id}>
                                {/* <Td>{index + 1}</Td> */}
                                <Td>{location.name}</Td>
                                <Td>{location.address}</Td>
                                <Td>{location.hoursOfOperation}</Td>
                                <Td>
                                    <HStack>
                                        {hasPermission('updatePSCLocations') &&
                                            <Button size="sm" onClick={() => setSelectedItem(location) || setIsOpenEdit(true)} colorScheme="green">
                                                <EditIcon mr={2} /> Edit
                                            </Button>
                                        }
                                        {hasPermission("deletePSCLocations") &&
                                            <Button size="sm" onClick={() => setSelectedItem(location) || setIsOpenDelete(true)} colorScheme="red">
                                                <DeleteIcon mr={2} /> Delete
                                            </Button>
                                        }
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>


                {/* Pagination */}
                <HStack mt={4} justify="center">
                    <Button onClick={() => fetchLocations(currentPage - 1)} isDisabled={currentPage == 1}>
                        &laquo; Previous
                    </Button>
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <Button
                            key={index}
                            onClick={() => fetchLocations(index + 1)}
                            colorScheme={currentPage == index + 1 ? 'teal' : 'gray'}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <Button onClick={() => fetchLocations(currentPage + 1)} isDisabled={currentPage == totalPages}>
                        Next &raquo;
                    </Button>
                </HStack>
            </VStack>

            {/* Modals for Create, Update */}
            <CreatePSCLocationModal
                isOpen={isOpenCreate}
                onClose={() => setIsOpenCreate(false)}
                setPSCLocations={setPSCLocations}
            />

            <DeletePSCModal
                isOpen={isOpenDelete}
                onClose={() => setIsOpenDelete(false)}
                onDelete={(locationId) =>
                    setPSCLocations((prevLocations) =>
                        prevLocations.filter((location) => location._id !== locationId)
                    )
                }  // Pass the ID to delete
                location={selectedItem}  // Show the name of the PSC location
            />

            {selectedItem && (
                <EditPSCLocationModal
                    isOpen={isOpenEdit}
                    onClose={() => setIsOpenEdit(false)}
                    locationData={selectedItem}
                    onSuccess={(data) =>
                        setPSCLocations((prevLocations) =>
                            prevLocations.map((location) =>
                                location._id === data._id ? { ...location, ...data } : location
                            )
                        )
                    }
                />
            )}
        </Box>
    );
};

export default PSCLocationsPage;
