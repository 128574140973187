import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Button,
    Text,
    useToast,
} from '@chakra-ui/react';
import { deletePSCLocation } from '../../api';
import { alert } from '../../Utils';

const DeletePSCModal = ({ isOpen, onClose, onDelete, location }) => {
    const toast = useToast();



    const handleDeletePSCLocation = async () => {
        try {
            const result = await deletePSCLocation(location._id);
            if (result.success) {
                onDelete(location._id)
                toast({
                    title: 'PSC Location Deleted Successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose()
            } else {
                alert('Oops !', 'error', result.error, "Okay !");
                onClose()

            }
        } catch (error) {
            console.error('Error deleting PSC location:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirm Deletion</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        Are you sure you want to delete the PSC Location <strong>{location?.name}</strong>?
                        This action cannot be undone.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={handleDeletePSCLocation} ml={3}>
                        Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeletePSCModal;
