import React from 'react';
import { CircularProgress } from '@chakra-ui/react';

const Loading = () => {
    return (
        <CircularProgress isIndeterminate />
    );
};

export default Loading;
