import { Box, FormControl, Input, Heading, Button, VStack, HStack, Text, IconButton, Checkbox, Tbody, Table, Tr, Td, Image, Alert, AlertIcon, FormLabel, Select, Center } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { createBusiness, fetchSalesReps } from '../../api';
import Loading from '../../components/Loading/Loading';
import SignatureModal from '../../components/Signature/SignatureModal';
import { alert } from '../../Utils';
import { useNavigate } from 'react-router-dom';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const molecularTestsLabels = {
    covid19: 'COVID-19',
    urinaryTractInfection: 'Urinary Tract Infection',
    giPathogen: 'GI Pathogen',
    respiratoryPathogen: 'Respiratory Pathogen',
    womensHealth: 'Women’s Health',
    toenailFungal: 'Toenail Fungal',
    pharmacogenomics: 'Pharmacogenomics',
};

const routineTestsLabels = {
    serumGeneral: 'Serum General',
    serumWellness: 'Serum Wellness',
    serumAllergy: 'Serum Allergy',
    covidIGG: 'COVID IGGw/ Antibodies',
    microbiology: 'Microbiology',
    cytopathology: 'Cytopathology',
    histopathology: 'Histopathology',
    toxicology: 'Toxicology',
};

const suppliesLabels = {
    centrifuge: 'Centrifuge',
    phlebotomyChair: 'Phlebotomy Chair',
    lockboxWithKeychain: 'Lockbox w/ Keychain',
};

const SignUpBusiness = () => {

    const { handleSubmit, register, formState: { errors } } = useForm();
    const [providers, setProviders] = useState([{ name: "", license: "", npi: "" }]);
    const [contacts, setContacts] = useState([{ name: "", title: "", phone: "" }]);
    const [offDays, setOffDays] = useState(daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: false }), {}));
    const [selectedBilling, setSelectedBilling] = useState('');
    const [clientName, setClientName] = useState(''); // State to store client name

    const [salesReps, setSalesReps] = useState([]); // State to hold sales reps
    const [selectedSalesRep, setSelectedSalesRep] = useState('');
    const [loadingSalesRep, setLoadingSalesRep] = useState(true);

    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [errorSr, setErrorSr] = useState(null);

    const navigate = useNavigate()
    ////////////////Signatures///////////////
    const [tosSignature, setTosSignature] = useState(null);
    const [phiSignature, setPhiSignature] = useState(null);
    const [psdrSignature, setPsdrSignature] = useState(null);


    const [clientEmail, setClientEmail] = useState("");

    // Handle first form submission
    const onFirstFormSubmit = async (data) => {
        console.log('First Form Data:', { ...data, providers, contacts });
        setLoadingSubmit(true)

        const formData = {
            clientDetails: {
                clinicName: data.clinicName,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                phone: data.phone,
                fax: data.fax,
                email: data.email,
            },
            salesRep: selectedSalesRep,
            providers,
            contacts,
            daysOfOperation: data.daysOfOperation,
            clientStartDate: data.clientStartDate,
            tests: {
                molecularTests: data.molecularTests,
                routineTests: data.routineTests,
                supplies: data.supplies,
            },
            billingPreference: selectedBilling,
            tos: {
                orderingProvider: data.tosOrderingProviderName,
                orderingProviderDate: data.tosOrderingProviderDate,
                tosSignature: tosSignature,

            },
            phi: {
                clientName: data.clientName,
                physicianName: data.physicianName,
                physicianSignature: data.physicianSignature,
                physicianDate: data.physicianDate,
                directorInitials: data.directorInitials,
                directorSignature: data.directorSignature,
                directorDate: data.directorDate,
                phiSignature: phiSignature
            },
            additionalInfo: {
                tigerConnect: data.tigerConnect,
                portalIDPassword: data.portalIDPassword,
                accountManager: data.accountManager,
            },
            reportingPreference: {
                fax: data.faxreporting,
                portal: data.portal,
                emr: data.emr,
                emrDetails: data.emrDetails,
            },
            emails: {
                sendAccountForm: data.sendAccountForm,
                newClientEmail: data.newClientEmail,
            },
            psdr: {
                clientName: data.psdrClientName,
                physicianName: data.psdrPhysicianName,
                physicianNPI: data.psdrPhysicianNPI,
                psdrSignature: psdrSignature,
                date: data.psdrDate,
            },
        };

        const newClient = await createBusiness(formData);
        console.log(newClient._id)


        const timer = setTimeout(() => {
            // Redirect to another page, e.g., '/target-page'
            navigate(`/business-details/${newClient._id}`);
        }, 5000);


        alert("Client Created", 'success', `Client Created Successfully <br><br> you will be taken to the client page account in a seconds`, "okay !")
        setLoadingSubmit(false)
    };

    const getSalesReps = async () => {
        const reps = await fetchSalesReps();
        if (reps.success) {
            setSalesReps(reps.data);
        } else {
            setErrorSr(reps.error)
        }
        setLoadingSalesRep(false)
    };

    useEffect(() => {
        getSalesReps();
    }, []);


    const handleBillingSelection = (selection) => {
        setSelectedBilling(selection);
    };

    const toggleOffDay = (day) => {
        setOffDays({ ...offDays, [day]: !offDays[day] });
    };

    const addProvider = () => {
        setProviders([...providers, { name: "", license: "", npi: "" }]);
    };

    const removeProvider = (index) => {
        setProviders(providers.filter((_, i) => i !== index));
    };

    const handleProviderChange = (index, field, value) => {
        const updatedProviders = providers.map((provider, i) =>
            i === index ? { ...provider, [field]: value } : provider
        );
        setProviders(updatedProviders);
    };

    const addContact = () => {
        setContacts([...contacts, { name: "", title: "", phone: "" }]);
    };

    const handleContactChange = (index, field, value) => {
        const updatedContacts = contacts.map((contact, i) =>
            i === index ? { ...contact, [field]: value } : contact
        );
        setContacts(updatedContacts);
    };

    const removeContact = (index) => {
        setContacts(contacts.filter((_, i) => i !== index));
    };


    if (loadingSalesRep) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }



    return (
        <>
            <Box maxW="full" mx="auto" p="8" align="stretch">
                <form onSubmit={handleSubmit(onFirstFormSubmit)}>
                    <VStack spacing={6} align="stretch">
                        <Heading mb="6" textAlign="center">New Client</Heading>
                        {/* Client Details */}
                        <VStack spacing={4} align="stretch">
                            <Heading size="md" mb="4">Client Details</Heading>

                            <HStack flexDirection={['column', 'row']}>
                                {!errorSr &&
                                    <FormControl id="salesRep" isRequired>
                                        <FormLabel>Account/Sales Rep</FormLabel>
                                        <Select
                                            width={"55%"}
                                            placeholder="Select Account/Sales Rep"
                                            {...register('salesRep', { required: true })}
                                            onChange={(e) => setSelectedSalesRep(e.target.value)}
                                        >
                                            {salesReps.map((rep) => (
                                                <option key={rep._id} value={rep._id}>
                                                    {rep.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }

                                <FormControl id="salesRep" isRequired>
                                    <FormLabel>Client Start Date</FormLabel>
                                    <Input
                                        width={"55%"}
                                        type="date"
                                        {...register('clientStartDate', { required: true })} />
                                </FormControl>
                            </HStack>




                            <br /><hr /><br />
                            <>
                                <FormControl>
                                    <FormLabel>Client/Clinic Name</FormLabel>
                                    <Input width={"55%"}
                                        placeholder="Clinic Name"
                                        maxWidth="100%"
                                        {...register('clinicName', { required: true })}
                                        value={clientName} // Bind value to state
                                        onChange={(e) => setClientName(e.target.value)} // Update state on change
                                    />
                                </FormControl>

                                {/* Address and City */}
                                <HStack spacing={4} flexDirection={['column', 'row']}>
                                    <FormControl>
                                        <FormLabel>Address</FormLabel>
                                        <Input width={"55%"} placeholder="Address" {...register('address', { required: true })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>City</FormLabel>
                                        <Input width={"55%"} placeholder="City" {...register('city', { required: true })} />
                                    </FormControl>
                                </HStack>

                                {/* State and Zip */}
                                <HStack spacing={4} flexDirection={['column', 'row']}>
                                    <FormControl>
                                        <FormLabel>State</FormLabel>
                                        <Input width={"55%"} placeholder="State" {...register('state', { required: true })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Zip</FormLabel>
                                        <Input width={"55%"} placeholder="Zip" {...register('zip', { required: true })} />
                                    </FormControl>
                                </HStack>

                                {/* Phone, Fax, Email */}
                                <HStack spacing={4} flexDirection={['column', 'row']}>
                                    <FormControl>
                                        <FormLabel>Phone</FormLabel>
                                        <Input width={"55%"} placeholder="Phone" {...register('phone', { required: true })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Fax</FormLabel>
                                        <Input width={"55%"} placeholder="Fax" {...register('fax')} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            width={"55%"}
                                            placeholder="Email"
                                            type="email"
                                            {...register('email', { required: true })}
                                            value={clientEmail}
                                            onChange={(e) => setClientEmail(e.target.value)} // Update state on change
                                        />
                                    </FormControl>
                                </HStack>
                            </>
                        </VStack>

                        {/* Providers Section */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="md" mt="6">Providers</Heading>
                                {providers.map((provider, index) => (
                                    <VStack key={index} spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                        <HStack justifyContent="space-between" w="full">
                                            <Text>Provider {index + 1}</Text>
                                            <IconButton
                                                size="sm"
                                                icon={<CloseIcon />}
                                                onClick={() => removeProvider(index)}
                                                aria-label="Remove provider"
                                            />
                                        </HStack>
                                        <HStack spacing={4} flexDirection={['column', 'row']} w="full">
                                            <FormControl>
                                                <FormLabel>Provider Name</FormLabel>
                                                <Input
                                                    placeholder="Provider Name"
                                                    value={provider.name}
                                                    onChange={(e) => handleProviderChange(index, 'name', e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>License</FormLabel>
                                                <Input
                                                    placeholder="License"
                                                    value={provider.license}
                                                    onChange={(e) => handleProviderChange(index, 'license', e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>NPI</FormLabel>
                                                <Input
                                                    placeholder="NPI#"
                                                    value={provider.npi}
                                                    onChange={(e) => handleProviderChange(index, 'npi', e.target.value)}
                                                />
                                            </FormControl>
                                        </HStack>
                                    </VStack>
                                ))}
                                <Button colorScheme="teal" onClick={addProvider}>
                                    Add Another Provider
                                </Button>
                            </>
                        </VStack>

                        {/* Office Contacts Section */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="md" mt="6">Office Contacts</Heading>
                                {contacts.map((contact, index) => (
                                    <VStack key={index} spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                        <HStack justifyContent="space-between" w="full">
                                            <Text>Contact {index + 1}</Text>
                                            <IconButton
                                                size="sm"
                                                icon={<CloseIcon />}
                                                onClick={() => removeContact(index)}
                                                aria-label="Remove contact"
                                            />
                                        </HStack>
                                        <HStack spacing={4} flexDirection={['column', 'row']} w="full">
                                            <FormControl>
                                                <FormLabel>Contact Name</FormLabel>
                                                <Input
                                                    placeholder="Contact Name"
                                                    value={contact.name}
                                                    onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Title</FormLabel>
                                                <Input
                                                    placeholder="Title"
                                                    value={contact.title}
                                                    onChange={(e) => handleContactChange(index, 'title', e.target.value)}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Phone Number</FormLabel>
                                                <Input
                                                    placeholder="Phone Number"
                                                    value={contact.phone}
                                                    onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                                                />
                                            </FormControl>
                                        </HStack>
                                    </VStack>
                                ))}
                                <Button colorScheme="teal" onClick={addContact}>
                                    Add Another Contact
                                </Button>
                            </>
                        </VStack>

                        {/* Days Of Operation Section */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="md" mt="6">Days of Operation</Heading>
                                <Table variant="simple" mt="4">
                                    <Tbody>
                                        {daysOfWeek.map((day, index) => (
                                            <Tr key={index}>
                                                <Td><Text>{day}</Text></Td>
                                                <Td>
                                                    <Checkbox
                                                        isChecked={offDays[day]}
                                                        onChange={() => toggleOffDay(day)}
                                                    >
                                                        Closed
                                                    </Checkbox>
                                                </Td>
                                                <Td>
                                                    <FormControl isDisabled={offDays[day]}>
                                                        <FormLabel>From</FormLabel>
                                                        <Input
                                                            type="time"
                                                            placeholder="From"
                                                            {...register(`daysOfOperation[${day}].from`, { required: !offDays[day] })}
                                                        />
                                                    </FormControl>
                                                </Td>
                                                <Td>
                                                    <FormControl isDisabled={offDays[day]}>
                                                        <FormLabel>To</FormLabel>
                                                        <Input
                                                            type="time"
                                                            placeholder="To"
                                                            {...register(`daysOfOperation[${day}].to`, { required: !offDays[day] })}
                                                        />
                                                    </FormControl>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        </VStack>

                        {/* Tests Section */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="md" mt="6">Tests and Supplies</Heading>
                                <Table variant="simple" mt="4">
                                    <Tbody>
                                        <Tr>
                                            <Td><Text fontWeight="bold">Molecular</Text></Td>
                                            <Td><Text fontWeight="bold">Routine</Text></Td>
                                            <Td><Text fontWeight="bold">Supplies</Text></Td>
                                        </Tr>
                                        {Array.from({ length: Math.max(Object.keys(molecularTestsLabels).length, Object.keys(routineTestsLabels).length, Object.keys(suppliesLabels).length) }).map((_, i) => (
                                            <Tr key={i}>
                                                <Td>
                                                    {molecularTestsLabels[Object.keys(molecularTestsLabels)[i]] && (
                                                        <Checkbox {...register(`molecularTests.${Object.keys(molecularTestsLabels)[i]}`)}>
                                                            {molecularTestsLabels[Object.keys(molecularTestsLabels)[i]]}
                                                        </Checkbox>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {routineTestsLabels[Object.keys(routineTestsLabels)[i]] && (
                                                        <Checkbox {...register(`routineTests.${Object.keys(routineTestsLabels)[i]}`)}>
                                                            {routineTestsLabels[Object.keys(routineTestsLabels)[i]]}
                                                        </Checkbox>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {suppliesLabels[Object.keys(suppliesLabels)[i]] && (
                                                        <Checkbox {...register(`supplies.${Object.keys(suppliesLabels)[i]}`)}>
                                                            {suppliesLabels[Object.keys(suppliesLabels)[i]]}
                                                        </Checkbox>
                                                    )}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                            </>
                        </VStack>

                        {/* Billing Section */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="md" mt="6">Billing Preferences</Heading>
                                <Text>If insurance / cash is not provided at the time of service, who is the preferred party to bill for the service?</Text>
                                <HStack spacing={4}>
                                    <Checkbox
                                        isChecked={selectedBilling === 'client'}
                                        onChange={() => handleBillingSelection('client')}
                                    >
                                        Bill the Client
                                    </Checkbox>
                                    <Checkbox
                                        isChecked={selectedBilling === 'patient'}
                                        onChange={() => handleBillingSelection('patient')}
                                    >
                                        Bill the Patient
                                    </Checkbox>
                                </HStack>

                            </>
                        </VStack>

                        <br /><hr /><br />
                        {/* Terms of Service and Signature Display */}
                        <VStack spacing={4} align="stretch">
                            <>
                                <Heading size="lg" textAlign="center">Terms of Service</Heading>


                                <Text>
                                    <Heading size="md">LABORATORY REQUISITIONS</Heading>

                                    All ordering physicians must provide complete information, including the NPI # the first time a specimen is submitted. Once that physician is added to our database, the physician’s name, office location and Account # are sufficient.
                                </Text>

                                <Text>

                                    <Heading size="md">BILLING INFORMATION</Heading>

                                    Patients demographics are required on the first day of service. Once in our system, billing information (insurance, ID, Address) is saved for future days of service.
                                </Text>


                                <Text>

                                    <Heading size="md">DIAGNOSIS</Heading>

                                    All laboratory test requisitions MUST have an ICD-10 (diagnosis code) especially with Medicare patients according to their “medical necessity” (see attached grid) and clinical (blood).

                                </Text>

                                <Text>

                                    <Heading size="md">CASH PATIENTS</Heading>

                                    As a courtesy, this office provides insurance billing services at no extra charge. However, client/or patients are responsible for payment of all services rendered and we will look to you for payment if insurance information or patient address was not provided by client in a timely manner.

                                </Text>

                                <Text>

                                    <Heading size="md">INSURANCE BILLING  </Heading>

                                    (PPO, HMO, ETC): All billing will be done by DIAGNOSTIC LABORATORY SCIENCE. However, the client and /or patients are personally responsible for all applicable deductibles, co-insurance co-payments and services denied as not a covered benefit by your health plan carrier. All information required to bill patient’s insurance must be received in time to meet the 60- day timely filling deadline set by most insurances. Client and or patient will be responsible for information not received on time.
                                </Text>






                                <Text>

                                    <Heading size="md"> WORKERS COMPENSATION</Heading>

                                    When billing claims to workers compensation, insurance name, claim number, date of injury and adjuster’s information must be submitted with requisition form. The patients private health insurance is not billed for services that are covered by workers’ compensation.
                                </Text>


                                <Text>
                                    <Heading size="md">ACKNOWLEDGMENT</Heading>

                                    I understand that DIAGNOSTIC LABORATORY SCIENCE will submit patient’s claims to the patients insurance company as a courtesy at no extra cost but timely submission of patient’s insurance and/or patients address is the clients responsibility.
                                </Text>















                                <Text mt="4">
                                    By signing below, I agree and acknowledge the Terms of Services for Diagnostic Laboratory Science
                                </Text>




                                <VStack align="stretch">
                                    <>
                                        <Heading size="md" mt="6">Client Signature</Heading>
                                        {!tosSignature &&
                                            <SignatureModal
                                                onSave={(data) => setTosSignature(data)}
                                                triggerButton={<Button>Sign TOS</Button>}
                                            />
                                        }
                                        {tosSignature &&
                                            <Box
                                                p="4"
                                                width="full">
                                                <Image mb={2} width={200} src={tosSignature} alt="Client Signature" />
                                                <Button size="sm" mt="2" onClick={() => setTosSignature(null)}>Clear Signature</Button>
                                            </Box>


                                        }
                                    </>
                                </VStack>

                                <HStack>
                                    <FormControl isRequired>
                                        <FormLabel>Ordering Provider / Clinician</FormLabel>
                                        <Input {...register('tosOrderingProviderName', { required: true })} placeholder="Ordering Provider / Clinician" />
                                    </FormControl>

                                    <FormControl isRequired>
                                        <FormLabel>Date</FormLabel>
                                        <Input {...register('tosOrderingProviderDate', { required: true })} placeholder="Date (mm/dd/yyyy)" type="date" />
                                    </FormControl>
                                </HStack>
                            </>
                        </VStack>
                        <br /><hr /><br />
                        {/* PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENTPROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT */}
                        <VStack spacing={4} align="stretch">
                            <>


                                <Heading size="lg" textAlign="center">PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT</Heading>

                                <Text>
                                    This Privacy Agreement ("Agreement") is effective upon signing this Agreement and is entered by and between
                                    <Text as="span" fontWeight="bold"> {clientName}</Text> ("Covered Entity")
                                    and  <Text as="span" fontWeight="bold">Diagnostic Laboratory Science</Text> (the "Business Associate").
                                </Text>

                                <Heading size="md">1. Term</Heading>
                                <Text>
                                    This Agreement shall remain in effect for the duration of this Agreement and shall apply to all the Services
                                    and/or Supplies delivered by the Business Associate pursuant to this Agreement.
                                </Text>

                                <Heading size="md">2. HIPAA Assurances</Heading>
                                <Text>
                                    In the event Business Associate creates, receives, maintains, or otherwise is exposed to personally identifiable
                                    or aggregate patient or other medical information defined as Protected Health Information ("PHI") in the Health
                                    Insurance Portability and Accountability Act of 1996 or its relevant regulations ("HIPAA") and otherwise meets
                                    the definition of Business Associate as defined in the HIPAA Privacy Standards (45 CFR Parts 160 and 164),
                                    Business Associate shall:
                                </Text>

                                <VStack align="start" spacing={2} pl="4">
                                    <Text as="p">(a) Recognize that HITECH (the Health Information Technology for Economic and Clinical Health Act of 2009) and the regulations thereunder apply to a business associate;</Text>
                                    <Text as="p">(b) Not use or further disclose the PHI, except as permitted by law;</Text>
                                    <Text as="p">(c) Not use or further disclose the PHI in a manner that had <Text as="span" fontWeight="bold">{clientName}</Text> done so, would violate the requirements of HIPAA;</Text>
                                    <Text as="p">(d) Use appropriate safeguards to protect the confidentiality, integrity, and availability of PHI;</Text>
                                    <Text as="p">(e) Comply with each applicable requirement of 45 C.F.R. Part 162 if the Business Associate conducts Standard Transactions for or on behalf of the Covered Entity;</Text>
                                    <Text as="p">(f) Report promptly to <Text as="span" fontWeight="bold">{clientName}</Text> any security incident or other use or disclosure of PHI;</Text>
                                    <Text as="p">(g) Ensure that subcontractors or agents who receive PHI agree to the same restrictions;</Text>
                                    <Text as="p">(h) Make available PHI in accordance with the individual’s rights as required under HIPAA regulations;</Text>
                                    <Text as="p">(i) Account for PHI disclosures for up to the past six (6) years;</Text>
                                    <Text as="p">(j) Make internal practices available to the U.S. Secretary of Health and Human Services;</Text>
                                    <Text as="p">(k) Incorporate any amendments or corrections to PHI when notified by the Covered Entity.</Text>
                                </VStack>

                                <Heading size="md">3. Termination Upon Breach of Provisions</Heading>
                                <Text>
                                    Covered Entity may immediately terminate this Agreement if Business Associate breaches any term. Alternatively,
                                    the Covered Entity may give written notice and allow five (5) business days to cure such breach.
                                </Text>

                                <Heading size="md">4. Return or Destruction of Protected Health Information upon Termination</Heading>
                                <Text>
                                    Upon termination, Business Associate shall return or destroy all PHI received. If infeasible, the terms of this Agreement
                                    shall survive.
                                </Text>

                                <Heading size="md">5. No Third-Party Beneficiaries</Heading>
                                <Text>
                                    The terms of this Agreement shall apply only to the parties and are not for the benefit of any third-party beneficiaries.
                                </Text>

                                <Heading size="md">6. De-Identified Data</Heading>
                                <Text>
                                    Business Associate and its subcontractors may disclose non-personally identifiable information, provided the information
                                    cannot be identified.
                                </Text>

                                <Heading size="md">7. Amendment</Heading>
                                <Text>
                                    Business Associate and Covered Entity agree to amend this Agreement to comply with HIPAA or other relevant state
                                    or federal laws.
                                </Text>

                                <Heading size="md">8. Interpretation</Heading>
                                <Text>
                                    Any ambiguity in this Agreement shall be resolved in favor of compliance with HIPAA regulations.
                                </Text>

                                <Heading size="md">9. Definitions</Heading>
                                <Text>
                                    Capitalized terms used in this Agreement shall have the meanings assigned to them in HIPAA.
                                </Text>

                                <Heading size="md">10. Survival</Heading>
                                <Text>
                                    The obligations imposed by this Agreement shall survive any expiration or termination of this Agreement.
                                </Text>

                                <hr />
                                <HStack>
                                    <VStack align="stretch" flex="1">
                                        <Text>Name of Client/Clinic:</Text>
                                        <Input placeholder="Enter Client/Clinic Name" {...register('clientName')} />
                                    </VStack>

                                    <VStack align="stretch" flex="1">
                                        <Text>Name of Laboratory:</Text>
                                        <Input disabled defaultValue="Diagnostic Laboratory Science" {...register('labName')} />
                                    </VStack>
                                </HStack>

                                {/* Name of Ordering Physician and Director of Laboratory Services Initials */}
                                <HStack>
                                    <VStack align="stretch" flex="1">
                                        <Text>Name of Ordering Physician:</Text>
                                        <Input placeholder="Enter Physician Name" {...register('physicianName')} />
                                    </VStack>

                                    <VStack align="stretch" flex="1">
                                        <Text>Director of Laboratory Services CEO:</Text>
                                        <Input disabled defaultValue="Firas Tamary, MS.MT (ASCP), Bioanalyst" {...register('labDirectorInitials')} />
                                    </VStack>
                                </HStack>

                                {/* Ordering Physician Signature and Director of Laboratory Services Initials */}
                                <HStack>
                                    {/* Ordering Physician Signature */}
                                    <VStack align="stretch" flex="1">
                                        <Text>Ordering Physician Signature</Text>
                                        {!phiSignature &&
                                            <SignatureModal
                                                onSave={(data) => setPhiSignature(data)}
                                                triggerButton={<Button>Sign PHI</Button>}
                                            />
                                        }
                                        {phiSignature &&
                                            <Box
                                                p="4"
                                                width="full">
                                                <Image mb={2} width={200} src={phiSignature} alt="Client Signature" />
                                                <Button size="sm" mt="2" onClick={() => setPhiSignature(null)}>Clear Signature</Button>
                                            </Box>


                                        }

                                    </VStack>


                                </HStack>
                                {/* Director of Laboratory Services Initials */}
                                <VStack align="stretch" flex="1">
                                    <Text>Director of Laboratory Services Signature</Text>
                                    <Input placeholder="Enter Initials" disabled defaultValue="FT" {...register('directorInitials')} />
                                </VStack>
                                {/* Date Fields */}
                                <HStack>
                                    <VStack align="stretch" flex="1">
                                        <Text>Date (Ordering Physician)</Text>
                                        <Input placeholder="Date (mm/dd/yyyy)" type="date" {...register('physicianDate')} />
                                    </VStack>

                                    <VStack align="stretch" flex="1">
                                        <Text>Date (Director of Laboratory Services)</Text>
                                        <Input placeholder="Date (mm/dd/yyyy)" type="date" {...register('directorDate')} />
                                    </VStack>
                                </HStack>

                            </>
                        </VStack>
                        <br /><hr /><br />
                        {/* Physicians Signature and Date Requirements */}
                        <VStack spacing={4} align="stretch">

                            {/* Physicians signature and date requirements */}

                            <Heading size="lg" textAlign="center">
                                Physicians Signature and Date Requirements
                            </Heading>

                            <Text>
                                For medical review purposes, Medicare requires that the person(s) responsible for the care of the beneficiary, including providing/ordering/certifying items/services for the beneficiary, be identifiable as such in accordance with Medicare billing coverage policies, such as the Social Security Act §1815(a) and §1833(e). Medicare contractors shall consider the totality of the medical record when reviewing for compliance with the above. Signatures are required upon medical review for two distinct purposes:
                            </Text>

                            <Text>
                                <strong>1)</strong> To satisfy specific signature requirements in statute, regulation, national coverage determination (NCD), or local coverage determination (LCD).
                            </Text>

                            <Text>
                                <strong>2)</strong> To resolve authenticity concerns related to the legitimacy or falsity of the documentation.
                            </Text>

                            <Text>
                                CMS/Medicare requires that laboratory services provided/ordered be authenticated by the provider. CMS considers a laboratory order form as a legal document between the provider and the laboratory. As with any other legal document, the test order form shall be signed by the ordering provider. The signature for each entry must be legible and should include the provider's first and last name. For clarification purposes, CMS recommends that the applicable credentials (e.g., P.A., D.O., or M.D.) also be included. These credentials confirm that the ordering physician has certified the reasonableness of the service(s) submitted to the Medicare program for payment consideration. CMS also requires that the patient sign the laboratory order form, to ensure the patient understands and agrees with the tests ordered.
                            </Text>

                            <Text>
                                Physician signatures are significant audit targets at the Federal/State level and with Commercial payers. The absence of physician signatures in these audits has led to significant claw-backs by the payers and Medicare. Furthermore, not providing a physician's signature may result in having the physicians or the patient pay for the services rendered themselves. Your cooperation is a legal requirement as outlined in the Social Security Act, Medicare Section 1842(p)(4).
                            </Text>

                            <hr />
                            {/* Fields Section */}
                            <VStack spacing={4} align="stretch">
                                <Heading size="md" mt="6">PSDR Information</Heading>
                                <HStack>
                                    <VStack align="stretch" flex="1">
                                        <Text>Client/Clinic Name</Text>
                                        <Input
                                            placeholder="Enter Client/Clinic Name"
                                            {...register('psdrClientName', { required: false })} // Add registration for PSDR client name
                                        />
                                    </VStack>
                                </HStack>

                                <HStack>
                                    <VStack align="stretch" flex="1">
                                        <Text>Physician/Practitioner Name</Text>
                                        <Input
                                            placeholder="Enter Physician/Practitioner Name"
                                            {...register('psdrPhysicianName', { required: false })} // Add registration for PSDR physician name
                                        />
                                    </VStack>

                                    <VStack align="stretch" flex="1">
                                        <Text>Physician/Practitioner NPI</Text>
                                        <Input
                                            placeholder="Enter NPI"
                                            {...register('psdrPhysicianNPI', { required: false })} // Add registration for PSDR NPI
                                        />
                                    </VStack>

                                    <VStack align="stretch" flex="1">
                                        <Text>Date</Text>
                                        <Input
                                            type="date"
                                            placeholder="Enter Date"
                                            {...register('psdrDate', { required: false })} // Add registration for PSDR date
                                        />
                                    </VStack>
                                </HStack>
                            </VStack>


                            <HStack>
                                {/* Ordering Physician Signature */}
                                <VStack align="stretch" flex="1">
                                    <Text>Physician/Practitioner Signature</Text>

                                    {!psdrSignature &&
                                        <SignatureModal
                                            onSave={(data) => setPsdrSignature(data)}
                                            triggerButton={<Button>Sign PHI</Button>}
                                        />
                                    }
                                    {psdrSignature &&
                                        <Box
                                            p="4"
                                            width="full">
                                            <Image mb={2} width={200} src={psdrSignature} alt="Client Signature" />
                                            <Button size="sm" mt="2" onClick={() => setPsdrSignature(null)}>Clear Signature</Button>
                                        </Box>


                                    }

                                </VStack>

                            </HStack>


                        </VStack>

                    </VStack>

                    <br /><hr /><br />
                    <VStack spacing={6} mt={9} align="stretch">

                        <Heading mb="6" textAlign="center">Additional Information Form</Heading>

                        {/* Checkmarks */}
                        <Heading size="md" mb="4" mt={4}>Lab Use</Heading>
                        <HStack spacing={6} flexDirection={['column', 'row']} align="stretch" mb={5}>
                            <Checkbox {...register('tigerConnect')}>Tiger Connect</Checkbox>
                            <Checkbox {...register('portalIDPassword')}>Portal ID & Password</Checkbox>
                            <Checkbox {...register('accountManager')}>Account Manager</Checkbox>
                        </HStack>

                        <hr />

                        {/* Reporting Preference Section */}
                        <Heading size="md" mb="4" mt={4}>Reporting Preference</Heading>
                        <VStack align="stretch" mb={5}>
                            <Checkbox {...register('faxreporting')}>Fax</Checkbox>
                            <Checkbox {...register('portal')}>Portal</Checkbox>
                            <HStack>
                                <Checkbox {...register('emr')}>EMR</Checkbox>
                                <Input placeholder="Enter EMR Details" {...register('emrDetails')} />
                            </HStack>
                        </VStack>

                        <hr />

                        {/* Send Client Emails Section */}
                        <Heading size="md" mb="4" mt={4}>Send Client Emails</Heading>
                        <VStack align="stretch" mb={5}>
                            <Checkbox {...register('sendAccountForm')}>Send Client Copy of this Account Form once approved</Checkbox>
                        </VStack>
                        <hr />

                        {/* Email Address Display */}
                        <VStack mt={4} align="stretch" >
                            {clientEmail ? (
                                <Text color="green.500">Emails will be sent to: {clientEmail}</Text>
                            ) : (
                                <VStack>
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        You didn't input client email in the client details, enter email address below to send the new account form details to the client.
                                    </Alert>
                                    <Input placeholder="Enter Email Address" {...register('newClientEmail', { required: 'Email is required if no client email provided' })} />
                                </VStack>
                            )}
                        </VStack>
                    </VStack>
                    <Button type="submit" isLoading={loadingSubmit} loadingText={`Creating Client`} colorScheme="blue" mt="6" width="md" height={16} >Create Client</Button>



                </form>


            </Box>

        </>

    );
};

export default SignUpBusiness;
