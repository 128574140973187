import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    Text,
    IconButton,
    Center,
    Heading,
    VStack,
    Alert,
    AlertIcon,
    AlertDescription,
    HStack
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    fetchInsuranceCategories,
    createInsuranceCategory,
    updateInsuranceCategory,
    deleteInsuranceCategory
} from '../../api';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { hasPermission } from '../../Utils';
import { FaBookmark, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const InsuranceCategories = () => {
    const navigate = useNavigate()
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [error, setError] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const result = await fetchInsuranceCategories();

                if (result.success) {
                    setCategories(result.data);
                } else {
                    setError(result.error)
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }

            setLoading(false);

        };
        fetchCategories();
    }, []);

    // Handle Create
    const handleCreate = async (categoryData) => {
        if (!categoryData || !categoryData.name || categoryData.name.trim() === '') {
            setErrorModal('Category name cannot be empty');
            return;
        }

        try {
            const newCategory = await createInsuranceCategory(categoryData);
            if (newCategory.success) {
                setCategories([newCategory.data, ...categories]);
                handleCloseModal();
            } else {
                setErrorModal(newCategory.error)
            }

        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    // Handle Update
    const handleUpdate = async (updatedData) => {
        if (!updatedData || !updatedData.name || updatedData.name.trim() === '') {
            setErrorModal('Category name cannot be empty');
            return;
        }

        try {
            const updatedCategory = await updateInsuranceCategory(selectedCategory._id, updatedData);
            if (updatedCategory.success) {
                setCategories(categories.map(cat => cat._id === selectedCategory._id ? updatedCategory.data : cat));
                handleCloseModal();
            } else {
                setErrorModal(updatedCategory.error)
            }


        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    // Handle Delete
    const handleDelete = async () => {
        try {
            const del = await deleteInsuranceCategory(selectedCategory._id);
            if (del.success) {
                setCategories(categories.filter(cat => cat._id !== selectedCategory._id));
                handleCloseModal();
            } else {
                setErrorModal(del.error)
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleCloseModal = () => {
        onEditClose()
        onCreateClose()
        onDeleteClose()
        setErrorModal(null)
    }

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return (
            <AccessDenied msg={error} />
        )
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Insurance categories"
                    buttonText={hasPermission("createInsuranceCategory") && "Create New Category"}
                    onClick={hasPermission("createInsuranceCategory") && onCreateOpen}


                    secondButton={hasPermission("viewInsurance")}
                    secondButtonText='Back To Insurances'
                    secondButtonOnClick={() => navigate('/insurance')}
                    secondButtonIcon={<FaChevronLeft />}


                />
                {categories.length > 0 ?
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Name</Th>
                                <Th>Created At</Th>
                                <Th>Last Update</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {categories.map((category, index) => (
                                <Tr _hover={{ bg: "gray.100" }} key={category._id}>
                                    <Td>{index + 1}</Td>
                                    <Td>{category.name}</Td>
                                    <Td>{new Date(category.createdAt).toLocaleDateString()}</Td>
                                    <Td>{new Date(category.updatedAt).toLocaleDateString()}</Td>
                                    <Td>

                                        <HStack spacing={4}>
                                            {hasPermission('updateInsuranceCategory') &&
                                                <Button size="sm" onClick={() => {
                                                    setSelectedCategory(category);
                                                    onEditOpen();
                                                }} colorScheme="blue">
                                                    <EditIcon mr={2} /> Edit
                                                </Button>
                                            }
                                            {hasPermission('deleteInsuranceCategory') &&
                                                <Button size="sm" onClick={() => {
                                                    setSelectedCategory(category);
                                                    onDeleteOpen();
                                                }} colorScheme="red">
                                                    <DeleteIcon mr={2} /> Delete
                                                </Button>
                                            }
                                        </HStack>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    :
                    <AccessDenied msg={"Nothing here yet !"} icon="info" />
                }


                {/* Create Modal */}
                <Modal isOpen={isCreateOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Insurance Category</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                        <ModalBody>
                            <FormControl id="name">
                                <FormLabel>Name</FormLabel>
                                <Input
                                    placeholder="Category Name"
                                    onChange={(e) => setSelectedCategory({ ...selectedCategory, name: e.target.value })}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={() => handleCreate(selectedCategory)}>
                                Create
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Modal */}
                <Modal isOpen={isEditOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Insurance Category</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <FormControl id="name">
                                <FormLabel>Name</FormLabel>
                                <Input
                                    defaultValue={selectedCategory?.name}
                                    onChange={(e) => setSelectedCategory({ ...selectedCategory, name: e.target.value })}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="yellow" mr={3} onClick={() => handleUpdate({ name: selectedCategory.name })}>
                                Update
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal isOpen={isDeleteOpen} onClose={handleCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Insurance Category</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <Text>Are you sure you want to delete the category "{selectedCategory?.name}"?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button onClick={handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default InsuranceCategories;
