import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Textarea,
    IconButton
} from '@chakra-ui/react';
import { InfoIcon, ArrowUpIcon, ArrowDownIcon, EditIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { deleteLabLocation, fetchLabLocations, updateLabLocation, createLabLocation } from '../../api';
import { hasPermission } from '../../Utils';
import PopoverId from '../../components/PopoverId';
import ViewLabFacilitiesModal from './ViewLabFacilitiesModal';

const AllLabLocationsPage = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure(); // For creating a new lab
    const [selectedLab, setSelectedLab] = useState(null);
    const [labs, setLabs] = useState([]);
    const [filteredLabs, setFilteredLabs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [addressSearchTerm, setAddressSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newLab, setNewLab] = useState({ name: '', address: { address1: '', city: '', state: '', zip: '' }, contact: { person: '', emailAddress: '' } });
    const [isModalOpen, setModalOpen] = useState(false);

    const handleViewFacilities = (lab) => {
        setSelectedLab(lab);
        setModalOpen(true);
    };
    // Sorting
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const handleSort = (field) => {
        const newOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newOrder);
    };

    const sortedLabs = [...filteredLabs].sort((a, b) => {
        if (sortField === 'name') {
            return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
        return 0;
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedLabs.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedLabs.length / itemsPerPage);

    const handleClick = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        getLabs();
    }, []);

    const getLabs = async () => {
        setLoading(true);
        try {
            const fetchedLabs = await fetchLabLocations();
            if (fetchedLabs.success) {
                setLabs(fetchedLabs.data);
            } else {
                setError(fetchedLabs.error);
            }
        } catch (error) {
            setError('Failed to load lab locations');
        }
        setLoading(false);
    };

    useEffect(() => {
        const filtered = labs.filter(
            (lab) =>
                lab.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                lab.address.city.toLowerCase().includes(addressSearchTerm.toLowerCase())
        );
        setFilteredLabs(filtered);
        setCurrentPage(1);
    }, [searchTerm, addressSearchTerm, labs]);

    const handleDeleteLab = (lab) => {
        setSelectedLab(lab);
        onOpenDeleteModal();
    };

    const confirmDeleteLab = async () => {
        if (selectedLab) {
            try {
                const response = await deleteLabLocation(selectedLab._id);
                if (response.success) {
                    getLabs();
                } else {
                    alert('Failed to delete lab: ' + response.message);
                }
            } catch (error) {
                console.error('Error deleting lab:', error);
            } finally {
                onCloseDeleteModal();
            }
        }
    };

    const handleEditLab = (lab) => {
        setSelectedLab(lab);
        onOpen();
    };

    const handleSaveLab = async () => {
        try {
            const updatedLab = { ...selectedLab };
            const response = await updateLabLocation(updatedLab._id, updatedLab);
            if (response.success) {
                getLabs();
                onClose();
            }
        } catch (error) {
            console.error('Error updating lab:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedLab((prevLab) => ({
            ...prevLab,
            [name]: value
        }));
    };

    const handleCreateLab = async () => {
        try {
            const response = await createLabLocation(newLab);
            if (response.success) {
                getLabs();
                onCloseCreateModal();
            }
        } catch (error) {
            console.error('Error creating lab:', error);
        }
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {!error ? (
                    <>
                        <TitleHeader title="Lab Locations" buttonText={hasPermission("createLabLocation") && "Create New Lab"} onClick={hasPermission("createLabLocation") && onOpenCreateModal} />
                        <HStack spacing={4} mb="6">
                            <Input
                                placeholder="Search by lab name"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Input
                                placeholder="Search by city"
                                value={addressSearchTerm}
                                onChange={(e) => setAddressSearchTerm(e.target.value)}
                            />
                        </HStack>

                        {filteredLabs.length > 0 ? (
                            <>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>#</Th>
                                            <Th style={{ cursor: 'pointer' }} onClick={() => handleSort('name')}>
                                                Name
                                                {sortField === 'name' && (
                                                    sortOrder === 'asc' ? <ArrowUpIcon ml={2} /> : <ArrowDownIcon ml={2} />
                                                )}
                                            </Th>
                                            <Th w={"10%"}>City</Th>
                                            <Th textAlign="right">Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {currentItems.map((lab, index) => (
                                            <Tr key={lab._id} _hover={{ bg: "gray.100" }}>
                                                <Td><PopoverId place='Labs' index={index + 1} content={lab._id} /></Td>

                                                <Td>{lab.name}</Td>
                                                <Td>{lab.address.city}</Td>
                                                <Td textAlign="right">
                                                    <HStack justifyContent="flex-end" spacing={4}>
                                                        <Button size="sm" onClick={() => handleViewFacilities(lab)} colorScheme="blue">
                                                            <ViewIcon mr={2} /> View
                                                        </Button>
                                                        {hasPermission("updateLabLocation") &&
                                                            <Button size="sm" onClick={() => handleEditLab(lab)} colorScheme="green">
                                                                <EditIcon mr={2} /> Edit
                                                            </Button>
                                                        }
                                                        {hasPermission("deleteLabLocation") &&
                                                            <Button size="sm" onClick={() => handleDeleteLab(lab)} colorScheme="red">
                                                                <DeleteIcon mr={2} /> Delete
                                                            </Button>
                                                        }
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                                <HStack spacing={4} mt={4} justify="center">
                                    {[...Array(totalPages)].map((_, index) => (
                                        <Button
                                            key={index}
                                            onClick={() => handleClick(index + 1)}
                                            colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
                                        >
                                            {index + 1}
                                        </Button>
                                    ))}
                                </HStack>

                                {/* Delete Confirmation Modal */}
                                <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Confirm Deletion</ModalHeader>
                                        <ModalBody>
                                            Are you sure you want to delete the lab{' '}
                                            <strong>{selectedLab?.name}</strong>?
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="red" mr={3} onClick={confirmDeleteLab}>
                                                Delete
                                            </Button>
                                            <Button onClick={onCloseDeleteModal}>Cancel</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>

                                {/* Edit Lab Modal */}
                                <Modal scrollBehavior='inside' isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Edit Lab Location</ModalHeader>
                                        <ModalBody>
                                            {selectedLab && (
                                                <VStack spacing={4}>
                                                    <FormControl>
                                                        <FormLabel>Lab Name</FormLabel>
                                                        <Input
                                                            name="name"
                                                            value={selectedLab.name || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Address 1</FormLabel>
                                                        <Input
                                                            name="address1"
                                                            value={selectedLab.address.address1 || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    address: { ...prevLab.address, address1: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Address 2</FormLabel>
                                                        <Input
                                                            name="address2"
                                                            value={selectedLab.address.address2 || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    address: { ...prevLab.address, address2: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>City</FormLabel>
                                                        <Input
                                                            name="city"
                                                            value={selectedLab.address.city || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    address: { ...prevLab.address, city: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>State</FormLabel>
                                                        <Input
                                                            name="state"
                                                            value={selectedLab.address.state || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    address: { ...prevLab.address, state: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Zip Code</FormLabel>
                                                        <Input
                                                            name="zip"
                                                            value={selectedLab.address.zip || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    address: { ...prevLab.address, zip: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Contact Person</FormLabel>
                                                        <Input
                                                            name="person"
                                                            value={selectedLab.contact.person || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    contact: { ...prevLab.contact, person: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Contact Phone</FormLabel>
                                                        <Input
                                                            name="phone"
                                                            value={selectedLab.contact.phone || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    contact: { ...prevLab.contact, phone: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Email Address</FormLabel>
                                                        <Input
                                                            name="emailAddress"
                                                            value={selectedLab.contact.emailAddress || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    contact: { ...prevLab.contact, emailAddress: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>Report Email</FormLabel>
                                                        <Input
                                                            name="reportEmail"
                                                            value={selectedLab.contact.reportEmail || ''}
                                                            onChange={(e) =>
                                                                setSelectedLab((prevLab) => ({
                                                                    ...prevLab,
                                                                    contact: { ...prevLab.contact, reportEmail: e.target.value }
                                                                }))
                                                            }
                                                        />
                                                    </FormControl>
                                                </VStack>
                                            )}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={onClose} mr={3}>Cancel</Button>
                                            <Button colorScheme="blue" onClick={handleSaveLab}>Save</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>


                                {/* Create New Lab Modal */}
                                <Modal scrollBehavior='inside' isOpen={isCreateModalOpen} onClose={onCloseCreateModal}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>Create New Lab Location</ModalHeader>
                                        <ModalBody>
                                            <VStack spacing={4}>
                                                <FormControl>
                                                    <FormLabel>Lab Name</FormLabel>
                                                    <Input
                                                        name="name"
                                                        value={newLab.name}
                                                        onChange={(e) => setNewLab({ ...newLab, name: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Address 1</FormLabel>
                                                    <Input
                                                        name="address1"
                                                        value={newLab.address.address1}
                                                        onChange={(e) => setNewLab({ ...newLab, address: { ...newLab.address, address1: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Address 2</FormLabel>
                                                    <Input
                                                        name="address2"
                                                        value={newLab.address.address2}
                                                        onChange={(e) => setNewLab({ ...newLab, address: { ...newLab.address, address2: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>City</FormLabel>
                                                    <Input
                                                        name="city"
                                                        value={newLab.address.city}
                                                        onChange={(e) => setNewLab({ ...newLab, address: { ...newLab.address, city: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>State</FormLabel>
                                                    <Input
                                                        name="state"
                                                        value={newLab.address.state}
                                                        onChange={(e) => setNewLab({ ...newLab, address: { ...newLab.address, state: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Zip Code</FormLabel>
                                                    <Input
                                                        name="zip"
                                                        value={newLab.address.zip}
                                                        onChange={(e) => setNewLab({ ...newLab, address: { ...newLab.address, zip: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Contact Person</FormLabel>
                                                    <Input
                                                        name="person"
                                                        value={newLab.contact.person}
                                                        onChange={(e) => setNewLab({ ...newLab, contact: { ...newLab.contact, person: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Contact Phone</FormLabel>
                                                    <Input
                                                        name="phone"
                                                        value={newLab.contact.phone}
                                                        onChange={(e) => setNewLab({ ...newLab, contact: { ...newLab.contact, phone: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Email Address</FormLabel>
                                                    <Input
                                                        name="emailAddress"
                                                        value={newLab.contact.emailAddress}
                                                        onChange={(e) => setNewLab({ ...newLab, contact: { ...newLab.contact, emailAddress: e.target.value } })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Report Email</FormLabel>
                                                    <Input
                                                        name="reportEmail"
                                                        value={newLab.contact.reportEmail}
                                                        onChange={(e) => setNewLab({ ...newLab, contact: { ...newLab.contact, reportEmail: e.target.value } })}
                                                    />
                                                </FormControl>
                                            </VStack>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={onCloseCreateModal} mr={3}>Cancel</Button>
                                            <Button colorScheme="blue" onClick={handleCreateLab}>Create</Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>


                                {selectedLab &&
                                    <ViewLabFacilitiesModal
                                        isOpen={isModalOpen}
                                        onClose={() => setModalOpen(false)}
                                        lab={selectedLab}
                                    />
                                }

                            </>
                        ) : (
                            <AccessDenied msg={'No Labs found!'} icon="info" />
                        )}
                    </>
                ) : (
                    <AccessDenied msg={error} />
                )}
            </VStack>
        </Box>
    );
};

export default AllLabLocationsPage;
