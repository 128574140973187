import { useState, useEffect } from 'react';
import { Box, VStack, HStack, Button, Text, IconButton, Spinner, Center } from '@chakra-ui/react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { getAllPickups, logoutUser } from '../api';
import { RiDashboardLine } from "react-icons/ri";
import { FaBookmark, FaCarAlt, FaCheckCircle, FaClinicMedical, FaDotCircle, FaFile, FaHospital, FaInvision, FaLocationArrow, FaMoneyBill, FaQuestion, FaTrash, FaTruck, FaUsers, FaVoicemail, FaWarehouse } from "react-icons/fa";
import { FaShield, FaUserAstronaut, FaCodePullRequest, FaBuildingFlag, FaShieldHalved, FaRoute, FaBoxArchive, FaAngleDown } from "react-icons/fa6";
import { GoLog, GoSignOut } from "react-icons/go";
import { PiUserFocus } from "react-icons/pi";
import { GiMedicalDrip } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import { rLogoutUser } from '../redux/actions/userActions';
import { persistor } from '../redux/store'; // Import persistor
import { hasPermission } from '../Utils';
import Loading from '../components/Loading/Loading';

const SidebarLayout = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Sidebar visible by default
    const [isMobile, setIsMobile] = useState(false); // Track if it's mobile screen size
    const [loading, setLoading] = useState(true); // Add loading state
    const [isHiding, setIsHiding] = useState(false);
    const [allTempPickups, setAllTempPickups] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch()
    // Function to toggle sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };


    // Set loading to false once isActive is determined on each location change
    useEffect(() => {
        setLoading(true);
        getTempPickups()
        const timer = setTimeout(() => setLoading(false), 300); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, [location.pathname]); // Run on route change




    // Handle window resize to automatically hide/show the sidebar based on screen width
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsSidebarVisible(false); // Hide sidebar on small screens
            setIsMobile(true);
        } else {
            setIsSidebarVisible(true); // Show sidebar on larger screens
            setIsMobile(false);
        }
    };

    // Effect to add window resize listener
    useEffect(() => {
        handleResize(); // Set initial sidebar visibility based on screen size
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLinkClick = (isClientItem = false) => {
        if (isMobile) {
            setIsSidebarVisible(false);
        }

    };

    const isActive = (path) => {
        return location.pathname.startsWith(path);
    };


    const handleLogout = async () => {
        const result = await logoutUser();
        if (result.ok) {
            // Dispatch logout action to clear Redux state
            dispatch(rLogoutUser());

            // Purge persisted state
            persistor.purge();

            // Redirect to login or home page after logout
            window.location.href = '/login';
        } else {
            alert('Something went wrong, Try again later')
        }
    };


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const hideSideParam = params.get('hide_side') === 'true';

        // If `hide_side=true` in URL, hide the sidebar regardless of screen size
        if (hideSideParam) {
            setIsHiding(true);
        } else {
            setIsHiding(false);
        }
    }, [location.search]);


    const getTempPickups = async (page = 1) => {
        try {
            const response = await getAllPickups(1, 9999, "TEMP");
            if (response.success) {
                const pickups = response.data.pickups;
                setAllTempPickups(pickups);
            }
        } catch (error) {
            // setError('Failed to load routes');
        }
        // setLoading(false);
    };


    return (
        <Box className='sidebar'>
            {/* Header */}
            <HStack
                as="header"
                justify="space-between"
                p="5"
                bg="gray.800"
                color="white"
                position="fixed"
                width="full"
                zIndex="1000"
            >
                <HStack>
                    <IconButton
                        size={'xs'}
                        icon={isSidebarVisible ? <CloseIcon /> : <HamburgerIcon />}
                        style={{ background: 'none', color: "#FFF" }}
                        onClick={toggleSidebar}
                    />
                    <Text fontSize="lg" fontWeight="bold">
                        LMS
                    </Text>
                </HStack>
            </HStack>

            <HStack spacing={0} align="stretch" height="100vh" pt="60px">
                {/* Sidebar */}
                {!isHiding &&
                    <>
                        {isSidebarVisible && (
                            <Box
                                width={{ base: 'full', md: '250px' }}
                                bg="gray.800"
                                color="white"
                                p="4"
                                position="fixed"
                                height="94vh"
                                zIndex="999"
                                overflowY="auto"
                            >
                                <VStack align="stretch" spacing="6">
                                    {/* Menu Links */}
                                    <VStack align="stretch" spacing="4">
                                        <Link to="/dashboard">
                                            <Button
                                                _hover={{ bg: 'gray.600' }}
                                                onClick={() => handleLinkClick()}
                                                width="full"
                                                justifyContent="start"
                                                colorScheme={isActive('/dashboard') ? 'teal' : 'white'}
                                                variant={isActive('/dashboard') ? 'solid' : 'ghost'}
                                            >
                                                <RiDashboardLine /> <Text ml={2}>Dashboard</Text>
                                            </Button>
                                        </Link>


                                        {hasPermission('viewCouriers') &&
                                            <Link to="/couriers/couriers">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/couriers') ? 'teal' : 'white'}
                                                    variant={isActive('/couriers') ? 'solid' : 'ghost'}
                                                >
                                                    <FaCarAlt />  <Text ml={2}>Couriers</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('viewRoutes') &&
                                            <Link to="/routes/routes">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/routes') ? 'teal' : 'white'}
                                                    variant={isActive('/routes') ? 'solid' : 'ghost'}
                                                >
                                                    <FaRoute />  <Text ml={2}>Routes</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('viewPickups') &&
                                            <Link to="/pickups">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={location.pathname.endsWith('/pickups') ? 'teal' : 'white'}
                                                    variant={location.pathname.endsWith('/pickups') ? 'solid' : 'ghost'}
                                                >
                                                    <FaTruck />  <Text ml={2}>Today Pickup</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('viewPickups') &&
                                            <Link to="/pickups/finished">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={location.pathname.endsWith('/finished') ? 'teal' : 'white'}
                                                    variant={location.pathname.endsWith('/finished') ? 'solid' : 'ghost'}
                                                >
                                                    <FaCheckCircle />  <Text ml={2}>Finished Pickup</Text>
                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewPickups') &&
                                            <Link to="/pickups/request">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={location.pathname.endsWith('/request') ? 'teal' : 'white'}
                                                    variant={location.pathname.endsWith('/request') ? 'solid' : 'ghost'}
                                                >
                                                    <FaQuestion />  <Text ml={2}>Request Pickup</Text>
                                                    {/* Red circle with the number */}
                                                    {allTempPickups.length > 0 &&
                                                        <Box
                                                            position="absolute"
                                                            top="3"
                                                            // right="5px"
                                                            bg="red.500"
                                                            color="white"
                                                            fontSize="xs"
                                                            fontWeight="bold"
                                                            borderRadius="full"
                                                            w="18px"
                                                            h="18px"
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                        >
                                                            {allTempPickups?.length}
                                                        </Box>

                                                    }

                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('receivePickups') &&
                                            <Link to="/pickups/receive">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={location.pathname.endsWith('/receive') ? 'teal' : 'white'}
                                                    variant={location.pathname.endsWith('/receive') ? 'solid' : 'ghost'}
                                                >
                                                    <FaAngleDown />  <Text ml={2}>Receive Pickups</Text>

                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewFacilities') &&
                                            <Link to="/facilities">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/facilities') ? 'teal' : 'white'}
                                                    variant={isActive('/facilities') ? 'solid' : 'ghost'}
                                                >
                                                    <FaBuildingFlag />  <Text ml={2}>Facilities</Text>
                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewLabLocation') &&
                                            <Link to="/labs">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/labs') ? 'teal' : 'white'}
                                                    variant={isActive('/labs') ? 'solid' : 'ghost'}
                                                >
                                                    <FaHospital />  <Text ml={2}>Labs</Text>
                                                </Button>
                                            </Link>
                                        }


                                        {hasPermission('getAllBusiness') &&
                                            <Link to="/all-businesses">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/all-businesses') || isActive('/signup') ? 'teal' : 'white'}
                                                    variant={isActive('/all-businesses') || isActive('/signup') ? 'solid' : 'ghost'}
                                                >
                                                    <FaClinicMedical />  <Text ml={2}>Clients</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('viewSalesRep') &&
                                            <Link to="/sales-reps-managements">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={(isActive('/sales-reps-managements') || isActive('/sales-reps')) ? 'teal' : 'white'}
                                                    variant={(isActive('/sales-reps-managements') || isActive('/sales-reps')) ? 'solid' : 'ghost'}
                                                >
                                                    <FaUserAstronaut /> <Text ml={2}>Sales Reps</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('getAllFiles') &&
                                            <Link to="/documents">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/documents') ? 'teal' : 'white'}
                                                    variant={isActive('/documents') ? 'solid' : 'ghost'}
                                                >
                                                    <FaFile /> <Text ml={2}>Documents</Text>

                                                </Button>
                                            </Link>
                                        }


                                        {hasPermission('listUsers') &&
                                            <Link to="/users">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/users') ? 'teal' : 'white'}
                                                    variant={isActive('/users') ? 'solid' : 'ghost'}
                                                >
                                                    <FaUsers /> <Text ml={2}>Users</Text>

                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewRoles') &&
                                            <Link to="/roles">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/roles') ? 'teal' : 'white'}
                                                    variant={isActive('/roles') ? 'solid' : 'ghost'}
                                                >
                                                    <FaShield /> <Text ml={2}>Roles</Text>

                                                </Button>
                                            </Link>
                                        }


                                        {hasPermission("viewWarehouses") &&
                                            <Link to="/warehouses">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/warehouses') ? 'teal' : 'white'}
                                                    variant={isActive('/warehouses') ? 'solid' : 'ghost'}
                                                >
                                                    <FaWarehouse /> <Text ml={2}>Warehouses</Text>

                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission("viewInventory") &&
                                            <Link to="/inventory">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/inventory') ? 'teal' : 'white'}
                                                    variant={isActive('/inventory') ? 'solid' : 'ghost'}
                                                >
                                                    <FaBoxArchive /> <Text ml={2}>Inventory</Text>

                                                </Button>
                                            </Link>
                                        }


                                        {hasPermission("getAllCategories") &&
                                            <Link to="/supplies-categories">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/supplies-categories') ? 'teal' : 'white'}
                                                    variant={isActive('/supplies-categories') ? 'solid' : 'ghost'}
                                                >
                                                    <FaBookmark /> <Text ml={2}>Supplies Categories</Text>

                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission("getAllSupplies") &&
                                            <Link to="/supplies-items">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/supplies-items') ? 'teal' : 'white'}
                                                    variant={isActive('/supplies-items') ? 'solid' : 'ghost'}
                                                >
                                                    <GiMedicalDrip /> <Text ml={2}>Supplies Items</Text>

                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission("viewOrders") &&
                                            <Link to="/clients-orders">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/clients-orders') || isActive('/create-order') || isActive('/order-details') || isActive('/clients-finished-orders') ? 'teal' : 'white'}
                                                    variant={isActive('/clients-orders') || isActive('/create-order') || isActive('/order-details') || isActive('/clients-finished-orders') ? 'solid' : 'ghost'}
                                                >
                                                    <FaCodePullRequest /> <Text ml={2}>Clients Orders</Text>

                                                </Button>
                                            </Link>
                                        }



                                        {hasPermission("viewLogs") &&
                                            <Link to="/logs">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/logs') ? 'teal' : 'white'}
                                                    variant={isActive('/logs') ? 'solid' : 'ghost'}
                                                >
                                                    <GoLog /> <Text ml={2}>Log</Text>

                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission("viewTrash") &&
                                            <Link to="/trash">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick()}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/trash') ? 'teal' : 'white'}
                                                    variant={isActive('/trash') ? 'solid' : 'ghost'}
                                                >
                                                    <FaTrash /> <Text ml={2}>Trash</Text>

                                                </Button>
                                            </Link>
                                        }





                                        {hasPermission('viewPSCLocations') &&
                                            <Link to="/pscs">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/pscs') ? 'teal' : 'white'}
                                                    variant={isActive('/pscs') ? 'solid' : 'ghost'}
                                                >
                                                    <FaLocationArrow />  <Text ml={2}>PSC's</Text>
                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewInsurance') &&
                                            <Link to="/insurance">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/insurance') ? 'teal' : 'white'}
                                                    variant={isActive('/insurance') ? 'solid' : 'ghost'}
                                                >
                                                    <FaShieldHalved />  <Text ml={2}>Insurances</Text>
                                                </Button>
                                            </Link>
                                        }
                                        {hasPermission('viewPrice') &&
                                            <Link to="/test-prices">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/test-prices') ? 'teal' : 'white'}
                                                    variant={isActive('/test-prices') ? 'solid' : 'ghost'}
                                                >
                                                    <FaMoneyBill />  <Text ml={2}>Tests Prices</Text>
                                                </Button>
                                            </Link>
                                        }

                                        {hasPermission('viewTemplates') &&
                                            <Link to="/email">
                                                <Button
                                                    _hover={{ bg: 'gray.600' }}
                                                    onClick={() => handleLinkClick(true)}
                                                    width="full"
                                                    justifyContent="start"
                                                    colorScheme={isActive('/email') ? 'teal' : 'white'}
                                                    variant={isActive('/email') ? 'solid' : 'ghost'}
                                                >
                                                    <FaVoicemail />  <Text ml={2}>Emails Configuration</Text>
                                                </Button>
                                            </Link>
                                        }


                                        <Link to="/account">
                                            <Button
                                                _hover={{ bg: 'gray.600' }}
                                                onClick={() => handleLinkClick()}
                                                width="full"
                                                justifyContent="start"
                                                colorScheme={isActive('/account') ? 'teal' : 'white'}
                                                variant={isActive('/account') ? 'solid' : 'ghost'}
                                            >
                                                <PiUserFocus /> <Text ml={2}>Your Account</Text>

                                            </Button>
                                        </Link>


                                        <Button
                                            _hover={{ bg: 'gray.600' }}
                                            onClick={() => handleLogout()}
                                            width="full"
                                            justifyContent="start"
                                            colorScheme={'white'}
                                            variant={'ghost'}
                                        >
                                            <GoSignOut /> <Text ml={2}>Signout</Text>

                                        </Button>


                                    </VStack>
                                </VStack>
                            </Box>
                        )}
                    </>
                }
                {/* Main Content Area */}
                <Box
                    flex="1"
                    p="6"
                    ml={{ base: '0', md: (isSidebarVisible && !isHiding) ? '250px' : '0' }}
                >
                    {loading ? (
                        <Center mt={"20%"}>
                            <Loading />
                        </Center>
                    ) : (
                        <Outlet />
                    )}
                </Box>
            </HStack>
        </Box>
    );
};

export default SidebarLayout;
