import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Checkbox,
    Box,
    Heading,
    Text,
    Divider,
    Badge,
    Tooltip,
    SimpleGrid,
    useToast
} from '@chakra-ui/react';
import { getAllUsers, updatePickup } from '../../api';


const getGoogleMapsLink = (address) => {
    const { address1, address2, city, state, zip } = address;
    const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
    return googleMapsUrl;
};


const EditPickupModal = ({ isOpen, onClose, pickup, onUpdatePickup }) => {
    const [formData, setFormData] = useState({
        status: pickup?.status || 'PENDING',
        samplesCount: pickup?.samplesCount || 0,
        samplesReceived: pickup?.samplesReceived || 0,
        receivedBy: pickup?.receivedBy || '',
        requestPhoto: pickup?.requirements?.requestPhoto || false,
        hasSupplies: pickup?.requirements?.hasSupplies || false,
        hasDocs: pickup?.requirements?.hasDocs || false,
        priority: pickup?.priority || 'Normal',
    });
    const toast = useToast()
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const result = await getAllUsers();
            if (result.success) {
                setUsers(result.data);
            } else {
                setError(result.error);
            }
        } catch (err) {
            setError('Error fetching users', err);
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSave = async () => {
        setLoading(true)
        const updatedData = {
            status: formData.status,
            samplesCount: formData.samplesCount,
            samplesReceived: formData.samplesReceived,
            receivedBy: formData.receivedBy,
            priority: formData.priority,
            requirements: {
                requestPhoto: formData.requestPhoto,
                hasSupplies: formData.hasSupplies,
                hasDocs: formData.hasDocs,
            },
        };

        try {
            const updatedPickup = await updatePickup(updatedData, pickup._id)
            if (updatedPickup.success) {
                toast({
                    title: "Great!",
                    description: "Pickup Updated successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                onUpdatePickup(updatedPickup.data);
            } else {
                toast({
                    title: "Failed",
                    description: `Pickup failed to update !`,
                    status: "warning",
                    duration: 4000,
                    isClosable: true,
                });
                console.log(updatedPickup.error)
            }
        } catch (error) {
            toast({
                title: "Failed",
                description: `Pickup failed to update !`,
                status: "warning",
                duration: 4000,
                isClosable: true,
            });
            console.log(error)

        } finally {
            setLoading(false)
        }

    };

    return (
        <Modal size={"6xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Pickup</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box p={4}>
                        <SimpleGrid columns={3} spacing={4}>

                            <Box pb={5}>
                                {/* Content for the right column goes here */}
                                <Heading Heading mt={5} as='h3' size='lg'>Pickup Details</Heading>
                                <Text mt={5}>
                                    <strong>Pickup</strong><br />
                                    <span style={{ fontWeight: "500", fontSize: 15, marginRight: 5 }}>{pickup.pickupLocation.name}</span>
                                    <small>({pickup.pickupLocation.address.city})  - <a href={getGoogleMapsLink(pickup.pickupLocation.address)} target='_blank'>View in map</a></small>
                                </Text>

                                <Text mt={5}>
                                    <strong>Drop</strong><br />
                                    <span style={{ fontWeight: "500", fontSize: 15 }}>{pickup.dropOffLocation.name}</span>
                                    <small> ({pickup.dropOffLocation.address.city}) </small>
                                </Text>
                            </Box>

                            <Box>
                                <Heading Heading mt={5} as='h3' size='lg'>Assigned Courier</Heading>
                                <Text mt={5}>
                                    <strong>
                                        {pickup.courier.name}
                                    </strong>
                                </Text>
                            </Box>

                            <Box>
                                <Heading Heading mt={5} as='h3' size='lg'>Pickup Type</Heading>

                                <Text mt={5}>
                                    <Badge mr={5} colorScheme={pickup.type === 'DropOffOnly' ? 'pink' : pickup.type === 'Supplies' ? 'purple' : "orange"}>
                                        {pickup.type === 'DropOffOnly' ? "Drop Off" : pickup.type === 'Default' ? "Pickup" : pickup.type}
                                    </Badge>
                                    <Badge mr={5} colorScheme={pickup.routeId?.name ? 'red' : 'green'}>
                                        {pickup.routeId?.name || "On Call"}
                                    </Badge>
                                    <Badge colorScheme={'purple'}>
                                        Pickup Order #{pickup.pickupOrder}
                                    </Badge>

                                </Text>
                            </Box>

                        </SimpleGrid>
                        <Divider />
                    </Box>

                    {/* Status */}
                    <FormControl mb={4}>
                        <FormLabel>Status</FormLabel>
                        <Select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                        >
                            {pickup.type == 'Default' ?
                                <>
                                    <option value="PENDING">Pending</option>
                                    <option value="PICKED_UP">Picked Up</option>
                                    <option value="RECEIVED">Received</option>
                                </>
                                :
                                <>
                                    <option value="PENDING">Pending</option>
                                    <option value="RECEIVED">Finished</option>
                                </>

                            }
                        </Select>
                    </FormControl>

                    {pickup.type == 'Default' &&
                        <SimpleGrid columns={3} spacing={4}>
                            {/* Samples Count */}
                            <FormControl mb={4}>
                                <FormLabel>Samples Count</FormLabel>
                                <Input
                                    type="number"
                                    name="samplesCount"
                                    value={formData.samplesCount}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            {/* Samples Received */}
                            <FormControl mb={4}>
                                <FormLabel>Samples Received</FormLabel>
                                <Input
                                    type="number"
                                    name="samplesReceived"
                                    value={formData.samplesReceived}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            {/* Received By */}
                            {users.length > 0 &&
                                <FormControl mb={4}>
                                    <FormLabel>Received By</FormLabel>
                                    <Select
                                        name="receivedBy"
                                        value={formData.receivedBy}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select User</option>
                                        {users.map((user) => (
                                            <option key={user._id} value={user._id}>
                                                {user.firstName}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </SimpleGrid>
                    }
                    <SimpleGrid columns={3} spacing={4}>

                        {/* Requirements Section */}
                        <FormControl mb={4}>
                            <FormLabel>Request Photo</FormLabel>
                            <Checkbox
                                name="requestPhoto"
                                isChecked={formData.requestPhoto}
                                onChange={handleChange}
                            >
                                Request Photo
                            </Checkbox>
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>Has Supplies</FormLabel>
                            <Checkbox
                                name="hasSupplies"
                                isChecked={formData.hasSupplies}
                                onChange={handleChange}
                            >
                                Has Supplies
                            </Checkbox>
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel>Has Documents</FormLabel>
                            <Checkbox
                                name="hasDocs"
                                isChecked={formData.hasDocs}
                                onChange={handleChange}
                            >
                                Has Documents
                            </Checkbox>
                        </FormControl>
                    </SimpleGrid>
                    {/* Priority */}
                    <FormControl mb={4}>
                        <FormLabel>Priority</FormLabel>
                        <Select
                            name="priority"
                            value={formData.priority}
                            onChange={handleChange}
                        >
                            <option value="Normal">Normal</option>
                            <option value="Stat">Stat</option>
                        </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button isLoading={loading} colorScheme="blue" onClick={handleSave}>
                        Save Changes
                    </Button>
                    <Button variant="ghost" ml={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default EditPickupModal;
