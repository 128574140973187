import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
    Divider,
    Badge,
} from '@chakra-ui/react';
import ImageLightbox from '../../components/ImageLightBox';

const ViewFacilityModal = ({ isOpen, onClose, facility }) => {
    if (!facility) return null; // If no facility is selected, return nothing

    const getGoogleMapsLink = (address) => {
        const { address1, address2, city, state, zip } = address;
        const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
        return googleMapsUrl;
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{facility.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>


                    <Box justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                        {/* Action By and Action Row */}
                        <Text as='u' fontSize='2xl'>Facility Details </Text>
                        <Box display="flex" justifyContent="space-between" mb={4} mt={4}>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold"> Facility </Text>
                                <Text>
                                    <span style={{ fontWeight: "500", fontSize: 15 }}>{facility.name}</span>
                                    <small>({facility.address.city}) </small>
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold"> Client Number</Text>
                                <Text>
                                    <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.clientNumber} </span>
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold" > Laboratory </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.lab.name} </span></Text >
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold" > Lockbox </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {<Badge colorScheme={facility.hasLockbox ? "green" : "red"}>{facility.hasLockbox ? "Yes" : "No"}</Badge>} </span></Text >
                            </Box>
                        </Box>


                    </Box>
                    <Divider mb={4} />

                    <Box justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                        {/* Action By and Action Row */}
                        <Text as='u' fontSize='2xl'>Address
                            <a href={getGoogleMapsLink(facility.address)} target="_blank" color="blue.500">
                                <small style={{ fontSize: 12 }}> - View in map</small>
                            </a>
                        </Text>

                        <Box mt={4} mb={5}>
                            <Text fontSize="sm" fontWeight="semibold" > Full Address </Text>
                            <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.address.address1 + ", " + (facility.address.address2 ? facility.address.address2 + ", " : "") + facility.address.city + ", " + facility.address.state + ", " + facility.address.zip} </span></Text >
                        </Box>

                        <Box display="flex" justifyContent="space-between" mb={4}>

                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">Address</Text>
                                <Text>
                                    <span style={{ fontWeight: "500", fontSize: 15 }}>{facility.address.address1}</span>
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold"> Address 2</Text>
                                <Text>
                                    <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.address.address2 || ""} </span>
                                </Text>
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold" > City </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.address.city} </span></Text >
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold" > State </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.address.state || "CA"} </span></Text >
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold" > ZIP </Text>
                                <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.address.zip || ""} </span></Text >
                            </Box>

                        </Box>


                    </Box>
                    <Divider mb={4} />

                    <Box display="flex" justifyContent="space-between" mb={5}>

                        <Box w={"80%"}>
                            <Text as='u' fontSize='2xl'>Contact</Text>

                            {/* Action By and Action Row */}
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold"> Contact Person </Text>
                                    <Text>
                                        <span style={{ fontWeight: "500", fontSize: 15 }}>{facility.contact.person || "N/A"}</span>
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold"> Contact Phone</Text>
                                    <Text>
                                        <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.contact.phone || "N/A"} </span>
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold" > Contact Email </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.contact.email || "N/A"} </span></Text >
                                </Box>

                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold" > Sales Rep </Text>
                                    <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {facility.salesRep?.name || "N/A"} </span></Text >
                                </Box>
                            </Box>

                        </Box>

                        <Box w={"15%"}>
                            <Text as='u' fontSize='2xl'>Facility Image</Text>

                            {/* Facility Image */}
                            {facility.image ? (
                                <ImageLightbox imageUrl={"http://206.189.202.224:5000/" + facility.image} altText="Facility Image" boxSize="50%" />
                            ) :
                                <Text mt={5}>No Image Available Yet !</Text>
                            }

                        </Box>
                    </Box>
                    <Divider mb={4} />

                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default ViewFacilityModal;
