import { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
} from '@chakra-ui/react';

const ScheduleSearchResults = ({ schedules, startDate, endDate }) => {
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleViewFullSchedule = (schedule) => {
        setSelectedSchedule(schedule);
        onOpen();
    };

    return (
        <>
            <Accordion allowMultiple>
                {schedules.map((schedule) => (
                    <AccordionItem key={schedule.courierId._id}>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left" fontWeight="bold">
                                    {schedule.courierId.name}
                                </Box>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Date</Th>
                                        <Th>Start Time</Th>
                                        <Th>End Time</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {schedule.days.map((day) => (
                                        <Tr key={day.date}>
                                            <Td>{`${new Date(day.date).getMonth() + 1}/${new Date(day.date).getDate()}/${new Date(day.date).getFullYear()}`}</Td>
                                            <Td>{day.startTime || 'N/A'}</Td>
                                            <Td>{day.endTime || 'N/A'}</Td>
                                            <Td>
                                                <Button size="sm" onClick={() => handleViewFullSchedule(schedule)}>View Full Schedule</Button>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>

            {/* Modal to show full schedule */}
            {selectedSchedule && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Full Schedule for Courier</ModalHeader>
                        <ModalBody>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Date</Th>
                                        <Th>Start Time</Th>
                                        <Th>End Time</Th>
                                        <Th>Day Off</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedSchedule.days.map((day) => (
                                        <Tr key={day.date}>
                                            <Td>{new Date(day.date).toLocaleDateString()}</Td>
                                            <Td>{day.startTime || 'N/A'}</Td>
                                            <Td>{day.endTime || 'N/A'}</Td>
                                            <Td>{day.isDayOff ? 'Yes' : 'No'}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default ScheduleSearchResults;
