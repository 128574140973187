import React, { useState, useEffect } from 'react';
import { fetchWarehouses, createWarehouse, updateWarehouse, deleteWarehouse } from '../../api';
import {
    Box, Button, Table, Tbody, Td, Th, Thead, Tr, VStack, useDisclosure, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, Text, Center, HStack
} from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import { hasPermission, trimText } from '../../Utils';

const WarehousesManagement = () => {
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [newWarehouse, setNewWarehouse] = useState({
        name: '',
        zipCodes: [],
        address: { address: '', city: '', state: '', zip: '' }
    });
    const [errorModal, setErrorModal] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredWarehouses, setFilteredWarehouses] = useState([]);
    const itemsPerPage = 20;

    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    useEffect(() => {
        fetchWarehousesData();
    }, []);

    const fetchWarehousesData = async () => {
        setLoading(true);
        const result = await fetchWarehouses();
        if (result.success) {
            setWarehouses(result.data);
        } else {
            setError(result.error);
        }
        setLoading(false);
    };

    // Handle creation of new warehouse
    const handleCreateWarehouse = async () => {
        setErrorModal(null);
        if (!newWarehouse.name || !newWarehouse.zipCodes.length) {
            setErrorModal('Warehouse name and zip codes are required');
            return;
        }
        const result = await createWarehouse(newWarehouse);
        if (result.success) {
            // Update warehouses state with the new warehouse
            setWarehouses((prevWarehouses) => [...prevWarehouses, result.data]);
            onCreateClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle warehouse update
    const handleUpdateWarehouse = async () => {
        setErrorModal(null);
        const result = await updateWarehouse(selectedWarehouse._id, selectedWarehouse);
        if (result.success) {
            // Update the warehouses state with the modified warehouse data
            setWarehouses((prevWarehouses) =>
                prevWarehouses.map((warehouse) =>
                    warehouse._id === selectedWarehouse._id ? result.data : warehouse
                )
            );
            onEditClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle deletion (soft delete) of a warehouse
    const handleDeleteWarehouse = async () => {
        const result = await deleteWarehouse(selectedWarehouse._id);
        if (result.success) {
            // Update warehouses state by filtering out the deleted warehouse
            setWarehouses((prevWarehouses) =>
                prevWarehouses.filter((warehouse) => warehouse._id !== selectedWarehouse._id)
            );
            onDeleteClose();
        } else {
            setErrorModal(result.error);
        }
    };


    useEffect(() => {
        handleSearch();
    }, [searchQuery, warehouses]);

    const handleSearch = () => {
        const filtered = warehouses.filter(warehouse =>
            warehouse.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredWarehouses(filtered);
    };

    const totalPages = Math.ceil(filteredWarehouses.length / itemsPerPage);

    const paginate = (warehousesList) => {
        const start = (currentPage - 1) * itemsPerPage;
        return warehousesList.slice(start, start + itemsPerPage);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Button key={i} onClick={() => setCurrentPage(i)} colorScheme={i === currentPage ? 'blue' : 'gray'} mx={1} size="sm">
                    {i}
                </Button>
            );
        }
        return pageNumbers;
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Warehouses Management" buttonText={hasPermission("createWarehouse") ? "New Warehouse" : null} onClick={hasPermission("createWarehouse") ? onCreateOpen : null} />

                <Input placeholder="Search warehouses..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} mb={4} />

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>Address</Th>
                            <Th>Zip Codes</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginate(filteredWarehouses).map((warehouse, index) => (
                            <Tr key={warehouse._id} _hover={{ bg: "gray.100" }}>
                                <Td>{index + 1}</Td>
                                <Td>{warehouse.name}</Td>
                                <Td>
                                    {`${warehouse?.address?.address}, ${warehouse?.address?.city}, ${warehouse?.address?.state} ${warehouse?.address?.zip}`}
                                </Td>
                                <Td>{trimText(warehouse.zipCodes.join(", "), 20)}</Td>
                                <Td>
                                    <HStack spacing={4}>
                                        {hasPermission("updateWarehouse") && <Button size="sm" colorScheme="green" onClick={() => {
                                            setSelectedWarehouse(warehouse);
                                            onEditOpen();
                                        }}><EditIcon mr={2} /> Edit</Button>}

                                        {hasPermission("deleteWarehouse") && <Button size="sm" colorScheme="red" onClick={() => {
                                            setSelectedWarehouse(warehouse);
                                            onDeleteOpen();
                                        }}><CloseIcon mr={2} /> Delete</Button>}
                                    </HStack>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <HStack justify="center" mt={4}>
                    <Button onClick={() => setCurrentPage(currentPage - 1)} isDisabled={currentPage === 1} size="sm">Previous</Button>
                    {renderPageNumbers()}
                    <Button onClick={() => setCurrentPage(currentPage + 1)} isDisabled={currentPage === totalPages} size="sm">Next</Button>
                </HStack>

                {/* Create Warehouse Modal */}
                <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Warehouse</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl mb={4}>
                                <Text>Name</Text>
                                <Input value={newWarehouse.name} onChange={(e) => setNewWarehouse({ ...newWarehouse, name: e.target.value })} />
                            </FormControl>
                            <FormControl mb={4}>
                                <Text>Address</Text>
                                <Input placeholder="Street Address" value={newWarehouse.address.address} onChange={(e) => setNewWarehouse({ ...newWarehouse, address: { ...newWarehouse.address, address: e.target.value } })} />
                                <Input placeholder="City" value={newWarehouse.address.city} mt={2} onChange={(e) => setNewWarehouse({ ...newWarehouse, address: { ...newWarehouse.address, city: e.target.value } })} />
                                <Input placeholder="State" value={newWarehouse.address.state} mt={2} onChange={(e) => setNewWarehouse({ ...newWarehouse, address: { ...newWarehouse.address, state: e.target.value } })} />
                                <Input placeholder="Zip Code" value={newWarehouse.address.zip} mt={2} onChange={(e) => setNewWarehouse({ ...newWarehouse, address: { ...newWarehouse.address, zip: e.target.value } })} />
                            </FormControl>
                            <FormControl mb={4}>
                                <Text>Zip Codes (comma separated)</Text>
                                <Input value={newWarehouse.zipCodes.join(", ")} onChange={(e) => setNewWarehouse({ ...newWarehouse, zipCodes: e.target.value.split(",").map(zip => zip.trim()) })} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={handleCreateWarehouse}>Create</Button>
                            <Button ml={3} onClick={onCreateClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Warehouse Modal */}
                {selectedWarehouse && <Modal isOpen={isEditOpen} onClose={onEditClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Warehouse</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl mb={4}>
                                <Text>Name</Text>
                                <Input value={selectedWarehouse.name} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, name: e.target.value })} />
                            </FormControl>
                            <FormControl mb={4}>
                                <Text>Address</Text>
                                <Input placeholder="Street Address" value={selectedWarehouse?.address?.address} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, address: { ...selectedWarehouse?.address, address: e.target.value } })} />
                                <Input placeholder="City" value={selectedWarehouse?.address?.city} mt={2} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, address: { ...selectedWarehouse?.address, city: e.target.value } })} />
                                <Input placeholder="State" value={selectedWarehouse?.address?.state} mt={2} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, address: { ...selectedWarehouse?.address, state: e.target.value } })} />
                                <Input placeholder="Zip Code" value={selectedWarehouse?.address?.zip} mt={2} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, address: { ...selectedWarehouse?.address, zip: e.target.value } })} />
                            </FormControl>
                            <FormControl mb={4}>
                                <Text>Zip Codes (comma separated)</Text>
                                <Input value={selectedWarehouse.zipCodes.join(", ")} onChange={(e) => setSelectedWarehouse({ ...selectedWarehouse, zipCodes: e.target.value.split(",").map(zip => zip.trim()) })} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="green" onClick={handleUpdateWarehouse}>Save</Button>
                            <Button ml={3} onClick={onEditClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>}

                {/* Delete Confirmation Modal */}
                {selectedWarehouse && <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Warehouse</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>Are you sure you want to delete the warehouse "{selectedWarehouse.name}"?</ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" onClick={handleDeleteWarehouse}>Yes, Delete</Button>
                            <Button ml={3} onClick={onDeleteClose}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>}
            </VStack>
        </Box>
    );
};

export default WarehousesManagement;
