import { Box, VStack, Heading, Stat, StatLabel, StatNumber, SimpleGrid } from '@chakra-ui/react';

const Dashboard = () => {
    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <Heading>Dashboard</Heading>
                <SimpleGrid spacing={10}>
                    <Stat>
                        <StatLabel>Coming Soon</StatLabel>
                        <StatNumber>Soon we will have more stuff here</StatNumber>
                    </Stat>
                </SimpleGrid>
            </VStack>
        </Box>
    );
};

export default Dashboard;
