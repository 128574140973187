import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    useToast,
    Text,
    SimpleGrid,
    Divider,
    Badge
} from '@chakra-ui/react';
import { createFacility, deleteFacility, getAllFacilities, restoreFacility, updateFacility } from '../../api';
import { ViewIcon, EditIcon, DeleteIcon, RepeatIcon } from '@chakra-ui/icons';
import AccessDenied from '../../components/AccessDenied';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import PopoverId from '../../components/PopoverId';
import { alert, hasPermission } from '../../Utils';
import ViewFacilityModal from './ViewFacilityModal';
import CreateFacilityModal from './CreateFacilityModal';
import UpdateFacilityModal from './UpdateFacilityModal';
import FacilityAddressMap from './FacilityAddressMap';
import Pagination from '../../components/Pagination'

const FacilitiesPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searching, setSearching] = useState(false)
    const itemsPerPage = 25; // Adjust per your requirement
    const toast = useToast();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [searchParams, setSearchParams] = useState({
        name: '',
        city: '',
        address: '',
        clientNumber: ''
    });


    // Fetch facilities on mount
    useEffect(() => {
        fetchFacilities();
        console.log("FI")
    }, []);


    // Fetch facilities with pagination and optional search parameters
    const fetchFacilities = async (page = 1, searchParams = {}) => {
        setLoading(true);
        try {
            // Merge searchParams into your getAllFacilities function
            const result = await getAllFacilities(page, itemsPerPage, searchParams);
            if (result.success) {
                setFacilities(result.data.facilities);
                setCurrentPage(result.data.currentPage);
                setTotalPages(result.data.totalPages);
            } else {
                setError(result.error || 'Failed to fetch facilities');
            }
        } catch (error) {
            setError('Error fetching facilities');
        } finally {
            setLoading(false);
        }
        console.log("HI")
    };


    // Open the modal and set the item to delete
    const openDeleteModal = (item) => {
        setSelectedItem(item);
        onOpen();
    };

    // Confirm delete
    const confirmDelete = () => {
        handleDelete(selectedItem); // Call your delete function
        onClose(); // Close the modal after deletion
    };

    // Handle facility deletion
    const handleDelete = async (facilityId) => {
        try {
            const result = await deleteFacility(facilityId._id);
            if (result.success) {
                toast({
                    title: "Facility deleted successfully.",
                    status: "success",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
                // Remove the deleted facility from the state without refetching
                setFacilities((prevFacilities) =>
                    prevFacilities.filter((f) => f._id !== facilityId._id)
                );
            } else {
                toast({
                    title: result.error,
                    status: "error",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error deleting facility.",
                status: "error",
                duration: 3000,
                position: 'top-right',
                isClosable: true,
            });
        }
    };

    // Handle facility restoration
    const handleViewDetails = (facility) => {
        setSelectedItem(facility);  // Set the facility to be displayed
        setModalOpen(true);  // Open the modal
    };

    // Pagination controls
    const handlePageChange = (newPage) => {
        fetchFacilities(newPage, searchParams); // Include searchParams in pagination
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSearch = async () => {
        setLoading(true);
        setSearching(true)
        try {
            await fetchFacilities(1, searchParams);
        } catch (error) {
            console.error('Error fetching facilities:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleClearSearch = () => {
        // Reset search parameters
        setSearchParams({
            name: '',
            city: '',
            address: '',
            clientNumber: '',
        });
        setSearching(false);
        fetchFacilities(1); // Start from page 1 when clearing the search
    };

    const handleCreateFacility = async (formData) => {
        setLoadingSubmit(true)
        try {
            // Call your API to create a new facility (replace with your actual API call)
            const response = await createFacility(formData);
            if (response.success) {
                // Refresh the list of facilities
                setFacilities((prevFacilities) => [response.data, ...prevFacilities]);
                alert("Facility Created !", 'success', "The facility created successfully", "Okay !");
            } else {
                alert("Oops !", 'error', response.error, "Okay !");
            }
            setIsOpenCreate(false)

        } catch (error) {
            console.error('Error creating facility:', error);
        }
        setLoadingSubmit(false)
    };




    const handleEdit = (facility) => {
        setSelectedItem(facility); // Set the selected facility to edit
        setIsOpenUpdate(true);
    };

    const handleUpdateFacility = async (updatedFacility) => {
        setLoadingSubmit(true);
        try {
            const response = await updateFacility(selectedItem._id, updatedFacility);
            if (response.success) {
                toast({
                    title: "Facility updated successfully.",
                    status: "success",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });

                setFacilities((prevFacilities) =>
                    prevFacilities.map((facility) =>
                        facility._id === selectedItem._id ? { ...facility, ...response.data } : facility
                    )
                );

            } else {
                toast({
                    title: response.error || "Failed to update facility.",
                    status: "error",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Error updating facility:", error);
            toast({
                title: "Error updating facility.",
                status: "error",
                duration: 3000,
                position: 'top-right',
                isClosable: true,
            });
        }
        setIsOpenUpdate(false);
        setLoadingSubmit(false);

    };




    if (error) {
        return <AccessDenied msg={error} />
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Facilities" buttonText={"New Facility"} onClick={() => setIsOpenCreate(true)} />


                {/* Search input */}
                <Text fontSize='lg'>Search Facility</Text>
                <VStack spacing={4} mb={5} width="full">
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="full">
                        <Input
                            name="name"
                            placeholder="Facility Name"
                            value={searchParams.name}
                            onChange={handleInputChange}
                        />
                        <Input
                            name="city"
                            placeholder="City"
                            value={searchParams.city}
                            onChange={handleInputChange}
                        />
                        <Input
                            name="address"
                            placeholder="Address"
                            value={searchParams.address}
                            onChange={handleInputChange}
                        />
                        <Input
                            name="clientNumber"
                            placeholder="Client Number"
                            value={searchParams.clientNumber}
                            onChange={handleInputChange}
                        />
                    </SimpleGrid>

                    {/* Show buttons only if any input field has a value */}
                    <HStack spacing={4} justifyContent="flex-start">
                        <Button onClick={handleSearch} size={"sm"} isLoading={loading} colorScheme="teal">
                            Search
                        </Button>
                        {searching && (
                            <Button
                                onClick={handleClearSearch}
                                colorScheme="red"
                                size={"sm"}
                            >
                                Clear Search
                            </Button>
                        )}
                    </HStack>
                </VStack>
                <Divider />
                {loading &&
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                }

                {/* Facilities table */}
                {!loading &&
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th w={"1%"}>#</Th>
                                <Th w={"1%"}>CN</Th>
                                <Th>Name</Th>
                                <Th>City</Th>
                                <Th>Lockbox</Th>
                                <Th>Sales Rep</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {facilities.map((facility, index) => (
                                <Tr key={facility._id} _hover={{ bg: "gray.100" }}>
                                    <Td><PopoverId place='Facilities' index={index + (currentPage - 1) * itemsPerPage} content={facility._id} /></Td>
                                    <Td>{facility.clientNumber || "N/A"}</Td>
                                    <Td>{facility.name}</Td>
                                    <Td><FacilityAddressMap facility={facility} /></Td>
                                    <Td><Badge colorScheme={facility.hasLockbox ? "green" : "red"}>{facility.hasLockbox ? "Yes" : "No"}</Badge></Td>
                                    <Td>{facility.salesRep?.name || "N/A"}</Td>
                                    <Td>
                                        <HStack spacing={4}>
                                            <Button onClick={() => handleViewDetails(facility)} size="sm" colorScheme="blue">
                                                <ViewIcon mr={2} /> View
                                            </Button>
                                            {hasPermission("updateFacilities") &&
                                                <Button colorScheme="green" onClick={() => handleEdit(facility)} size={"sm"}>
                                                    <EditIcon mr={2} /> Edit
                                                </Button>
                                            }
                                            {hasPermission("deleteFacilities") &&
                                                <Button
                                                    size="sm"
                                                    colorScheme="red"
                                                    onClick={() => openDeleteModal(facility)}>
                                                    <DeleteIcon mr={2} /> Delete
                                                </Button>
                                            }
                                        </HStack>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                }


                {/* Confirmation Modal */}
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Deletion</ModalHeader>
                        <ModalBody>
                            <Text>Are you sure you want to delete the facility <strong>{selectedItem?.name}</strong> ?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" onClick={onClose}>Cancel</Button>
                            <Button colorScheme="red" onClick={confirmDelete} ml={3}>Delete</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <ViewFacilityModal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    facility={selectedItem}
                />
                {selectedItem && (
                    <UpdateFacilityModal
                        facility={selectedItem}
                        isOpen={isOpenUpdate}
                        onClose={() => setIsOpenUpdate(false)}
                        onSubmit={handleUpdateFacility} // Function to handle update
                        loadingSubmit={loadingSubmit} // Loading state for the submit button
                    />
                )}
                <CreateFacilityModal
                    isOpen={isOpenCreate}
                    onClose={() => setIsOpenCreate(false)}
                    onSubmit={handleCreateFacility} // Pass the function to handle the creation
                    loadingSubmit={loadingSubmit}
                />


                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                />





            </VStack>
        </Box>
    );
};

export default FacilitiesPage;
