import React, { useState, useEffect } from 'react';
import { Select, Box, Heading, Table, Thead, Tbody, Tr, Th, Td, VStack, Spinner, Badge, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Text, Divider, Center, Input, Icon } from '@chakra-ui/react';
import { fetchLogs } from '../../api';  // Import your API function
import { ViewIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import PopoverId from '../../components/PopoverId';

const LogView = () => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedResource, setSelectedResource] = useState('');
    const [searchQuery, setSearchQuery] = useState('');  // For search box
    const [sortOrder, setSortOrder] = useState('asc');  // For sorting
    const [currentPage, setCurrentPage] = useState(1);  // For pagination
    const itemsPerPage = 20;  // Number of logs per page
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedLog, setSelectedLog] = useState(null);  // Track selected log for modal

    useEffect(() => {
        fetchLogsData();
    }, []);

    const fetchLogsData = async () => {
        try {
            const response = await fetchLogs();
            if (response.success) {
                setLogs(response.data);
                setFilteredLogs(response.data);
            } else {
                setError(response.error);
            }
        } catch (err) {
            setError('Error fetching logs');
        }
        setLoading(false);
    };

    // Filter logic
    useEffect(() => {
        let updatedLogs = logs;

        if (selectedAction) {
            updatedLogs = updatedLogs.filter(log => log.action === selectedAction);
        }

        if (selectedResource) {
            updatedLogs = updatedLogs.filter(log => log.resource === selectedResource);
        }

        if (searchQuery) {
            updatedLogs = updatedLogs.filter(log =>
                log.userId?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                log.userId?.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sorting
        updatedLogs = updatedLogs.sort((a, b) => {
            return sortOrder === 'asc'
                ? new Date(a.timestamp) - new Date(b.timestamp)
                : new Date(b.timestamp) - new Date(a.timestamp);
        });

        setFilteredLogs(updatedLogs);
    }, [selectedAction, selectedResource, searchQuery, sortOrder, logs]);

    const handleSortTimestamp = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const renderActionBadge = (action) => {
        switch (action) {
            case 'create':
                return <Badge colorScheme="blue">Create</Badge>;
            case 'update':
                return <Badge colorScheme="green">Update</Badge>;
            case 'delete':
                return <Badge colorScheme="red">Delete</Badge>;
            default:
                return <Badge colorScheme="purple">{action}</Badge>;
        }
    };

    const openLogDetails = (log) => {
        setSelectedLog(log);  // Set the selected log for modal
        onOpen();  // Open the modal
    };

    const renderLogDetails = () => {
        if (!selectedLog) return null;

        return (
            <Box mb={5} >
                {/* Action By and Action Row */}
                < Box display="flex" justifyContent="space-between" mb={4} >
                    <Box>
                        <Text fontSize="sm" fontWeight="semibold" > Action By </Text>
                        < Text >
                            <span style={{ fontWeight: "500", fontSize: 15 }}> {selectedLog.userId?.firstName + " " + selectedLog.userId?.lastName || 'Unknown User'} </span>
                            <small>({selectedLog.userId?.email}) </small>
                        </Text>
                    </Box>
                    < Box >
                        <Text fontSize="sm" fontWeight="semibold" > Action </Text>
                        < Text > <span style={{ fontWeight: "500", fontSize: 15 }}> {renderActionBadge(selectedLog.action)} </span></Text >
                    </Box>
                </Box>

                < Divider mb={4} />

                {/* Resource and Timestamp Row */}
                < Box display="flex" justifyContent="space-between" mb={4} >
                    <Box>
                        <Text fontSize="sm" fontWeight="semibold" > Resource </Text>
                        <Text> <span style={{ fontWeight: "500", fontSize: 15 }}> {
                            selectedLog.resource == 'supplies_categories' ? 'Supplies Categories' :
                                selectedLog.resource == 'supplies_items' ? 'Supplies Items' :
                                    selectedLog.resource == 'salesRep' ? 'Sales Rep' : selectedLog.resource
                        } </span></Text>
                    </Box>
                    < Box >
                        <Text fontSize="sm" fontWeight="semibold" > Timestamp </Text>
                        < Text > <span style={{ fontWeight: "500", fontSize: 15 }}> {new Date(selectedLog.timestamp).toLocaleString()} </span></Text >
                    </Box>
                </Box>

                < Divider mb={4} />

                {/* Details Section */}
                < Box mb={4} >
                    <Text fontSize="sm" fontWeight="semibold" > Details </Text>
                    < Box ml={4} mt={3} >
                        {
                            Object.entries(selectedLog.details).map(([key, value]) => (
                                <Text key={key} >
                                    <Text><span style={{ fontWeight: "500", fontSize: 15 }} > {key} </span>: <span style={{ fontSize: 15 }}>{value}</span > </Text>
                                </Text>
                            ))}
                    </Box>
                </Box>

                < Divider mb={4} />
                {
                    selectedLog.resourceId && (
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold" > Affected Item</ Text >
                            <Box ml={4} mt={5} >
                                {
                                    Object.entries(selectedLog.resourceId).map(([key, value]) => {
                                        // Skip password field
                                        if (key === 'password' || key === '__v' || key === 'username') return null;

                                        // Format active field as boolean
                                        if (key === 'active') {
                                            return (
                                                <Text mt={3} key={key} >
                                                    <span style={{ fontWeight: "500", fontSize: 15 }}> Active : </span> <span style={{ fontSize: 15 }}>{value ? 'True' : 'False'}</span >
                                                </Text>
                                            );
                                        }

                                        // Format createdAt and updatedAt as human-readable dates
                                        if (key === 'createdAt' || key === 'updatedAt') {
                                            return (
                                                <Text mt={3} key={key} >
                                                    <span style={{ fontWeight: "500", fontSize: 15 }}> {key === 'createdAt' ? "Created At" : "Updated At"
                                                    } : </span> <span style={{ fontSize: 15 }}>{new Date(value).toLocaleString()}</span >
                                                </Text>
                                            );
                                        }

                                        // If the field is role, display the role name instead of ID
                                        if (key === 'role' && value && value.name) {
                                            return (
                                                <Text mt={3} key={key} >
                                                    <span style={{ fontWeight: "500", fontSize: 15 }}> Role : </span> <span style={{ fontSize: 15 }}>{value.name}</span >
                                                </Text>
                                            );
                                        }

                                        // Default case for other fields
                                        return (
                                            <Text mt={3} key={key} >
                                                <span style={{ fontWeight: "500", fontSize: 15 }}> {
                                                    key == "lastName" ? "Last Name "
                                                        :
                                                        key == 'firstName' ? "First Name "
                                                            :
                                                            key == 'email' ? "Email "
                                                                :
                                                                key == '_id' ? "ID "
                                                                    :
                                                                    key
                                                } : </span><span style={{ fontSize: 15 }}>{value}</span >
                                            </Text>
                                        );
                                    })}
                            </Box>
                        </Box>
                    )}

            </Box>
        );
    };

    // Pagination logic
    const totalPages = Math.ceil(filteredLogs.length / itemsPerPage);
    const currentLogs = filteredLogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {!error ?
                    <>
                        <TitleHeader title={"System Log"} />



                        {/* Filter Section */}
                        <Box display="flex" justifyContent="space-between">
                            {/* Search Box */}
                            <Input
                                placeholder="Search by name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                mb={4}
                                width="52%"
                            />

                            {/* Filter by Action */}
                            <Select
                                placeholder="Filter by Action"
                                value={selectedAction}
                                onChange={(e) => setSelectedAction(e.target.value)}
                                width="22%"
                            >
                                <option value="create">Create</option>
                                <option value="update">Update</option>
                                <option value="delete">Delete</option>
                            </Select>

                            {/* Filter by Resource */}
                            <Select
                                placeholder="Filter by Resource"
                                value={selectedResource}
                                onChange={(e) => setSelectedResource(e.target.value)}
                                width="22%"
                            >
                                <option value="User">User</option>
                                <option value="Business">Business</option>
                                <option value="SalesRep">Sales Rep</option>
                                <option value="Role">Role</option>
                                <option value="clients_order">Orders</option>
                                <option value="LabLocation">Labs</option>
                                <option value="Facility">Facilities</option>

                            </Select>
                        </Box>

                        {/* Logs Table */}
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th w={1}>#</Th>
                                    <Th>Action by</Th>
                                    <Th>Action</Th>
                                    <Th>Resource</Th>
                                    <Th onClick={handleSortTimestamp} cursor="pointer">
                                        Timestamp {sortOrder === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    </Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {currentLogs.map((log, index) => (
                                    <Tr key={log._id} _hover={{ bg: "gray.100" }}>
                                        <Td><PopoverId place='Logs Management' index={index} content={log._id} /></Td>
                                        <Td>{log.userId?.firstName + " " + log.userId.lastName || 'Unknown User'} <br /> <small>{log.userId?.email}</small></Td>
                                        <Td>{renderActionBadge(log.action)}</Td>
                                        <Td>{log.resource == 'supplies_categories' ? 'Supplies Categories' :
                                            log.resource == 'supplies_items' ? 'Supplies Items' :
                                                log.resource == 'SalesRep' ? 'Sales Rep' : log.resource}</Td>
                                        <Td>{new Date(log.timestamp).toLocaleString()}</Td>
                                        <Td>
                                            <Button size="sm" onClick={() => openLogDetails(log)} colorScheme="blue">
                                                <ViewIcon mr={2} /> View Details
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        {/* Pagination */}
                        <Box mt={4} display="flex" justifyContent="center">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Button
                                    key={index}
                                    size="sm"
                                    mx={1}
                                    colorScheme={currentPage === index + 1 ? 'blue' : 'gray'}
                                    onClick={() => setCurrentPage(index + 1)}
                                >
                                    {index + 1}
                                </Button>
                            ))}
                        </Box>

                        {/* Modal for viewing log details */}
                        <Modal isOpen={isOpen} size={'xl'} scrollBehavior='inside' onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Log Details</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    {renderLogDetails()}
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </>
                    :
                    <AccessDenied msg={error} />
                }
            </VStack>
        </Box>
    );
};

export default LogView;
