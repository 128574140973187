import { Box, FormControl, Input, Button, Heading, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { loginUser, signupUser } from '../../api';
import { alert } from '../../Utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserInfo, setUserPermissions } from '../../redux/actions/userActions';

const Login = () => {
    const { handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const result = await loginUser(data);  // Make the login request
            if (result.success) {
                const token = result.data.token;  // Assuming the backend returns the token in the response

                // Check if cookies are enabled
                const cookieEnabled = navigator.cookieEnabled;

                if (cookieEnabled) {
                    // No need to store the token manually, it's in the HTTP-only cookie.
                    console.log('Token stored in HTTP-only cookie');
                } else {
                    // Fallback: Store the token in localStorage if cookies are disabled
                    localStorage.setItem('token', token);
                    console.log('Cookies are disabled, token stored in localStorage:', token);
                }

                // Set a client-side `isAuthenticated` flag for route protection
                localStorage.setItem('isAuthenticated', true);
                const userData = result.data.user;

                // Dispatch user info
                dispatch(setUserInfo({
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    role: userData?.role?.name || "N/A"
                }));

                // Dispatch permissions from role
                dispatch(setUserPermissions(userData.role.permissions));
                // Redirect to dashboard after successful login
                navigate('/dashboard');
            } else {
                alert('Oops !', 'error', result.error, 'Okay !', true)
            }
        } catch (error) {
            alert('Oops !', 'error', 'An error occurred. Please try again later.', 'Okay !', true)
        }
        setLoading(false)
    };


    return (
        <Box display="flex" bg="#faf7f7" justifyContent="center" alignItems="center" height="100vh">
            <Box
                maxW="sm"
                w="100%"
                p="8"
                boxShadow="lg"
                borderRadius="md"
                bg="white"
            >
                <Heading mb="6" textAlign="center">Login</Heading>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mb="4" isInvalid={errors.username}>
                        <Input
                            placeholder="Username or Email"
                            {...register('identifier', { required: 'Username/Email is required' })}
                        />
                        {errors.username && <Text color="red.500">{errors.username.message}</Text>}
                    </FormControl>
                    <FormControl mb="4" isInvalid={errors.password}>
                        <Input
                            type="password"
                            placeholder="Password"
                            {...register('password', { required: 'Password is required' })}
                        />
                        {errors.password && <Text color="red.500">{errors.password.message}</Text>}
                    </FormControl>
                    <Button isLoading={loading} type="submit" colorScheme="blue" width="full">Login</Button>
                </form>
            </Box>
        </Box>
    );
};

export default Login;
