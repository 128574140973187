import React, { Suspense } from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    Button,
    VStack,
    HStack,
    Divider,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Select,
    Alert,
    AlertIcon,
    AlertDescription,
    useDisclosure,
    Badge,
    Center
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { fetchRouteById, updateRoute, fetchAllCouriers, fetchPickupsByRouteId } from '../../api'; // You need to create these API functions
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import { hasPermission } from '../../Utils';

const ViewPickupsByDay = React.lazy(() => import('./ViewPickupsByDay'));



const ViewRoute = () => {
    const { routeId } = useParams(); // Get the routeId from the URL
    const [route, setRoute] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [errorModal, setErrorModal] = useState(null); // Error handling for modals
    const [couriers, setCouriers] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState('');
    const [routePickups, setRoutePickups] = useState([]);

    const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
    const { isOpen: isCourierModalOpen, onOpen: onOpenCourierModal, onClose: onCloseCourierModal } = useDisclosure();

    const [routeDetails, setRouteDetails] = useState({ name: '', cities: '' });

    useEffect(() => {
        getRouteDetails();
    }, [routeId]);


    const getRoutePickups = async () => {
        try {
            const response = await fetchPickupsByRouteId(routeId);
            if (response.success) {
                setRoutePickups(response.data);
                console.log(response.data)
            } else {
                setError(response.error)
            }
        } catch (error) {
            setError('Error fetching route details');
        }
    }

    const getRouteDetails = async () => {
        setError(null);
        try {
            const response = await fetchRouteById(routeId);
            if (response.success) {
                setRoute(response.data);
                setRouteDetails({ name: response.data.name, cities: response.data.cities });
                getRoutePickups();
            } else {
                window.location.href = "../../404";
            }
        } catch (error) {
            setError('Error fetching route details');
        }
        setLoading(false);
    };

    const handleAssignCourier = async () => {
        setErrorModal(null);
        if (!selectedCourier) {
            setErrorModal('Please select a courier.');
            return;
        }
        setLoadingProcess(true);
        try {
            const response = await updateRoute(route._id, { courier: selectedCourier });
            if (response.success) {
                setRoute(response.data);
                onCloseCourierModal();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error assigning courier.');
        }
        setLoadingProcess(false);
    };

    const handleUpdateRoute = async () => {
        setErrorModal(null);
        if (!routeDetails.name || !routeDetails.cities) {
            setErrorModal('Please fill in all fields.');
            return;
        }
        setLoadingProcess(true);
        try {
            const response = await updateRoute(route._id, routeDetails);
            if (response.success) {
                setRoute(response.data);
                onCloseEditModal();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error updating route.');
        }
        setLoadingProcess(false);
    };

    const getCouriers = async () => {
        try {
            const response = await fetchAllCouriers();
            if (response.success) {
                setCouriers(response.data.couriers);
            } else {
                setErrorModal('Failed to load couriers');
            }
        } catch (error) {
            setErrorModal('Error loading couriers');
        }
    };

    if (loading) {
        return (
            <VStack mt={10}>
                <Spinner size="lg" />
                <Heading as="h5" size="sm">Loading Route...</Heading>
            </VStack>
        );
    }


    return (
        <Box maxW="full" mx="auto" p={6}>

            <TitleHeader title={"Route Details"} buttonText={hasPermission('updateRoute') ? "Edit Route" : null} onClick={hasPermission('updateRoute') ? onOpenEditModal : null} />

            <HStack mt={9} align="start" spacing={8} mb={6} w="full">
                <VStack align="start" spacing={4} flex="1">
                    <Text fontWeight="bold">Route Name:</Text>
                    <Text>{route?.name}</Text>
                </VStack>

                <VStack align="start" spacing={4} flex="1">
                    <Text fontWeight="bold">Courier Assigned:</Text>
                    <Badge
                        colorScheme={route?.courier?.name ? 'green' : 'red'}
                        onClick={hasPermission('updateRoute') ? () => { getCouriers(); onOpenCourierModal(); } : null}
                        cursor="pointer"
                    >
                        {route?.courier?.name || "No Courier Assigned"}
                    </Badge>
                </VStack>

                <VStack flex="1" spacing={4} align="start">
                    <Text fontWeight="bold">Cities:</Text>
                    <Text>{route?.cities}</Text>
                </VStack>

            </HStack>


            <Divider />
            {error ?
                <VStack mt={10}>
                    <AccessDenied msg={error} icon={"info"} />
                </VStack>
                :

                <>
                    <VStack align="start" mt={6}>
                        <Suspense fallback={<Spinner />}>
                            <ViewPickupsByDay pickups={routePickups} />
                        </Suspense>
                    </VStack>

                    <Divider />
                </>


            }


            {/* Edit Route Modal */}
            <Modal isOpen={isEditModalOpen} onClose={onCloseEditModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Route</ModalHeader>
                    <ModalBody>
                        {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                        <FormControl>
                            <FormLabel>Route Name</FormLabel>
                            <Input
                                value={routeDetails.name}
                                onChange={(e) => setRouteDetails({ ...routeDetails, name: e.target.value })}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Cities</FormLabel>
                            <Input
                                value={routeDetails.cities}
                                onChange={(e) => setRouteDetails({ ...routeDetails, cities: e.target.value })}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseEditModal} mr={3}>Cancel</Button>
                        <Button isLoading={loadingProcess} colorScheme="blue" onClick={handleUpdateRoute}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Assign Courier Modal */}
            <Modal isOpen={isCourierModalOpen} onClose={onCloseCourierModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{route?.courier ? 'Change Courier' : 'Assign Courier'}</ModalHeader>
                    <ModalBody>
                        {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                        <FormControl>
                            <FormLabel>Select Courier</FormLabel>
                            <Select
                                placeholder="Select courier"
                                onChange={(e) => setSelectedCourier(e.target.value)}
                            >
                                {couriers.map((courier) => (
                                    <option key={courier._id} value={courier._id}>
                                        {courier.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseCourierModal} mr={3}>Cancel</Button>
                        <Button isLoading={loadingProcess} colorScheme="green" onClick={handleAssignCourier}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ViewRoute;
