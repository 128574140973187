import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Text } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadIcon } from '@chakra-ui/icons';

const PdfGeneratorOrders = ({ orderDetailsRef, name, title }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const generatePdf = async () => {
        setIsGenerating(true);
        onOpen();
        const pdf = new jsPDF('p', 'mm', 'letter'); // Create a new PDF instance
        const margin = 12.7; // 0.5 inch in mm
        const imgWidth = 190.5; // Width for content

        try {
            // Capture the Client Details and Days of Operation
            if (!isCancelled && orderDetailsRef.current) {
                const clientDetailsCanvas = await html2canvas(orderDetailsRef.current, { scale: 2 });
                const clientDetailsImgData = clientDetailsCanvas.toDataURL('image/png');
                const imgHeightClientDetails = (clientDetailsCanvas.height * imgWidth) / clientDetailsCanvas.width;

                pdf.addImage(clientDetailsImgData, 'PNG', margin, margin, imgWidth, imgHeightClientDetails, undefined, 'SLOW');
            }
            if (!isCancelled) {
                pdf.save(`${name} - Supplies Order.pdf`); // Save the PDF
            }

            setIsGenerating(false);
        } catch (error) {
            console.error('PDF generation error:', error);
            setIsGenerating(false);
        }
    };


    const handleGenerate = () => {
        setIsCancelled(false);
        generatePdf().then(() => { setIsGenerating(false) });
    };

    return (
        <>
            <Button color={"lightSeaGreen"} onClick={handleGenerate}> <DownloadIcon color={'lightSeaGreen'} mr={2} />  {title} PDF</Button>

            <Modal isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isGenerating ? `Generating ${name} PDF` : 'Generation Completed'}</ModalHeader>
                    <ModalBody>
                        <Text>{isGenerating ? `Please wait while ${name} PDF is being generated...` : `${name} PDF is downloaded and ready to view.`}</Text>
                    </ModalBody>
                    <ModalFooter>
                        {!isGenerating &&
                            <Button colorScheme="green" onClick={onClose}>Close</Button>
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PdfGeneratorOrders;
