// Action to set user information
export const setUserInfo = (userInfo) => ({
    type: 'SET_USER_INFO',
    payload: userInfo,
});

// Action to set user permissions
export const setUserPermissions = (permissions) => ({
    type: 'SET_USER_PERMISSIONS',
    payload: permissions,
});

// Action to log out user
export const rLogoutUser = () => ({
    type: 'LOGOUT_USER',
});
