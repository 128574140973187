// components/ConfirmDeleteModal.js
import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, itemName }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalBody>
                Are you sure you want to delete template <strong>{itemName}</strong>?
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={onConfirm}>Delete</Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
);

export default ConfirmDeleteModal;
