import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Text } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

const SignatureGenerator = ({ signatureBase64, name }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleGenerate = () => {
        onOpen(); // Open the modal
        setIsGenerating(true);

        try {
            if (signatureBase64) {
                // Create a link element, use it to trigger the download
                const link = document.createElement('a');
                link.href = signatureBase64;
                link.download = 'signature.png';
                link.click();
            }
        } catch (error) {
            console.error('Error generating signature:', error);
        }

        setIsGenerating(false);
    };

    return (
        <>
            <Button color={"darkBlue"} onClick={handleGenerate}>
                <DownloadIcon color={'darkBlue'} mr={2} /> {name}
            </Button>

            <Modal isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isGenerating ? 'Generating Signature' : 'Generation Completed'}</ModalHeader>
                    <ModalBody>
                        <Text>{isGenerating ? 'Please wait while the signature is being generated...' : 'Signature has been downloaded and is ready to view.'}</Text>
                    </ModalBody>
                    <ModalFooter>
                        {!isGenerating &&
                            <Button colorScheme="green" onClick={onClose}>Close</Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SignatureGenerator;
