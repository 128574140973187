import React, { useState, useEffect } from 'react';
import { fetchAllOrders, fetchBusinesses, fetchWarehouses, getAllFacilities, softDeleteOrder, updateOrder } from '../../api';
import {
    Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, HStack,
    VStack, Center, FormControl, FormLabel, Input, Stack, Badge,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Text,
    useToast,
    Divider,
    SimpleGrid
} from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { alert, hasPermission, trimText } from "../../Utils";
import PopoverId from '../../components/PopoverId';
import Select from 'react-select';
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import PrintPage from './PrintLabel';
const ClientsOrders = () => {
    const {
        isOpen: isOpenAssignClient,
        onOpen: onOpenAssignClient,
        onClose: onCloseAssignClient
    } = useDisclosure();
    const navigate = useNavigate()
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const toast = useToast();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isDeleting, setIsDeleting] = useState(false);

    const [isWarehouseModalOpen, setIsWarehouseModalOpen] = useState(false);
    // const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    const [businesses, setBusinesses] = useState([])
    const itemsPerPage = 25; // Adjust per your requirement
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searching, setSearching] = useState(false);
    const [searchParams, setSearchParams] = useState({
        name: '',
        city: '',
        address: '',
        clientNumber: ''
    });


    const [currentPageOrders, setCurrentPageOrders] = useState(1);
    const [totalPagesOrders, setTotalPagesOrders] = useState(1);
    const itemsPerPageOrders = 25; // Adjust per your requirement



    const handleSearch = async () => {
        setLoading(true);
        setSearching(true)
        try {
            await fetchFacilities(1, searchParams);
        } catch (error) {
            console.error('Error fetching facilities:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleClearSearch = () => {
        // Reset search parameters
        setSearchParams({
            name: '',
            city: '',
            address: '',
            clientNumber: '',
        });
        setSearching(false);
        fetchFacilities(1); // Start from page 1 when clearing the search
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prev => ({
            ...prev,
            [name]: value
        }));
    };



    const fetchFacilities = async (page = 1, searchParams = {}) => {
        setLoading(true);
        try {
            // Merge searchParams into your getAllFacilities function
            const result = await getAllFacilities(page, itemsPerPage, searchParams);
            if (result.success) {
                setBusinesses(result.data.facilities);
                setCurrentPage(result.data.Orders);
                setTotalPages(result.data.totalPages);
            } else {
                setError(result.error || 'Failed to fetch facilities');
            }
        } catch (error) {
            setError('Error fetching facilities');
        } finally {
            setLoading(false);
        }
        console.log("HI")
    };




    useEffect(() => {
        // Load orders once when the component mounts
        loadOrders();
        fetchWarehousesData();
        fetchFacilities();
    }, []);

    const fetchWarehousesData = async () => {
        const result = await fetchWarehouses();
        if (result.success) {
            setWarehouses(result.data); // Save warehouses to state
        } else {
            console.error(result.error);
        }
    };

    const loadOrders = async (page = 1) => {
        setLoading(true);
        try {
            const result = await fetchAllOrders(page, itemsPerPageOrders);
            if (result.success) {
                // Store all orders in state
                setOrders(result.data.orders);
                setCurrentPageOrders(result.data.currentPage);
                setTotalPagesOrders(result.data.totalPages);

            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to load orders');
        }
        setLoading(false);
    };

    // Pagination controls
    const handlePageChangeOrders = (newPage) => {
        loadOrders(newPage); // Include searchParams in pagination
    };

    // Update Client 
    const handleClientClick = async (businessId) => {
        if (!selectedOrderId) return;
        try {
            const result = await updateOrder(selectedOrderId, { business: businessId });
            console.log(result.data)
            if (result.success) {
                let cname = result.data.business?.name;
                setOrders(prevOrders =>
                    prevOrders.map(order =>
                        order._id === selectedOrderId
                            ? { ...order, business: result.data.business }
                            : order
                    )
                );

                toast({
                    title: "Client assigned successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onCloseAssignClient(); // Close the modal after successful assignment
            } else {
                toast({
                    title: "Failed to assign client.",
                    description: result.error,
                    status: "error",
                    position: "top-right",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error assigning client.",
                description: error.message,
                status: "error",
                position: "top-right",
                duration: 3000,
                isClosable: true,
            });
        }
    };


    const handleWarehouseSelect = async (warehouseId) => {
        if (!selectedOrderId) return;
        try {
            const result = await updateOrder(selectedOrderId, { warehouseId });

            if (result.success) {
                setOrders(prevOrders =>
                    prevOrders.map(order =>
                        order._id === selectedOrderId
                            ? { ...order, warehouseId: result.data.warehouseId }
                            : order
                    )
                );

                toast({
                    title: "Warehouse assigned successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                handleCloseWarehouseModal(); // Close the modal after successful assignment
            } else {
                toast({
                    title: "Failed to assign Warehouse.",
                    description: result.error,
                    status: "error",
                    position: "top-right",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error assigning warehouse.",
                description: error.message,
                status: "error",
                position: "top-right",
                duration: 3000,
                isClosable: true,
            });
        }
    };



    const openAssignClientModal = (orderId) => {
        setSelectedOrderId(orderId);
        onOpenAssignClient(); // Open the modal
    };

    // Open modal with the order data
    const handleOpenWarehouseModal = (order) => {
        setSelectedOrderId(order);
        // setSelectedWarehouse(order.warehouseId?._id || null); // Set initial selected warehouse
        setIsWarehouseModalOpen(true);
    };

    // Close modal
    const handleCloseWarehouseModal = () => {
        setIsWarehouseModalOpen(false);
        setSelectedOrderId(null);
        // setSelectedWarehouse(null);
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            const response = await softDeleteOrder(selectedOrderId);
            if (response.success) {
                // Remove the deleted order from the list
                setOrders(prevOrders => prevOrders.filter(order => order._id !== selectedOrderId));

                toast({
                    title: "Order deleted successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                alert('Oops !', 'warning', response.error, "Okay !")
            }
        } catch (error) {
            alert('Oops !', 'error', error.message, "Okay !")
        } finally {
            setIsDeleting(false);
            onClose(); // Close the modal
        }
    };

    const handlePageChange = (newPage) => {
        fetchFacilities(newPage, searchParams); // Include searchParams in pagination
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">

                <TitleHeader
                    title="Clients Orders"
                    buttonText="Create New Order"
                    onClick={() => { navigate('/create-order-internal') }}
                    secondButton={hasPermission("viewFinishedOrders")}
                    secondButtonText='Finished Orders'
                    secondButtonOnClick={() => { navigate('/clients-finished-orders') }}
                />

                <Divider />

                {/* Orders Table */}
                {orders.length === 0 ? (
                    <AccessDenied msg={"Nothing here yet !"} icon="info" />
                ) : (
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th maxW={1}>#</Th>
                                    <Th>Client Name</Th>
                                    <Th>RI</Th>
                                    <Th>Address</Th>
                                    <Th>Order Status</Th>
                                    <Th>Ordered By</Th>
                                    <Th>Warehouse</Th>
                                    <Th>Created At</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {orders.map((order, index) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={order._id}>
                                        <Td maxW={1}><PopoverId place='Clients Orders' index={index} content={order._id} /></Td>

                                        <Td>
                                            {!order.business ? (
                                                <Box as="span" ml={2} color="red.400" fontWeight={'bold'}>
                                                    <PopoverId id={false} place='Client Orders' header='No Client Assigned' index={index} content={"This order is not assigned to any client !"} />
                                                    {order.clientName}
                                                </Box>
                                            ) :

                                                (
                                                    <Box as="span" ml={2} color="blue.800" fontWeight={'bold'}>
                                                        {order.business.name}
                                                    </Box>
                                                )
                                            }
                                        </Td>
                                        <Td>{order?.requestedItems?.length || "N/A"}</Td>

                                        <Td>
                                            <PopoverId id={false} place='Client Orders' header='Client Address' index={index} content={(order?.clientAddress?.address || "N/A") + ", " + (order?.clientAddress?.city || "N/A") + ", " + (order?.clientAddress?.state || "N/A") + ", " + (order?.clientAddress?.zip || "N/A")} />
                                            {trimText((order?.clientAddress?.address || "N/A"), 37)}
                                        </Td>

                                        <Td>
                                            <VStack align="start" spacing={1}>
                                                <Badge cursor="pointer" onClick={hasPermission("changeOrderClient") ? () => openAssignClientModal(order._id) : null} colorScheme={!order.business ? "orange" : "green"}>{!order.business ? "No Client Assigned" : "Change Client"}</Badge>

                                                <Badge colorScheme={order.finished ? 'green' : 'red'}>
                                                    {order.finished ? 'Finished' : 'Not Finished'}
                                                </Badge>
                                            </VStack>
                                        </Td>
                                        <Td>{order.orderedBy}</Td>


                                        <Td>
                                            <Badge cursor="pointer" onClick={hasPermission("changeOrderWarehouse") ? () => handleOpenWarehouseModal(order._id) : null} colorScheme={!order?.warehouseId?.name ? "orange" : "green"}>{!order?.warehouseId?.name ? "No Warehouse Assigned" : order?.warehouseId?.name}</Badge>

                                        </Td>

                                        {/* <Td>{order?.warehouseId?.name || "N/A"}</Td> */}

                                        <Td>{new Date(order.createdAt).toLocaleDateString()}</Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                {hasPermission('viewOrder') &&
                                                    <Button size="sm" onClick={() =>
                                                        !order.business ? alert("No Client", 'warning', 'You have to assign a client to this order befor you can view it.', "Okay !")
                                                            :
                                                            navigate(`/order-details/${order._id}`)

                                                    } colorScheme="blue">
                                                        <ViewIcon mr={2} /> View
                                                    </Button>
                                                }
                                                {hasPermission('deleteOrder') &&
                                                    <Button size="sm" onClick={() => { setSelectedOrderId(order._id); onOpen(); }} colorScheme="red">
                                                        <DeleteIcon mr={2} /> Delete
                                                    </Button>
                                                }
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>


                        <Pagination
                            currentPage={currentPageOrders}
                            totalPages={totalPagesOrders}
                            handlePageChange={handlePageChangeOrders}
                        />
                    </>
                )}


                <Modal scrollBehavior='inside' isOpen={isOpenAssignClient} size={"xla"} onClose={onCloseAssignClient}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Assign Client</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>

                            <Text fontSize='lg'>Search Facility</Text>
                            <VStack spacing={4} mb={5} width="full">
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="full">
                                    <Input
                                        name="name"
                                        placeholder="Facility Name"
                                        value={searchParams.name}
                                        onChange={handleInputChange}
                                    />
                                    <Input
                                        name="city"
                                        placeholder="City"
                                        value={searchParams.city}
                                        onChange={handleInputChange}
                                    />
                                    <Input
                                        name="address"
                                        placeholder="Address"
                                        value={searchParams.address}
                                        onChange={handleInputChange}
                                    />
                                    <Input
                                        name="clientNumber"
                                        placeholder="Client Number"
                                        value={searchParams.clientNumber}
                                        onChange={handleInputChange}
                                    />
                                </SimpleGrid>

                                {/* Show buttons only if any input field has a value */}
                                <HStack spacing={4} justifyContent="flex-start">
                                    <Button onClick={handleSearch} size={"sm"} isLoading={loading} colorScheme="teal">
                                        Search
                                    </Button>
                                    {searching && (
                                        <Button
                                            onClick={handleClearSearch}
                                            colorScheme="red"
                                            size={"sm"}
                                        >
                                            Clear Search
                                        </Button>
                                    )}
                                </HStack>
                            </VStack>

                            <VStack align="start" spacing={4}>
                                {businesses.map((business) => (
                                    <Box
                                        key={business._id}
                                        cursor="pointer"
                                        p={3}
                                        borderWidth="1px"
                                        borderRadius="md"
                                        w="full"
                                        onClick={() => handleClientClick(business._id)}
                                        _hover={{ bg: 'gray.100' }}
                                    >
                                        <Text fontWeight="bold">{business.name}</Text>
                                        <Text fontSize="sm" color="gray.600">
                                            {business.address.address1}, {business.address.city}, {business.address.state}, {business.address.zip}
                                        </Text>
                                    </Box>
                                ))}
                            </VStack>
                        </ModalBody>
                        <ModalFooter>

                            {/* Pagination controls */}
                            <HStack mr={5} justify="center">
                                <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    isDisabled={currentPage == 1}
                                >
                                    &laquo; Previous
                                </Button>

                                {/* First page */}
                                <Button
                                    onClick={() => handlePageChange(1)}
                                    colorScheme={currentPage == 1 ? 'teal' : 'gray'}
                                    isDisabled={currentPage == 1}
                                >
                                    1
                                </Button>

                                {/* Ellipses before current range */}
                                {currentPage > 3 && <Button isDisabled>...</Button>}

                                {/* Middle pages (show currentPage, currentPage-1, and currentPage+1) */}
                                {Array.from({ length: 3 }, (_, i) => currentPage - 1 + i)
                                    .filter(page => page > 1 && page < totalPages)
                                    .map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            colorScheme={currentPage == page ? 'teal' : 'gray'}
                                            isDisabled={currentPage == page}
                                        >
                                            {page}
                                        </Button>
                                    ))}

                                {/* Ellipses after current range */}
                                {currentPage < totalPages - 2 && <Button isDisabled>...</Button>}

                                {/* Last page */}
                                {totalPages > 1 && (
                                    <Button
                                        onClick={() => handlePageChange(totalPages)}
                                        colorScheme={currentPage == totalPages ? 'teal' : 'gray'}
                                        isDisabled={currentPage == totalPages}
                                    >
                                        {totalPages}
                                    </Button>
                                )}

                                <Button
                                    onClick={() => handlePageChange(Number(currentPage) + 1)}
                                    isDisabled={currentPage == totalPages}
                                >
                                    Next &raquo;
                                </Button>
                            </HStack>



                            <Button colorScheme="blue" onClick={onCloseAssignClient}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>



                </Modal>

                {/* Confirmation Modal */}
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Delete Order</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>Are you sure you want to delete this order? This action cannot be undone.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose} mr={3}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={handleDelete}
                                isLoading={isDeleting}
                            >
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>


                <Modal scrollBehavior="inside" isOpen={isWarehouseModalOpen} size="xl" onClose={handleCloseWarehouseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Select Warehouse</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack align="start" spacing={4}>
                                {warehouses.map((warehouse) => (
                                    <Box
                                        key={warehouse._id}
                                        cursor="pointer"
                                        p={3}
                                        borderWidth="1px"
                                        borderRadius="md"
                                        w="full"
                                        onClick={() => handleWarehouseSelect(warehouse._id)}
                                        _hover={{ bg: 'gray.100' }}
                                    >
                                        <Text fontWeight="bold">{warehouse.name}</Text>
                                        <Text fontSize="sm" color="gray.600">
                                            {warehouse?.address?.address}, {warehouse?.address?.city}, {warehouse?.address?.state}, {warehouse?.address?.zip}
                                        </Text>
                                    </Box>
                                ))}
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={handleCloseWarehouseModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>


            </VStack>
        </Box>
    );
};

export default ClientsOrders;
