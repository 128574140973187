import { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Checkbox,
    useToast,
    Box,
    VStack,
    Center,
    Heading
} from '@chakra-ui/react';
import { addDays, format, isAfter } from 'date-fns'; // Import date comparison helpers
import { createSchedule, fetchSchedulesByCourierId, updateSchedule } from '../../api'; // API function to create schedule
import Loading from '../../components/Loading/Loading';

const CreateScheduleModal = ({ isOpen, onClose, courier }) => {
    const toast = useToast();
    const today = new Date();
    const maxDays = 14;
    const [scheduleData, setScheduleData] = useState({
        courierId: courier._id,
        days: Array(7).fill().map((_, index) => ({
            date: format(addDays(today, index), 'MM/dd/y'),
            startTime: '',
            endTime: '',
            isDayOff: false,
        })),
        status: 'active',
    });
    const [scheduleId, setScheduleId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingChecking, setLoadingChecking] = useState(false);
    const [allowNewSchedule, setAllowNewSchedule] = useState(false);

    useEffect(() => {
        setScheduleData({
            courierId: courier._id,
            days: Array(7).fill().map((_, index) => ({
                date: format(addDays(today, index), 'MM/dd/y'),
                startTime: '',
                endTime: '',
                isDayOff: false,
            })),
            status: 'active',
        })
        fetchExistingSchedule();
    }, [isOpen]);

    const fetchExistingSchedule = async () => {
        setLoadingChecking(true);
        try {
            const response = await fetchSchedulesByCourierId(courier._id);


            console.log('...........')
            console.log(response)
            console.log('...........')


            if (response.success && response.data?.schedules?.length > 0) {
                const existingSchedule = response.data.schedules[0];
                const updatedDays = existingSchedule.days.map(day => ({
                    date: format(new Date(day.date), 'MM/dd/y'),
                    startTime: day.startTime || '',
                    endTime: day.endTime || '',
                    isDayOff: day.isDayOff,
                }));

                setScheduleData({
                    courierId: courier._id,
                    days: updatedDays,
                    status: existingSchedule.status,
                });

                const lastDay = new Date(existingSchedule.days[existingSchedule.days.length - 1].date);
                const lastStartTime = existingSchedule.days[existingSchedule.days.length - 1].startTime;

                // Combine last day and start time to check if current time is after the last day start time
                const lastDayStartTime = new Date(`${format(lastDay, 'MM/dd/y')} ${lastStartTime}`);

                if (isAfter(new Date(), lastDayStartTime)) {
                    setAllowNewSchedule(true); // Allow new schedule creation
                } else {
                    setScheduleId(existingSchedule._id); // Allow updates to the existing schedule
                    setAllowNewSchedule(false); // Don't allow new schedule creation until after the last start time
                }
            } else {
                // No existing schedule, so allow creating a new one
                setScheduleId(null);
                setAllowNewSchedule(true);
            }
        } catch (error) {
            console.error('Error fetching existing schedule:', error);
        }
        setLoadingChecking(false);
    };

    const handleAddDay = () => {
        if (scheduleData.days.length < maxDays) {
            const newDay = {
                date: format(addDays(today, scheduleData.days.length), 'MM/dd/y'),
                startTime: '',
                endTime: '',
                isDayOff: false,
            };
            setScheduleData({
                ...scheduleData,
                days: [...scheduleData.days, newDay],
            });
        }
    };

    const handleDayOffChange = (index, isDayOff) => {
        const updatedDays = scheduleData.days.map((day, i) =>
            i === index ? { ...day, isDayOff, startTime: '', endTime: '' } : day
        );
        setScheduleData({ ...scheduleData, days: updatedDays });
    };

    const handleSubmit = async () => {
        setLoading(true);

        // Validation: Check if all non-day-off days have start and end times
        const hasErrors = scheduleData.days.some((day) => {
            return !day.isDayOff && (!day.startTime || !day.endTime);
        });

        if (hasErrors) {
            toast({
                title: 'Please fill in Start Time and End Time for all working days.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }

        try {
            let response;
            if (scheduleId && !allowNewSchedule) {
                // Update existing schedule
                response = await updateSchedule(scheduleId, scheduleData);
            } else {
                // Create a new schedule
                response = await createSchedule(scheduleData);
            }

            if (response.success) {
                toast({
                    title: `Schedule ${scheduleId ? 'updated' : 'created'} successfully.`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            } else {
                toast({
                    title: `Failed to ${scheduleId ? 'update' : 'create'} schedule.`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }

        } catch (error) {
            toast({
                title: 'Error creating schedule.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const parseDate = (dateString) => {
        const [month, day, year] = dateString.split('/'); // Split the MM/DD/YYYY format
        return new Date(year, month - 1, day); // Create a Date object, month is 0-indexed
    };

    return (
        <Modal size={'5xl'} scrollBehavior='inside' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{allowNewSchedule ? 'Create New Schedule' : 'Update Existing Schedule'}</ModalHeader>
                <ModalBody>

                    {loadingChecking &&
                        <Center mt={"20%"}>
                            <Loading />
                        </Center>
                    }

                    {!loadingChecking &&
                        <>
                            {scheduleData.days.map((day, index) => {
                                const isPastDate = parseDate(day.date).setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);

                                return (
                                    <HStack spacing={4} mt={4} key={index}>
                                        <FormControl>
                                            <FormLabel>Date</FormLabel>
                                            <Input
                                                type="text"
                                                value={`${format(new Date(day.date), 'EEE, MM/dd/y')}`}
                                                isReadOnly
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Start Time</FormLabel>
                                            <Input
                                                type="time"
                                                value={day.startTime}
                                                onChange={(e) => {
                                                    const updatedDays = [...scheduleData.days];
                                                    updatedDays[index].startTime = e.target.value;
                                                    setScheduleData({ ...scheduleData, days: updatedDays });
                                                }}
                                                isDisabled={day.isDayOff || isPastDate} // Disable if day is marked as off or if it's a past date
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>End Time</FormLabel>
                                            <Input
                                                type="time"
                                                value={day.endTime}
                                                onChange={(e) => {
                                                    const updatedDays = [...scheduleData.days];
                                                    updatedDays[index].endTime = e.target.value;
                                                    setScheduleData({ ...scheduleData, days: updatedDays });
                                                }}
                                                isDisabled={day.isDayOff || isPastDate} // Disable if day is marked as off or if it's a past date
                                            />
                                        </FormControl>
                                        <Checkbox
                                            mt={8}
                                            isChecked={day.isDayOff}
                                            onChange={(e) => handleDayOffChange(index, e.target.checked)}
                                            isDisabled={isPastDate} // Disable if it's a past date
                                        >
                                            Off
                                        </Checkbox>
                                    </HStack>
                                );
                            })}

                            {scheduleData.days.length < maxDays && (
                                <Button mt={4} onClick={handleAddDay}>
                                    Add More Days
                                </Button>
                            )}
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button isLoading={loading} colorScheme="blue" onClick={handleSubmit}>
                        {allowNewSchedule ? 'Create Schedule' : 'Update Schedule'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateScheduleModal;
