import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchWarehouseTransactions } from '../../api';
import {
    Box, Table, Thead, Tbody, Tr, Th, Td, Center, Spinner, HStack, Button, VStack, Text
} from '@chakra-ui/react';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';

const TransactionsHistory = () => {
    const { warehouseId } = useParams(); // Get warehouseId from URL parameters
    const [transactions, setTransactions] = useState([]);
    const [warehouseName, setWarehouseName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchTransactionsData();
    }, [warehouseId, currentPage]);

    const fetchTransactionsData = async () => {
        setLoading(true);
        try {
            const result = await fetchWarehouseTransactions(warehouseId, currentPage, itemsPerPage);
            if (result.success) {
                setTransactions(result.data.transactions);
                setTotalPages(result.data.totalPages);

                // Set warehouse name based on the populated data
                if (result.data.transactions.length > 0) {
                    setWarehouseName(result.data.warehouse.name || 'Warehouse');
                }
            } else {
                setError(result.error);
            }
        } catch (error) {
            console.error("Error fetching transactions:", error);
            setError(error);
        }
        setLoading(false);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    if (loading) {
        return (
            <Center mt="20">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="7xl" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                {/* Title with Warehouse Name */}
                <TitleHeader title={`Transactions History for ${warehouseName} Warehouse`} />

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Date</Th>
                            <Th>Item</Th>
                            <Th>Quantity</Th>
                            <Th>Type</Th>
                            <Th>Receiver</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {transactions.map((transaction) => (
                            <Tr key={transaction._id}>
                                <Td>{new Date(transaction.date).toLocaleDateString()}</Td>
                                <Td>{transaction.supplyItemId?.name || "Unknown"}</Td>
                                <Td>{transaction.quantity}</Td>
                                <Td>{transaction.transactionType}</Td>
                                <Td>{transaction.receivedBy?.firstName || "Unknown"}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                {/* Pagination Controls */}
                <HStack justifyContent="center" spacing={4} mt={4}>
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Text>{currentPage} of {totalPages}</Text>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </HStack>
            </VStack>
        </Box>
    );
};

export default TransactionsHistory;
