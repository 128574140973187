import React, { useEffect, useState } from 'react';
import {
    Spinner,
    Box,
    VStack,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Th,
    Tr,
    Td,
    Tbody,
    Table,
    Thead,
    Heading,
    Divider,
    HStack,
    Badge,
    Center
} from '@chakra-ui/react';

import { fetchOrdersByBusiness } from '../../api';
import OrdersInternalNotes from './OrdersInternalNotes';

const LastTenOrders = ({ businessId }) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();


    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        try {
            const response = await fetchOrdersByBusiness(businessId);
            if (response.success) {
                setOrders(response.data);
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Failed to load orders.', err);
        }
        setLoading(false);
    };


    const handleOrderClick = (order) => {
        setSelectedOrder(order);
        onOpen();
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Text color="red.500">{error}</Text>;
    }

    return (
        <Box
            borderWidth="1px"
            borderRadius="md"
            p={4}
            mb={5}
        >
            <VStack spacing={3} align="stretch">
                {orders.length === 0 ? (
                    <Text>No recent orders available.</Text>
                ) : (
                    orders.map((order, index) => (
                        <Box
                            key={order._id}
                            p={3}
                            borderWidth="1px"
                            borderRadius="md"
                            w="full"
                            cursor={'pointer'}
                            onClick={() => handleOrderClick(order)}
                        >
                            <Text fontWeight="bold">Order #{index + 1}</Text>
                            <Text><strong>Client Name :</strong> {order.clientName}</Text>
                            <Text><strong>Ordered By :</strong> {order.orderedBy}</Text>
                            <Text><strong>Ordered At :</strong> {new Date(order.createdAt).toLocaleDateString()}</Text>
                        </Box>
                    ))
                )}
            </VStack>



            {/* Modal for displaying order details */}
            <Modal isOpen={isOpen} scrollBehavior='inside' onClose={onClose} size="xla">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Order Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedOrder ? (

                            <>
                                <Box borderWidth="1px" borderRadius="lg" p={6} mb={8} boxShadow="sm" bg="white">
                                    <Heading size="md" mb={4} color="gray.700">Order Details</Heading>

                                    <HStack align="start" spacing={10}>
                                        {/* Business Details */}
                                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                            <Heading size="sm" color="gray.400">Business Information</Heading>
                                            {selectedOrder.business ? (
                                                <>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Name:</Text>
                                                        <Text>{selectedOrder.business.name}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Address:</Text>
                                                        <Text>{selectedOrder.business.address.address1 + ", " + (selectedOrder.business.address.address2 ? selectedOrder.business.address.address2 + ", " : '') + selectedOrder.business.address.city + ", " + selectedOrder.business.address.state + ", " + selectedOrder.business.address.zip}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Email:</Text>
                                                        <Text>{selectedOrder.business.contact.email}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Phone:</Text>
                                                        <Text>{selectedOrder.business.contact.phone}</Text>
                                                    </HStack>
                                                </>
                                            ) : (
                                                <Text color="gray.500">No business information available.</Text>
                                            )}
                                        </VStack>

                                        {/* Client-Entered Details */}
                                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                            <Heading size="sm" color="gray.400">Client-Entered Information</Heading>
                                            <HStack>
                                                <Text fontWeight="bold">Client Name:</Text>
                                                <Text>{selectedOrder.clientName}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Client Email:</Text>
                                                <Text>{selectedOrder.clientEmail}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Address:</Text>
                                                <Text>{selectedOrder.clientAddress.address + ", " + selectedOrder.clientAddress.city + ", " + selectedOrder.clientAddress.state + ", " + selectedOrder.clientAddress.zip}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Phone:</Text>
                                                <Text>{selectedOrder.clientPhone}</Text>
                                            </HStack>
                                        </VStack>

                                        {/* Order Status */}
                                        <VStack align="start" spacing={3} flex="1">
                                            <Heading size="sm" color="gray.400">Order Status</Heading>
                                            <HStack>
                                                <Text fontWeight="bold">Status:</Text>
                                                <Badge colorScheme={selectedOrder.finished ? "green" : "red"}>
                                                    {selectedOrder.finished ? "Finished" : "Not Finished"}
                                                </Badge>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Ordered By:</Text>
                                                <Text>{selectedOrder.orderedBy}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Ordered At:</Text>
                                                <Text>{new Date(selectedOrder.createdAt).toLocaleString()}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Last Update:</Text>
                                                <Text>{new Date(selectedOrder.updatedAt).toLocaleString()}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Finished At:</Text>
                                                <Text>{selectedOrder.finished ? new Date(selectedOrder.updatedAt).toLocaleString() : "Not Finished Yet"}</Text>
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                </Box>

                                <Divider />

                                <Heading size="md" mb={4} mt={5}>Requested Items</Heading>
                                <Table variant="simple" tableLayout="auto">
                                    <Thead>
                                        <Tr>
                                            <Th width="20%">Item Name</Th>
                                            <Th width="10%">Size</Th>
                                            <Th width="10%">QR</Th>
                                            <Th width="20%">QS</Th>
                                            <Th width="20%">Note</Th>
                                            <Th width="10%">Status</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {selectedOrder.requestedItems.map((item, index) => (
                                            <Tr _hover={{ bg: "gray.100" }} key={item.item._id}>
                                                <Td>{item.item.name}</Td>
                                                <Td>{item.item.size}</Td>
                                                <Td>{item.quantityRequested}</Td>

                                                <Td>{item.quantitySent || 0}</Td>
                                                <Td>{item.note}</Td>
                                                <Td>
                                                    <VStack>
                                                        <Badge mt={5} colorScheme={!item.isAvailable ? "red" : 'blue'}>{item.isAvailable ? "Available" : "Not Available"}</Badge>
                                                        <Badge colorScheme={item.notSending ? "red" : 'blue'}>{item.notSending ? "Not Sent" : "Sent"}</Badge>
                                                    </VStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Center mt={5} mb={10}>
                                    <Heading color="gray.400">End of order</Heading>
                                </Center>


                                <Box>
                                    <Heading size='md' as={'h5'} mb={5} color="gray.400">Order Internal Notes</Heading>
                                    <OrdersInternalNotes
                                        orderId={selectedOrder._id}
                                        existingNotes={selectedOrder.internalNotes || []}
                                        addNote={false}
                                    />
                                </Box>

                            </>

                        ) : (
                            <Text>No details available</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} colorScheme="blue">
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>



        </Box>
    );
};

export default LastTenOrders;
