import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Button, IconButton, HStack, useToast } from '@chakra-ui/react';
import { CopyIcon, InfoIcon } from '@chakra-ui/icons';

const PopoverId = ({ index, content, header = 'DBI', place = 'N/A', id = true }) => {
    const toast = useToast();

    const handleCopy = () => {
        if (id) {
            navigator.clipboard.writeText(content + " - " + place);
        } else {
            navigator.clipboard.writeText(content);
        }
        toast({
            title: `Copied`,
            description: `${header} copied!`,
            status: "success",
            duration: 1500,
            position: "top-right",
            isClosable: true,
        });
    };

    return (
        <Popover placement="right">
            <PopoverTrigger>
                <Button variant="none" fontWeight={'normal'}>{id ? index + 1 : <InfoIcon />}</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color={"black"} fontWeight={'bold'}>{`${header} - ${place}`}</PopoverHeader>
                <PopoverBody>
                    <HStack justify="space-between">
                        <span style={{ color: "black" }}>{content}</span>
                        <IconButton
                            color={"black"}
                            icon={<CopyIcon />}
                            aria-label="Copy to clipboard"
                            size="sm"
                            onClick={handleCopy}
                            variant="ghost"
                        />
                    </HStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default PopoverId;
