import React, { useState } from 'react';
import { Center, Heading, VStack } from '@chakra-ui/react';
import { InfoIcon, WarningTwoIcon } from '@chakra-ui/icons';

const AccessDenied = ({ msg, icon = "warning" }) => {

    return (
        <Center>
            <VStack>
                {icon == 'warning' &&
                    <WarningTwoIcon boxSize={120} />
                }

                {icon == 'info' &&
                    <InfoIcon boxSize={120} />
                }
                <Heading mb="6" as='h3' fontWeight={'light'} mt={10} size='lg' >{msg}</Heading>
            </VStack>

        </Center>
    );
};

export default AccessDenied;
