import React, { useState, useEffect } from 'react';
import { fetchSupplies, receiveInventory } from '../../api';
import {
    Box, VStack, FormControl, FormLabel, Input, Select, Button, Center, Alert, AlertIcon, Spinner, IconButton,
    Divider
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';

const ReceiveInventory = () => {
    const { warehouseId } = useParams(); // WarehouseId from URL params
    const [supplies, setSupplies] = useState([]);
    const [items, setItems] = useState([{ supplyItem: '', quantity: '' }]); // Initial state with one row
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        fetchSuppliesData();
    }, []);

    const fetchSuppliesData = async () => {
        try {
            const supplyResult = await fetchSupplies();
            if (supplyResult.success) {
                setSupplies(supplyResult.data);
            } else {
                setError("Failed to load supplies.");
            }
        } catch (error) {
            console.error("Error fetching supplies:", error);
            setError("An error occurred while loading supplies.");
        }
        setLoading(false);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const addItemRow = () => {
        setItems([...items, { supplyItem: '', quantity: '' }]);
    };

    const removeItemRow = (index) => {
        if (items.length > 1) {
            const updatedItems = items.filter((_, i) => i !== index);
            setItems(updatedItems);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const dataToSend = items.map(item => ({
                warehouseId,
                supplyItemId: item.supplyItem,
                quantity: item.quantity,
            }));

            const result = await receiveInventory(dataToSend);

            if (result.success) {
                setSuccess("Inventory received successfully!");
                setItems([{ supplyItem: '', quantity: '' }]); // Reset form
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error("Error receiving inventory:", error);
            setError("Failed to receive inventory.");
        }
    };

    if (loading) {
        return (
            <Center mt="20">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="7xl" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Receive Inventory" />

                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert status="success">
                        <AlertIcon />
                        {success}
                    </Alert>
                )}

                <form onSubmit={handleFormSubmit}>
                    {items.map((item, index) => (
                        <>
                            <Box key={index} display="flex" alignItems="center" mb={5} mt={4}>
                                <FormControl mr={2} isRequired>
                                    <FormLabel>Supply Item</FormLabel>
                                    <Select
                                        placeholder="Select Supply Item"
                                        value={item.supplyItem}
                                        onChange={(e) => handleItemChange(index, 'supplyItem', e.target.value)}
                                    >
                                        {supplies.map((supply) => (
                                            <option key={supply._id} value={supply._id}>
                                                {supply.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl mr={2} isRequired>
                                    <FormLabel>Quantity Received</FormLabel>
                                    <Input
                                        type="number"
                                        placeholder="Quantity"
                                        value={item.quantity}
                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                    />
                                </FormControl>

                                <IconButton
                                    icon={<CloseIcon />}
                                    colorScheme="red"
                                    onClick={() => removeItemRow(index)}
                                    aria-label="Remove Item"
                                    variant="outline"
                                    size="sm"
                                    mt={8}
                                    isDisabled={items.length === 1} // Disable delete if only one row remains
                                />
                            </Box>
                            <Divider />
                        </>


                    ))}

                    <Button
                        onClick={addItemRow}
                        leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="outline"
                        mb={4}
                        mt={5}
                    >
                        Add Another Item
                    </Button>

                    <Box mt={4}>
                        <Button colorScheme="blue" type="submit" width="full">
                            Receive Inventory
                        </Button>
                    </Box>
                </form>
            </VStack>
        </Box>
    );
};

export default ReceiveInventory;
