import React, { useState, useEffect } from 'react';
import { fetchSupplies, fetchCategories, fetchZipCode, createOrder } from '../../api';
import {
    Box,
    Heading,
    VStack,
    SimpleGrid,
    Input, Text,
    Stack,
    Center,
    HStack,
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Textarea,
    FormControl, FormLabel,
    Spinner,
    Badge,
    Divider
} from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import { alert, Logo } from '../../Utils';
import { PiCheckSquareBold } from "react-icons/pi";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Invoice from '../../components/Invoice';
const emailProviders = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'AOL.com'];
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const CreateOrder = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validNext, setValidNext] = useState();
    const [supplies, setSupplies] = useState([])
    const [clientName, setClientName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [loadingZipCode, setLoadingZipCode] = useState(false);
    const [city, setCity] = useState('');
    const [inits, setInits] = useState('')

    const [email, setEmail] = useState('');
    const [showProviders, setShowProviders] = useState(false);


    const [selectedItems, setSelectedItems] = useState([]);

    const [quantities, setQuantities] = useState({}); // To store quantity values for each item
    const [validatedItems, setValidatedItems] = useState([]);
    const [loadingSubmission, setLoadingSubmission] = useState(false)

    const [orderSuccess, setOrderSuccess] = useState(false)


    const [note, setNote] = useState('')
    // Fetch supplies and categories when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);

            const categoriesResult = await fetchCategories();
            const suppliesResult = await fetchSupplies();

            if (!categoriesResult.success) {
                setError(`Supplies Categories ` + categoriesResult.error)
                setLoading(false)
                return;
            }
            if (!suppliesResult.success) {
                setError(`Supplies Items ` + suppliesResult.error)
                setLoading(false)
                return;
            }
            setSupplies(suppliesResult.data)
            matchSuppliesWithCategories(categoriesResult.data, suppliesResult.data);

        } catch (error) {
            setError('Failed to load data');
            setLoading(false);
        }
    };

    const matchSuppliesWithCategories = (categories, supplies) => {
        const matchedItems = categories.map(category => {
            const matchedSupplies = supplies.filter((supply) => supply.category._id === category._id);
            return { category: category.name, supplies: matchedSupplies };
        });
        setItems(matchedItems);
        setLoading(false);
    };




    const toggleSelect = (supplyId) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(supplyId)
                ? prevSelected.filter((id) => id !== supplyId)
                : [...prevSelected, supplyId]
        );
    };

    const handleQuantityChange = (supplyId, value) => {
        const quantity = parseInt(value, 10) || 0;

        if (quantity > 0 && !selectedItems.includes(supplyId)) {
            setSelectedItems((prevSelected) => [...prevSelected, supplyId]);
        }


        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [supplyId]: value,
        }));

    };

    const validateAndShowSelectedItems = () => {
        // Clear any previous validation error
        setValidNext(false);
        if ((clientName.trim()).length == 0 || (clientAddress.trim()).length == 0 || (email.trim()).length == 0 || (city.trim()).length == 0) {
            alert("Missing fields", 'warning', 'All Account Details informations are required', "Okay !")
            return;
        }
        if (!emailPattern.test(email)) {
            alert("Invalid email", 'warning', 'Enter a valid email so we can send confirmation to it.', "Okay !")
            return;
        }
        if ((inits.trim()).length == 0) {

            alert("Your Initials", 'warning', 'Please enter your full name or your initials.', "Okay !")
            return;
        }
        if (selectedItems.length === 0) {
            alert("No supplies selected", 'warning', 'Please select the supplies needed and input the quantity', "Okay !")
            return;
        }
        // Find selected items with invalid quantities
        const invalidItems = selectedItems.filter((itemId) => {
            const quantity = quantities[itemId];
            return !quantity || isNaN(quantity) || quantity <= 0;
        });

        if (invalidItems.length > 0) {
            // Get names of items with invalid quantities
            const invalidItemNames = items
                .flatMap((item) => item.supplies)
                .filter((supply) => invalidItems.includes(supply._id))
                .map((supply) => supply.name);

            let emptyNames = '';
            {
                invalidItemNames.map((name, index) => (
                    emptyNames += name + '<br /><br />'
                ))
            }
            alert("No Quantity !", 'warning', `Please provide the quantity for <br><br><hr /><br> ${emptyNames}`, 'Okay !')

            return;
        }

        // If validation passes, show selected items with quantities
        const selectedWithQuantities = selectedItems.map((itemId) => ({
            id: itemId,
            name: items
                .flatMap((item) => item.supplies)
                .find((supply) => supply._id === itemId)?.name,
            quantity: quantities[itemId],
        }));

        setValidatedItems(selectedWithQuantities);
        setValidNext(true);
    };



    // Handle zip code change
    const handleZipCodeChange = async (e) => {
        const value = e.target.value;
        setZipCode(value);

        // Check if the zip code has 5 characters
        if (value.length === 5) {
            setLoadingZipCode(true)
            try {
                const result = await fetchZipCode(value); // Call API to get city based on zip
                if (result.success) {
                    setCity(result.data);
                    console.log(result)
                }
            } catch (error) {
                console.error('Failed to fetch city from zip code:', error);
            }
            setLoadingZipCode(false)
        } else {
            if (loadingZipCode) {
                setLoadingZipCode(false)
            }
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Show provider suggestions only if "@" is typed and no valid domain exists yet
        if (value.includes('@') && !emailPattern.test(value)) {
            setShowProviders(true);
        } else {
            setShowProviders(false); // Hide suggestions if email seems valid
        }
    };

    // Handle selecting a provider
    const handleProviderClick = (provider) => {
        // Split the email at "@" and only keep the part before "@"
        console.log(provider)
        const localPart = email.split('@')[0];
        console.log(localPart)
        setEmail(`${localPart}@${provider}`);
        setShowProviders(false);
    };


    const handleSubmitOrder = async () => {
        setLoadingSubmission(true)

        // Example order data structure, ensure you're collecting this from the form
        const orderDetails = {
            clientName: clientName,       // Example: collected from input field
            clientEmail: email,     // Example: collected from input field
            clientAddress: {
                address: clientAddress,
                city: city,
                state: "CA",
                zip: zipCode,
            },
            orderedBy: inits,         // Example: collected from input field
            note: note || '',             // Optional note
            requestedItems: selectedItems.map((itemId) => {
                const item = supplies.find(supply => supply._id === itemId);
                return {
                    item: item._id,                     // Supply item ID
                    quantityRequested: quantities[itemId], // The requested quantity from form input
                    quantitySent: 0                     // Initially set to 0, for admin use later
                };
            }),
        };
        console.log(orderDetails)

        try {
            const result = await createOrder(orderDetails); // Call to the API function

            if (result.success) {
                alert('Order Submitted', 'success', 'Your order submitted successfully!', "Okay !");
                setOrderSuccess(true)
            } else {
                alert('Oops !', 'error', 'Failed to submit order. Please try again later.', 'Okay');
            }
        } catch (error) {
            alert('Oops !', 'error', 'Failed to submit order. Please try again later.', 'Okay');
        }

        setLoadingSubmission(false)


    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }
    if (error) {
        return (
            <AccessDenied msg={error} />
        );
    }

    return (
        <Box maxW="8xl" mx="auto" p="8">
            <Center>
                <HStack style={{ borderBottom: "9px solid #f4b31b" }} pb={4} mb={6} w={'full'}>
                    <img width={150} src={Logo} />
                    <Heading ml="15%" color={'#253853'}>Create New Order</Heading>
                    <br />
                </HStack>
            </Center>
            {(!orderSuccess && !validNext) && (

                <>

                    <VStack spacing={4} mb={5} align="stretch">
                        {/* Client Name and Email in the same row */}
                        <HStack spacing={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>Client Name</FormLabel>
                                <Input value={clientName} onChange={(e) => setClientName(e.target.value)} placeholder="Enter client name" />
                            </FormControl>

                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" placeholder="Enter email address" value={email} onChange={handleEmailChange} />
                                {showProviders && (
                                    <HStack spacing={2} mt={2} position={'absolute'} zIndex={900}>
                                        {emailProviders.map((provider) => (
                                            <Badge
                                                key={provider}
                                                onClick={() => handleProviderClick(provider)}
                                                cursor="pointer"
                                                colorScheme="blue"
                                                borderRadius="md"
                                            >
                                                {provider}
                                            </Badge>
                                        ))}
                                    </HStack>
                                )}
                            </FormControl>
                        </HStack>

                        {/* Address and Zip Code in the same row */}
                        <HStack spacing={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>Address</FormLabel>
                                <Input value={clientAddress} onChange={(e) => setClientAddress(e.target.value)} placeholder="Enter street address" />
                            </FormControl>

                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>Zip Code</FormLabel>
                                <Input
                                    placeholder="Enter zip code"
                                    value={zipCode}
                                    onChange={handleZipCodeChange}
                                    maxLength={5}
                                />
                            </FormControl>
                        </HStack>

                        {/* City and State in the same row */}
                        <HStack spacing={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>City</FormLabel>
                                {loadingZipCode ? (
                                    <Spinner />
                                ) : (
                                    <Input
                                        placeholder="Enter city"
                                        value={city || ''}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                )}
                            </FormControl>

                            <FormControl flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                                <FormLabel>State</FormLabel>
                                <Input placeholder="Enter state" onChange={() => { }} value={"CA"} />
                            </FormControl>
                        </HStack>
                    </VStack>

                    <VStack spacing={8} align="stretch">
                        {items.map((item, index) => (
                            <Box key={index} position="relative">
                                {/* Category Name Box */}
                                <Box
                                    bg="blue.900"
                                    p={7}
                                    borderRadius="10px 10px 0 0"
                                    width="full"
                                    mb={-1} // Negative margin to connect it visually to the box below
                                    zIndex={1} // Ensures this stays above the item box
                                >
                                    <Center>
                                        <Heading color={'#f4b31b'} size="lg">{item.category}</Heading>
                                    </Center>
                                </Box>

                                {/* Items Box */}
                                <Box
                                    p={4}
                                    borderWidth="1px"
                                    borderRadius="0 0 10px 10px" // Rounded corners only at the bottom
                                    boxShadow="md"
                                    borderTop="none" // Removes the top border to connect with the category name
                                >
                                    {item.supplies.length > 0 ? (
                                        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3 }} spacing={6}>
                                            {item.supplies.map((supply) => (
                                                <Box
                                                    onClick={() => toggleSelect(supply._id)}
                                                    cursor="pointer"
                                                    key={supply._id}
                                                    p={4}
                                                    color={selectedItems.includes(supply._id) ? "white" : 'blue.900'}
                                                    bg={selectedItems.includes(supply._id) ? "blue.900" : "white"}
                                                    borderWidth="1px"
                                                    borderRadius="md"
                                                    position="relative"
                                                >
                                                    <Stack direction="column" align="center">

                                                        <HStack>
                                                            {selectedItems.includes(supply._id) && (<PiCheckSquareBold size={25} color={'#f4b31b'} />)}
                                                            <Text>
                                                                {supply.name}
                                                            </Text>
                                                        </HStack>

                                                        {supply.size ?
                                                            <Text fontWeight={'300'}>{supply.size}</Text>
                                                            :
                                                            <br />
                                                        }
                                                        <Input
                                                            placeholder="Quantity"
                                                            type="number"
                                                            size="lg"
                                                            textAlign={"center"}
                                                            color={'#f4b31b'}
                                                            mt={2}
                                                            border={'1px solid'}
                                                            borderColor={selectedItems.includes(supply._id) ? 'blue.700' : 'gray.200'}
                                                            fontWeight={'500'}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={(e) => handleQuantityChange(supply._id, e.target.value)}

                                                        />
                                                    </Stack>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text>No supplies found under this category.</Text>
                                    )}
                                </Box>
                            </Box>
                        ))}


                        <FormControl mt={5} flex={{ base: '1 1 100%', md: '1 1 45%' }}>
                            <Heading size="md" mb={2}>Your Initials</Heading>
                            <Input value={inits} onChange={(e) => setInits(e.target.value)} placeholder="Your Initials or name" />
                        </FormControl>
                        <Box mt={6}>
                            <Heading size="md" mb={2}>Notes on this order</Heading>
                            <Textarea
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder="Any other requests you might want to add..."
                                rows={8}
                                size="lg"
                            />
                        </Box>


                        <Button onClick={validateAndShowSelectedItems} _hover={{ bg: '#425570' }} mt={5} w='full' height={70} bg="#253853" color={"white"} >
                            Next <ChevronRightIcon boxSize={10} />
                        </Button>




                    </VStack>

                </>
            )}

            {(!orderSuccess && validNext) && (
                <>


                    <Divider mt={5} />



                    <Invoice
                        clientName={clientName}
                        clientAddress={clientAddress + ", " + city + ", CA, " + zipCode}
                        clientEmail={email}
                        requestBy={inits}
                        validatedItems={validatedItems}
                    />

                    <Alert
                        mt={5}
                        status='warning'
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        height='auto'
                    >
                        <AlertIcon boxSize='40px' mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                            SUPPLIES ACKNOWLEDGMENT!
                        </AlertTitle>
                        <AlertDescription maxWidth='full' mt={4}>
                            * Orders are fullfilled based on your monthly productivity,
                            however if you require more than usual, Please reach out to client services.
                        </AlertDescription>
                        <AlertDescription maxWidth='full' mt={4}>
                            * Please Allow 24-48 hours for the order to be delivered.
                        </AlertDescription>
                    </Alert>

                    <Stack direction={['column', 'row']} spacing={4}>
                        <Button isLoading={loadingSubmission} onClick={() => setValidNext(false)} _hover={{ bg: '#425570' }} mt={5} height={70} width={['100%', '35%']} bg="#253853" color={"white"}>
                            <ChevronLeftIcon boxSize={10} /> Go Back
                        </Button>

                        <Button isLoading={loadingSubmission} onClick={() => { handleSubmitOrder() }} _hover={{ bg: '#425570' }} mt={5} height={70} width={['100%', '75%']} bg="#253853" color={"white"}>
                            <CheckIcon boxSize={5} mr={5} /> Send Supplies Request
                        </Button>
                    </Stack>




                </>
            )}


            {orderSuccess &&
                <Alert
                    mt={5}
                    status='success'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    p={6}
                    height='auto'
                >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        Order Submitted !
                    </AlertTitle>
                    <AlertDescription maxWidth='full' mt={4}>
                        Your order was submitted successfully !<br />
                        You will receive a confirmation email shortly.
                        <br /> <br />
                        Thank You !
                    </AlertDescription>
                </Alert>
            }

        </Box>
    );
};

export default CreateOrder;
