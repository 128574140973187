import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    Text,
    Alert,
    AlertIcon,
    AlertDescription,
    Divider,
    Badge
} from '@chakra-ui/react';
import { ViewIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import Pagination from '../../components/Pagination';  // Assuming you have a reusable Pagination component
import { fetchAllCouriers, createCourier, updateCourier, deleteCourier } from '../../api';
import { hasPermission } from '../../Utils';
import CreateScheduleModal from './CreateScheduleModal';
import PopoverId from '../../components/PopoverId';
import { FaCalendar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const AllCouriers = () => {
    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isViewModalOpen, onOpen: onOpenViewModal, onClose: onCloseViewModal } = useDisclosure();
    const { isOpen: isCreateScheduleModalOpen, onOpen: onOpenCreateScheduleModal, onClose: onCloseCreateScheduleModal } = useDisclosure();
    const navigate = useNavigate();
    const [couriers, setCouriers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [newCourier, setNewCourier] = useState({
        name: '', email: '', phone: '', hireDate: '', dob: '',
        dl: { licenseNumber: '', licenseExpiry: '', address: '', state: '', class: '' },
        car: { make: '', model: '', color: '', year: '', licensePlate: '' },
        appInfo: { pushNotification: false, appDetails: '' },
        online: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [errorModal, setErrorModal] = useState(null); // Error state for modal
    const [loadingProcess, setLoadingProcess] = useState(false)
    const itemsPerPage = 25
    useEffect(() => {
        getCouriers();
    }, []);

    const getCouriers = async (page = 1) => {
        setLoading(true);
        try {
            const response = await fetchAllCouriers(page, itemsPerPage);
            console.log(response)
            if (response.success) {
                setCouriers(response.data.couriers);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load couriers');
        }
        setLoading(false);
    };

    const handleCreateCourier = async () => {
        setErrorModal(null)
        // Validate fields
        if (!newCourier.name || !newCourier.email || !newCourier.password || !newCourier.phone ||
            !newCourier.hireDate || !newCourier.dob || !newCourier.dl.licenseNumber || !newCourier.dl.licenseExpiry ||
            !newCourier.dl.address || !newCourier.dl.state || !newCourier.dl.class ||
            !newCourier.car.make || !newCourier.car.model || !newCourier.car.color || !newCourier.car.year || !newCourier.car.licensePlate) {
            setErrorModal("Please fill in all required fields.");
            return;
        }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newCourier.email)) {
            setErrorModal("Please enter a valid email address.");
            return;
        }

        // Validate date of birth (must be over 18 years old)
        const dob = new Date(newCourier.dob);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        const monthDifference = today.getMonth() - dob.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        if (age < 18) {
            setErrorModal("Courier must be over 18 years old.");
            return;
        }

        // Validate license expiry (must not be expired)
        const licenseExpiry = new Date(newCourier.dl.licenseExpiry);
        if (licenseExpiry < today) {
            setErrorModal("Driver's license is expired.");
            return;
        }
        setLoadingProcess(true)
        // Proceed to create courier if validations pass
        try {
            const response = await createCourier(newCourier);

            if (response.success) {
                setCouriers(prevCouriers => [response.data, ...prevCouriers]);
                onCloseCreateModal();
            } else {
                setErrorModal('Failed to create courier: ' + response.message);
            }
        } catch (error) {
            console.error('Error creating courier:', error);
            setErrorModal('Error creating courier.');
        }
        setLoadingProcess(false)
    };

    const handleEditCourier = async () => {
        // Validate fields
        setErrorModal(null)

        if (!selectedCourier.name || !selectedCourier.email || !selectedCourier.phone ||
            !selectedCourier.hireDate || !selectedCourier.dob || !selectedCourier.dl.licenseNumber || !selectedCourier.dl.licenseExpiry ||
            !selectedCourier.dl.address || !selectedCourier.dl.state || !selectedCourier.dl.class ||
            !selectedCourier.car.make || !selectedCourier.car.model || !selectedCourier.car.color || !selectedCourier.car.year || !selectedCourier.car.licensePlate) {
            setErrorModal("Please fill in all required fields.");
            return;
        }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(selectedCourier.email)) {
            setErrorModal("Please enter a valid email address.");
            return;
        }

        // Validate date of birth (must be over 18 years old)
        const dob = new Date(selectedCourier.dob);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        const monthDifference = today.getMonth() - dob.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        if (age < 18) {
            setErrorModal("Courier must be over 18 years old.");
            return;
        }

        // Validate license expiry (must not be expired)
        const licenseExpiry = new Date(selectedCourier.dl.licenseExpiry);
        if (licenseExpiry < today) {
            setErrorModal("Driver's license is expired.");
            return;
        }
        setLoadingProcess(true)
        // Proceed to update courier if validations pass
        try {
            const response = await updateCourier(selectedCourier._id, selectedCourier);
            if (response.success) {
                setCouriers(prevCouriers =>
                    prevCouriers.map(courier =>
                        courier._id === selectedCourier._id ? response.data : courier
                    )
                );
                onCloseEditModal();
            } else {
                setErrorModal('Failed to update courier: ' + response.message);
            }
        } catch (error) {
            console.error('Error updating courier:', error);
            setErrorModal('Error updating courier.');
        }
        setLoadingProcess(false)
    };

    // Clear error modal state when the modal is closed
    const handleModalClose = () => {
        setErrorModal(null);
        onCloseCreateModal();
        onCloseEditModal();
        setLoadingProcess(false)
    };

    const handleDeleteCourier = async () => {
        setLoadingProcess(true)
        try {
            const response = await deleteCourier(selectedCourier._id);
            if (response.success) {
                getCouriers();
                onCloseDeleteModal();
            } else {
                alert('Failed to delete courier: ' + response.message);
            }
        } catch (error) {
            console.error('Error deleting courier:', error);
        }
        setLoadingProcess(false)
    };

    const formatDateForInput = (date) => {
        if (!date) return ''; // Return an empty string if date is null/undefined
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Add leading zero
        const day = String(d.getDate()).padStart(2, '0'); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    // Pagination controls
    const handlePageChange = (newPage) => {
        getCouriers(newPage); // Include searchParams in pagination
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {!error ? (
                    <>
                        <TitleHeader
                            title="Couriers"
                            buttonText={hasPermission('createCouriers') && 'Create New Courier'}
                            onClick={hasPermission('createCouriers') ? onOpenCreateModal : null}

                            secondButton
                            secondButtonIcon={<FaCalendar />}
                            secondButtonOnClick={() => { navigate('/couriers/schedules') }}
                            secondButtonText='Schedules'
                        />

                        {couriers.length > 0 ? (
                            <>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>#</Th>
                                            <Th>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Phone</Th>
                                            <Th textAlign="right">Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {couriers.map((courier, index) => (
                                            <Tr _hover={{ bg: "gray.100" }} key={courier._id}>
                                                <Td><PopoverId place='Couriers' index={index} content={courier._id} /></Td>
                                                <Td>{courier.name}</Td>
                                                <Td>{courier.email}</Td>
                                                <Td>{courier.phone}</Td>
                                                <Td textAlign="right">
                                                    <HStack justifyContent="flex-end" spacing={4}>
                                                        <Button onClick={() => { setSelectedCourier(courier); onOpenViewModal(); }} size="sm" colorScheme="blue">
                                                            <ViewIcon mr={2} /> View
                                                        </Button>

                                                        <Button onClick={() => { setSelectedCourier(courier); onOpenCreateScheduleModal(); }} size="sm" colorScheme="purple">
                                                            <ViewIcon mr={2} /> Schedule
                                                        </Button>

                                                        {hasPermission('updateCouriers') && (
                                                            <Button size="sm" onClick={() => { setSelectedCourier(courier); onOpenEditModal(); }} colorScheme="green">
                                                                <EditIcon mr={2} /> Edit
                                                            </Button>
                                                        )}
                                                        {hasPermission('deleteCouriers') && (
                                                            <Button size="sm" onClick={() => { setSelectedCourier(courier); onOpenDeleteModal(); }} colorScheme="red">
                                                                <DeleteIcon mr={2} /> Delete
                                                            </Button>
                                                        )}
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                                <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />

                            </>
                        ) : (
                            <AccessDenied msg={'No couriers found!'} />
                        )}
                    </>
                ) : (
                    <AccessDenied msg={error} />
                )}



                {/* Create Courier Modal */}
                <Modal isOpen={isCreateModalOpen} size={"5xl"} onClose={handleModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create New Courier</ModalHeader>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                        <ModalBody>
                            {/* Row 1: Name, Email, Password */}
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Name</FormLabel>
                                    <Input value={newCourier.name} onChange={(e) => setNewCourier({ ...newCourier, name: e.target.value })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Email</FormLabel>
                                    <Input value={newCourier.email} onChange={(e) => setNewCourier({ ...newCourier, email: e.target.value })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" onChange={(e) => setNewCourier({ ...newCourier, password: e.target.value })} />
                                </FormControl>
                            </HStack>

                            {/* Row 2: Phone, Hire Date, DOB */}
                            <HStack spacing={4} mt={4}>
                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Input value={newCourier.phone} onChange={(e) => setNewCourier({ ...newCourier, phone: e.target.value })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Hire Date</FormLabel>
                                    <Input type="date" value={newCourier.hireDate} onChange={(e) => setNewCourier({ ...newCourier, hireDate: e.target.value })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Date of Birth</FormLabel>
                                    <Input type="date" value={newCourier.dob} onChange={(e) => setNewCourier({ ...newCourier, dob: e.target.value })} />
                                </FormControl>
                            </HStack>

                            {/* Driver License Info */}
                            <Heading size="md" mt={6} mb={4}>Driver License Info</Heading>

                            {/* Row 3: License Number, License Expiry */}
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>License Number</FormLabel>
                                    <Input value={newCourier.dl.licenseNumber} onChange={(e) => setNewCourier({ ...newCourier, dl: { ...newCourier.dl, licenseNumber: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>License Expiry</FormLabel>
                                    <Input type="date" value={newCourier.dl.licenseExpiry} onChange={(e) => setNewCourier({ ...newCourier, dl: { ...newCourier.dl, licenseExpiry: e.target.value } })} />
                                </FormControl>
                            </HStack>

                            {/* Row 4: Address, State, Class */}
                            <HStack spacing={4} mt={4}>
                                <FormControl>
                                    <FormLabel>Address</FormLabel>
                                    <Input value={newCourier.dl.address} onChange={(e) => setNewCourier({ ...newCourier, dl: { ...newCourier.dl, address: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>State</FormLabel>
                                    <Input value={newCourier.dl.state} onChange={(e) => setNewCourier({ ...newCourier, dl: { ...newCourier.dl, state: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Class</FormLabel>
                                    <Input value={newCourier.dl.class} onChange={(e) => setNewCourier({ ...newCourier, dl: { ...newCourier.dl, class: e.target.value } })} />
                                </FormControl>
                            </HStack>

                            {/* Car Info */}
                            <Heading size="md" mt={6} mb={4}>Car Info</Heading>

                            {/* Row 5: Make, Model, Color */}
                            <HStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Make</FormLabel>
                                    <Input value={newCourier.car.make} onChange={(e) => setNewCourier({ ...newCourier, car: { ...newCourier.car, make: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Model</FormLabel>
                                    <Input value={newCourier.car.model} onChange={(e) => setNewCourier({ ...newCourier, car: { ...newCourier.car, model: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Color</FormLabel>
                                    <Input value={newCourier.car.color} onChange={(e) => setNewCourier({ ...newCourier, car: { ...newCourier.car, color: e.target.value } })} />
                                </FormControl>
                            </HStack>

                            {/* Row 6: Year, License Plate */}
                            <HStack spacing={4} mt={4}>
                                <FormControl>
                                    <FormLabel>Year</FormLabel>
                                    <Input value={newCourier.car.year} onChange={(e) => setNewCourier({ ...newCourier, car: { ...newCourier.car, year: e.target.value } })} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>License Plate</FormLabel>
                                    <Input value={newCourier.car.licensePlate} onChange={(e) => setNewCourier({ ...newCourier, car: { ...newCourier.car, licensePlate: e.target.value } })} />
                                </FormControl>
                            </HStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleModalClose} mr={3}>Cancel</Button>
                            <Button isLoading={loadingProcess} colorScheme="blue" onClick={handleCreateCourier}>Create</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Courier Modal */}
                {selectedCourier &&
                    <Modal isOpen={isEditModalOpen} size={"5xl"} onClose={handleModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit Courier</ModalHeader>
                            {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                            <ModalBody>
                                {/* Row 1: Name, Email, Password */}
                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input value={selectedCourier?.name} onChange={(e) => setSelectedCourier({ ...selectedCourier, name: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input value={selectedCourier?.email} onChange={(e) => setSelectedCourier({ ...selectedCourier, email: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Password</FormLabel>
                                        <Input type="password" onChange={(e) => setSelectedCourier({ ...selectedCourier, password: e.target.value })} />
                                    </FormControl>
                                </HStack>

                                {/* Row 2: Phone, Hire Date, DOB */}
                                <HStack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>Phone</FormLabel>
                                        <Input value={selectedCourier?.phone} onChange={(e) => setSelectedCourier({ ...selectedCourier, phone: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Hire Date</FormLabel>
                                        <Input type="date" value={formatDateForInput(selectedCourier?.hireDate)} onChange={(e) => setSelectedCourier({ ...selectedCourier, hireDate: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Date of Birth</FormLabel>
                                        <Input type="date" value={formatDateForInput(selectedCourier?.dob)} onChange={(e) => setSelectedCourier({ ...selectedCourier, dob: e.target.value })} />
                                    </FormControl>
                                </HStack>

                                {/* Driver License Info */}
                                <Heading size="md" mt={6} mb={4}>Driver License Info</Heading>

                                {/* Row 3: License Number, License Expiry */}
                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>License Number</FormLabel>
                                        <Input value={selectedCourier?.dl?.licenseNumber} onChange={(e) => setSelectedCourier({ ...selectedCourier, dl: { ...selectedCourier.dl, licenseNumber: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>License Expiry</FormLabel>
                                        <Input type="date" value={formatDateForInput(selectedCourier?.dl?.licenseExpiry)} onChange={(e) => setSelectedCourier({ ...selectedCourier, dl: { ...selectedCourier.dl, licenseExpiry: e.target.value } })} />
                                    </FormControl>
                                </HStack>

                                {/* Row 4: Address, State, Class */}
                                <HStack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>Address</FormLabel>
                                        <Input value={selectedCourier?.dl?.address} onChange={(e) => setSelectedCourier({ ...selectedCourier, dl: { ...selectedCourier.dl, address: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>State</FormLabel>
                                        <Input value={selectedCourier?.dl?.state} onChange={(e) => setSelectedCourier({ ...selectedCourier, dl: { ...selectedCourier.dl, state: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Class</FormLabel>
                                        <Input value={selectedCourier?.dl?.class} onChange={(e) => setSelectedCourier({ ...selectedCourier, dl: { ...selectedCourier.dl, class: e.target.value } })} />
                                    </FormControl>
                                </HStack>

                                {/* Car Info */}
                                <Heading size="md" mt={6} mb={4}>Car Info</Heading>

                                {/* Row 5: Make, Model, Color */}
                                <HStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Make</FormLabel>
                                        <Input value={selectedCourier?.car?.make} onChange={(e) => setSelectedCourier({ ...selectedCourier, car: { ...selectedCourier.car, make: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Model</FormLabel>
                                        <Input value={selectedCourier?.car?.model} onChange={(e) => setSelectedCourier({ ...selectedCourier, car: { ...selectedCourier.car, model: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Color</FormLabel>
                                        <Input value={selectedCourier?.car?.color} onChange={(e) => setSelectedCourier({ ...selectedCourier, car: { ...selectedCourier.car, color: e.target.value } })} />
                                    </FormControl>
                                </HStack>

                                {/* Row 6: Year, License Plate */}
                                <HStack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>Year</FormLabel>
                                        <Input value={selectedCourier?.car?.year} onChange={(e) => setSelectedCourier({ ...selectedCourier, car: { ...selectedCourier.car, year: e.target.value } })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>License Plate</FormLabel>
                                        <Input value={selectedCourier?.car?.licensePlate} onChange={(e) => setSelectedCourier({ ...selectedCourier, car: { ...selectedCourier.car, licensePlate: e.target.value } })} />
                                    </FormControl>
                                </HStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={handleModalClose} mr={3}>Cancel</Button>
                                <Button isLoading={loadingProcess} colorScheme="blue" onClick={handleEditCourier}>Save</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                }
                {/* Delete Confirmation Modal */}

                <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Deletion</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete courier <strong>{selectedCourier?.name}</strong>?
                        </ModalBody>
                        <ModalFooter>
                            <Button isLoading={loadingProcess} colorScheme="red" mr={3} onClick={handleDeleteCourier}>Delete</Button>
                            <Button onClick={onCloseDeleteModal}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {selectedCourier &&
                    <Modal isOpen={isViewModalOpen} size={"5xl"} onClose={onCloseViewModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <Heading size="md" p={6} >Courier {selectedCourier.name} <Badge colorScheme={selectedCourier?.online ? 'green' : 'red'}>{selectedCourier?.online ? "Online" : "Offline"}</Badge></Heading>
                            <ModalBody>
                                <HStack spacing={4} mb={5}>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Name</FormLabel>
                                        <Text>{selectedCourier?.name}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Email</FormLabel>
                                        <Text>{selectedCourier?.email}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Phone</FormLabel>
                                        <Text>{selectedCourier?.phone}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Hire Date</FormLabel>
                                        <Text>{selectedCourier?.hireDate}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Date of Birth</FormLabel>
                                        <Text>{selectedCourier?.dob}</Text>
                                    </FormControl>
                                </HStack>
                                <Divider />
                                <Heading size="md" mt={6} mb={4}>Driver License Info</Heading>
                                <HStack spacing={4} mb={8}>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>License Number</FormLabel>
                                        <Text>{selectedCourier?.dl?.licenseNumber}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>License Expiry</FormLabel>
                                        <Text>{selectedCourier?.dl?.licenseExpiry}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Address</FormLabel>
                                        <Text>{selectedCourier?.dl?.address}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>State</FormLabel>
                                        <Text>{selectedCourier?.dl?.state}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Class</FormLabel>
                                        <Text>{selectedCourier?.dl?.class}</Text>
                                    </FormControl>
                                </HStack>

                                <Divider />

                                <Heading size="md" mt={6} mb={4}>Car Info</Heading>
                                <HStack spacing={4} mb={8}>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Make</FormLabel>
                                        <Text>{selectedCourier?.car?.make}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Model</FormLabel>
                                        <Text>{selectedCourier?.car?.model}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Color</FormLabel>
                                        <Text>{selectedCourier?.car?.color}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Year</FormLabel>
                                        <Text>{selectedCourier?.car?.year}</Text>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>License Plate</FormLabel>
                                        <Text>{selectedCourier?.car?.licensePlate}</Text>
                                    </FormControl>
                                </HStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onCloseViewModal}>Close</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                }
                {selectedCourier &&
                    <CreateScheduleModal
                        isOpen={isCreateScheduleModalOpen}
                        onClose={onCloseCreateScheduleModal}
                        courier={selectedCourier}
                    />
                }

            </VStack>
        </Box >
    );
};

export default AllCouriers;
