import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Text } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadIcon } from '@chakra-ui/icons';

const PdfGenerator = ({ clientDetailsRef, testsAndBilling, tos, phi, psdr, addInfo, name }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const generatePdf = async () => {
        setIsGenerating(true);
        onOpen();
        const pdf = new jsPDF('p', 'mm', 'letter'); // Create a new PDF instance
        const margin = 12.7; // 0.5 inch in mm
        const imgWidth = 190.5; // Width for content

        try {
            // Capture the Client Details and Days of Operation
            if (!isCancelled && clientDetailsRef.current) {
                const clientDetailsCanvas = await html2canvas(clientDetailsRef.current, { scale: 2 });
                const clientDetailsImgData = clientDetailsCanvas.toDataURL('image/png');
                const imgHeightClientDetails = (clientDetailsCanvas.height * imgWidth) / clientDetailsCanvas.width;

                pdf.addImage(clientDetailsImgData, 'PNG', margin, margin, imgWidth, imgHeightClientDetails, undefined, 'SLOW');
            }

            if (!isCancelled && testsAndBilling.current) {
                pdf.addPage();
                const testsAndBillingCanvas = await html2canvas(testsAndBilling.current, { scale: 2 });
                const testsAndBillingImgData = testsAndBillingCanvas.toDataURL('image/png');
                const imgHeight2 = (testsAndBillingCanvas.height * imgWidth) / testsAndBillingCanvas.width;

                pdf.addImage(testsAndBillingImgData, 'PNG', margin, margin, imgWidth, imgHeight2, undefined, 'SLOW');
            }

            if (!isCancelled && tos.current) {
                pdf.addPage();
                const tosCanvas = await html2canvas(tos.current, { scale: 2 });
                const tosImgData = tosCanvas.toDataURL('image/png');
                const imgHeight3 = (tosCanvas.height * imgWidth) / tosCanvas.width;

                pdf.addImage(tosImgData, 'PNG', margin, margin, imgWidth, imgHeight3, undefined, 'SLOW');
            }

            if (!isCancelled && phi.current) {
                pdf.addPage();
                const phiCanvas = await html2canvas(phi.current, { scale: 2 });
                const phiImgData = phiCanvas.toDataURL('image/png');
                const imgHeight4 = (phiCanvas.height * imgWidth) / phiCanvas.width;

                pdf.addImage(phiImgData, 'PNG', margin, 5, imgWidth, imgHeight4, undefined, 'SLOW');
            }

            if (!isCancelled && psdr.current) {
                pdf.addPage();
                const psdrCanvas = await html2canvas(psdr.current, { scale: 2 });
                const psdrImgData = psdrCanvas.toDataURL('image/png');
                const imgHeight5 = (psdrCanvas.height * imgWidth) / psdrCanvas.width;

                pdf.addImage(psdrImgData, 'PNG', margin, margin, imgWidth, imgHeight5, undefined, 'SLOW');
            }

            if (!isCancelled && addInfo != null && addInfo.current) {
                pdf.addPage();
                const addInfoCanvas = await html2canvas(addInfo.current, { scale: 2 });
                const addInfoImgData = addInfoCanvas.toDataURL('image/png');
                const imgHeight6 = (addInfoCanvas.height * imgWidth) / addInfoCanvas.width;

                pdf.addImage(addInfoImgData, 'PNG', margin, margin, imgWidth, imgHeight6, undefined, 'SLOW');
            }

            if (!isCancelled) {
                pdf.save('business-details.pdf'); // Save the PDF
            }

            setIsGenerating(false);
        } catch (error) {
            console.error('PDF generation error:', error);
            setIsGenerating(false);
        }
    };


    const handleGenerate = () => {
        setIsCancelled(false);
        generatePdf().then(() => { setIsGenerating(false) });
    };

    return (
        <>
            <Button color={"lightSeaGreen"} onClick={handleGenerate}> <DownloadIcon color={'lightSeaGreen'} mr={2} />  {name} PDF</Button>

            <Modal isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isGenerating ? `Generating ${name} PDF` : 'Generation Completed'}</ModalHeader>
                    <ModalBody>
                        <Text>{isGenerating ? `Please wait while ${name} PDF is being generated...` : `${name} PDF is downloaded and ready to view.`}</Text>
                    </ModalBody>
                    <ModalFooter>
                        {!isGenerating &&
                            <Button colorScheme="green" onClick={onClose}>Close</Button>
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PdfGenerator;
