import React, { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import { generateLinkForBusiness } from '../api'; // Add this API call
import { alert, WURL } from '../Utils';
import { LinkIcon } from '@chakra-ui/icons';

const GenerateLinkButton = ({ businessId }) => {
    const [loading, setLoading] = useState(false);

    const handleGenerateLink = async () => {
        setLoading(true);
        try {
            const response = await generateLinkForBusiness(businessId);
            navigator.clipboard.writeText(WURL + response.data.link)
                .then(() => {
                    // Optionally, show a success message
                    alert('Link Generated', 'success', `Link has been generated and copied, <br><br> This link is valid for one time use only.<br><br><br><hr /><br>${WURL + response.data.link}`, 'Okay !');
                })
                .catch((err) => {
                    // Handle errors if any
                    alert('Link Generated', 'error', `Link has been generated but not copied, here is the link <br>${WURL + response.data.link}`, 'Okay !');

                });

        } catch (err) {
            alert('Oops !', 'error', `Something went wrong generating a link for this client, please try again later`, 'Okay !');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button size={"sm"} onClick={handleGenerateLink} isLoading={loading}>
                <LinkIcon mr={2} /> Link
            </Button>
        </>
    );
};

export default GenerateLinkButton;
