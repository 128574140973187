import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderById, updateOrder, fetchSupplies } from '../../api';
import {
    Box,
    Heading,
    VStack,
    Text,
    Input,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Textarea,
    Badge,
    Alert,
    Center,
    AlertTitle,
    AlertDescription,
    Divider,
    Flex,
    Tooltip,
    Grid,
    useDisclosure,
    Modal,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
    Checkbox,
    Select
} from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import { alert, hasPermission } from '../../Utils';
import { CheckIcon, ChevronLeftIcon, EditIcon, NotAllowedIcon, PlusSquareIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import PdfGeneratorOrders from '../../components/PdfGeneratorOrders';
import LastRequestedDate from './LastRequestedDate';
import OrdersInternalNotes from './OrdersInternalNotes';
import LastTenOrders from './LastTenOrders';
import InventoryQuantity from './InventoryQuantity';
import AddItemsModal from './AddItemsModal'; // Import the new component
import PrintLabel from './PrintLabel';


const OrderDetails = () => {
    const { id } = useParams(); // Get the order ID from the URL
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updatedItems, setUpdatedItems] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAddItemsModalOpen, setIsAddItemsModalOpen] = useState(false); // Custom state for AddItemsModal
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);

    // Functions to open and close the AddItemsModal
    const openAddItemsModal = () => setIsAddItemsModalOpen(true);
    const closeAddItemsModal = () => setIsAddItemsModalOpen(false);

    const tableRef = useRef();

    const handleNoteAdded = (updatedNotes) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            internalNotes: updatedNotes,
        }));
    };
    useEffect(() => {
        loadOrderDetails();
    }, [id]);

    const loadOrderDetails = async () => {
        setLoading(true);
        try {
            const result = await fetchOrderById(id);
            if (result.success) {
                setOrder(result.data);
                setUpdatedItems(result.data.requestedItems.map(item => ({
                    itemId: item.item._id,
                    quantitySent: item.quantitySent,
                    note: item.note || '',
                    isAvailable: item.isAvailable || false,
                    notSending: item.notSending || false,
                })));

                console.log(result.data)
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to load order details', { error });
        }
        setLoading(false);
    };

    const handleQuantityChange = (index, value) => {
        const newItems = [...updatedItems];
        newItems[index].quantitySent = parseInt(value, 10);
        setUpdatedItems(newItems);
    };

    const handleNoteChange = (index, value) => {
        const newItems = [...updatedItems];
        newItems[index].note = value;
        setUpdatedItems(newItems);
    };

    const handleToggleNotAvailable = (index) => {
        const newItems = [...updatedItems];
        newItems[index].isAvailable = !newItems[index].isAvailable;
        if (!newItems[index].isAvailable) {
            newItems[index].notSending = true; // Set notSending to true if item is not available
        }
        setUpdatedItems(newItems);
    };

    const handleToggleNotSending = (index) => {
        const newItems = [...updatedItems];
        newItems[index].notSending = !newItems[index].notSending;
        setUpdatedItems(newItems);
    };

    const handleSubmit = async (isFinished = false, ignoreValidation = false) => {
        try {

            if (!ignoreValidation) {
                // Check for validation
                const invalidItems = updatedItems.filter(item => {
                    // If quantity is 0, item must be marked as notSending and must have a note
                    if (item.quantitySent === 0 && (!item.notSending || !item.note)) {
                        return true;
                    }
                    // For other cases, if item is not available or not sending, it requires a note
                    if ((!item.isAvailable || item.notSending) && !item.note) {
                        return true;
                    }
                    return false;
                });

                if (invalidItems.length > 0) {
                    alert("Oops!", 'error', 'Some items require a note because they are marked as not available, not being sent, or have a quantity of 0. Items with a quantity of 0 must also be marked as not being sent.', 'Okay!');
                    return;
                }


            }
            const formattedItems = updatedItems.map(item => {
                const existingItem = order.requestedItems.find(
                    reqItem => reqItem.item._id === item.itemId
                );

                return {
                    item: item.itemId,
                    quantityRequested: existingItem ? existingItem.quantityRequested : item.quantityRequested,
                    quantitySent: item.quantitySent,
                    note: item.note,
                    isAvailable: item.isAvailable,
                    notSending: item.notSending,
                };
            });
            const updatedData = {
                requestedItems: formattedItems,
                finished: isFinished
            };


            console.log({ updatedData })

            const result = await updateOrder(id, updatedData);
            if (result.success) {
                loadOrderDetails()
            } else {
                alert("Oops !", 'error', result.error, 'Okay !')
            }

        } catch (error) {
            alert("Oops !", 'error', "something went wrong, please try again later !", 'Okay !')
        }
    };



    // Updated handleAddItems function with functional state update
    const handleAddItems = async (newItems) => {
        const formattedItems = newItems.map(item => ({
            itemId: item.itemId,
            quantityRequested: item.quantity,
            quantitySent: 0,
            isAvailable: true,
            notSending: false,
        }));

        // Use functional update to ensure latest state
        setUpdatedItems(prevItems => [...prevItems, ...formattedItems]);



        setShouldSubmit(true);
    };




    useEffect(() => {
        if (shouldSubmit) {
            setIsSubmitting(true);
            handleSubmit(false, true);
            setShouldSubmit(false);
        } else {
            setIsSubmitting(false)
        }
    }, [updatedItems, shouldSubmit]);



    // Function to handle confirmation and submission
    const handleConfirm = async () => {
        setIsSubmitting(true);
        try {
            await handleSubmit(true); // Proceed with the submission
        } finally {
            setIsSubmitting(false);
            onClose(); // Close the modal after submission
        }
    };



    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        return <AccessDenied msg={error} />;
    }

    return (
        <Box maxW="full" mx="auto" p="8">
            {/* Client-Entered Details */}
            <HStack align="start" spacing={3} mb={5} flex="1" >
                {order.finished &&
                    <PdfGeneratorOrders orderDetailsRef={tableRef} name={order?.business?.name} title={"Download"} />
                }

                {!order?.finished &&
                    <HStack mt={6} spacing={4} justify="space-between" width="full">
                        {/* Left-aligned buttons */}
                        <HStack spacing={4}>
                            <Button onClick={() => window.history.back()}> <ChevronLeftIcon mr={3} boxSize={5} /> Go Back</Button>
                            {hasPermission('updateOrder') &&
                                <Button colorScheme="blue" isLoading={isSubmitting} onClick={() => handleSubmit(false)}> <EditIcon mr={3} /> Save Changes</Button>
                            }
                        </HStack>
                        {hasPermission('downloadOrder') &&
                            <PdfGeneratorOrders orderDetailsRef={tableRef} name={order?.business?.name} title={"Download"} />
                        }

                        <Button colorScheme="orange" onClick={openAddItemsModal}>
                            <PlusSquareIcon mr={3} /> Add More Items
                        </Button>


                        {/* Right-aligned button with tooltip */}
                        {hasPermission("finishOrder") &&
                            <Tooltip label="Finish order means you can't go back and update it" aria-label="Finish Order Tooltip" placement={"left"}>
                                <Button isLoading={isSubmitting} colorScheme="green" onClick={onOpen}>
                                    <CheckIcon mr={3} /> Finish Order
                                </Button>
                            </Tooltip>
                        }
                    </HStack>
                }

            </HStack>

            <Box ref={tableRef} >
                <Box borderWidth="1px" borderRadius="lg" p={6} mb={8} boxShadow="sm" bg="white">
                    <Heading size="md" mb={5} color="gray.700" >Order Details</Heading>

                    <HStack align="start" spacing={10}>
                        {/* Business Details */}
                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                            <Heading size="sm" color="gray.400">Business Information</Heading>
                            {order.business ? (
                                <>
                                    <HStack>
                                        <Text fontWeight="bold">Client Name:</Text>
                                        <Text>{order.business.name}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text fontWeight="bold">Client Address:</Text>
                                        <Text>{order.business.address.address1 + ", " + (order.business.address.address2 ? order.business.address.address2 + ", " : '') + order.business.address.city + ", " + order.business.address.state + ", " + order.business.address.zip}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text fontWeight="bold">Client Email:</Text>
                                        <Text>{order.business.contact.email || "N/A"}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text fontWeight="bold">Client Phone:</Text>
                                        <Text>{order.business.contact.phone || "N/A"}</Text>
                                    </HStack>
                                    {order?.orderTakenBy &&
                                        <HStack>
                                            <Text fontWeight="bold">Order Created By:</Text>
                                            <Text>{order.orderTakenBy.firstName}</Text>
                                        </HStack>
                                    }
                                </>
                            ) : (
                                <Text color="gray.500">No business information available.</Text>
                            )}
                        </VStack>

                        {/* Client-Entered Details */}
                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                            <Heading size="sm" color="gray.400">Client-Entered Information</Heading>
                            <HStack>
                                <Text fontWeight="bold">Client Name:</Text>
                                <Text>{order.clientName || "N/A"}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Client Email:</Text>
                                <Text>{order.clientEmail || "N/A"}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Address:</Text>
                                <Text>{(order.clientAddress.address || "N/A") + ", " + (order.clientAddress.city || "N/A") + ", " + (order.clientAddress.state || "N/A") + ", " + (order.clientAddress.zip || "N/A")}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Phone:</Text>
                                <Text>{order.clientPhone || "N/A"}</Text>
                            </HStack>
                        </VStack>

                        {/* Order Status */}
                        <VStack align="start" spacing={3} flex="1">
                            <Heading size="sm" color="gray.400">Order Status</Heading>
                            <HStack>
                                <Text fontWeight="bold">Status:</Text>
                                <Badge colorScheme={order.finished ? "green" : "red"}>
                                    {order.finished ? "Finished" : "Not Finished"}
                                </Badge>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Ordered By:</Text>
                                <Text>{order.orderedBy}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Ordered At:</Text>
                                <Text>{new Date(order.createdAt).toLocaleString()}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Last Update:</Text>
                                <Text>{new Date(order.updatedAt).toLocaleString()}</Text>
                            </HStack>
                            <HStack>
                                <Text fontWeight="bold">Finished At:</Text>
                                <Text fontSize={"sm"}>{order.finished ? (new Date(order.finishedAt).toLocaleString() + " By " + order?.finishedBy?.firstName) : "Not Finished Yet"}</Text>
                            </HStack>
                        </VStack>
                    </HStack>
                </Box>


                <Heading size="md" mb={4}>Client Notes on this order</Heading>
                <Alert mb={10} status='warning'>
                    <EditIcon boxSize={9} />
                    <Box ml={5}>
                        <AlertTitle mb={5}>Order Notes</AlertTitle>
                        <AlertDescription>
                            {order?.note || "No Notes"}
                        </AlertDescription>
                    </Box>
                </Alert>

                <Divider />

                <Heading size="md" mb={4} mt={5}>Requested Items</Heading>

                <Table variant="simple" tableLayout="auto">
                    <Thead>
                        <Tr>
                            <Th width="20%">Item Name</Th>
                            <Th width="10%">Size</Th>
                            <Th width="10%">QR</Th>
                            <Th width="20%">QS</Th>
                            <Th width="20%">Note</Th>
                            <Th width="10%">Status</Th>
                            {!order?.finished &&
                                <Th>Actions</Th>
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {order.requestedItems.map((item, index) => (
                            <Tr _hover={{ bg: "gray.100" }} key={item?.item?._id}>
                                <Td>{item.item.name}
                                    <br /><br />
                                    <LastRequestedDate currentOrderId={order._id} businessId={order.business?._id} itemId={item.item._id} />
                                    <Badge cursor={'default'}>
                                        <Tooltip label={item?.addedAt ? "Added At " + new Date(item?.addedAt).toLocaleDateString() + " - " + new Date(item?.addedAt).toLocaleTimeString() : ""}>
                                            <Text fontSize="xs" color="gray.500">
                                                {item?.addedBy?.firstName && "Added By " + item?.addedBy?.firstName}
                                            </Text>
                                        </Tooltip>
                                    </Badge>

                                </Td>
                                <Td>{item.item.size}</Td>
                                <Td>{item.quantityRequested}</Td>

                                <Td>
                                    {(order.finished || !hasPermission('updateOrder')) ? updatedItems[index]?.quantitySent :
                                        <Flex align="center" justify="space-between">
                                            <Input
                                                type="number"
                                                value={updatedItems[index]?.quantitySent || 0}
                                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                min={0}
                                            />
                                        </Flex>
                                    }
                                    {order?.warehouseId?._id &&
                                        <InventoryQuantity warehouseId={order?.warehouseId?._id} itemId={item.item._id} />
                                    }
                                </Td>
                                <Td>
                                    {(order.finished || !hasPermission('updateOrder')) ? updatedItems[index]?.note :
                                        <>
                                            <Select placeholder="Select a note" size="sm" mb={2}
                                                onChange={(e) => handleNoteChange(index, e.target.value)}>
                                                <option value="Item is out of stock">Item is out of stock</option>
                                                <option value="Item not available">Item not available</option>
                                                <option value="Client Requested to Cancel of this item">Client Requested to Cancel of this item</option>
                                                <option value="Client didn't send enough samples to send this item again">Client didn't send enough samples to send this item again</option>
                                            </Select>

                                            <Textarea
                                                placeholder="Select or type your own note"
                                                value={updatedItems[index]?.note || ''}
                                                onChange={(e) => handleNoteChange(index, e.target.value)}
                                            />

                                        </>
                                    }
                                </Td>
                                <Td>
                                    <VStack>
                                        <Badge mt={5} colorScheme={!updatedItems[index]?.isAvailable ? "red" : 'blue'}>{updatedItems[index]?.isAvailable ? "Available" : "Not Available"}</Badge>
                                        <Badge colorScheme={updatedItems[index]?.notSending ? "red" : 'blue'}>{updatedItems[index]?.notSending ? "Not Sent" : "Sent"}</Badge>
                                    </VStack>
                                </Td>
                                {(!order?.finished && hasPermission('updateOrder')) &&
                                    <Td>
                                        <Tooltip label={updatedItems[index]?.isAvailable ? "Mark as Not Available" : "Mark as Available"} aria-label="Toggle Availability">
                                            <Button size={"sm"} colorScheme={updatedItems[index]?.isAvailable ? "green" : "red"} onClick={() => handleToggleNotAvailable(index)}>
                                                {updatedItems[index]?.isAvailable ? <NotAllowedIcon /> : <CheckIcon />}
                                            </Button>
                                        </Tooltip>

                                        {/* Only show the Not Sending toggle if the item is available */}
                                        {updatedItems[index]?.isAvailable && (
                                            <Tooltip label={updatedItems[index]?.notSending ? "Mark as Sending" : "Mark as Not Sending"} aria-label="Toggle Sending Status">
                                                <Button ml={5} size={"sm"} colorScheme={updatedItems[index]?.notSending ? "red" : "blue"} onClick={() => handleToggleNotSending(index)}>
                                                    {updatedItems[index]?.notSending ? <ViewOffIcon /> : <ViewIcon />}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Td>
                                }
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Center mt={5} mb={10}>
                    <Heading color="gray.400">End of order</Heading>
                </Center>


                <VStack>
                    <Text fontSize='md' fontWeight={'bold'}>

                    </Text>
                </VStack>
            </Box>

            <Divider />

            <Grid mt={5} templateColumns={order.finished ? ['1fr', '1fr'] : ['2fr', '2fr 1fr']} gap={6} mb={5}>
                {/* Second Column: OrdersInternalNotes Component */}
                <Box>
                    <OrdersInternalNotes
                        orderId={order._id}
                        existingNotes={order.internalNotes.reverse() || []}
                        onNoteAdded={handleNoteAdded}
                        addNote={!order.finished}
                    />
                </Box>


                <Box>
                    <Box>
                        <PrintLabel clientName={order.business.name} />
                    </Box>

                    {!order.finished &&
                        <Box>
                            <Heading size='md' as={'h5'} mb={5} color="gray.400">Client Last 10 Orders</Heading>
                            <LastTenOrders businessId={order.business._id} />
                        </Box>
                    }
                </Box>

            </Grid>

            {/* Confirmation Modal */}
            {!order.finished &&
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Finish Order</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>Are you sure you want to finish this order? This action cannot be undone.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose} mr={3}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={handleConfirm}
                                isLoading={isSubmitting}
                            >
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

            <AddItemsModal
                isOpen={isAddItemsModalOpen}
                onClose={closeAddItemsModal}
                onAddItems={handleAddItems}
                requestedItems={order?.requestedItems}
            />




        </Box>
    );
};

export default OrderDetails;
