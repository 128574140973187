import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    FormControl,
    FormLabel,
    Center,
    useToast,
    Divider,
    Input,
    Textarea,
    Alert,
    AlertIcon
} from '@chakra-ui/react';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { addPickupNote, fetchCouriersBySearch, getAllPickups, searchPickups, updatePickup } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import AsyncSelect from 'react-select/async';
import PopoverId from '../../components/PopoverId';
import { FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2'

const ReceivePickups = () => {
    const toast = useToast();
    const [allPickups, setAllPickups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [courier, setCourier] = useState(null);
    const [searching, setSearching] = useState(false);
    const [pickupData, setPickupData] = useState({}); // State to store count and notes for each pickup

    // Initialize pickupData for all pickups when they are loaded
    useEffect(() => {
        const initialPickupData = allPickups.reduce((acc, pickup) => {
            acc[pickup._id] = {
                receivedCount: '',
                note: '',
            };
            return acc;
        }, {});
        setPickupData(initialPickupData);
    }, [allPickups]);

    const handleSearch = async (currentPage = 1) => {
        if (!courier?.value) {
            return;
        }

        setLoading(true);
        try {
            const searchParams = {
                status: "PICKED_UP",
                courier: courier?.value,
                page: currentPage,
                limit: 9999,
            };
            setSearching(true);

            const response = await searchPickups(searchParams);

            if (response.success) {
                setAllPickups(response.data.pickups);
            } else if (response.data.totalItems === 0) {
                setAllPickups([]);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error);
        }
        setLoading(false);
    };

    const handleInputChange = (pickupId, field, value) => {
        setPickupData((prevState) => ({
            ...prevState,
            [pickupId]: {
                ...prevState[pickupId],
                [field]: value,
            },
        }));
    };

    const onUpdate = async (pickupId) => {
        const { receivedCount, note } = pickupData[pickupId];
        const samplesCount = allPickups.find((pickup) => pickup._id === pickupId)?.samplesCount;

        if (!receivedCount) {
            toast({
                title: "Error",
                description: "Please enter a receiving count.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        // Check for mismatch and show Swal confirmation
        if (receivedCount != samplesCount) {
            const result = await Swal.fire({
                title: 'Mismatch Detected!',
                html: `The received count you entered ( <strong> ${receivedCount}</strong> ) does not match <br><br>the samples count indicated by the courier on pickup. 
                <br><br>Do you want to proceed? <br><br> <small style="color: red">Please double check your count before proceeding</small>`,
                // text: `The received count ( ${receivedCount} ) does not match the samples count (${samplesCount}). Do you want to proceed?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, proceed',
                cancelButtonText: 'No, cancel',
            });

            if (!result.isConfirmed) {
                toast({
                    title: "Update Cancelled",
                    description: "Please verify the counts and try again.",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }
        }

        const updatedData = {
            status: "RECEIVED",
            samplesReceived: receivedCount,
        };

        setLoading(true);

        try {
            // Add a note if provided
            if (note) {
                const noteResponse = await addPickupNote(pickupId, note);
                if (!noteResponse.success) {
                    toast({
                        title: "Error",
                        description: "Failed to add note. Pickup update aborted.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                    return; // Abort if adding note fails
                }
            }

            // Update the pickup
            const updatedPickup = await updatePickup(updatedData, pickupId);
            if (updatedPickup.success) {
                toast({
                    title: "Success",
                    description: "Pickup updated successfully.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });

                // Update the UI to remove the updated pickup from the list
                setAllPickups((prev) => prev.filter((pickup) => pickup._id !== pickupId));
            } else {
                toast({
                    title: "Error",
                    description: updatedPickup.message || "Failed to update pickup.",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Error updating pickup:", error);
            toast({
                title: "Error",
                description: "An unexpected error occurred.",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };


    const clearSearch = () => {
        setSearching(false);
        setCourier(null);
        setAllPickups([]);
    };

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title={`Receive Pickups`} />

                <HStack p={2} mb={3} spacing={4} align="start">
                    <FormControl isRequired>
                        <FormLabel>Courier</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchCouriersBySearch}
                            defaultOptions
                            onChange={(option) => setCourier(option)} // Set entire option
                            value={courier || null} // Use the selected option directly
                            placeholder="Search and select a courier"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>

                    <HStack mt={9}>
                        {courier?.value && (
                            <Button colorScheme="blue" size={"sm"} onClick={() => handleSearch(1)}>
                                View Courier Pickups
                            </Button>
                        )}

                        {searching && (
                            <Button colorScheme="red" size={"sm"} onClick={clearSearch}>
                                <FaTimes />
                            </Button>
                        )}
                    </HStack>
                </HStack>

                <Divider />

                {loading ? (
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                ) : (
                    <>
                        {error ? (
                            <AccessDenied msg={error} />
                        ) : allPickups.length > 0 ? (
                            <>
                                <Alert status="warning">
                                    <AlertIcon />
                                    If there are any notes about any sample/pickup, please don't forget to document them in the notes section for each pickup.
                                </Alert>

                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>#ID</Th>
                                            <Th>Pickup</Th>
                                            <Th>Courier</Th>
                                            <Th textAlign="right">Samples Count</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {allPickups?.map((pickup, index) => (
                                            <Tr
                                                color={pickup.type === 'Supplies' ? 'white' : 'gray.900'}
                                                bg={
                                                    pickup.type === 'DropOffOnly'
                                                        ? "pink.100"
                                                        : pickup.type === 'Supplies'
                                                            ? 'purple'
                                                            : 'white'
                                                }
                                                _hover={{ bg: "gray.300", color: "black" }}
                                                key={pickup._id}
                                            >
                                                <Td maxW={1}>
                                                    <PopoverId place="Pickup" index={index} content={pickup._id} />
                                                </Td>
                                                <Td fontWeight={"bold"}>{pickup?.pickupLocation?.name || "N/A"}</Td>
                                                <Td>{pickup.courier?.name || "N/A"}</Td>
                                                <Td textAlign="right">
                                                    <VStack align="flex-end" spacing={2}>
                                                        {/* Input for Samples Count */}
                                                        <Input
                                                            size="lg"
                                                            width={"80%"}
                                                            placeholder="Receiving count"
                                                            value={pickupData[pickup._id]?.receivedCount || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    pickup._id,
                                                                    "receivedCount",
                                                                    e.target.value
                                                                )
                                                            }
                                                            type="number"
                                                        />
                                                        {/* Text Area for Notes */}
                                                        <Textarea
                                                            size={"lg"}
                                                            placeholder="Notes if there is any..."
                                                            resize={"none"}
                                                            width={"80%"}
                                                            value={pickupData[pickup._id]?.note || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(pickup._id, "note", e.target.value)
                                                            }
                                                        />

                                                        <Button
                                                            size="sm"
                                                            colorScheme="teal"
                                                            onClick={() => onUpdate(pickup._id)}
                                                        >
                                                            Receive
                                                        </Button>
                                                    </VStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        ) : (
                            <AccessDenied msg="Nothing here yet!" icon="info" />
                        )}
                    </>
                )}
            </VStack>
        </Box>
    );
};

export default ReceivePickups;
