import React, { useEffect, useState } from 'react';
import { fetchLastRequestedDate, fetchOrderById } from '../../api'; // Import the API functions
import {
    Spinner, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalBody, ModalFooter, Button, VStack, HStack, Badge,
    Heading,
    Box,
    Tr, Thead, Td, Divider, Table, Center, Tbody, Th
} from '@chakra-ui/react';

const LastRequestedDate = ({ businessId, itemId, currentOrderId }) => {
    const [lastRequestedDate, setLastRequestedDate] = useState(null);
    const [lastOrder, setLastOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Function to load last requested date
    const loadLastRequestedDate = async () => {
        try {
            const result = await fetchLastRequestedDate(businessId, itemId, currentOrderId);
            if (result.success) {
                setLastRequestedDate(result.data.lastRequestedDate);
                setLastOrder(result.data.lastOrderId); // Assuming the API returns lastOrderId
                setOrder(result.data.order); // Assuming the API returns lastOrderId
            }
        } catch (error) {
            console.error('Error fetching last requested date:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch last requested date when the component mounts
    useEffect(() => {
        if (businessId && itemId) {
            loadLastRequestedDate();
        } else {
            setLoading(false);
        }
    }, [businessId, itemId]);

    // Function to fetch and display the last order details
    const handleShowOrderDetails = async () => {

        console.log(lastOrder)

        if (!lastOrder) return;
        try {
            const result = await fetchOrderById(lastOrder);
            if (result.success) {
                setLastOrder(result.data._id);
                onOpen();
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    // Render loading spinner, date with clickable functionality, or "Never ordered before"
    return (
        <>
            {loading ? (
                <Text fontSize="sm" color="gray.500">Last ordered <Spinner size="xs" /></Text>
            ) : lastRequestedDate ? (
                <Text fontSize="sm" cursor="pointer" color="gray.500" onClick={handleShowOrderDetails}>
                    Last ordered {new Date(lastRequestedDate).toLocaleDateString()}
                </Text>
            ) : (
                <Text fontSize="sm" color="gray.500">Never ordered before</Text>
            )}

            {/* Modal for showing order details */}
            <Modal scrollBehavior='inside' isOpen={isOpen} onClose={onClose} size="xla">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Previous Order Details</ModalHeader>
                    <ModalBody>
                        {order ? (

                            <>
                                <Box borderWidth="1px" borderRadius="lg" p={6} mb={8} boxShadow="sm" bg="white">
                                    <Heading size="md" mb={4} color="gray.700">Order Details</Heading>

                                    <HStack align="start" spacing={10}>
                                        {/* Business Details */}
                                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                            <Heading size="sm" color="gray.400">Business Information</Heading>
                                            {order.business ? (
                                                <>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Name:</Text>
                                                        <Text>{order.business.name}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Address:</Text>
                                                        <Text>{order.business.address.address1 + ", " + (order.business.address.address2 ? order.business.address.address2 + ", " : '') + order.business.address.city + ", " + order.business.address.state + ", " + order.business.address.zip}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Email:</Text>
                                                        <Text>{order.business.contact.email}</Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontWeight="bold">Client Phone:</Text>
                                                        <Text>{order.business.contact.phone}</Text>
                                                    </HStack>
                                                </>
                                            ) : (
                                                <Text color="gray.500">No business information available.</Text>
                                            )}
                                        </VStack>

                                        {/* Client-Entered Details */}
                                        <VStack align="start" spacing={3} flex="1" borderRight={"1px solid #ccc"}>
                                            <Heading size="sm" color="gray.400">Client-Entered Information</Heading>
                                            <HStack>
                                                <Text fontWeight="bold">Client Name:</Text>
                                                <Text>{order.clientName}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Client Email:</Text>
                                                <Text>{order.clientEmail}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Address:</Text>
                                                <Text>{order.clientAddress.address + ", " + order.clientAddress.city + ", " + order.clientAddress.state + ", " + order.clientAddress.zip}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Phone:</Text>
                                                <Text>{order.clientPhone}</Text>
                                            </HStack>
                                        </VStack>

                                        {/* Order Status */}
                                        <VStack align="start" spacing={3} flex="1">
                                            <Heading size="sm" color="gray.400">Order Status</Heading>
                                            <HStack>
                                                <Text fontWeight="bold">Status:</Text>
                                                <Badge colorScheme={order.finished ? "green" : "red"}>
                                                    {order.finished ? "Finished" : "Not Finished"}
                                                </Badge>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Ordered By:</Text>
                                                <Text>{order.orderedBy}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Ordered At:</Text>
                                                <Text>{new Date(order.createdAt).toLocaleString()}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Last Update:</Text>
                                                <Text>{new Date(order.updatedAt).toLocaleString()}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text fontWeight="bold">Finished At:</Text>
                                                <Text>{order.finished ? new Date(order.updatedAt).toLocaleString() : "Not Finished Yet"}</Text>
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                </Box>

                                <Divider />

                                <Heading size="md" mb={4} mt={5}>Requested Items</Heading>
                                <Table variant="simple" tableLayout="auto">
                                    <Thead>
                                        <Tr>
                                            <Th width="20%">Item Name</Th>
                                            <Th width="10%">Size</Th>
                                            <Th width="10%">QR</Th>
                                            <Th width="20%">QS</Th>
                                            <Th width="20%">Note</Th>
                                            <Th width="10%">Status</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {order.requestedItems.map((item, index) => (
                                            <Tr _hover={{ bg: "gray.100" }} key={item.item._id}>
                                                <Td>{item.item.name}</Td>
                                                <Td>{item.item.size}</Td>
                                                <Td>{item.quantityRequested}</Td>

                                                <Td>{item.quantitySent || 0}</Td>
                                                <Td>{item.note}</Td>
                                                <Td>
                                                    <VStack>
                                                        <Badge mt={5} colorScheme={!item.isAvailable ? "red" : 'blue'}>{item.isAvailable ? "Available" : "Not Available"}</Badge>
                                                        <Badge colorScheme={item.notSending ? "red" : 'blue'}>{item.notSending ? "Not Sent" : "Sent"}</Badge>
                                                    </VStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Center mt={5} mb={10}>
                                    <Heading color="gray.400">End of order</Heading>
                                </Center>
                            </>

                        ) : (
                            <Text>No details available</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LastRequestedDate;
