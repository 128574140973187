import React, { useEffect, useState } from 'react';
import { Box, VStack, Heading, Button, HStack, Table, Thead, Tbody, Tr, Th, Td, Input, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Center, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // For navigation to Sales Rep's business page
import { addNewSalesRep, deleteSalesRepById, fetchSalesReps, updateSalesRep } from '../../api'
import Loading from '../../components/Loading/Loading';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { alert, hasPermission } from '../../Utils';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import PopoverId from '../../components/PopoverId';
import ViewSalesRepFacilitiesModal from './ViewSalesRepFacilitiesModal';
import { FaBuilding } from 'react-icons/fa';


const SalesRepManagement = () => {
    // Sample data for Sales Reps
    const [salesReps, setSalesReps] = useState([]);

    const [selectedSalesRep, setSelectedSalesRep] = useState(null); // Track the Sales Rep for deletion confirmation
    const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control for adding/editing
    const [isModalOpen, setModalOpen] = useState(false);

    const {
        isOpen: isAlertOpen,
        onOpen: onAlertOpen,
        onClose: onAlertClose,
    } = useDisclosure(); // Alert dialog for delete confirmation
    const cancelRef = React.useRef();

    const navigate = useNavigate(); // For navigation

    // Form state for new Sales Rep
    const [newSalesRep, setNewSalesRep] = useState({ name: '', email: '', password: '', status: 'Active' });

    // Edit Sales Rep state
    const [editSalesRep, setEditSalesRep] = useState(null);
    const [newPassword, setNewPassword] = useState(''); // State for new password in edit form

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorSr, setErrorSr] = useState(null);

    // Reset form states
    const resetForm = () => {
        setNewSalesRep({ name: '', email: '', password: '', status: 'Active' });
        setEditSalesRep(null);
        setNewPassword('');
        setError('')
    };


    // Fetch sales reps when the component mounts
    useEffect(() => {
        getSalesReps();
    }, []); // Empty dependency array means this will run once when the component mounts

    const getSalesReps = async () => {

        const sr = await fetchSalesReps();
        if (sr.success)
            setSalesReps(sr.data);
        else {
            setErrorSr(sr.error);
        }
        setLoading(false);
    };

    // Show loading indicator
    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        )
    }

    // Handle form changes (for both add and edit forms)
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (editSalesRep) {
            setEditSalesRep({ ...editSalesRep, [name]: value });
        } else {
            setNewSalesRep({ ...newSalesRep, [name]: value });
        }
    };

    const handleCreateSalesRep = async () => {
        if (!newSalesRep.name || !newSalesRep.email || !newSalesRep.password || !newSalesRep.status) {
            setError("All fields are required.");
        } else {
            try {
                const createdSalesRep = await addNewSalesRep(newSalesRep);
                console.log(createdSalesRep)
                if (createdSalesRep.success) {
                    alert('Created', 'success', "Sales rep created successfully", "Okay !")
                    // Update the state with the newly created sales rep
                    setSalesReps([createdSalesRep.data, ...salesReps]);
                    // Reset the form and close the modal
                    resetForm();
                    onClose();
                } else {
                    setError(createdSalesRep.error)
                }

            } catch (error) {
                // Handle errors (e.g., show an error message to the user)
                setError('Failed to create sales rep:', error)
            }
        }

    };

    // Handle editing a Sales Rep
    const handleEditSalesRep = (id) => {
        setError("")
        const rep = salesReps.find((r) => r._id === id);
        setEditSalesRep(rep);
        setNewPassword(''); // Reset new password field
        onOpen();
    };

    // Handle Save Changes for Edit (including password)
    const handleSaveChanges = async () => {
        if (!editSalesRep.name || !editSalesRep.email || !editSalesRep.status) {
            setError("All fields are required.");
        } else {
            try {
                // Check if there's a new password, and update the sales rep object accordingly
                const updatedRep = {
                    ...editSalesRep,
                    password: newPassword ? newPassword : undefined, // Send password only if it's changed
                };

                // Call the API to update the sales rep
                const response = await updateSalesRep(editSalesRep._id, updatedRep);
                const updatedSalesRep = response.data; // Assuming the API response contains the updated sales rep in 'data'

                alert('Updated', 'success', "Sales rep updated successfully", "Okay !");

                // Update the state with the updated sales rep data
                const updatedReps = salesReps.map((rep) =>
                    rep._id === updatedSalesRep._id ? updatedSalesRep : rep
                );

                setSalesReps(updatedReps); // Update the sales reps list in the state
                resetForm(); // Reset the form after saving changes
                onClose(); // Close the modal
            } catch (error) {
                console.error('Error updating sales rep:', error);
            }
        }
    };


    // Confirm Delete Sales Rep
    const confirmDelete = (id) => {
        setSelectedSalesRep(id); // Track which Sales Rep to delete
        onAlertOpen(); // Open the confirmation dialog
    };

    // Delete Sales Rep
    const deleteSalesRep = async () => {
        try {
            // Call the API to delete the sales rep
            await deleteSalesRepById(selectedSalesRep);

            // Update the state by filtering out the deleted sales rep
            setSalesReps(salesReps.filter((rep) => rep._id !== selectedSalesRep)); // Use `_id` for MongoDB

            setSelectedSalesRep(null); // Clear selected sales rep
            onAlertClose(); // Close the confirmation dialog
        } catch (error) {
            console.error('Failed to delete sales rep:', error);
            // You can show an error message to the user here if needed
        }
    };

    const onClickAdd = () => {
        resetForm();
        onOpen();
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {/* Table for displaying Sales Reps */}
                {!errorSr ?
                    <>

                        {/* viewSalesRep: "View All Sales Rep",
                        deleteSalesRep: "Delete Sales Rep",
                        restoreSalesRep: "Restore deleted sales rep",
                         */}
                        <TitleHeader title={"Sales Reps Management"} onClick={hasPermission("createSalesRep") ? onClickAdd : null} buttonText={hasPermission("createSalesRep") ? "New Sales Rep" : null} />
                        {salesReps?.length > 0 ?
                            <>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>#</Th>
                                            <Th width={"60%"}>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Status</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {salesReps.map((rep, index) => (
                                            <Tr key={rep._id} _hover={{ bg: "gray.100" }}>
                                                <Td><PopoverId place='Sales Reps Management' index={index} content={rep._id} /></Td>
                                                <Td>{rep.name}</Td>
                                                <Td>{rep.email}</Td>
                                                <Td>{rep.status}</Td>
                                                <Td>
                                                    <HStack spacing={4}>
                                                        {hasPermission("updateSalesRep") &&
                                                            <Button size="sm" colorScheme="blue" onClick={() => handleEditSalesRep(rep._id)}>
                                                                <EditIcon mr={2} /> Edit
                                                            </Button>
                                                        }
                                                        {hasPermission("viewSalesRepClients") &&
                                                            <Button size="sm" colorScheme="green" onClick={() => navigate(`/sales-reps/${rep._id}/clients`)}>
                                                                <ViewIcon mr={2} /> Clients
                                                            </Button>
                                                        }
                                                        {hasPermission("viewFacilities") &&
                                                            <Button size="sm" colorScheme="purple" onClick={() => {
                                                                setSelectedSalesRep(rep)
                                                                setModalOpen(true)
                                                            }}>
                                                                <FaBuilding /> <span style={{ marginLeft: 7 }}>Facilities</span>
                                                            </Button>
                                                        }
                                                        {hasPermission('deleteSalesRep') &&
                                                            <Button size="sm" colorScheme="red" onClick={() => confirmDelete(rep._id)}>
                                                                <DeleteIcon mr={2} />  Delete
                                                            </Button>
                                                        }
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                                {/* Modal for Creating/Editing Sales Rep */}
                                <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <ModalHeader>{editSalesRep ? 'Edit Sales Rep' : 'Add New Sales Rep'}</ModalHeader>
                                        <ModalCloseButton />

                                        {error &&
                                            <Alert status='error'>
                                                <AlertIcon />
                                                <AlertDescription>{error}</AlertDescription>
                                            </Alert>

                                        }

                                        <ModalBody>
                                            <VStack spacing={4} align="stretch">
                                                <Input
                                                    placeholder="Name"
                                                    value={editSalesRep ? editSalesRep.name : newSalesRep.name}
                                                    name="name"
                                                    onChange={(e) =>
                                                        editSalesRep
                                                            ? setEditSalesRep({ ...editSalesRep, name: e.target.value })
                                                            : handleChange(e)
                                                    }
                                                />
                                                <Input
                                                    placeholder="Email"
                                                    value={editSalesRep ? editSalesRep.email : newSalesRep.email}
                                                    name="email"
                                                    onChange={(e) =>
                                                        editSalesRep
                                                            ? setEditSalesRep({ ...editSalesRep, email: e.target.value })
                                                            : handleChange(e)
                                                    }
                                                />
                                                {!editSalesRep && (
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        value={newSalesRep.password}
                                                        name="password"
                                                        onChange={handleChange}
                                                    />
                                                )}
                                                {/* Password Field for Edit Sales Rep */}
                                                {editSalesRep && (
                                                    <Input
                                                        placeholder="New Password (Leave blank to keep current)"
                                                        type="password"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                )}
                                                <Select
                                                    placeholder="Status"
                                                    value={editSalesRep ? editSalesRep.status : newSalesRep.status}
                                                    onChange={(e) =>
                                                        editSalesRep
                                                            ? setEditSalesRep({ ...editSalesRep, status: e.target.value })
                                                            : handleChange(e)
                                                    }
                                                >
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </Select>
                                            </VStack>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button colorScheme="blue" mr={3} onClick={editSalesRep ? handleSaveChanges : handleCreateSalesRep}>
                                                {editSalesRep ? 'Save Changes' : 'Add Sales Repsss'}
                                            </Button>
                                            <Button variant="ghost" onClick={onClose}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>

                                {/* Alert Dialog for Delete Confirmation */}
                                <AlertDialog
                                    isOpen={isAlertOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onAlertClose}
                                >
                                    <AlertDialogOverlay>
                                        <AlertDialogContent>
                                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                                Delete Sales Rep
                                            </AlertDialogHeader>

                                            <AlertDialogBody>
                                                Are you sure you want to delete this Sales Rep? This action cannot be undone.
                                            </AlertDialogBody>

                                            <AlertDialogFooter>
                                                <Button ref={cancelRef} onClick={onAlertClose}>
                                                    No
                                                </Button>
                                                <Button colorScheme="red" onClick={deleteSalesRep} ml={3}>
                                                    Yes
                                                </Button>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialogOverlay>
                                </AlertDialog>
                            </>
                            :
                            <AccessDenied msg={"No Sales reps here yet !"} icon='info' />

                        }
                    </>

                    :

                    <AccessDenied msg={errorSr} />
                }
            </VStack>


            <ViewSalesRepFacilitiesModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                salesRep={selectedSalesRep}
            />

        </Box>
    );
};

export default SalesRepManagement;
