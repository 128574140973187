import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    // Protect routes based on authentication state
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
