import React, { useRef, useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Box,
    Text,
    HStack,
    Image,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Spinner,
} from '@chakra-ui/react';
import { Logo } from '../../Utils';
import PdfGeneratorPsc from '../../components/PdfGeneratorPsc';
import { fetchAllInsurances, fetchAllCategories, fetchPrices, fetchPriceCategories } from '../../api'; // Update with your actual API calls

const ExportModal = ({ isOpen, onClose }) => {
    const [organizedData, setOrganizedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const chunkRefs = useRef([]);

    useEffect(() => {
        if (isOpen) {
            fetchAllData();
        }
    }, [isOpen]);

    const fetchAllData = async () => {
        try {
            setLoading(true);
            setError(null); // Reset error

            // Fetch all insurances and categories (without pagination)
            const insDataResult = await fetchPrices(1, 999999);
            const insCatResult = await fetchPriceCategories();

            if (insDataResult.success && insCatResult.success) {
                organizeData(insDataResult.data.prices, insCatResult.data);
            } else {
                throw new Error('Failed to fetch data.');
            }
        } catch (err) {
            setError('Error fetching data: ' + err.message);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const organizeData = (insData, insCat) => {
        // Organize insurance data by categories and chunk them into pages of 13 or 19 items
        const data = insCat.map((category) => {
            const insurances = insData.filter((ins) => ins.category?._id === category._id);
            return {
                category,
                chunks: chunkArray(insurances, category.name === 'Other Custom Panels & Profiles' ? 13 : 19),
            };
        });
        setOrganizedData(data);

        // Create refs for each chunk of category section for PDF generation
        chunkRefs.current = data.flatMap(({ chunks }) => chunks.map(() => React.createRef()));
    };

    // Function to split array into chunks of a given size
    const chunkArray = (arr, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <PdfGeneratorPsc chunkRefs={chunkRefs} name="Cash Prices" title="Download Cash Prices" />
                </ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                            <Spinner size="lg" />
                        </Box>
                    ) : error ? (
                        <Text color="red.500">{error}</Text>
                    ) : organizedData.length > 0 ? (
                        organizedData.map(({ category, chunks }, categoryIndex) => (
                            chunks.map((chunk, chunkIndex) => (
                                <Box key={`${category._id}-${chunkIndex}`} ref={chunkRefs.current[categoryIndex * organizedData[0].chunks.length + chunkIndex]} mb={8}>
                                    <Box bg="blue.900" color="white" p={10} mb={4}>
                                        <HStack>
                                            <Image src={Logo} alt="Logo" boxSize="150px" w={200} objectFit="contain" />
                                            <Box textAlign="center" ml={"10%"}>
                                                <Text fontSize="4xl" fontWeight="bold">{category.name}</Text>
                                                <Text>info@mydlslab.com | +1(888)-882-3330</Text>
                                            </Box>
                                        </HStack>
                                    </Box>

                                    <Table variant="simple" mt={4} px={10}>
                                        <Thead>
                                            <Tr>
                                                <Th>Test Code</Th>
                                                <Th>Description</Th>
                                                <Th>CPT Code</Th>
                                                <Th>Price</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {chunk.map((ins) => (
                                                <Tr key={ins._id}>
                                                    <Td>{ins.testCode}</Td>
                                                    <Td>{ins.description}</Td>
                                                    <Td>{ins.cptCodes}</Td>
                                                    <Td>{ins.cashPrice}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Box>
                            ))
                        ))
                    ) : (
                        <Text>No data available to display.</Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ExportModal;
