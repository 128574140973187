
import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Text } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadIcon } from '@chakra-ui/icons';

const PdfGeneratorPsc = ({ chunkRefs, name, title }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const generatePdf = async () => {
        setIsGenerating(true);
        onOpen();

        const pdf = new jsPDF('p', 'mm', 'letter'); // Create PDF, letter size
        const pageWidth = pdf.internal.pageSize.getWidth(); // Full page width

        try {
            if (!isCancelled) {
                for (let i = 0; i < chunkRefs.current.length; i++) {
                    const ref = chunkRefs.current[i];
                    if (ref && ref.current && document.contains(ref.current)) {
                        const canvas = await html2canvas(ref.current, { scale: 2 });

                        const imgData = canvas.toDataURL('image/png');
                        const imgWidth = pageWidth;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        // Add the image to the PDF
                        if (i > 0) {
                            pdf.addPage(); // Add a new page after the first
                        }
                        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, "MEDIUM");

                    }
                }
            }

            if (!isCancelled) {
                pdf.save(`${name} - PSC Location.pdf`); // Save the PDF
            }
            setIsGenerating(false);
        } catch (error) {
            console.error('PDF generation error:', error);
            setIsGenerating(false);
        }
    };
    const handleGenerate = () => {
        setIsCancelled(false);
        generatePdf().then(() => { setIsGenerating(false) });
    };

    return (
        <>
            <Button color={"lightSeaGreen"} onClick={handleGenerate}> <DownloadIcon color={'lightSeaGreen'} mr={2} />  {title} PDF</Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{isGenerating ? `Generating ${name} PDF` : 'Generation Completed'}</ModalHeader>
                    <ModalBody>
                        <Text>{isGenerating ? `Please wait while ${name} PDF is being generated...` : `${name} PDF is downloaded and ready to view.`}</Text>
                    </ModalBody>
                    <ModalFooter>
                        {!isGenerating &&
                            <Button colorScheme="green" onClick={onClose}>Close</Button>
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PdfGeneratorPsc;
