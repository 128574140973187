import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    Badge,
    Tooltip,
    useToast,
    Divider,
    Alert,
    AlertTitle,
    AlertDescription,
    Input,
    Text,
    SimpleGrid,
    Card,
    CardHeader,
    Heading,
    CardBody,
    Flex,
    Circle,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    IconButton
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import Pagination from '../../components/Pagination';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { cancelPickup, deletePickupAttachements, fetchCouriersBySearch, fetchFacilitiesBySearch, fetchLabLocations, fetchRouteBySearch, getAllPickups, getPickupDetails, savePickupOrder, searchPickups, transferPickup, updatePickup, uploadPickupAttachements } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { hasPermission } from '../../Utils';
import PopoverId from '../../components/PopoverId';
import { FaBox, FaClock, FaCloudUploadAlt, FaEnvelope, FaFileUpload, FaPhotoVideo, FaShare, FaShareAlt } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PickupNotes from './PickupNotes';
import EditPickupModal from './EditPickupModal';
import CreatePickupModal from './CreatePickupModal';
import ImageLightbox from '../../components/ImageLightBox';
import { useParams } from 'react-router-dom';

const noReturn = ['pickupLocation', 'dropOffLocation', 'courier']


const getGoogleMapsLink = (address) => {
    const { address1, address2, city, state, zip } = address;
    const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
    return googleMapsUrl;
};

const humanReadableField = (field) => {


    const fieldMappings = {
        status: "Status",
        samplesReceived: "Samples Received",
        samplesCount: "Samples Count",
        "requirements.requestPhoto": "Request Photo",
        "requirements.hasSupplies": "Has Supplies",
        "requirements.hasDocs": "Has Documents",
        "priority": "Priority",
        "receivedBy": "Received By",
    };

    return fieldMappings[field] || field.replace(/\./g, " "); // Default fallback: replace dots with spaces
};

const formatValue = (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (value === null || value === undefined) return "N/A";
    if (typeof value === "string") return value; // Return the string as is, without JSON.stringify
    return JSON.stringify(value); // Use JSON.stringify for objects/arrays
};

const ViewPickup = () => {
    const { id } = useParams()

    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const { isOpen: isViewModalOpen, onOpen: onOpenViewModal, onClose: onCloseViewModal } = useDisclosure();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const toast = useToast()
    const [pickupDetail, setPickupDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [error, setError] = useState(null);

    const [cancellationReason, setCancellationReason] = useState('');
    const [modalError, setModalError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);



    useEffect(() => {
        getPickup();
    }, []);


    const getPickup = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await getPickupDetails(id);
            if (response.success) {
                const pickupDetails = response.data;
                setPickupDetail(pickupDetails)
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };


    const status = (status) => {
        switch (status) {
            case 'PENDING':
                return "Pending";
            case 'PICKED_UP':
                return "Picked Up"
            case 'COMPLETED':
                return 'Received'
            case 'CANCELED':
                return "CANCELED"
            case 'RECEIVED':
                return "FINISHED"
            default:
                break;
        }
    }

    function getRequirementsIcons(requirements = {}) {
        const icons = [];


        if (requirements.hasDocs) {
            icons.push(
                <Tooltip label="This pickup has documents/envelopes" placement='top' key="docs">
                    <span><FaEnvelope size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.hasSupplies) {
            icons.push(
                <Tooltip label="This pickup has supplies" placement='top' key="supplies">
                    <span><FaBox size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.requestPhoto) {
            icons.push(
                <Tooltip label="This pickup requires a photo" placement='top' key="photo">
                    <span><FaPhotoVideo size={15} /></span>
                </Tooltip>
            );
        }


        return icons.length > 0 ? (
            <span style={{ display: 'flex', gap: '15px', alignItems: 'center', padding: 5 }}>
                {icons}
            </span>
        ) : "N/A";
    }


    const handleUpdatePickup = (updatedPickup) => {
        setPickupDetail(updatedPickup);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to array
        const imageFiles = files.filter(file => file.type.startsWith('image/')); // Only keep image files
        setSelectedFiles(imageFiles);

        // Alert if some files are not images
        if (files.length !== imageFiles.length) {
            alert('Only image files are allowed.');
        }
    };

    // Handle file upload submission
    const handleFileUpload = async () => {
        if (selectedFiles.length === 0) {
            alert('No files selected');
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('documents', file);
        });
        formData.append('folder', 'couriers-attachements');

        try {

            const response = await uploadPickupAttachements(formData, selectedPickup._id)
            console.log(response)
            if (response.success) {
                alert('Files uploaded successfully');
                setSelectedFiles([]); // Clear selected files
                // onCloseViewModal(); // Optionally close the modal
            } else {
                alert(response.error);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('Error uploading files');
        }
    };


    const handleDeleteAttachment = async (attachmentId) => {
        if (!window.confirm('Are you sure you want to delete this attachment?')) {
            return;
        }
        try {
            const response = await deletePickupAttachements(attachmentId, selectedPickup._id);
            if (response.success) {
                // Directly update selectedPickup's attachments
                selectedPickup.attachments = selectedPickup.attachments.filter(
                    (attachment) => attachment._id !== attachmentId
                );

                // Optionally, if selectedPickup is in state, trigger a re-render
                setSelectedPickup({ ...selectedPickup });

                // alert("Attachment deleted successfully");
            } else {
                console.log(response.message || 'Failed to delete attachment');
            }
        } catch (error) {
            console.error('Error deleting attachment:', error);
            alert('Error deleting attachment');
        }
    };




    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                {loading ?
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                    :
                    <>
                        {error ? <AccessDenied msg={error} /> :


                            <Box>
                                <Heading>{pickupDetail?.pickupLocation?.name || pickupDetail?.dropOffLocation?.name}
                                    <Badge ml={5} p={1} colorScheme={"blue"}>
                                        {status(pickupDetail?.status)}
                                    </Badge>
                                </Heading>

                                <Flex direction={["column", "row"]} wrap="wrap" maxW={'100%'} gap={4} p={4}>
                                    {/* Left Column (25% width) */}
                                    <Box flex={["1 1 100%", "1 1 48%"]} maxHeight="550px" overflowY="auto" bg="gray.50" p={4} boxShadow="md">
                                        {/* Content for the left column goes here */}
                                        <VStack spacing={6} align="start" p={4}>
                                            {/* Timeline for pickup creation */}
                                            <HStack align="start" spacing={4}>
                                                {/* Timeline Marker */}
                                                <Flex direction="column" align="center">
                                                    <Circle size="10px" bg="blue.500" />
                                                    <Box h="100px" w="2px" bg="gray.300" />
                                                </Flex>

                                                {/* Timeline Content */}
                                                <Box>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {new Date(pickupDetail?.createdAt).toLocaleDateString() +
                                                            " - " +
                                                            new Date(pickupDetail?.createdAt).toLocaleTimeString()}
                                                    </Text>
                                                    <Text fontSize="lg" fontWeight="bold">Pickup Created</Text>
                                                    <Text fontSize="md" color="gray.600">
                                                        Pickup was{" "}
                                                        {pickupDetail?.routeId?.name ? (
                                                            <>
                                                                generated from route{" "}
                                                                <Popover>
                                                                    <PopoverTrigger>
                                                                        <Text
                                                                            as="span"
                                                                            fontWeight="bold"
                                                                            color="blue.500"
                                                                            cursor="pointer"
                                                                        >
                                                                            {pickupDetail?.routeId?.name}
                                                                        </Text>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton />
                                                                        <PopoverHeader fontWeight="bold" fontSize="lg">
                                                                            {pickupDetail?.routeId?.name}
                                                                        </PopoverHeader>
                                                                        <PopoverBody>
                                                                            <VStack align="start" spacing={2}>
                                                                                <Text fontSize="sm">
                                                                                    {pickupDetail?.routeId?.cities}
                                                                                </Text>
                                                                            </VStack>
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </>
                                                        ) : (
                                                            "added"
                                                        )}{" "}
                                                        By {pickupDetail?.createdBy?.firstName}
                                                    </Text>


                                                </Box>
                                            </HStack>

                                            {/* Scrollable Timeline */}
                                            <Box w="100%" pt={2}>
                                                {pickupDetail?.lastUpdated?.length > 0 &&
                                                    pickupDetail?.lastUpdated.map((log, index) => (
                                                        <>
                                                            <HStack align="start" spacing={4} mt={4} pb={3} key={index}>
                                                                {/* Timeline Marker */}
                                                                <Flex direction="column" align="center">
                                                                    <Circle size="10px" bg="blue.500" />
                                                                    {index < pickupDetail?.lastUpdated.length - 1 && <Box h="90px" w="2px" bg="gray.300" />}
                                                                </Flex>

                                                                {/* Timeline Content */}
                                                                <Box style={{ width: '100%' }}>
                                                                    <Text fontSize="sm" color="gray.500">
                                                                        {new Date(log.timestamp).toLocaleDateString() +
                                                                            " - " +
                                                                            new Date(log.timestamp).toLocaleTimeString()}
                                                                    </Text>
                                                                    <Text fontSize="lg" fontWeight="bold">{log.action}</Text>
                                                                    <Text fontSize="md" color="gray.600">
                                                                        {log.action === 'Pickup Requested' ? "Requested By " : "Updated by "}  {log.updatedBy?.firstName || "Unknown User"}
                                                                    </Text>
                                                                    <Box mt={2}>
                                                                        <SimpleGrid columns={3} spacing={4}>
                                                                            {log.fields.map((field, fieldIndex) => (
                                                                                noReturn.includes(field.field) ? null :
                                                                                    <Box key={fieldIndex} mb={2}>
                                                                                        <Text fontSize="sm" color="gray.600">
                                                                                            <strong>{humanReadableField(field.field)}</strong>:
                                                                                        </Text>
                                                                                        <Text fontSize="sm" color="gray.500">
                                                                                            Previously : <strong>{formatValue(field.previousValue)}</strong>
                                                                                        </Text>
                                                                                        <Text fontSize="sm" color="gray.500">
                                                                                            Changed To : <strong>{formatValue(field.newValue)}</strong>
                                                                                        </Text>
                                                                                    </Box>
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </Box>
                                                                </Box>
                                                            </HStack>
                                                            <Divider />
                                                        </>
                                                    ))}
                                            </Box>
                                        </VStack>
                                    </Box>


                                    {/* Right Column (75% width) */}
                                    <Box flex={["1 1 100%", "1 1 48%"]} p={4}>
                                        {/* Content for the right column goes here */}
                                        <HStack alignItems="flex-start" spacing={8}>
                                            {/* Pickup and Drop Details */}
                                            <Box borderRight={'1px solid #eef1f6'} pr={5} pb={5}>
                                                <Heading as="h2" size="lg" mb={4}>Pickup Details</Heading>
                                                <Text mb={3}>
                                                    <strong>Pickup</strong><br />
                                                    <span style={{ fontWeight: "500", fontSize: 15, marginRight: 5 }}>
                                                        {pickupDetail?.pickupLocation.name}
                                                    </span>
                                                    <small>
                                                        ({pickupDetail?.pickupLocation.address.city}) - <a
                                                            href={getGoogleMapsLink(pickupDetail?.pickupLocation.address)}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            View in map
                                                        </a>
                                                    </small>
                                                </Text>

                                                <Text>
                                                    <strong>Drop</strong><br />
                                                    <span style={{ fontWeight: "500", fontSize: 15 }}>
                                                        {pickupDetail?.dropOffLocation.name}
                                                    </span>
                                                    <small>
                                                        ({pickupDetail?.dropOffLocation.address.city})
                                                    </small>
                                                </Text>
                                            </Box>

                                            {/* Assigned Courier */}
                                            <Box>
                                                <Heading as="h3" size="lg" mb={4}>Assigned Courier</Heading>
                                                <Text>
                                                    <strong>{pickupDetail?.courier.name}</strong>
                                                </Text>
                                                <Text mt={5}>
                                                    <Badge mr={5} colorScheme={pickupDetail?.type === 'DropOffOnly' ? 'pink' : pickupDetail?.type === 'Supplies' ? 'purple' : "orange"}>
                                                        {pickupDetail?.type === 'DropOffOnly' ? "Drop Off" : pickupDetail?.type === 'Default' ? "Pickup" : pickupDetail?.type}
                                                    </Badge>
                                                    <Badge mr={5} colorScheme={pickupDetail?.routeId?.name ? 'red' : 'green'}>
                                                        {pickupDetail?.routeId?.name || "On Call"}
                                                    </Badge>
                                                    <Badge colorScheme={'purple'}>
                                                        Pickup Order #{pickupDetail?.pickupOrder}
                                                    </Badge>

                                                </Text>

                                            </Box>
                                        </HStack>


                                        <Divider />

                                        <HStack alignItems="flex-start" spacing={8}>
                                            {/* Pickup Requirements */}
                                            <Box pb={5} borderRight={'1px solid #eef1f6'} pr={5} >
                                                <Heading mt={5} as="h3" size="lg">Pickup Requirements</Heading>
                                                <Text mt={2}>
                                                    {getRequirementsIcons(pickupDetail?.requirements)}
                                                </Text>
                                            </Box>

                                            {/* Samples Count */}
                                            <Box pb={5}>
                                                <Heading mt={5} as="h3" size="lg">Samples Count</Heading>
                                                {pickupDetail.status === 'RECEIVED' ?
                                                    <Box pb={5}>
                                                        <Text mt={2}>
                                                            Receiver : <strong>{pickupDetail?.receivedBy?.firstName}</strong><br></br>
                                                            Picked Up Samples : <strong>{pickupDetail?.samplesCount}</strong><br></br>
                                                            Received Samples : <strong>{pickupDetail?.samplesReceived}</strong>
                                                        </Text>

                                                    </Box>
                                                    :
                                                    <Heading mt={5} as="h6" size="lg" fontWeight={'light'}>
                                                        {pickupDetail?.samplesCount || "Not Picked Up Yet"}
                                                    </Heading>
                                                }
                                            </Box>
                                        </HStack>

                                        <Divider />


                                        <HStack alignItems="flex-start" spacing={8}>
                                            <Box pb={5}>
                                                <Heading mt={5} as="h3" size="lg">
                                                    Attachments
                                                    {/* Icon for file selection */}
                                                    {pickupDetail?.status === 'PENDING' || pickupDetail?.status === 'PICKED_UP' &&
                                                        <IconButton
                                                            ml={4}
                                                            icon={<FaCloudUploadAlt />}
                                                            onClick={() => document.getElementById('file-input').click()} // Trigger file input click
                                                            aria-label="Add Files"
                                                        />
                                                    }
                                                </Heading>

                                                {/* Hidden file input */}
                                                <Input
                                                    id="file-input"
                                                    type="file"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleFileChange}
                                                    display="none" // Hide the input field
                                                />

                                                {/* Display number of files selected */}
                                                {selectedFiles.length > 0 && (
                                                    <>
                                                        <Text mt={2} fontSize="md">
                                                            {selectedFiles.length} file(s) selected
                                                        </Text>

                                                        <Button
                                                            mt={4}
                                                            colorScheme="blue"
                                                            onClick={() => handleFileUpload(selectedFiles)}
                                                            isDisabled={selectedFiles.length === 0}
                                                        >
                                                            Submit Files
                                                        </Button>
                                                    </>
                                                )}
                                                {/* Display attachments */}
                                                <SimpleGrid columns={[2, 3, 8]} spacing={6} mt={6}>
                                                    {pickupDetail?.attachments?.length > 0 && (
                                                        pickupDetail?.attachments
                                                            .filter((attachment) => !attachment.deletedAt) // Filter out deleted attachments
                                                            .map((attachment, index) => (
                                                                <Box
                                                                    key={index}
                                                                    position="relative"
                                                                    textAlign="center"
                                                                    _hover={{ '.delete-button': { opacity: 1 } }} // Show the delete button on hover
                                                                >
                                                                    {/* Image */}
                                                                    <ImageLightbox
                                                                        imageUrl={"http://206.189.202.224:5000" + attachment.fileUrl}
                                                                        altText="Pickup Attachment"
                                                                        boxSize="64px"
                                                                        borderRadius="md"
                                                                        boxShadow="md"
                                                                        cursor="pointer"
                                                                    />

                                                                    {/* Delete Button */}
                                                                    {pickupDetail?.status === 'PENDING' || pickupDetail?.status === 'PICKED_UP' &&
                                                                        <Button
                                                                            className="delete-button"
                                                                            position="absolute"
                                                                            top="10%"
                                                                            left="0%"
                                                                            transform="translate(-50%, -50%)"
                                                                            opacity={0} // Hidden by default
                                                                            transition="opacity 0.2s ease"
                                                                            colorScheme="red"
                                                                            size="xs"
                                                                            onClick={() => handleDeleteAttachment(attachment._id)}
                                                                        >
                                                                            X
                                                                        </Button>
                                                                    }
                                                                </Box>
                                                            ))
                                                    )}
                                                </SimpleGrid>




                                            </Box>
                                        </HStack>

                                    </Box>
                                </Flex>
                                <Divider mb={8} mt={4} />
                                <PickupNotes pickupId={pickupDetail?._id} existingNotes={pickupDetail?.notes} addNote={pickupDetail?.status === 'PENDING' || pickupDetail?.status === 'PICKED_UP'} />

                            </Box>
                        }


                        {selectedPickup &&
                            <>




                                <EditPickupModal
                                    isOpen={isModalOpen}
                                    onClose={() => {
                                        setSelectedPickup(null)
                                        setIsModalOpen(false)
                                    }}
                                    pickup={selectedPickup}
                                    onUpdatePickup={handleUpdatePickup}
                                />

                            </>
                        }
                    </>
                }




            </VStack >
        </Box >
    );
};

export default ViewPickup;
