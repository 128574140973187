import React, { useState, useEffect } from 'react';
import { fetchAllOrders, fetchBusinesses, fetchFacilitiesBySearch, fetchSupplies, fetchSuppliesBySearch, getAllFacilities, searchOrders } from '../../api';
import {
    Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, HStack,
    VStack, Center, FormControl, FormLabel, Input, Stack, Badge,
    Divider
} from '@chakra-ui/react';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import TitleHeader from '../../components/TitleHeader';
import { hasPermission, trimText } from "../../Utils";
import PopoverId from '../../components/PopoverId';
import AsyncSelect from 'react-select/async';
import { ViewIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { FaSearch, FaTimes } from 'react-icons/fa';
const ClientsFinishedOrders = () => {
    const navigate = useNavigate()

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const [currentPageOrders, setCurrentPageOrders] = useState(1);
    const [totalPagesOrders, setTotalPagesOrders] = useState(1);
    const itemsPerPageOrders = 25; // Adjust per your requirement
    const [searchParams, setSearchParams] = useState({
        clientId: '',
        startDate: '',
        endDate: '',
        itemId: ''
    });




    const onInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };


    useEffect(() => {
        // Load orders once when the component mounts
        loadOrders();
    }, []);

    const loadOrders = async (page = 1) => {
        setLoading(true);
        try {
            const result = await fetchAllOrders(page, itemsPerPageOrders, true);
            if (result.success) {
                // Store all orders in state
                setOrders(result.data.orders);
                setCurrentPageOrders(result.data.currentPage);
                setTotalPagesOrders(result.data.totalPages);

            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to load orders');
        }
        setLoading(false);
    };

    // Pagination controls
    const handlePageChangeOrders = (newPage) => {
        loadOrders(newPage); // Include searchParams in pagination
    };

    const clearSearch = () => {
        setSearchParams({
            clientId: '',
            startDate: '',
            endDate: '',
            itemId: ''
        })
        setIsSearching(false)
        loadOrders()
    }

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error || !hasPermission("viewFinishedOrders")) {
        return <AccessDenied msg={error || "Access Denied."} />;
    }


    const performSearch = async () => {
        setLoading(true);
        try {
            // Call searchOrders with search parameters, including pagination settings
            const result = await searchOrders({
                clientId: searchParams.clientId,
                startDate: searchParams.startDate,
                endDate: searchParams.endDate,
                itemId: searchParams.itemId,
                deleted: searchParams.deleted,
                finished: true,          // Assuming we are only fetching finished orders
                page: 1,                  // Start on the first page
                limit: itemsPerPageOrders // Set items per page for pagination
            });

            if (result.success) {
                // Update state with search results
                setOrders(result.data.orders);
                setCurrentPageOrders(result.data.currentPage);
                setTotalPagesOrders(result.data.totalPages);
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError('Failed to perform search');
        }
        setLoading(false);
    };

    // Resets to the first page and initiates a search
    const handleSearch = () => {
        setCurrentPageOrders(1);  // Reset to the first page
        performSearch();           // Call the search function
        setIsSearching(true)
    };



    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Finished Orders"
                    buttonText="New Orders"
                    onClick={() => { navigate('/create-order') }}
                    secondButton={hasPermission("viewOrders")}
                    secondButtonText='Current Orders'
                    secondButtonOnClick={() => { navigate('/clients-orders') }}
                />
                <Box mb={4} p={4} bg="gray.50" borderRadius="md" boxShadow="sm">
                    <HStack spacing={4}>
                        <FormControl>
                            <FormLabel>Client</FormLabel>

                            <AsyncSelect
                                cacheOptions
                                loadOptions={fetchFacilitiesBySearch}
                                defaultOptions
                                onChange={(option) => setSearchParams(prev => ({
                                    ...prev,
                                    clientId: option ? option.value : '',    // Store the value (ID)
                                    clientName: option ? option.label : ''   // Optionally store the label to show after search
                                }))}
                                value={searchParams.clientId ? { value: searchParams.clientId, label: searchParams.clientName } : null} // Persist selection
                                placeholder="Search and select a facility"
                                closeMenuOnSelect={true}
                                isClearable
                            />

                        </FormControl>

                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input
                                name="startDate"
                                type="date"
                                value={searchParams.startDate || ''}
                                onChange={onInputChange}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>End Date</FormLabel>
                            <Input
                                name="endDate"
                                type="date"
                                value={searchParams.endDate || ''}
                                onChange={onInputChange}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Item ID</FormLabel>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={fetchSuppliesBySearch}
                                defaultOptions
                                onChange={(option) => setSearchParams(prev => ({
                                    ...prev,
                                    itemId: option ? option.value : '',       // Store the item ID
                                    itemName: option ? option.label : ''      // Optionally store the item name to display after search
                                }))}
                                value={searchParams.itemId ? { value: searchParams.itemId, label: searchParams.itemName } : null} // Persist selection
                                placeholder="Search and select an item"
                                closeMenuOnSelect={true}
                                isClearable
                            />

                        </FormControl>

                        <Button mt={7} colorScheme="blue" size={"sm"} onClick={handleSearch}>
                            <FaSearch boxSize={30} />
                        </Button>
                        {isSearching &&
                            <Button mt={7} colorScheme="red" size={"sm"} onClick={clearSearch}>
                                <FaTimes boxSize={30} />
                            </Button>
                        }

                    </HStack>
                </Box>

                <Divider />

                {/* Orders Table */}
                {orders.length === 0 ? (
                    <AccessDenied msg={"Nothing here yet !"} icon="info" />
                ) : (
                    <>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th maxW={1}>#</Th>
                                    <Th>Client Name</Th>
                                    <Th>RI</Th>
                                    <Th>Address</Th>
                                    <Th>Order Status</Th>
                                    <Th>Ordered By</Th>
                                    <Th>Order Dates</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {orders.map((order, index) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={order._id}>
                                        <Td maxW={1}><PopoverId place='Clients Orders' index={index} content={order._id} /></Td>

                                        <Td>
                                            <Box as="span" ml={2} color="blue.800" fontWeight={'bold'}>
                                                {order.business.name}
                                            </Box>
                                        </Td>
                                        <Td>{order?.requestedItems?.length || "N/A"}</Td>

                                        <Td>
                                            <PopoverId id={false} place='Client Orders' header='Client Address' index={index} content={(order?.clientAddress?.address || "N/A") + ", " + (order?.clientAddress?.city || "N/A") + ", " + (order?.clientAddress?.state || "N/A") + ", " + (order?.clientAddress?.zip || "N/A")} />
                                            {trimText((order?.clientAddress?.address || "N/A"), 37)}
                                        </Td>

                                        <Td>
                                            <VStack align="start" spacing={1}>
                                                <Badge colorScheme={order.finished ? 'green' : 'red'}>
                                                    {order.finished ? 'Finished' : 'Not Finished'}
                                                </Badge>
                                            </VStack>
                                        </Td>
                                        <Td>{order.orderedBy}</Td>

                                        <Td>Created At: {new Date(order.createdAt).toLocaleDateString()} <br /> Finished At: {new Date(order.updatedAt).toLocaleDateString()}</Td>
                                        <Td>
                                            <HStack spacing={4}>

                                                {hasPermission('viewOrder') &&

                                                    // <Button size="sm" onClick={() => navigate(`/order-details/${order._id}`)} colorScheme="blue">
                                                    <Button size="sm" onClick={() => window.open(`/order-details/${order._id}/?hide_side=true`, '_blank')} colorScheme="blue">
                                                        <ViewIcon mr={2} /> View
                                                    </Button>
                                                }

                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        <Pagination
                            currentPage={currentPageOrders}
                            totalPages={totalPagesOrders}
                            handlePageChange={handlePageChangeOrders}
                        />
                    </>

                )}


            </VStack>
        </Box>
    );
};

export default ClientsFinishedOrders;
