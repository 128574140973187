import React, { useEffect, useState } from 'react';
import { getAllUsers, createUser, updateUser, deleteUser, fetchLabLocations, getAllRoles, fetchWarehouses } from '../../api';
import { Box, Button, Center, Heading, HStack, Table, Tbody, Td, Th, Thead, Tr, VStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormLabel, FormControl, Text, Alert, AlertIcon, Flex, Divider, Badge, Checkbox } from '@chakra-ui/react';
import ReactSelect from 'react-select'; // Alias React-Select
import { Select as ChakraSelect } from '@chakra-ui/react'; // Chakra UI's Select
import { CloseIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import AccessDenied from '../../components/AccessDenied';
import { alert, generateUsername, hasPermission } from '../../Utils';
import TitleHeader from '../../components/TitleHeader';
import PopoverId from '../../components/PopoverId';

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);  // User for View/Edit/Delete
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', role: '', active: true, labLocation: [], warehouseIds: [], allWarehousesAccess: false, allLabLocationsAccess: false });  // New User Data
    const [warehouses, setWarehouses] = useState([]);
    const [labLocations, setLabLocations] = useState([]);
    const [roles, setRoles] = useState([]);

    const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();



    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: closeCreateModal } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: closeEditModal } = useDisclosure();

    const onCreateClose = () => {
        setErrorModal(null);  // Clear error modal on close
        closeCreateModal();    // Call the original onClose handler
    };

    const onEditClose = () => {
        setErrorModal(null);  // Clear error modal on close
        closeEditModal();     // Call the original onClose handler
    };




    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const result = await getAllUsers();
            if (result.success) {
                setUsers(result.data);
            } else {
                setError(result.error);
            }
        } catch (err) {
            setError('Error fetching users', err);
        } finally {
            setLoading(false);
        }
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        onEditOpen();
    };

    const handleViewUser = (user) => {
        setSelectedUser(user);
        onViewOpen();
    };

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        onDeleteOpen();
    };

    const handleCreateUser = () => {
        setNewUser({ firstName: '', lastName: '', email: '', role: '', password: '', active: true });
        onCreateOpen();
    };

    // Handle user creation
    const handleSubmitCreate = async () => {
        setErrorModal(null);

        // Validation for required fields in create
        if (!newUser.firstName || !newUser.lastName || !newUser.email || !newUser.role || !newUser.password) {
            setErrorModal("All fields are required.");
            return;
        }

        try {
            // Generate the username from first and last name
            const generatedUsername = generateUsername(newUser.firstName, newUser.lastName);
            const theUser = { ...newUser, username: generatedUsername };

            // Call the API to create the user
            const response = await createUser(theUser);

            if (response.success) {
                fetchUsers();  // Refresh users list
                onCreateClose();  // Close modal
                alert('User Created', 'success', "User created successfully", "Okay!");
                setErrorModal(null);  // Clear error modal
            } else {
                setErrorModal(response.error);  // Handle error response
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Handle user update
    const handleSubmitEdit = async () => {
        setErrorModal(null);

        // Validation for required fields except password in edit
        if (!selectedUser.firstName || !selectedUser.lastName || !selectedUser.email || !selectedUser.role) {
            setErrorModal("All fields are required, except password unless you want to change it.");
            return;
        }

        try {
            console.log(selectedUser)
            // Call the API to update the user
            const response = await updateUser(selectedUser._id, selectedUser);

            if (response.success) {
                fetchUsers();  // Refresh users list
                onEditClose();  // Close modal
                alert('User Updated', 'success', "User updated successfully", "Okay!");
                setErrorModal(null);  // Clear error modal
            } else {
                setErrorModal(response.error);  // Handle error response
            }
        } catch (err) {
            console.error(err);
        }
    };


    const handleSubmitDelete = async () => {
        try {
            await deleteUser(selectedUser._id);
            fetchUsers();
            onDeleteClose();
        } catch (err) {
            console.error(err);
        }
    };


    useEffect(() => {
        fetchWarehousesData();
        getLabLocations();
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const result = await getAllRoles();  // Assume this function fetches roles
            if (result.success) {
                setRoles(result.data);
            } else {
                console.log(result)
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const getLabLocations = async () => {
        const result = await fetchLabLocations();
        if (result.success) {
            const labsOptions = result.data.map(labs => ({
                value: labs._id,
                label: labs.name
            }));
            setLabLocations(labsOptions);
        } else {
            console.error(result.error);
        }
    };


    const fetchWarehousesData = async () => {
        const result = await fetchWarehouses(true);
        if (result.success) {
            // Transform warehouses data to React-Select format
            const warehouseOptions = result.data.map(warehouse => ({
                value: warehouse._id,
                label: warehouse.name
            }));
            setWarehouses(warehouseOptions); // Store options formatted for React-Select
        } else {
            console.error(result.error);
        }
    };



    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">

                {!error ?
                    <>
                        <TitleHeader onClick={hasPermission('createUser') ? handleCreateUser : null} buttonText={hasPermission('createUser') ? "New User" : null} title={"Users Management"} />

                        <Divider />

                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th width={"60%"}>Name</Th>
                                    <Th>Email</Th>
                                    <Th>Active</Th>
                                    <Th>Role</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {users.map((user, index) => (
                                    <Tr key={user?._id} _hover={{ bg: "gray.100" }}>
                                        <Td><PopoverId place={`Users List`} index={index} content={user._id} /></Td>

                                        <Td>{user?.firstName + " " + user?.lastName}</Td>
                                        <Td>{user?.email}</Td>
                                        <Td><Badge colorScheme={user?.active ? "green" : "red"}>{user?.active ? "Active" : "Not Active"}</Badge></Td>
                                        <Td>{user?.role?.name}</Td>
                                        <Td>
                                            <HStack spacing={4}>
                                                {hasPermission('viewUser') &&
                                                    <Button size="sm" colorScheme="blue" onClick={() => handleViewUser(user)}>
                                                        <ViewIcon mr={2} /> View
                                                    </Button>
                                                }
                                                {hasPermission('updateUser') &&
                                                    <Button size="sm" colorScheme="green" onClick={() => handleEditUser(user)}>
                                                        <EditIcon mr={2} /> Edit
                                                    </Button>
                                                }
                                                {hasPermission('deleteUser') &&
                                                    <Button size="sm" colorScheme="red" onClick={() => handleDeleteUser(user)}>
                                                        <CloseIcon mr={2} /> Delete
                                                    </Button>
                                                }
                                            </HStack>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>

                        {/* View User Modal */}
                        <Modal isOpen={isViewOpen} onClose={onViewClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>View User</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    {selectedUser && (
                                        <>
                                            <Text><strong>Name:</strong> {selectedUser.firstName} {selectedUser.lastName}</Text>
                                            <Text><strong>Email:</strong> {selectedUser.email}</Text>
                                            <Text><strong>Active:</strong> {selectedUser.active ? "Active" : "Not Active"}</Text>
                                            <Text><strong>Role:</strong> {selectedUser.role?.name}</Text>
                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={onViewClose}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        {/* Edit User Modal */}
                        <Modal isOpen={isEditOpen} onClose={onEditClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Edit User</ModalHeader>
                                <ModalCloseButton />
                                {errorModal &&
                                    <Alert status='error'>
                                        <AlertIcon />
                                        {errorModal}
                                    </Alert>

                                }
                                <ModalBody>
                                    {selectedUser && (
                                        <>
                                            <FormControl>
                                                <FormLabel>First Name</FormLabel>
                                                <Input value={selectedUser.firstName} onChange={(e) => setSelectedUser({ ...selectedUser, firstName: e.target.value })} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Last Name</FormLabel>
                                                <Input value={selectedUser.lastName} onChange={(e) => setSelectedUser({ ...selectedUser, lastName: e.target.value })} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Email</FormLabel>
                                                <Input value={selectedUser.email} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Password (leave blank if unchanged)</FormLabel>
                                                <Input type="password" onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Role</FormLabel>
                                                <ChakraSelect placeholder="Select Role" value={selectedUser.role._id} onChange={(e) => setSelectedUser({ ...selectedUser, role: { ...selectedUser.role, _id: e.target.value } })}>
                                                    {roles.map((role) => (
                                                        <option key={role._id} value={role._id}>
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </ChakraSelect>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Active</FormLabel>
                                                <ChakraSelect value={selectedUser.active} onChange={(e) => setSelectedUser({ ...selectedUser, active: e.target.value === 'true' })}>
                                                    <option value="true">Active</option>
                                                    <option value="false">Inactive</option>
                                                </ChakraSelect>
                                            </FormControl>


                                            <FormControl mt={4} mb={4}>
                                                <Checkbox
                                                    isChecked={selectedUser.allWarehousesAccess}
                                                    onChange={(e) => setSelectedUser({ ...selectedUser, allWarehousesAccess: e.target.checked })}
                                                >
                                                    Access All Warehouses and orders
                                                </Checkbox>
                                            </FormControl>

                                            {/* Conditionally render ReactSelect for Warehouses */}
                                            {!selectedUser.allWarehousesAccess && (
                                                <FormControl>
                                                    <FormLabel>Warehouses</FormLabel>
                                                    {warehouses.length > 0 && (
                                                        <ReactSelect
                                                            isMulti
                                                            options={warehouses}
                                                            value={warehouses.filter(option => selectedUser?.warehouseIds?.includes(option.value))}
                                                            onChange={(selectedOptions) =>
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    warehouseIds: selectedOptions.map(option => option.value)
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormControl>
                                            )}

                                            <FormControl mt={4} mb={4}>
                                                <Checkbox
                                                    isChecked={selectedUser.allLabLocationsAccess}
                                                    onChange={(e) => setSelectedUser({ ...selectedUser, allLabLocationsAccess: e.target.checked })}
                                                >
                                                    Access All Lab Locations
                                                </Checkbox>
                                            </FormControl>

                                            {/* Conditionally render ReactSelect for Lab Locations */}
                                            {!selectedUser.allLabLocationsAccess && (
                                                <FormControl>
                                                    <FormLabel>Lab Locations</FormLabel>
                                                    {labLocations.length > 0 && (
                                                        <ReactSelect
                                                            isMulti
                                                            options={labLocations}
                                                            value={labLocations.filter(option => selectedUser?.labLocation?.includes(option.value))}
                                                            onChange={(selectedOptions) =>
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    labLocation: selectedOptions.map(option => option.value)
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </FormControl>
                                            )}

                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="green" onClick={handleSubmitEdit}>Save</Button>
                                    <Button ml={3} onClick={onEditClose}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>


                        {/* Create User Modal */}
                        <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Create New User</ModalHeader>
                                <ModalCloseButton />
                                {errorModal &&
                                    <Alert status='error'>
                                        <AlertIcon />
                                        {errorModal}
                                    </Alert>

                                }
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>First Name</FormLabel>
                                        <Input value={newUser.firstName} onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Last Name</FormLabel>
                                        <Input value={newUser.lastName} onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Password</FormLabel>
                                        <Input type="password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Role</FormLabel>
                                        <ChakraSelect placeholder="Select Role" value={newUser.role} onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}>
                                            {roles.map((role) => (
                                                <option key={role._id} value={role._id}>
                                                    {role.name}
                                                </option>
                                            ))}
                                        </ChakraSelect>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Active</FormLabel>
                                        <ChakraSelect value={newUser.active} onChange={(e) => setNewUser({ ...newUser, active: e.target.value === 'true' })}>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </ChakraSelect>
                                    </FormControl>


                                    <FormControl mt={4} mb={4}>
                                        <Checkbox
                                            isChecked={newUser.allWarehousesAccess}
                                            onChange={(e) => setNewUser({ ...newUser, allWarehousesAccess: e.target.checked })}
                                        >
                                            Access All Warehouses and orders
                                        </Checkbox>
                                    </FormControl>

                                    {/* Conditionally render ReactSelect for Warehouses */}
                                    {!newUser.allWarehousesAccess && (
                                        <FormControl>
                                            <FormLabel>Warehouses</FormLabel>
                                            {warehouses.length > 0 && (
                                                <ReactSelect
                                                    isMulti
                                                    options={warehouses}
                                                    value={warehouses.filter(option => newUser?.warehouseIds?.includes(option.value))}
                                                    onChange={(selectedOptions) =>
                                                        setNewUser({
                                                            ...newUser,
                                                            warehouseIds: selectedOptions.map(option => option.value)
                                                        })
                                                    }
                                                />
                                            )}
                                        </FormControl>
                                    )}

                                    <FormControl mt={4} mb={4}>
                                        <Checkbox
                                            isChecked={newUser.allLabLocationsAccess}
                                            onChange={(e) => setNewUser({ ...newUser, allLabLocationsAccess: e.target.checked })}
                                        >
                                            Access All Lab Locations
                                        </Checkbox>
                                    </FormControl>

                                    {/* Conditionally render ReactSelect for Lab Locations */}
                                    {!newUser.allLabLocationsAccess && (
                                        <FormControl>
                                            <FormLabel>Lab Locations</FormLabel>
                                            {labLocations.length > 0 && (
                                                <ReactSelect
                                                    isMulti
                                                    options={labLocations}
                                                    value={labLocations.filter(option => newUser?.labLocation?.includes(option.value))}
                                                    onChange={(selectedOptions) =>
                                                        setNewUser({
                                                            ...newUser,
                                                            labLocation: selectedOptions.map(option => option.value)
                                                        })
                                                    }
                                                />
                                            )}
                                        </FormControl>
                                    )}


                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleSubmitCreate}>Create</Button>
                                    <Button ml={3} onClick={onCreateClose}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        {/* Delete User Confirmation Modal */}
                        <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Delete User</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    Are you sure you want to delete {selectedUser?.firstName} {selectedUser?.lastName}?
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme="red" onClick={handleSubmitDelete}>Yes, Delete</Button>
                                    <Button ml={3} onClick={onDeleteClose}>No</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </>
                    :
                    <AccessDenied msg={error} />
                }
            </VStack>



        </Box>
    );
};

export default UsersList;
