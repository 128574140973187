// EditTemplate.js
import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Textarea, Button, VStack, Divider, Heading, useToast } from '@chakra-ui/react';
import { fetchEmailTemplateById, updateEmailTemplate } from '../../api';
import TitleHeader from '../../components/TitleHeader';
import { useParams, useNavigate } from 'react-router-dom';

const EditTemplate = () => {
    const { id } = useParams(); // Get the template ID from the route params
    // const navigate = useNavigate();
    const toast = useToast();

    const [templateData, setTemplateData] = useState({
        template_name: '',
        body: '' // HTML content will be stored here
    });

    useEffect(() => {
        const loadTemplate = async () => {
            try {
                const result = await fetchEmailTemplateById(id);
                if (result.success) {
                    setTemplateData(result.data);
                } else {
                    toast({
                        title: "Failed to load template.",
                        status: "error",
                        duration: 3000,
                        position: 'top-right',
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error loading template.",
                    status: "error",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
        };
        loadTemplate();
    }, [id, toast]);

    const handleUpdateTemplate = async () => {
        try {
            const result = await updateEmailTemplate(id, templateData);
            if (result.success) {
                toast({
                    title: "Email Template updated successfully.",
                    status: "success",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
                // navigate('/email-templates');
            } else {
                toast({
                    title: result.error,
                    status: "error",
                    duration: 3000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error updating email template.",
                status: "error",
                duration: 3000,
                position: 'top-right',
                isClosable: true,
            });
        }
    };

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Edit Email Template" />

                <FormControl mb="4">
                    <FormLabel>Template Name</FormLabel>
                    <Input
                        value={templateData.template_name}
                        onChange={(e) => setTemplateData({ ...templateData, template_name: e.target.value })}
                        placeholder="Enter a name for this template"
                    />
                </FormControl>

                <FormControl mb="4">
                    <FormLabel>Body (HTML Content)</FormLabel>
                    <Textarea
                        value={templateData.body}
                        onChange={(e) => setTemplateData({ ...templateData, body: e.target.value })}
                        placeholder="Enter HTML content for the email body"
                        rows={10}
                        resize="vertical"
                        bg="gray.100"
                        border="1px solid"
                        borderColor="gray.300"
                        fontFamily="monospace" // Optional: Adds a code-like feel to the textarea
                    />
                </FormControl>

                <Divider my={4} />

                <Heading size="md" mb={2}>Preview</Heading>
                <Box
                    p={4}
                    bg="gray.50"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="md"
                    dangerouslySetInnerHTML={{ __html: templateData.body }}
                />

                <Button colorScheme="blue" onClick={handleUpdateTemplate}>
                    Save Changes
                </Button>
            </VStack>
        </Box>
    );
};

export default EditTemplate;
