import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    VStack,
    Input,
    FormControl,
    FormLabel,
    Select,
    Checkbox,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { fetchLabLocations, fetchSalesReps, fetchZipCode } from '../../api'; // Fetch labs and sales reps API calls

const UpdateFacilityModal = ({ isOpen, onClose, facility, onSubmit, loadingSubmit = false }) => {
    const [labOptions, setLabOptions] = useState([]);
    const [salesRepOptions, setSalesRepOptions] = useState([]);
    const [selectedLab, setSelectedLab] = useState('');
    const [selectedSalesRep, setSelectedSalesRep] = useState('');
    const [loadingSr, setLoadingSr] = useState(false);
    const [loadingLb, setLoadingLb] = useState(false);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        clientNumber: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: 'CA',
        zip: '',
        contactPerson: '',
        contactPhone: '',
        contactEmail: '',
        hasLockbox: false,
        image: null,
    });

    useEffect(() => {
        if (isOpen && facility) {
            setFormData({
                name: facility.name,
                clientNumber: facility.clientNumber,
                address1: facility.address.address1,
                address2: facility.address.address2,
                city: facility.address.city,
                state: facility.address.state || 'CA',
                zip: facility.address.zip,
                contactPerson: facility.contact.person,
                contactPhone: facility.contact.phone,
                contactEmail: facility.contact.emailAddress,
                hasLockbox: facility.hasLockbox,
                image: facility.image
            });
            setSelectedLab(facility?.lab?._id || '');
            setSelectedSalesRep(facility?.salesRep?._id || '');
            fetchLabsAndSalesReps();
        }
    }, [isOpen, facility]);

    const fetchLabsAndSalesReps = async () => {
        try {
            setLoadingLb(true);
            setLoadingSr(true);
            const sr = await fetchSalesReps();
            const lb = await fetchLabLocations();

            setLabOptions(lb.data);
            setSalesRepOptions(sr.data);
        } catch (error) {
            console.error('Error fetching labs or sales reps:', error);
        } finally {
            setLoadingLb(false);
            setLoadingSr(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (name === 'zip' && value.length === 5) {
            try {
                const result = await fetchZipCode(value);
                if (result.data) {
                    setFormData((prevData) => ({
                        ...prevData,
                        city: result.data,
                    }));
                }
            } catch (error) {
                console.error('Error fetching city for zip code:', error);
            }
        }
    };

    const handleSubmit = () => {

        // Validation checks

        if (!formData.clientNumber) {
            setError("Client Number is required");
            return;
        }
        if (!formData.name) {
            setError("Facility Name is required");
            return;
        }
        if (!selectedLab) {
            setError("Select Lab");
            return;
        }
        if (!formData.address1) {
            setError("Address 1 is required");
            return;
        }
        if (!formData.city) {
            setError("City is required");
            return;
        }
        if (!formData.state) {
            setError("State is required");
            return;
        }
        if (!formData.zip) {
            setError("ZIP code is required");
            return;
        }
        if (!selectedSalesRep) {
            setError("Select Sales Rep");
            return;
        }

        // If all validations pass, clear error and submit
        setError(null);


        const updatedFacilityData = {
            ...formData,
            lab: selectedLab, // Include lab from dropdown
            salesRep: selectedSalesRep // Include sales rep from dropdown
        };
        onSubmit(updatedFacilityData); // Pass updated form data to the parent component or API call
    };



    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xla"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Facility</ModalHeader>
                <ModalCloseButton />
                {error &&
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                }
                <ModalBody>
                    <VStack spacing={4}>
                        <HStack spacing={4} width="full">
                            <FormControl isRequired>
                                <FormLabel>Client Number</FormLabel>
                                <Input
                                    name="clientNumber"
                                    type="number"
                                    value={formData.clientNumber}
                                    onChange={handleChange}
                                    placeholder="Enter Client Number"
                                    min="0"
                                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                    required
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Facility Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Facility Name"
                                    required
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Lab</FormLabel>
                                {loadingLb ? <Spinner /> : (
                                    <Select
                                        placeholder="Select Lab"
                                        value={selectedLab}
                                        onChange={(e) => setSelectedLab(e.target.value)}
                                    >
                                        {labOptions?.map((lab) => (
                                            <option key={lab._id} value={lab._id}>
                                                {lab.name}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            </FormControl>
                        </HStack>

                        <HStack spacing={4} width="full" mt={4}>
                            <FormControl isRequired>
                                <FormLabel>Address 1</FormLabel>
                                <Input
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleChange}
                                    placeholder="Enter Address 1"
                                    required
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Address 2</FormLabel>
                                <Input
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleChange}
                                    placeholder="Enter Address 2"
                                />
                            </FormControl>
                        </HStack>

                        <HStack spacing={4} width="full">
                            <FormControl isReadOnly>
                                <FormLabel>City</FormLabel>
                                <Input
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="Enter City"
                                    required
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>State</FormLabel>
                                <Input
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    placeholder="Enter State"
                                    required
                                />
                            </FormControl>

                            <FormControl isRequired mt={6}>
                                <FormLabel>ZIP</FormLabel>
                                <Input
                                    name="zip"
                                    value={formData.zip}
                                    onChange={handleChange}
                                    placeholder="Enter ZIP"
                                    min="0"
                                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                    type="number"
                                    required
                                />
                            </FormControl>
                        </HStack>

                        <HStack spacing={4} width="full">
                            <FormControl>
                                <FormLabel>Contact Person</FormLabel>
                                <Input
                                    name="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleChange}
                                    placeholder="Enter Contact Person"
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Contact Phone</FormLabel>
                                <Input
                                    name="contactPhone"
                                    value={formData.contactPhone}
                                    onChange={handleChange}
                                    placeholder="Enter Contact Phone"
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Contact Email</FormLabel>
                                <Input
                                    name="contactEmail"
                                    value={formData.contactEmail}
                                    onChange={handleChange}
                                    placeholder="Enter Contact Email"
                                />
                            </FormControl>
                        </HStack>

                        <HStack spacing={4} width="full" mt={4}>
                            <FormControl isRequired>
                                <FormLabel>Sales Rep</FormLabel>
                                {loadingSr ? <Spinner /> : (
                                    <Select
                                        placeholder="Select Sales Rep"
                                        value={selectedSalesRep}
                                        onChange={(e) => setSelectedSalesRep(e.target.value)}
                                        required
                                    >
                                        {salesRepOptions?.map((rep) => (
                                            <option key={rep._id} value={rep._id}>
                                                {rep.name}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            </FormControl>

                            <FormControl mt={7} display="flex" alignItems="center">
                                <Checkbox
                                    name="hasLockbox"
                                    isChecked={formData.hasLockbox}
                                    onChange={handleChange}
                                >
                                    This facility has the Lab Lockbox
                                </Checkbox>
                            </FormControl>
                        </HStack>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button isLoading={loadingSubmit} colorScheme="blue" mr={3} onClick={handleSubmit}>
                        Update Facility
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UpdateFacilityModal;
