import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    VStack,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td
} from '@chakra-ui/react';
import { fetchFacilitiesBySalesRep } from '../../api'; // API call to fetch facilities by lab

const ViewSalesRepFacilitiesModal = ({ isOpen, onClose, salesRep }) => {
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (isOpen && salesRep?._id) {
            fetchFacilities();
        }
    }, [isOpen, salesRep, page]);

    const fetchFacilities = async () => {
        try {
            setLoading(true);
            const result = await fetchFacilitiesBySalesRep(salesRep._id, page, 50);
            if (result.success) {
                setFacilities(result.data.facilities);
                setTotalPages(result.data.totalPages);
            }
        } catch (error) {
            console.error('Error fetching facilities by salesRep:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => setPage(newPage);

    return (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size="xla">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`Facilities for ${salesRep?.name}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loading ? (
                        <VStack>
                            <Spinner />
                            <p>Loading facilities...</p>
                        </VStack>
                    ) : facilities.length === 0 ? (
                        <p>No facilities found for this Sales Rep.</p>
                    ) : (
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Facility Name</Th>
                                    <Th>Client Number</Th>
                                    <Th>City</Th>
                                    <Th>Sales Rep</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {facilities.map((facility) => (
                                    <Tr _hover={{ bg: "gray.100" }} key={facility._id}>
                                        <Td>{facility.name}</Td>
                                        <Td>{facility.clientNumber}</Td>
                                        <Td>{facility.address.city}</Td>
                                        <Td>{facility.salesRep ? facility.salesRep.name : 'N/A'}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
                </ModalBody>


                <HStack mt={4} justify="center">
                    <Button
                        onClick={() => handlePageChange(page - 1)}
                        isDisabled={page == 1}
                    >
                        &laquo; Previous
                    </Button>

                    {/* First page */}
                    <Button
                        onClick={() => handlePageChange(1)}
                        colorScheme={page == 1 ? 'teal' : 'gray'}
                        isDisabled={page == 1}
                    >
                        1
                    </Button>

                    {/* Ellipses before current range */}
                    {page > 3 && <Button isDisabled>...</Button>}

                    {/* Middle pages (show currentPage, currentPage-1, and currentPage+1) */}
                    {Array.from({ length: 3 }, (_, i) => page - 1 + i)
                        .filter(p => p > 1 && p < totalPages)
                        .map((p) => (
                            <Button
                                key={p}
                                onClick={() => handlePageChange(p)}
                                colorScheme={page == p ? 'teal' : 'gray'}
                                isDisabled={page == p}
                            >
                                {p}
                            </Button>
                        ))}

                    {/* Ellipses after current range */}
                    {page < totalPages - 2 && <Button isDisabled>...</Button>}

                    {/* Last page */}
                    {totalPages > 1 && (
                        <Button
                            onClick={() => handlePageChange(totalPages)}
                            colorScheme={page == totalPages ? 'teal' : 'gray'}
                            isDisabled={page == totalPages}
                        >
                            {totalPages}
                        </Button>
                    )}

                    <Button
                        onClick={() => handlePageChange(page + 1)}
                        isDisabled={page == totalPages}
                    >
                        Next &raquo;
                    </Button>
                </HStack>


                <ModalFooter>

                    <Button variant="ghost" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ViewSalesRepFacilitiesModal;
