import React from 'react';
import { Permissions } from '../../Utils'; // Import your grouped permissions
import { Checkbox, Table, Tbody, Tr, Td, Thead, Th, Box, Heading, Grid, GridItem } from '@chakra-ui/react';

const PermissionsTable = ({ selectedRole, setSelectedRole }) => {
    // Function to handle "Select All" for a category
    const handleSelectAll = (categoryPermissions, isChecked) => {
        const updatedPermissions = isChecked
            ? [...new Set([...selectedRole.permissions, ...Object.keys(categoryPermissions)])]
            : selectedRole.permissions.filter((perm) => !Object.keys(categoryPermissions).includes(perm));

        setSelectedRole({ ...selectedRole, permissions: updatedPermissions });
    };

    // Function to handle individual permission change
    const handlePermissionChange = (permissionKey, isChecked) => {
        const updatedPermissions = isChecked
            ? [...selectedRole.permissions, permissionKey]
            : selectedRole.permissions.filter((perm) => perm !== permissionKey);

        setSelectedRole({ ...selectedRole, permissions: updatedPermissions });
    };

    return (
        <Box>
            <Heading size="lg" mt={10}>Permissions</Heading>

            <Grid templateColumns="repeat(2, 1fr)" gap={6}>

                {/* Iterate through each category */}
                {Object.keys(Permissions).map((categoryKey) => {
                    const category = Permissions[categoryKey]; // Get the category object
                    const allSelected = Object.keys(category?.permissions).every((key) =>
                        selectedRole?.permissions?.includes(key)
                    );

                    return (
                        <GridItem key={categoryKey}>
                            <Box mb={8}>
                                <Heading size="md" mb={4} mt={10}>
                                    {category.name} {/* Render category name */}
                                    <Checkbox
                                        ml={3}
                                        isChecked={allSelected}
                                        onChange={(e) => handleSelectAll(category.permissions, e.target.checked)}
                                    >
                                        <small>Select All</small>
                                    </Checkbox>
                                </Heading>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Permission</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {/* Iterate through each permission in the category */}
                                        {Object.keys(category.permissions).map((permissionKey, inx) => (
                                            <Tr _hover={{ bg: "gray.100" }} key={permissionKey}>
                                                <Td>
                                                    <Checkbox
                                                        id={permissionKey}
                                                        isChecked={selectedRole.permissions.includes(permissionKey)}
                                                        onChange={(e) =>
                                                            handlePermissionChange(permissionKey, e.target.checked)
                                                        }
                                                        mr={3}
                                                    />
                                                    <label htmlFor={permissionKey}>
                                                        {(inx + 1) + category.permissions[permissionKey]} {/* Render permission name */}
                                                    </label>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        </GridItem>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default PermissionsTable;
