import { Box, FormControl, Input, Heading, Button, VStack, HStack, Text, Tbody, Table, Tr, Td, Image, Center } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { fetchBusinessByToken, submitBusinessForm } from '../../api';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { alert, Logo } from '../../Utils';
import SignatureModal from '../../components/Signature/SignatureModal';

const UpdateBusiness = () => {
    const { handleSubmit, register, setValue } = useForm();
    const [business, setBusiness] = useState(null); // Store fetched business data
    const { token } = useParams(); // Get the business ID from URL
    const [clientName, setClientName] = useState(''); // State to store client name
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    ////////////////Signatures///////////////
    const [tosSignature, setTosSignature] = useState(null);
    const [phiSignature, setPhiSignature] = useState(null);
    const [psdrSignature, setPsdrSignature] = useState(null);



    // Fetch the existing business details on page load
    useEffect(() => {
        fetchBusiness()
    }, [token]);


    const fetchBusiness = async () => {
        try {
            const fetchedBusiness = await fetchBusinessByToken(token);


            if (fetchedBusiness === "NO") {
                window.location.href = '../4oh4';
            } else {
                // setBusiness(businessData);
                populateForm(fetchedBusiness.data);
                setClientName(fetchedBusiness.data.clientDetails.clinicName)

                const { daysOfOperation, molecularTests, routineTests, supplies, ...rest } = fetchedBusiness.data;
                delete daysOfOperation._id;
                delete molecularTests._id;
                delete routineTests._id;
                delete supplies._id;

                setBusiness({
                    ...rest,
                    daysOfOperation,
                    molecularTests,
                    routineTests,
                    supplies
                });

                // setBusiness(fetchedBusiness);
                // setFormData(fetchedBusiness);
                setLoading(false);
            }


        } catch (err) {
            setError(true);
            setLoading(false);
        }
    };



    const populateForm = (businessData) => {
        // Use setValue to populate form fields with business data
        setValue('tosOrderingProviderName', businessData?.tos?.orderingProvider)
        setValue('clientName', businessData?.phi?.clientName)
        setValue('physicianName', businessData?.phi?.physicianName)
        setValue('psdrClientName', businessData?.psdr?.clientName)
        setValue('psdrPhysicianName', businessData?.psdr?.physicianName)
        setValue('psdrPhysicianNPI', businessData?.psdr?.physicianNPI)

        setLoading(false)
    };



    // Handle first form submission
    const onFirstFormSubmit = async (data) => {

        const formData = {
            tos: {
                orderingProvider: data.tosOrderingProviderName,
                tosSignature: tosSignature,
                orderingProviderDate: new Date()
            },
            phi: {
                clientName: data.clientName,
                physicianName: data.physicianName,
                phiSignature: phiSignature,
                physicianDate: new Date(),
                directorDate: new Date()

            },
            psdr: {
                clientName: data.psdrClientName,
                physicianName: data.psdrPhysicianName,
                physicianNPI: data.psdrPhysicianNPI,
                psdrSignature: psdrSignature,
                date: new Date()
            },
        };

        console.log('Grouped Form Data:', formData);
        await submitBusinessForm(token, formData)

        alert('Thank You', 'success', 'Thank you for completing your account<br>Your account manager has been notified and will reach out to you soon.', 'Okay !')

        // await createBusiness(formData);
        // alert("OK");
    };



    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }

    if (error) {
        alert('Oops !', 'error', "Invalid or Expired Link", null, false)
        return;
    }

    return (
        <>
            <Box maxW="full" mx="auto" p="8" align="stretch">
                <form onSubmit={handleSubmit(onFirstFormSubmit)}>

                    {/* Client Details */}
                    <VStack spacing={4} align="stretch">

                        <HStack style={{ borderBottom: "9px solid #f4b31b" }} pb={4} mb={6}>
                            <img width={150} src={Logo} />
                            <Heading ml="15%" color={'#253853'}>Account Details for {business?.clientDetails?.clinicName}</Heading>
                            <br />
                        </HStack>


                        <VStack spacing={4} align="stretch">
                            <>
                                <HStack mb={4}>
                                    <Text><strong>Client Name: </strong> {business?.clientDetails?.clinicName}</Text>
                                    <Text ml={'20%'}><strong>Client Start Date: </strong> {new Date(business.clientStartDate).toLocaleDateString()} </Text>
                                </HStack>


                                {/* Address and City */}
                                <HStack mb={4} spacing={10} flexDirection={['column', 'row']}>
                                    <Text><strong>Address: </strong>{business.clientDetails.address}</Text>
                                    <Text><strong>City: </strong>{business.clientDetails.city}</Text>
                                    <Text><strong>State: </strong>{business.clientDetails.state}</Text>
                                    <Text><strong>Zip: </strong>{business.clientDetails.zip}</Text>
                                </HStack>

                                {/* Phone, Fax, Email */}
                                <HStack mb={6} spacing={4} flexDirection={['column', 'row']}>
                                    <Text><strong>Phone:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.phone} </Text>
                                    </FormControl>
                                    <Text><strong>Fax:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.fax} </Text>
                                    </FormControl>
                                    <Text><strong>Email:</strong> </Text>
                                    <FormControl>
                                        <Text>{business.clientDetails.email} </Text>
                                    </FormControl>
                                </HStack>


                            </>
                        </VStack>

                        <hr />

                        {/* Providers */}
                        <Box mb={6}>
                            <Heading size="md" mt="6" mb={6}>Providers</Heading>
                            <VStack spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                {business?.providers?.length > 0 ? (

                                    <Table variant="simple">
                                        <Tbody>
                                            {business.providers.map((provider, index) => (
                                                <Tr key={index}>
                                                    <Td><strong>Name:</strong> {provider.name}</Td>
                                                    <Td><strong>License:</strong> {provider.license}</Td>
                                                    <Td><strong>NPI:</strong> {provider.npi}</Td>
                                                </Tr>

                                            ))}
                                        </Tbody>
                                    </Table>
                                ) : <Text>No providers available</Text>}
                            </VStack>
                        </Box>
                        <hr />
                        {/* Contacts */}

                        {/* Office Contacts Section */}
                        <Box mb={6}>
                            <Heading size="md" mt="6">Office Contacts</Heading>
                            <VStack spacing={4} align="stretch" mt="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                                <>
                                    {business?.officeContacts?.length > 0 ? (
                                        <>

                                            <Table variant="simple">
                                                <Tbody>
                                                    {business.officeContacts.map((contact, index) => (
                                                        <Tr key={index}>
                                                            <Td><strong>Name:</strong> {contact.name}</Td>
                                                            <Td><strong>Title:</strong> {contact.title}</Td>
                                                            <Td><strong>Phone:</strong> {contact.phone}</Td>
                                                        </Tr>

                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </>
                                    ) : <Text>No contacts available</Text>}
                                </>
                            </VStack>
                        </Box>

                        <hr />
                        {/* Days of Operation */}
                        <VStack mb={6} mt={6} spacing={6} align="stretch">
                            <Heading size="md">Days of Operation</Heading>
                            {Object.keys(business?.daysOfOperation).length > 0 ?

                                <Table variant="simple" mt={4}>
                                    <Tbody>
                                        <Tr>
                                            {Object.keys(business.daysOfOperation).map((day) => (
                                                <Td key={day} textAlign="center"><strong>{day}</strong></Td>
                                            ))}
                                        </Tr>
                                        <Tr>
                                            {Object.keys(business.daysOfOperation).map((day) => (
                                                <Td key={day} textAlign="center">
                                                    {business.daysOfOperation[day].from
                                                        ? `${business.daysOfOperation[day].from} - ${business.daysOfOperation[day].to}`
                                                        : 'Closed'}
                                                </Td>
                                            ))}
                                        </Tr>
                                    </Tbody>
                                </Table>
                                :
                                <Text>No Days of Operation Provided</Text>
                            }
                        </VStack>


                        <hr />
                    </VStack>



                    <br /><hr /><br />
                    {/* Terms of Service and Signature Display */}
                    <VStack spacing={4} align="stretch">
                        <>
                            <Heading size="lg" textAlign="center">Terms of Service</Heading>


                            <Text>
                                <Heading size="md">LABORATORY REQUISITIONS</Heading>

                                All ordering physicians must provide complete information, including the NPI # the first time a specimen is submitted. Once that physician is added to our database, the physician’s name, office location and Account # are sufficient.
                            </Text>

                            <Text>

                                <Heading size="md">BILLING INFORMATION</Heading>

                                Patients demographics are required on the first day of service. Once in our system, billing information (insurance, ID, Address) is saved for future days of service.
                            </Text>


                            <Text>

                                <Heading size="md">DIAGNOSIS</Heading>

                                All laboratory test requisitions MUST have an ICD-10 (diagnosis code) especially with Medicare patients according to their “medical necessity” (see attached grid) and clinical (blood).

                            </Text>

                            <Text>

                                <Heading size="md">CASH PATIENTS</Heading>

                                As a courtesy, this office provides insurance billing services at no extra charge. However, client/or patients are responsible for payment of all services rendered and we will look to you for payment if insurance information or patient address was not provided by client in a timely manner.

                            </Text>

                            <Text>

                                <Heading size="md">INSURANCE BILLING  </Heading>

                                (PPO, HMO, ETC): All billing will be done by DIAGNOSTIC LABORATORY SCIENCE. However, the client and /or patients are personally responsible for all applicable deductibles, co-insurance co-payments and services denied as not a covered benefit by your health plan carrier. All information required to bill patient’s insurance must be received in time to meet the 60- day timely filling deadline set by most insurances. Client and or patient will be responsible for information not received on time.
                            </Text>






                            <Text>

                                <Heading size="md"> WORKERS COMPENSATION</Heading>

                                When billing claims to workers compensation, insurance name, claim number, date of injury and adjuster’s information must be submitted with requisition form. The patients private health insurance is not billed for services that are covered by workers’ compensation.
                            </Text>


                            <Text>
                                <Heading size="md">ACKNOWLEDGMENT</Heading>

                                I understand that DIAGNOSTIC LABORATORY SCIENCE will submit patient’s claims to the patients insurance company as a courtesy at no extra cost but timely submission of patient’s insurance and/or patients address is the clients responsibility.
                            </Text>















                            <Text mt="4" fontWeight={'bold'} color={'red'}>
                                By signing below, I agree and acknowledge the Terms of Services for Diagnostic Laboratory Science
                            </Text>



                            <VStack align="stretch">
                                <>


                                    {!tosSignature &&
                                        <SignatureModal
                                            onSave={(data) => setTosSignature(data)}
                                            triggerButton={<Button>Sign TOS</Button>}
                                        />
                                    }
                                    {tosSignature &&
                                        <Box
                                            p="4"
                                            width="full">
                                            <Image mb={2} width={200} src={tosSignature} alt="Client Signature" />
                                            <Button size="sm" mt="2" onClick={() => setTosSignature(null)}>Clear Signature</Button>
                                        </Box>


                                    }




                                </>
                            </VStack>


                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Ordering Provider / Clinician</strong></Text>
                                    <Input {...register('tosOrderingProviderName', { required: true })} placeholder="Ordering Provider / Clinician" />

                                </VStack>

                                <VStack align="stretch" ml="3" flex="1">
                                    <Text><strong>Date</strong></Text>
                                    <Text>{new Date().toLocaleDateString()}</Text>
                                </VStack>
                            </HStack>

                        </>
                    </VStack>
                    <br /><hr /><br />
                    {/* PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENTPROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT */}
                    <VStack spacing={4} align="stretch">
                        <>


                            <Heading size="lg" textAlign="center">PROTECTED HEALTH INFORMATION (PHI) ACCESS AGREEMENT</Heading>

                            <Text>
                                This Privacy Agreement ("Agreement") is effective upon signing this Agreement and is entered by and between
                                <Text as="span" fontWeight="bold"> {clientName}</Text> ("Covered Entity")
                                and  <Text as="span" fontWeight="bold">Diagnostic Laboratory Science</Text> (the "Business Associate").
                            </Text>

                            <Heading size="md">1. Term</Heading>
                            <Text>
                                This Agreement shall remain in effect for the duration of this Agreement and shall apply to all the Services
                                and/or Supplies delivered by the Business Associate pursuant to this Agreement.
                            </Text>

                            <Heading size="md">2. HIPAA Assurances</Heading>
                            <Text>
                                In the event Business Associate creates, receives, maintains, or otherwise is exposed to personally identifiable
                                or aggregate patient or other medical information defined as Protected Health Information ("PHI") in the Health
                                Insurance Portability and Accountability Act of 1996 or its relevant regulations ("HIPAA") and otherwise meets
                                the definition of Business Associate as defined in the HIPAA Privacy Standards (45 CFR Parts 160 and 164),
                                Business Associate shall:
                            </Text>

                            <VStack align="start" spacing={2} pl="4">
                                <Text as="p">(a) Recognize that HITECH (the Health Information Technology for Economic and Clinical Health Act of 2009) and the regulations thereunder apply to a business associate;</Text>
                                <Text as="p">(b) Not use or further disclose the PHI, except as permitted by law;</Text>
                                <Text as="p">(c) Not use or further disclose the PHI in a manner that had <Text as="span" fontWeight="bold">{clientName}</Text> done so, would violate the requirements of HIPAA;</Text>
                                <Text as="p">(d) Use appropriate safeguards to protect the confidentiality, integrity, and availability of PHI;</Text>
                                <Text as="p">(e) Comply with each applicable requirement of 45 C.F.R. Part 162 if the Business Associate conducts Standard Transactions for or on behalf of the Covered Entity;</Text>
                                <Text as="p">(f) Report promptly to <Text as="span" fontWeight="bold">{clientName}</Text> any security incident or other use or disclosure of PHI;</Text>
                                <Text as="p">(g) Ensure that subcontractors or agents who receive PHI agree to the same restrictions;</Text>
                                <Text as="p">(h) Make available PHI in accordance with the individual’s rights as required under HIPAA regulations;</Text>
                                <Text as="p">(i) Account for PHI disclosures for up to the past six (6) years;</Text>
                                <Text as="p">(j) Make internal practices available to the U.S. Secretary of Health and Human Services;</Text>
                                <Text as="p">(k) Incorporate any amendments or corrections to PHI when notified by the Covered Entity.</Text>
                            </VStack>

                            <Heading size="md">3. Termination Upon Breach of Provisions</Heading>
                            <Text>
                                Covered Entity may immediately terminate this Agreement if Business Associate breaches any term. Alternatively,
                                the Covered Entity may give written notice and allow five (5) business days to cure such breach.
                            </Text>

                            <Heading size="md">4. Return or Destruction of Protected Health Information upon Termination</Heading>
                            <Text>
                                Upon termination, Business Associate shall return or destroy all PHI received. If infeasible, the terms of this Agreement
                                shall survive.
                            </Text>

                            <Heading size="md">5. No Third-Party Beneficiaries</Heading>
                            <Text>
                                The terms of this Agreement shall apply only to the parties and are not for the benefit of any third-party beneficiaries.
                            </Text>

                            <Heading size="md">6. De-Identified Data</Heading>
                            <Text>
                                Business Associate and its subcontractors may disclose non-personally identifiable information, provided the information
                                cannot be identified.
                            </Text>

                            <Heading size="md">7. Amendment</Heading>
                            <Text>
                                Business Associate and Covered Entity agree to amend this Agreement to comply with HIPAA or other relevant state
                                or federal laws.
                            </Text>

                            <Heading size="md">8. Interpretation</Heading>
                            <Text>
                                Any ambiguity in this Agreement shall be resolved in favor of compliance with HIPAA regulations.
                            </Text>

                            <Heading size="md">9. Definitions</Heading>
                            <Text>
                                Capitalized terms used in this Agreement shall have the meanings assigned to them in HIPAA.
                            </Text>

                            <Heading size="md">10. Survival</Heading>
                            <Text>
                                The obligations imposed by this Agreement shall survive any expiration or termination of this Agreement.
                            </Text>

                            <hr />
                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Name of Client/Clinic:</strong></Text>
                                    <Input placeholder="Enter Client/Clinic Name" {...register('clientName')} />
                                </VStack>

                                <VStack align="stretch" ml="3" flex="1">
                                    <Text><strong>Name of Laboratory:</strong></Text>
                                    <Text>Diagnostic Laboratory Science</Text>
                                </VStack>
                            </HStack>

                            {/* Name of Ordering Physician and Director of Laboratory Services Initials */}
                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Name of Ordering Physician:</strong></Text>
                                    <Input placeholder="Enter Physician Name" {...register('physicianName')} />
                                </VStack>

                                <VStack align="stretch" flex="1" ml="3">
                                    <Text><strong>Director of Laboratory Services CEO:</strong></Text>
                                    <Text>Firas Tamary, MS.MT (ASCP), Bioanalyst</Text>
                                </VStack>
                            </HStack>

                            {/* Ordering Physician Signature and Director of Laboratory Services Initials */}
                            <HStack>
                                {/* Ordering Physician Signature */}
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Ordering Physician Signature</strong></Text>


                                    {!phiSignature &&
                                        <SignatureModal
                                            onSave={(data) => setPhiSignature(data)}
                                            triggerButton={<Button>Sign PHI</Button>}
                                        />
                                    }
                                    {phiSignature &&
                                        <Box
                                            p="4"
                                            width="full">
                                            <Image mb={2} width={200} src={phiSignature} alt="Client Signature" />
                                            <Button size="sm" mt="2" onClick={() => setPhiSignature(null)}>Clear Signature</Button>
                                        </Box>


                                    }
                                </VStack>

                                {/* Director of Laboratory Services Initials */}
                                <VStack align="stretch" flex="1" ml={3}>
                                    <Text><strong>Director of Laboratory Services Signature</strong></Text>
                                    <Text>FT</Text>
                                </VStack>
                            </HStack>

                            {/* Date Fields */}
                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Date (Ordering Physician)</strong></Text>
                                    <Text>{new Date().toLocaleDateString()}</Text>
                                </VStack>

                                <VStack align="stretch" flex="1">
                                    <Text><strong>Date (Director of Laboratory Services)</strong></Text>
                                    <Text>{new Date().toLocaleDateString()}</Text>
                                </VStack>
                            </HStack>

                        </>
                    </VStack>
                    <br /><hr /><br />
                    {/* Physicians Signature and Date Requirements */}
                    <VStack spacing={4} align="stretch">

                        {/* Physicians signature and date requirements */}

                        <Heading size="lg" textAlign="center">
                            Physicians Signature and Date Requirements
                        </Heading>

                        <Text>
                            For medical review purposes, Medicare requires that the person(s) responsible for the care of the beneficiary, including providing/ordering/certifying items/services for the beneficiary, be identifiable as such in accordance with Medicare billing coverage policies, such as the Social Security Act §1815(a) and §1833(e). Medicare contractors shall consider the totality of the medical record when reviewing for compliance with the above. Signatures are required upon medical review for two distinct purposes:
                        </Text>

                        <Text>
                            <strong>1)</strong> To satisfy specific signature requirements in statute, regulation, national coverage determination (NCD), or local coverage determination (LCD).
                        </Text>

                        <Text>
                            <strong>2)</strong> To resolve authenticity concerns related to the legitimacy or falsity of the documentation.
                        </Text>

                        <Text>
                            CMS/Medicare requires that laboratory services provided/ordered be authenticated by the provider. CMS considers a laboratory order form as a legal document between the provider and the laboratory. As with any other legal document, the test order form shall be signed by the ordering provider. The signature for each entry must be legible and should include the provider's first and last name. For clarification purposes, CMS recommends that the applicable credentials (e.g., P.A., D.O., or M.D.) also be included. These credentials confirm that the ordering physician has certified the reasonableness of the service(s) submitted to the Medicare program for payment consideration. CMS also requires that the patient sign the laboratory order form, to ensure the patient understands and agrees with the tests ordered.
                        </Text>

                        <Text>
                            Physician signatures are significant audit targets at the Federal/State level and with Commercial payers. The absence of physician signatures in these audits has led to significant claw-backs by the payers and Medicare. Furthermore, not providing a physician's signature may result in having the physicians or the patient pay for the services rendered themselves. Your cooperation is a legal requirement as outlined in the Social Security Act, Medicare Section 1842(p)(4).
                        </Text>

                        <hr />
                        {/* Fields Section */}
                        <VStack spacing={4} align="stretch">
                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Client/Clinic Name</strong></Text>
                                    <Input
                                        placeholder="Enter Client/Clinic Name"
                                        {...register('psdrClientName', { required: false })}
                                    />
                                </VStack>


                            </HStack>

                            <HStack>
                                <VStack align="stretch" flex="1">
                                    <Text><strong>Physician/Practitioner Name</strong></Text>
                                    <Input
                                        placeholder="Enter Physician/Practitioner Name"
                                        {...register('psdrPhysicianName', { required: false })}
                                    />
                                </VStack>

                                <VStack align="stretch" flex="1">
                                    <Text><strong>Physician/Practitioner NPI</strong></Text>
                                    <Input
                                        placeholder="Enter NPI"
                                        {...register('psdrPhysicianNPI', { required: false })}
                                    />
                                </VStack>

                                <VStack align="stretch" flex="1" ml={2}>
                                    <Text><strong>Date</strong></Text>
                                    <Text>{new Date().toLocaleDateString()}</Text>
                                </VStack>
                            </HStack>



                            {/* Signature Section */}
                            <VStack spacing={4} align="stretch">


                                {!psdrSignature &&
                                    <SignatureModal
                                        onSave={(data) => setPsdrSignature(data)}
                                        triggerButton={<Button>Sign PHI</Button>}
                                    />
                                }
                                {psdrSignature &&
                                    <Box
                                        p="4"
                                        width="full">
                                        <Image mb={2} width={200} src={psdrSignature} alt="Client Signature" />
                                        <Button size="sm" mt="2" onClick={() => setPsdrSignature(null)}>Clear Signature</Button>
                                    </Box>


                                }


                            </VStack>


                        </VStack>

                    </VStack>


                    <br /><hr /><br />
                    <Button type="submit" colorScheme="blue" mt="6" width="full">Submit</Button>



                </form>


            </Box>

        </>

    );
};

export default UpdateBusiness;
