import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const ClientLayout = () => {
    return (
        <Box minHeight="100vh" p={4}>
            <Outlet /> {/* This will render the content of the complete-business page */}
        </Box>
    );
};

export default ClientLayout;
