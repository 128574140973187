import { HStack, Button } from '@chakra-ui/react';
import React from 'react';

const Pagination = React.memo(({ currentPage, totalPages, handlePageChange }) => {

    console.log({ currentPage });

    return (
        <HStack mt={4} justify="center">
            <Button
                onClick={() => handlePageChange(Number(currentPage) - 1)}
                isDisabled={currentPage == 1}
            >
                &laquo; Previous
            </Button>

            <Button
                onClick={() => handlePageChange(1)}
                colorScheme={currentPage == 1 ? 'teal' : 'gray'}
                isDisabled={currentPage == 1}
            >
                1
            </Button>

            {currentPage > 3 && <Button isDisabled>...</Button>}

            {Array.from({ length: 3 }, (_, i) => currentPage - 1 + i)
                .filter(page => page > 1 && page < totalPages)
                .map(page => (
                    <Button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        colorScheme={currentPage == page ? 'teal' : 'gray'}
                        isDisabled={currentPage == page}
                    >
                        {page}
                    </Button>
                ))}

            {currentPage < totalPages - 2 && <Button isDisabled>...</Button>}

            {totalPages > 1 && (
                <Button
                    onClick={() => handlePageChange(totalPages)}
                    colorScheme={currentPage == totalPages ? 'teal' : 'gray'}
                    isDisabled={currentPage == totalPages}
                >
                    {totalPages}
                </Button>
            )}

            <Button
                onClick={() => handlePageChange(Number(currentPage) + 1)}
                isDisabled={currentPage == totalPages}
            >
                Next &raquo;
            </Button>
        </HStack>
    );
});

export default Pagination;
