// SignaturePad.js
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Box } from '@chakra-ui/react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = forwardRef((props, ref) => {
    const signaturePadRef = useRef(null);

    useImperativeHandle(ref, () => ({
        clear: () => signaturePadRef.current && signaturePadRef.current.clear(),
        isEmpty: () => signaturePadRef.current && signaturePadRef.current.isEmpty(),
        getSignatureData: () =>
            signaturePadRef.current
                ? signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png')
                : null,
    }));

    return (
        <Box
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            p="2"
            flex="1"
            width="full"
        >
            <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 520, height: 250, className: 'sigCanvas' }}
                // canvasProps={{ style: { width: '100%', height: 300 } }}
                ref={signaturePadRef}
            />
        </Box>
    );
});

export default SignaturePad;
