import React, { useState, useEffect } from 'react';
import { createCategory, updateCategory, deleteCategory, fetchCategories } from '../../api';
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr, VStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, Text, Alert, AlertIcon, AlertDescription, Center, HStack, Heading } from '@chakra-ui/react';
import { CloseIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons';
import Loading from '../../components/Loading/Loading';
import TitleHeader from '../../components/TitleHeader';
import AccessDenied from '../../components/AccessDenied';
import PopoverId from '../../components/PopoverId';
import { hasPermission } from '../../Utils';

const CategoriesManagement = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [newCategory, setNewCategory] = useState({ name: '' });
    const [errorModal, setErrorModal] = useState(null);
    const [errorCategories, setErrorCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    // Fetch categories when the component mounts
    useEffect(() => {
        getAllCategories();
    }, []);

    const getAllCategories = async () => {
        setLoading(true);
        setErrorCategories(null)
        const result = await fetchCategories();
        if (result.success) {
            setCategories(result.data);
        } else {
            setErrorCategories(result.error)
        }
        setLoading(false);
    };

    // Handle category creation
    const handleCreateCategory = async () => {
        setErrorModal(null);
        if (!newCategory.name) {
            setErrorModal('Category name is required');
            return;
        }
        const result = await createCategory(newCategory);
        if (result.success) {
            getAllCategories();
            onCreateClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle category update
    const handleUpdateCategory = async () => {
        setErrorModal(null);
        if (!selectedCategory.name) {
            setErrorModal('Category name is required');
            return;
        }
        const result = await updateCategory(selectedCategory._id, selectedCategory);
        if (result.success) {
            getAllCategories();
            onEditClose();
        } else {
            setErrorModal(result.error);
        }
    };

    // Handle category deletion (soft delete)
    const handleDeleteCategory = async () => {
        const result = await deleteCategory(selectedCategory._id);
        if (result.success) {
            getAllCategories();
            onDeleteClose();
        } else {
            setErrorModal(result.error);
        }
    };

    const handleClose = (modalCloseFunc) => {
        setErrorModal(null);
        modalCloseFunc(); // Call the specific modal's onClose function
    };

    if (loading) {
        return (
            <Center mt={"20%"}>
                <Loading />
            </Center>
        );
    }


    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">

                <TitleHeader title={"Supplies Categories Management"} buttonText={hasPermission('createCategory') ? "New Category" : null} onClick={hasPermission('createCategory') ? onCreateOpen : null} />

                {errorCategories &&
                    <AccessDenied msg={errorCategories} />
                }

                {!errorCategories &&
                    <>
                        {categories.length === 0 ?

                            <AccessDenied msg={"Nothing here yet !"} icon={'info'} />

                            :
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th w={1}>#</Th>
                                        <Th width={"70%"}>Name</Th>
                                        <Th>Created At</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {categories.map((category, index) => (
                                        <Tr _hover={{ bg: "gray.100" }} key={category._id}>
                                            <Td><PopoverId place='Supplies Categories Management' index={index} content={category._id} /></Td>
                                            <Td>{category.name}</Td>
                                            <Td>{(new Date(category.createdAt)).toLocaleDateString()}</Td>
                                            <Td>
                                                <HStack spacing={4}>
                                                    {hasPermission('updateCategory') &&
                                                        <Button size="sm" colorScheme="green" onClick={() => {
                                                            setSelectedCategory(category);
                                                            onEditOpen();
                                                        }}>
                                                            <EditIcon mr={2} /> Edit
                                                        </Button>
                                                    }
                                                    {hasPermission('deleteCategory') &&
                                                        <Button size="sm" colorScheme="red" onClick={() => {
                                                            setSelectedCategory(category);
                                                            onDeleteOpen();
                                                        }}>
                                                            <CloseIcon mr={2} /> Delete
                                                        </Button>
                                                    }
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        }
                    </>
                }

                {/* Create Category Modal */}
                <Modal isOpen={isCreateOpen} onClose={() => handleClose(onCreateClose)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Supplies Category</ModalHeader>
                        <ModalCloseButton />
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <FormControl>
                                <Text>Supplies Category Name</Text>
                                <Input value={newCategory.name} onChange={(e) => setNewCategory({ name: e.target.value })} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={handleCreateCategory}>Create</Button>
                            <Button ml={3} onClick={() => handleClose(onCreateClose)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Category Modal */}
                <Modal isOpen={isEditOpen} onClose={() => handleClose(onEditClose)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Supplies Category</ModalHeader>
                        <ModalCloseButton />
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalBody>
                            <FormControl>
                                <Text>Supplies Category Name</Text>
                                <Input value={selectedCategory?.name} onChange={(e) => setSelectedCategory({ ...selectedCategory, name: e.target.value })} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="green" onClick={handleUpdateCategory}>Save</Button>
                            <Button ml={3} onClick={() => handleClose(onEditClose)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal isOpen={isDeleteOpen} onClose={() => handleClose(onDeleteClose)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Delete Supplies Category</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>Are you sure you want to delete the Supplies category "{selectedCategory?.name}"?</ModalBody>
                        {errorModal && <Alert status='error'><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}
                        <ModalFooter>
                            <Button colorScheme="red" onClick={handleDeleteCategory}>Yes, Delete</Button>
                            <Button ml={3} onClick={() => handleClose(onDeleteClose)}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </VStack>
        </Box>
    );
};

export default CategoriesManagement;
