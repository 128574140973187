import { useState, useEffect } from 'react';
import { Text, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Box, VStack, Table, Thead, Tbody, Tr, Th, Td, Heading, Center, Spinner, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, HStack } from '@chakra-ui/react';
import { fetchDeletedBusinesses, fetchDeletedUsers, fetchDeletedSalesReps, fetchDeletedRoles, restoreItem, fetchDeletedSuppliesCategory, fetchDeletedSuppliesItems, fetchDeletedOrders, fetchDeletedLabLocations, fetchDeletedFacilities } from '../../api';  // These will be the API functions to fetch each data
import AccessDenied from '../../components/AccessDenied'
import { IoMdShareAlt, IoMdInformationCircle } from "react-icons/io";
import Loading from '../../components/Loading/Loading';
import { alert, hasPermission } from '../../Utils';
import PopoverId from '../../components/PopoverId';


const TrashPage = () => {
    const [activeTab, setActiveTab] = useState(0); // To track which tab is active
    const [data, setData] = useState(null);        // To store the fetched data
    const [loading, setLoading] = useState(false); // Loading state for data fetching
    const [error, setError] = useState(null);      // Error state
    const [errorModal, setErrorModal] = useState(null);

    const { isOpen: isRestoreModalOpen, onOpen: onRestoreOpen, onClose: onRestoreClose } = useDisclosure();
    const [selectedItemToRestore, setSelectedItemToRestore] = useState(null);  // Store the selected item to restore

    const { isOpen: isDetailsModalOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();
    const [selectedItemDetails, setSelectedItemDetails] = useState(null);  // Store selected item details


    // Fetch data based on active tab
    useEffect(() => {
        fetchData();
    }, [activeTab]);

    const fetchData = async () => {
        setLoading(true);
        setError(null); // Reset error state before fetching
        try {
            let result;

            if (activeTab === 0) {
                result = await fetchDeletedBusinesses();
            } else if (activeTab === 1) {
                result = await fetchDeletedUsers();
            } else if (activeTab === 2) {
                result = await fetchDeletedSalesReps();
            } else if (activeTab === 3) {
                result = await fetchDeletedRoles();
            } else if (activeTab === 4) {
                result = await fetchDeletedSuppliesCategory();
            } else if (activeTab === 5) {
                result = await fetchDeletedSuppliesItems();
            } else if (activeTab === 6) {
                result = await fetchDeletedOrders();
            } else if (activeTab === 7) {
                result = await fetchDeletedLabLocations();
            } else if (activeTab === 8) {
                result = await fetchDeletedFacilities();
            }


            if (result.success) {
                if (activeTab === 8) {
                    setData(result.data.facilities);
                } else {
                    setData(result.data);
                }
            } else {
                setError(result.error); // Set error if something goes wrong
            }
        } catch (err) {
            setError('Something went wrong while fetching data');
        }

        setLoading(false);
    };

    // Tab Titles
    const tabs = ['Business', 'Users', 'Sales Reps', 'Roles', 'Supplies Categories', 'Supplies Items', "Clients Orders", "Labs", 'Facilities'];

    const hpr = (tap) => {
        switch (tap) {
            case 0:
                return hasPermission("restoreBusiness");
            case 1:
                return hasPermission("restoreUser");
            case 2:
                return hasPermission("restoreSalesRep");
            case 3:
                return hasPermission("restoreRole");
            case 4:
                return hasPermission("restoreCategory");
            case 5:
                return hasPermission("restoreSupply");
            case 6:
                return hasPermission("restoreOrder");
            case 7:
                return hasPermission("restoreLabLocation");
            case 8:
                return hasPermission("restoreFacilities")
            default:
                break;
        }
    }


    const handleRestore = async (item) => {
        let type;

        // Determine the type based on the active tab
        if (activeTab === 0) type = 'businesses';
        else if (activeTab === 1) type = 'users';
        else if (activeTab === 2) type = 'sales-reps';
        else if (activeTab === 3) type = 'roles';
        else if (activeTab === 4) type = 'supplies-categories';
        else if (activeTab === 5) type = 'supplies-items';
        else if (activeTab === 6) type = 'client-orders';
        else if (activeTab === 7) type = 'labs';
        else if (activeTab === 8) type = 'facilities';

        try {
            const response = await restoreItem(type, item._id);  // Pass the determined type and item ID
            if (response.success) {
                alert('Restored', 'success', 'Item restored successfully', 'Okay !');
                fetchData();  // Re-fetch deleted items to refresh the list
                onRestoreClose();  // Close the modal after restore
            } else {
                onRestoreClose();
                alert('Oops !', 'error', response.error, 'Okay !');
            }
        } catch (error) {
            console.error('Error restoring item:', error);
        }
    };

    if (!hasPermission('viewTrash')) {
        return (
            <AccessDenied msg={("Access Denied.")} />
        )
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <Heading mb={6}>Trash</Heading>
            <Tabs onChange={(index) => setActiveTab(index)}>
                <TabList>
                    {tabs.map((tab) => (
                        <Tab key={tab}>{tab}</Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {/* Tab Content */}
                    <TabPanel>
                        {loading ? (
                            <Center mt={"20%"}>
                                <Loading />
                            </Center>
                        ) : error ? (
                            <Center mt={5}>
                                <AccessDenied msg={error} />
                            </Center>
                        ) : data && data.length > 0 ? (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th width={1}>#</Th>
                                        <Th width={"50%"}>Item Name</Th>
                                        <Th>Deleted By</Th>
                                        <Th>Deleted At</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.map((item, index) => (
                                        <Tr key={item._id} _hover={{ bg: "gray.100" }}>
                                            <Td><PopoverId place={`Trash Page - ${tabs[activeTab]}`} index={index} content={item._id} /></Td>
                                            <Td>{item.firstName || item.clientDetails?.clinicName || 'N/A'}</Td>
                                            <Td>{item?.deletedBy?.firstName ? item?.deletedBy?.firstName : "N/A"}</Td>
                                            <Td>{new Date(item.deletedAt).toLocaleString()}</Td>
                                            <Td>

                                                <HStack spacing={4}>
                                                    {hpr(0) &&
                                                        <Button size="sm" onClick={() => {
                                                            setSelectedItemToRestore(item);  // Set the item to restore
                                                            onRestoreOpen();
                                                        }} colorScheme="green">
                                                            <IoMdShareAlt mr={3} /> <Text ml={2}>Restore</Text>
                                                        </Button>
                                                    }
                                                    <Button size="sm" onClick={() => {
                                                        setSelectedItemDetails(item);  // Set the item details
                                                        onDetailsOpen();
                                                    }} colorScheme="blue">
                                                        <IoMdInformationCircle mr={2} /> <Text ml={2}>View Details</Text>

                                                    </Button>
                                                </HStack>

                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <Box mt={10}>
                                <AccessDenied msg={"No deleted items yet !"} icon="info" />
                            </Box>
                        )}
                    </TabPanel>

                    {/* Similar TabPanels for other tabs */}
                    {tabs.slice(1).map((tab, index) => (
                        <TabPanel key={tab}>
                            {/* Re-use the same structure for each tab */}
                            {loading ? (
                                <Center mt={"20%"}>
                                    <Loading />
                                </Center>
                            ) : error ? (
                                <Center mt={5}>
                                    <AccessDenied msg={error} />
                                </Center>
                            ) : data && data.length > 0 ? (
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th w={1}>#</Th>
                                            <Th width={"50%"}>Item Name</Th>
                                            <Th>Deleted By</Th>
                                            <Th>Deleted At</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.map((item, index) => (
                                            <Tr key={item._id} _hover={{ bg: "gray.100" }}>
                                                <Td><PopoverId place={`Trash Page - ${tabs[activeTab]}`} index={index} content={item._id} /></Td>
                                                <Td>{
                                                    item.name || item.clientName || (item.firstName + " " + item.lastName) || item.clientDetails?.clinicName || 'N/A'
                                                }</Td>
                                                <Td>{item?.deletedBy?.firstName || "N/A"}</Td>
                                                <Td>{new Date(item.deletedAt).toLocaleString()}</Td>

                                                <Td>
                                                    <HStack spacing={4}>
                                                        {hpr(index + 1) &&
                                                            <Button size="sm" onClick={() => {
                                                                setSelectedItemToRestore(item);  // Set the item to restore
                                                                onRestoreOpen();
                                                            }} colorScheme="green">
                                                                <IoMdShareAlt mr={3} /> <Text ml={2}>Restore</Text>
                                                            </Button>
                                                        }
                                                        <Button size="sm" onClick={() => {
                                                            setSelectedItemDetails(item);  // Set the item details
                                                            onDetailsOpen();
                                                        }} colorScheme="blue">
                                                            <IoMdInformationCircle mr={2} /> <Text ml={2}>View Details</Text>

                                                        </Button>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            ) : (
                                <Box mt={10}>
                                    <AccessDenied msg={"No deleted items yet !"} icon="info" />
                                </Box>
                            )}
                        </TabPanel>
                    ))}
                </TabPanels>

                <Modal isOpen={isRestoreModalOpen} onClose={onRestoreClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Confirm Restore</ModalHeader>
                        <ModalBody>
                            <Text>Are you sure you want to restore this item?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={() => handleRestore(selectedItemToRestore)}>
                                Yes, Restore
                            </Button>
                            <Button onClick={onRestoreClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isDetailsModalOpen} scrollBehavior='inside' onClose={onDetailsClose} size="lg">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Item Details</ModalHeader>
                        <ModalBody>
                            {selectedItemDetails && (
                                <Box>
                                    {Object.entries(selectedItemDetails).map(([key, value]) => (
                                        <Text key={key}><strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</Text>
                                    ))}
                                </Box>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onDetailsClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Tabs>
        </Box >
    );
};

export default TrashPage;
