import React, { useState } from 'react';
import { Box, FormControl, Textarea, Button, VStack, Text, useToast, Heading } from '@chakra-ui/react';
import AccessDenied from '../../components/AccessDenied';
import { addPickupNote } from '../../api';

const PickupNotes = ({ pickupId, existingNotes = [], addNote = true }) => {
    const [noteText, setNoteText] = useState('');
    const [notes, setNotes] = useState(existingNotes);
    const toast = useToast();

    const handleAddNote = async () => {
        if (!noteText) {
            toast({
                title: "Note text is required.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const newNote = await addPickupNote(pickupId, noteText);
            console.log(newNote)
            setNotes([newNote.data, ...notes]);  // Add the new note to the notes array
            setNoteText('');  // Clear the input field
            toast({
                title: "Note added successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Failed to add note.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            {addNote &&
                <VStack spacing={4} align="stretch" mb={5}>
                    <FormControl>
                        <Heading mb={5} size='md' as='h5' color="gray.400">Pickup Notes</Heading>
                        <Textarea
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            placeholder="Enter note text"
                            resize="vertical"
                        />
                    </FormControl>
                    <Box alignSelf="flex-end">
                        <Button onClick={handleAddNote} colorScheme="blue" size="sm">
                            Add Note
                        </Button>
                    </Box>
                </VStack>
            }

            <Box mt={4}>
                {notes.length === 0 ? (
                    <AccessDenied msg={"No Notes on this Pickup"} icon='info' />
                ) : (
                    <VStack align="start" spacing={3}>
                        {notes.map((note, index) => (
                            <Box key={index} p={3} borderWidth="1px" borderRadius="md" w="full">
                                <Text fontSize="sm" mt={3}>{note.noteText}</Text>
                                <Text fontSize="xs" mt={5} color="gray.500">
                                    {new Date(note.createdAt).toLocaleString()} by {note?.addedBy?.firstName || "The Courier"}
                                </Text>
                            </Box>
                        ))}
                    </VStack>
                )}
            </Box>
        </Box>
    );
};

export default PickupNotes;
